<div class="surveys-element-pharmacy-list">
    <div class="placeholders" *ngIf="!dataLoaded">
        <surveys-element-pharmacy-placeholder></surveys-element-pharmacy-placeholder>
        <surveys-element-pharmacy-placeholder></surveys-element-pharmacy-placeholder>
        <surveys-element-pharmacy-placeholder></surveys-element-pharmacy-placeholder>
    </div>

    <div class="empty-state" *ngIf="dataLoaded && (!pharmacies || !pharmacies.length)">
        <div class="icon">
            <img src="https://revel.imgix.net/clients/humana/web/images/no_locations.svg" alt="No locations" />
        </div>
        <div class="heading"><h1>Uh Oh! We can’t find any locations in this area.</h1></div>
        <div class="zoom">Zoom out to search a wider area or</div>
        <div class="zoom action" (click)="enteringZipCode.emit()">Enter a new Zip Code</div>
    </div>

    <surveys-element-pharmacy *ngFor="let pharmacy of pharmacies" [pharmacy]="pharmacy"> </surveys-element-pharmacy>
</div>
