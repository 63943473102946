import {HttpErrorResponse} from '@angular/common/http';

export function parseMemberDirectErrors(error: HttpErrorResponse): string {
    switch (error.status) {
        case 401:
            return 'You do not appear to be authorized to view this survey. Please try again or contact your provider for assistance.';
        case 500:
            return 'We have experienced an error and are working quickly to fix it. Please try your request later and we apologize for the inconvenience.';
        default:
            return `We can't find the page you're looking for.`;
    }
}
