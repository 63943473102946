/* istabul ignore file */
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {SurveysElementsState} from './elements.interfaces';
import {ElementsActionTypes} from './elements.actions';
import {elementsInitialState} from './elements.init';

export const getElementId = (element: SurveyElement): string => element.id;

export const elementsAdapter: EntityAdapter<SurveyElement> = createEntityAdapter<SurveyElement>({
    selectId: getElementId
});

export function elementsReducer(state: SurveysElementsState, action: any): SurveysElementsState {
    switch (action.type) {
        // todo | fix this terrible thing https://healthtel.atlassian.net/browse/SB-993
        case ElementsActionTypes.RESET:
            return {
                ...elementsInitialState,
                hidden: state.hidden,
                idOfSelectedEntity: state.idOfSelectedEntity
            };

        case ElementsActionTypes.ELEMENTS_LOADED:
            return elementsAdapter.upsertMany(action.payload, state);

        case ElementsActionTypes.HIDE_ELEMENTS:
            return {...state, hidden: [...state.hidden, ...action.payload]};

        case ElementsActionTypes.HIDE_ELEMENT:
            // @ts-ignore
            if (state.hidden.includes(action.payload)) return state;

            return {
                ...state,
                hidden: [...state.hidden, action.payload as string]
            };

        case ElementsActionTypes.SHOW_ELEMENT:
            if (state.hidden.length === 0) return state;

            return {
                ...state,
                hidden: [...state.hidden].filter((val) => val !== action.payload)
            };

        case ElementsActionTypes.HIGHLIGHT_ELEMENT:
        case ElementsActionTypes.HIGHLIGHT_ELEMENT_WITHOUT_SCROLL:
            return {...state, idOfSelectedEntity: action.payload as string};

        case ElementsActionTypes.REHYDRATE:
            return {
                ...state,
                idOfSelectedEntity: action.payload.idOfSelectedEntity || elementsInitialState.idOfSelectedEntity
            };

        default:
            return state;
    }
}

export const getIdOfSelectedEntity = (state: SurveysElementsState) => state.idOfSelectedEntity;
export const getHidden = (state: SurveysElementsState) => state.hidden;
