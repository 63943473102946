import {Theme} from '@clients/member-direct/surveys';

export const memberDirectLibConfig: {defaultTheme: Theme} = {
    defaultTheme: {
        variables: {
            black: '#000000',
            blue: '#0071bc',
            cyan: '#30ccd1',
            darkGrey: '#585858',
            green: '#2e8540',
            lightGrey: '#f3f5f6',
            violet: '#ae3783'
        },
        colors: {
            primary: '--var-blue',
            secondary: '--var-cyan'
        },
        typography: {
            body: {
                color: '--var-black',
                fontSize: '24px',
                fontWeight: 300,
                lineHeight: '1.5',
                margin: '0 0 10px 0',
                mobile: {
                    fontSize: '16px',
                    color: '--var-black'
                }
            },
            a: {
                color: '--var-blue',
                textDecoration: 'none'
            },
            small: {
                color: '--var-black',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '1.29',
                mobile: {
                    color: '--var-black'
                }
            },
            h1: {
                color: '--var-black',
                fontSize: '48px',
                fontWeight: 300,
                lineHeight: '1.05',
                margin: '0',
                padding: '0',
                mobile: {
                    fontSize: '24px',
                    lineHeight: '1.2'
                }
            },
            h2: {
                color: '--var-black',
                fontSize: '29px',
                fontWeight: 400,
                lineHeight: '1.2',
                margin: '0',
                padding: '0',
                mobile: {
                    fontSize: '18px'
                }
            },
            h3: {
                color: '--var-black',
                fontSize: '48px',
                fontWeight: 400,
                lineHeight: '1.4',
                margin: '0',
                padding: '0'
            },
            h4: {
                color: '--var-black',
                fontSize: '48px',
                fontWeight: 400,
                lineHeight: '1.4',
                margin: '0',
                padding: '0'
            },
            disclaimer: {
                fontSize: '12px',
                lineHeight: '1.2'
            }
        },
        elements: {
            accordion: {
                label: {
                    color: '--var-black',
                    fontSize: '36px',
                    fontWeight: 300,
                    lineHeight: '1.3',
                    mobile: {
                        fontSize: '18px',
                        lineHeight: '1.2'
                    }
                },
                content: {
                    padding: '20px'
                },
                icon: {
                    paddingTop: '20px',
                    mobile: {
                        paddingTop: '5px'
                    }
                }
            },
            accordionGroup: {
                marginAfterAccordions: '20px',
                mobile: {
                    marginAfterAccordions: '10px'
                }
            },
            address: {
                accentOpacity: '0.87'
            },
            buttons: {
                general: {
                    border: '1px solid --var-blue',
                    color: '--var-blue',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '1',
                    margin: '0',
                    padding: '8px 14px',
                    backgroundColor: '#fff',
                    opacity: '1',
                    __hover: {
                        backgroundColor: '--var-blue',
                        color: '#fff'
                    },
                    __selected: {}
                },
                affirmative: {
                    border: '1px solid --var-blue',
                    color: '--var-blue',
                    fontSize: '16px',
                    fontWeight: 400,
                    letterSpacing: '0',
                    lineHeight: '1',
                    margin: '0',
                    padding: '8px 14px',
                    backgroundColor: '#fff',
                    opacity: '1',
                    __hover: {
                        backgroundColor: '--var-blue',
                        color: '#fff',
                        opacity: '1'
                    },
                    __selected: {
                        backgroundColor: '--var-blue',
                        opacity: '1',
                        color: '#fff'
                    }
                },
                selection: {
                    border: '1px solid --var-lightGrey',
                    color: '--var-blue',
                    fontSize: '16px',
                    fontWeight: 400,
                    letterSpacing: '0',
                    lineHeight: '1',
                    margin: '0',
                    padding: '8px 14px',
                    backgroundColor: '--var-lightGrey',
                    opacity: '1',
                    __hover: {
                        border: '1px solid --var-lightGrey',
                        backgroundColor: '#fff',
                        color: '--var-blue',
                        opacity: '1'
                    },
                    __selected: {
                        backgroundColor: '--var-blue',
                        color: '#fff'
                    }
                },
                negative: {
                    border: '1px solid --var-blue',
                    color: '--var-blue',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '1',
                    margin: '0',
                    padding: '8px 14px',
                    backgroundColor: '#fff',
                    opacity: '1',
                    __hover: {
                        backgroundColor: '--var-blue',
                        color: '#fff'
                    },
                    __selected: {
                        backgroundColor: '--var-blue',
                        color: '#fff'
                    }
                },
                subtext: {
                    color: '--var-black',
                    fontSize: '14px',
                    fontWeight: 400,
                    letterSpacing: '0',
                    lineHeight: '1.4',
                    margin: '0',
                    padding: '0'
                }
            },
            input: {
                color: '--var-black'
            },
            panel: {
                backgroundColor: '--var-lightGrey',
                textAlign: 'left',

                p: {
                    color: '--var-black',
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '1.2',

                    margin: '0',
                    padding: '12px'
                }
            },
            pharmacyFinder: {
                accentColor: '--var-violet',
                accentOpacity: '0.87',
                changeLocationLinkColor: '--var-black',
                savingsPill: {
                    backgroundColor: '--var-green'
                },
                networkPill: {
                    backgroundColor: '--var-blue'
                },
                distancePill: {
                    backgroundColor: '--var-darkGrey',
                    color: '#fff'
                }
            },
            quote: {
                body: {
                    color: '--var-black',
                    fontSize: '14px',
                    margin: '0 0 20px 0',
                    lineHeight: '1.4rem'
                },
                name: {
                    fontSize: '15px',
                    fontWeight: 600,
                    margin: '0 0 8px 0',
                    lineHeight: '1'
                },
                title: {
                    fontSize: '15px',
                    lineHeight: '1'
                }
            },
            stats: {
                main: {
                    color: '--var-blue',
                    fontSize: '64px',
                    fontWeight: 800,
                    letterSpacing: '0',
                    lineHeight: '1',
                    margin: '0 0 5px 0',
                    padding: '0',
                    mobile: {
                        fontSize: '40px'
                    }
                },
                subtext: {
                    color: '--var-black',
                    fontSize: '14px',
                    fontWeight: 300,
                    letterSpacing: '0',
                    lineHeight: '1.5',
                    margin: '0',
                    padding: '0'
                }
            },
            yesNo: {
                small: {
                    fontWeight: 600,
                    fontSize: '18px',
                    margin: '0 0 20px 0',
                    color: '#222'
                },
                large: {
                    fontWeight: 300,
                    fontSize: '36px',
                    margin: '0 0 10px 0',
                    lineHeight: '1.2',
                    mobile: {
                        fontSize: '24px'
                    }
                }
            }
        },
        icons: {
            color: '--var-black',
            fill: '--var-black'
        }
    }
};
