import {Directive, Input} from '@angular/core';

@Directive()
export class ElementBaseComponent {
    @Input()
    public xsWidth? = 100;
    @Input()
    public smWidth? = 100;
    @Input()
    public mdWidth? = 100;
    @Input()
    public lgWidth? = 100;
    @Input()
    public xlgWidth? = 100;

    @Input()
    public hideXs? = false;
    @Input()
    public hideSm? = false;
    @Input()
    public hideMd? = false;
    @Input()
    public hideLg? = false;
}
