import {Component, Input, OnChanges} from '@angular/core';
import {SurveyElementType} from '../../enum/SurveyElementType';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {isNotNullOrUndefined} from '@clients/shared/utilities';

@Component({
    selector: 'surveys-render-survey-element',
    templateUrl: './render-survey-element.component.html',
    styleUrls: ['./render-survey-element.component.scss']
})
export class RenderSurveyElementComponent implements OnChanges {
    @Input() public element: Partial<SurveyElement>;
    @Input() public multipartQuestionHeader: string;
    @Input() public matchChildHeight: boolean;
    @Input() public multipartQuestionId: string;

    public types = SurveyElementType;
    public elementIsDynamic: boolean;

    public ngOnChanges() {
        this.elementIsDynamic = this.element && isNotNullOrUndefined(this.element.dynamicContentId);
    }
}
