import {Component, Injector, Input, OnInit} from '@angular/core';
import {ISurveyImage} from '../../../models/survey/ISurveyContent';
import {BaseElement} from '../../base-element.abstract';
import {get} from 'lodash';

export interface IImageWrapperStyles {
    height: string;
    background: string;
    'background-size': string;
}

export interface IImageStyles {
    width: string;
    'max-width': string;
    'max-height': string;
}

@Component({
    selector: 'surveys-element-image',
    templateUrl: './element-image.component.html',
    styleUrls: ['./element-image.component.scss']
})
export class ElementImageComponent extends BaseElement implements OnInit {
    @Input()
    public element: ISurveyImage;
    public wrapperStyles: any;
    public imageStyles: any;

    /* istanbul ignore file */
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (this.imageShouldRenderAsBackground(this.element)) {
            this.wrapperStyles = this.getWrapperStyles(this.element, this.language);
        } else {
            this.imageStyles = this.getImageStyles(this.element);
        }
    }

    private getWrapperStyles(element: ISurveyImage, language: string): IImageWrapperStyles {
        const backgroundPosition: string = this.getBackgroundPosition(element);

        return {
            height: '100%',
            background: `url(${element.data[language]}) no-repeat ${backgroundPosition}`,
            'background-size': 'cover'
        };
    }

    private getBackgroundPosition(element: ISurveyImage): string {
        const elementDefinedBackgroundPositionX = get(element, 'config.backgroundPositionX') || 'center';
        const elementDefinedBackgroundPositionY = get(element, 'config.backgroundPositionY') || 'center';

        return `${elementDefinedBackgroundPositionX} ${elementDefinedBackgroundPositionY}`;
    }

    private getImageStyles(element: ISurveyImage): IImageStyles {
        const elementDefinedWidth = get(element, 'config.width');
        const elementDefinedHeight = get(element, 'config.height');
        const elementCustomStyles = get(element, 'config.customStyles') || {};

        const styles = {
            width: elementDefinedHeight ? 'auto' : '100%',
            'max-width': elementDefinedWidth ? elementDefinedWidth : '100%',
            'max-height': elementDefinedHeight ? elementDefinedHeight : '100%'
        };

        return {
            ...styles,
            ...elementCustomStyles
        };
    }

    private imageShouldRenderAsBackground(element: ISurveyImage): boolean {
        return get(element, 'config.renderAsBackground') === true;
    }
}
