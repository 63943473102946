import {get} from 'lodash';
import {Observable, of} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {MultilingualData} from '@clients/member-direct/translation';
import {ENV_SERVICE} from '@clients/shared/config';
import {arrayIsNullUndefinedOrEmpty, isNotNullOrUndefined} from '@clients/shared/utilities';

import {SurveyContent} from '../+state/survey-content/survey-content.model';
import {
    ISurveyAccordion,
    ISurveyAccordionGroup,
    ISurveyContainer,
    ISurveyHtml,
    ISurveyMultipartQuestion,
    ISurveyRow,
    ISurveyRowElement
} from '../../models/survey/ISurveyContent';
import {SurveyElement} from '../../models/survey/ISurveyElement';
import {ISurveyElementYesNo, ISurveyMultipleChoice, ISurveyQuestion} from '../../models/survey/ISurveyQuestion';
import {SurveyElementType} from '../enum/SurveyElementType';

const endpoints = (api: string) => ({
    customContent: (surveyRecordId: string) => `${api}/surveys/${surveyRecordId}/dynamic-content`
});

@Injectable({
    providedIn: 'root'
})
export class SurveyContentService {
    /**
     * Mapping functions to iterate over survey element types and access nested content.
     */
    private elementNestedContentMap = {
        [SurveyElementType.ACCORDION]: (element: ISurveyAccordion): SurveyElement[] => get(element, 'data.content'),
        [SurveyElementType.ACCORDION_GROUP]: (element: ISurveyAccordionGroup): ISurveyAccordion[] =>
            get(element, 'data.content'),
        [SurveyElementType.CONTAINER]: (element: ISurveyContainer): SurveyElement[] => get(element, 'data'),
        [SurveyElementType.MULTIPLE_CHOICE]: (element: ISurveyMultipleChoice): SurveyElement[] =>
            element.content || element.elements,
        [SurveyElementType.MULTIPART_QUESTION]: (element: ISurveyMultipartQuestion): SurveyElement[] => element.data,
        [SurveyElementType.ROW]: (element: ISurveyRow): SurveyElement[] => {
            if (!arrayIsNullUndefinedOrEmpty(element.data)) {
                return element.data.map((rowElement: ISurveyRowElement | SurveyElement): any =>
                    this.isLegacyRowElement(rowElement) ? rowElement : (rowElement as ISurveyRowElement).element
                );
            }

            return [];
        },
        [SurveyElementType.SURVEY_QUESTION]: (element: ISurveyQuestion<any>): SurveyElement[] => [element.question],
        [SurveyElementType.YES_NO]: (element: ISurveyElementYesNo): SurveyElement[] =>
            element.content || element.elements
    };

    /**
     * This list of functions defines how content for each element type
     * should be replaced. In order for an element to even support having
     * dynamic content you must define a mapping function in this object.
     */
    private elementContentReplacementMap = {
        // Accordion Group
        [SurveyElementType.ACCORDION_GROUP]: (
            element: ISurveyAccordionGroup,
            dynamicContent: ISurveyAccordion[]
        ): ISurveyAccordionGroup => ({
            ...element,
            data: {
                ...element.data,
                content: dynamicContent
            }
        }),

        // Container
        [SurveyElementType.CONTAINER]: (
            element: ISurveyContainer,
            dynamicContent: Array<SurveyElement[]>
        ): ISurveyContainer => ({
            ...element,
            data: dynamicContent[0]
        }),

        // HTML
        [SurveyElementType.HTML]: (element: ISurveyHtml, dynamicContent: MultilingualData<string>[]): ISurveyHtml =>
            ({
                ...element,
                data: dynamicContent[0]
            } as ISurveyHtml)
    };

    private api: string;
    private pureDevMode: boolean;
    /* istanbul ignore next */
    constructor(@Inject(ENV_SERVICE) envService: MemberDirectEnvService, private http: HttpClient) {
        this.api = envService.surveyServiceApi;
        this.pureDevMode = envService.surveySettings?.pureDevMode;
    }

    /**
     * Returns the ids of all of the dynamic content regions that need to be
     * loaded for this set of elements.
     */
    public getCustomContentIds(elements: SurveyElement[]): string[] {
        return this.extractCustomContentIds(elements);
    }

    /**
     * Pass an empty array for `types` to get back all types.
     */
    public getElementsByType(elements: SurveyElement[], types: SurveyElementType[] = []): SurveyElement[] {
        return this.extractElementsByType(elements, types);
    }

    public dynamicElementTypeIsValid(elementType: SurveyElementType): boolean {
        return isNotNullOrUndefined(this.elementContentReplacementMap[elementType]);
    }

    /**
     * Replaces the content of an element with the dynamic content
     * that was loaded from the server.
     */
    public replaceElementContent(element: SurveyElement, content: SurveyContent): SurveyElement {
        // create a new version of the element with its data replaced
        const elementWithContentReplaced = this.elementContentReplacementMap[element.type](element, content.data);
        // remove the dynamic content ID. we absolutely must do this
        // because otherwise we will enter an infinite loop.
        delete elementWithContentReplaced.dynamicContentId;

        // return transformed element to the result
        return elementWithContentReplaced;
    }

    /**
     * Returns true if the element used in the data property of a row
     * is of the legacy type (where it's just the element) or the modern
     * type where the config and element properties were split.
     */
    public isLegacyRowElement(element: ISurveyRowElement | SurveyElement): boolean {
        if ('type' in element) return true;
        if ('element' in element) return false;

        throw new Error(
            `Could not determine if element was a legacy row element. Neither type nor element properties were present.`
        );
    }

    public getCustomContent(surveyRecordId: string, ids: string[]): Observable<any> {
        if (this.pureDevMode) return of([]);
        const endpoint = endpoints(this.api).customContent(surveyRecordId);
        return this.http.get(endpoint, {
            params: {
                group: ids
            }
        });
    }

    /**
     * Recursive function that iterates over the set of elements, searches for nested
     * elements and extracts all of the ids for which we need to load custom content.
     * This function returns a completely flat list.
     */
    private extractCustomContentIds(elements: SurveyElement[]): string[] {
        if (!elements || !elements.length) return [];

        return elements.reduce((agg: string[], element: SurveyElement) => {
            // fail safe in case our schema is misconfigured
            if (!element || !element.type) return agg;

            // only some elements can have dynamic content so if this element
            // is of this type, then search for a dynamicContentId.
            if (isNotNullOrUndefined(this.elementContentReplacementMap[element.type])) {
                const dynamicContentId = (element as {dynamicContentId: string}).dynamicContentId;
                dynamicContentId && agg.push(dynamicContentId);
            }

            // if we have a nested content mapping for this element type then
            // call this function recursively so that we check all nested elements.
            if (this.elementNestedContentMap[element.type]) {
                const nestedElements = this.elementNestedContentMap[element.type](element);
                agg = agg.concat(this.extractCustomContentIds(nestedElements));
            }

            return agg;
        }, []);
    }

    /**
     * Recursive function that extracts all elements by their type at any nested level. This function
     * returns a completely flat list.
     *
     * Passing an empty array for `types` gets you all types
     */
    private extractElementsByType(elements: SurveyElement[], types: SurveyElementType[]): SurveyElement[] {
        if (!elements || !elements.length) return [];

        return elements.reduce((agg, element: SurveyElement) => {
            // fail safe in case our schema is misconfigured
            if (!element || !element.type) return agg;

            if (!types.length || types.includes(element.type)) agg.push(element);

            if (this.elementNestedContentMap[element.type]) {
                const nestedElements = this.elementNestedContentMap[element.type](element);
                const extractedNestedElements = this.extractElementsByType(nestedElements, types);
                agg = agg.concat(extractedNestedElements);
            }

            return agg;
        }, []);
    }
}
