import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    SurveyActionTypes,
    SurveyValidationSuccessAction,
    ISurveyValidationSuccessApiResponse
} from '@clients/member-direct/surveys';
import {
    MemberDirectConfigActionTypes,
    SetConfigAction,
    SetDefaultLanguageAction,
    SetAppLanguageAction
} from './config.actions';
import {map, mergeMap, tap} from 'rxjs/operators';
import {TranslationService} from '@clients/member-direct/translation';

@Injectable()
export class MemberDirectConfigEffects {
    surveyVerificationSuccess = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.SURVEY_VALIDATION_SUCCESS),
            map((action: SurveyValidationSuccessAction) => action.payload),
            mergeMap((payload: ISurveyValidationSuccessApiResponse) =>
                payload.options.map(
                    (option) =>
                        new SetConfigAction({
                            prop: option.name,
                            value: option.value
                        })
                )
            )
        )
    );

    setDefaultLanguageAction = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MemberDirectConfigActionTypes.SET_DEFAULT_LANGUAGE),
                map((action: SetDefaultLanguageAction) => action.payload),
                tap((language: string) => this.translationService.setLanguage(language))
            ),
        {dispatch: false}
    );

    setLanguageAction = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MemberDirectConfigActionTypes.SET_LANGUAGE),
                map((action: SetAppLanguageAction) => action.payload),
                tap((language: string) => this.translationService.setLanguage(language))
            ),
        {dispatch: false}
    );

    constructor(private actions$: Actions, private translationService: TranslationService) {}
}
