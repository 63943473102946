import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectApplicationLanguage} from '../../+state';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'member-direct-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public language: Observable<string>;
    private destroyed$: Subject<boolean> = new Subject<boolean>();

    constructor(private store: Store<any>) {}

    ngOnInit() {
        this.language = this.store.pipe(select(selectApplicationLanguage));
        this.language.pipe(takeUntil(this.destroyed$)).subscribe((language: string) => {
            this.updateRootLangAttribute(language);
        });
    }

    updateRootLangAttribute(language: string): void {
        document.documentElement.setAttribute('lang', language);
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
