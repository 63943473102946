import {MultilingualData} from '@clients/member-direct/translation';
import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

// initial state
import {memberDirectInitialState} from './app/app.init';
import {MemberDirectReducerState} from './app/app.interface';
import * as fromApp from './app/app.reducer';
import {memberDirectConfigInitialState} from './config/config.init';
import {IQuoteSchema, MemberDirectConfigReducerState} from './config/config.interfaces';
import * as fromConfig from './config/config.reducer';
import {memberDirectSurveysState} from './surveys/surveys.init';
import {MemberDirectSurveysState} from './surveys/surveys.interface';
import * as fromSurveys from './surveys/surveys.reducer';

export interface AppCombinedReducerState {
    app: MemberDirectReducerState;
    config: MemberDirectConfigReducerState;
    surveys: MemberDirectSurveysState;
}

export const selectTopLevelReducer = createFeatureSelector<AppCombinedReducerState>('app');

export const appReducers: ActionReducerMap<AppCombinedReducerState> = {
    app: fromApp.memberDirectReducer,
    config: fromConfig.memberDirectConfigReducer,
    surveys: fromSurveys.memberDirectSurveysReducer
};

export const appInitialState: AppCombinedReducerState = {
    app: memberDirectInitialState,
    config: memberDirectConfigInitialState,
    surveys: memberDirectSurveysState
};

// app
export const selectMemberDirectAppState = createSelector(
    selectTopLevelReducer,
    (state: AppCombinedReducerState) => state.app
);
export const selectErrorText = createSelector(selectMemberDirectAppState, fromApp.getErrorText);

// config
export const selectMemberDirectConfigState = createSelector(
    selectTopLevelReducer,
    (state: AppCombinedReducerState) => state.config
);
export const selectCompanyName = createSelector(selectMemberDirectConfigState, fromConfig.getCompanyName);
export const selectLogo = createSelector(selectMemberDirectConfigState, fromConfig.getLogo);
export const selectLanguage = createSelector(selectMemberDirectConfigState, fromConfig.getLanguage);
export const selectDefaultLanguage = createSelector(selectMemberDirectConfigState, fromConfig.getDefaultLanguage);
export const selectQuote = createSelector(selectMemberDirectConfigState, fromConfig.getQuote);

export const selectTranslatedCompanyName = createSelector(
    selectCompanyName,
    selectLanguage,
    selectDefaultLanguage,
    (companyName: MultilingualData<string>, lang: string, defaultLang: string) => {
        if (!companyName) return null;

        return lang ? companyName[lang] : companyName[defaultLang];
    }
);

export const selectTranslatedLogo = createSelector(
    selectLogo,
    selectLanguage,
    selectDefaultLanguage,
    (logo: MultilingualData<string>, lang: string, defaultLang: string) => {
        if (!logo) return null;

        return lang ? logo[lang] : logo[defaultLang];
    }
);

export const selectApplicationLanguage = createSelector(
    selectLanguage,
    selectDefaultLanguage,
    (lang: string, defaultLang: string) => lang || defaultLang || 'en'
);

export const selectNavigationQuote = createSelector(
    selectLanguage,
    selectDefaultLanguage,
    selectQuote,
    (lang: string, defaultLang: string, quote: MultilingualData<IQuoteSchema>) => {
        if (!quote) return null;
        return lang ? quote[lang] : quote[defaultLang];
    }
);

// surveys
export const selectMemberDirectSurveysState = createSelector(
    selectTopLevelReducer,
    (state: AppCombinedReducerState) => state.surveys
);
export const selectPreauthorizedSurvey = createSelector(
    selectMemberDirectSurveysState,
    fromSurveys.getPreAuthorizedSurvey
);
