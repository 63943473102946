import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
    SurveyActionTypes,
    SurveyValidationSuccessAction,
    ISurveyValidationSuccessApiResponse
} from '@clients/member-direct/surveys';
import {MemberDirectConfigActionTypes, SetAppLanguageAction} from '../config/config.actions';
import {
    EventTrackAction,
    SetSuperPropertiesAction,
    SetUsernameAction,
    SetUserPropertiesAction,
    EnableTrackingAction
} from '@clients/shared/analytics';
import {PersistenceProvider} from '@clients/shared/persistence';
import {map, mergeMap} from 'rxjs/operators';
import {SurveySuperProperties} from './super-properties';
import {LanguageChanged} from './analytics.events';

@Injectable()
export class MemberDirectAnalyticsEffects {
    setLanguage = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberDirectConfigActionTypes.SET_LANGUAGE),
            map((action: SetAppLanguageAction) => action.payload),
            mergeMap((payload: string) => {
                const userProps = {
                    [SurveySuperProperties.language]: payload
                };

                const superProps = {
                    [SurveySuperProperties.language]: payload
                };

                return [
                    new EventTrackAction(new LanguageChanged(payload)),
                    new SetUserPropertiesAction(userProps),
                    new SetSuperPropertiesAction(superProps)
                ];
            })
        )
    );

    surveyValidationSucceeded = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.SURVEY_VALIDATION_SUCCESS),
            map((action: SurveyValidationSuccessAction) => action.payload),
            mergeMap((payload: ISurveyValidationSuccessApiResponse) => {
                const superProps = {
                    [SurveySuperProperties.surveyName]: payload.surveyName,
                    [SurveySuperProperties.defaultLanguage]: payload.defaultLanguage,
                    [SurveySuperProperties.language]: payload.defaultLanguage,
                    [SurveySuperProperties.surveyConfigurationId]: payload.surveyConfigId,
                    [SurveySuperProperties.linkId]: this.persistence.get('linkId')
                };

                const userProps = {
                    [SurveySuperProperties.language]: payload.defaultLanguage
                };

                return [
                    new SetUsernameAction(payload.enrollmentId),
                    new SetUserPropertiesAction(userProps),
                    new SetSuperPropertiesAction(superProps),
                    new EnableTrackingAction()
                ];
            })
        )
    );

    constructor(private actions$: Actions, private persistence: PersistenceProvider) {}
}
