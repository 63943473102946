<div
    class="surveys-survey-block"
    [class.single-question-mode]="shouldRenderInSingleQuestionMode"
    [class.scroll-mode]="shouldRenderInScrollMode"
    [class.enable-manual-scroll]="shouldAllowManualScroll"
    [class.disable-manual-scroll]="!shouldAllowManualScroll"
>
    <div *ngIf="shouldShowBlockHeader" class="surveys-survey-block-header">
        <div *ngIf="block?.heading" class="text-and-border">
            {{ (block?.heading)[language] }}
            <div [style.right]="getProgress()" class="rvl-progress"></div>
        </div>

        <div *ngIf="shouldShowQuestionCounter" class="counter">
            <div class="current">{{ selectedQuestionNumber }}</div>

            <div class="separator">/</div>

            <div class="total">{{ elementCount }}</div>
        </div>
    </div>

    <div *ngIf="shouldRenderInSingleQuestionMode; else scrollableElementList">
        <surveys-render-survey-element
            [element]="selectedQuestionElement"
            [multipartQuestionHeader]="singleQuestionHeader"
        ></surveys-render-survey-element>
        <div class="single-question-nav-container">
            <r-button [e2e]="'single-question-nav-' + block.id + '-back'" (onClick)="goToPrevQuestion()">
                {{ getBackNavButtonText() }}
            </r-button>
            <r-button [e2e]="'single-question-nav-' + block.id + '-next'" (onClick)="goToNextQuestion()">
                {{ getNextNavButtonText() }}
            </r-button>
        </div>
    </div>

    <ng-template #scrollableElementList>
        <surveys-render-survey-element
            *ngFor="let element of elements"
            [element]="element"
        ></surveys-render-survey-element>
    </ng-template>

    <div *ngIf="shouldShowBlockNav()" [class.active]="selectedElementIsLastElement" class="block-navigation">
        <div class="content">
            <r-button [e2e]="'button-block-' + block.id + '-next'" (onClick)="loadNextBlock(nextBlock)">
                {{ getNextActionButtonText() }}
            </r-button>
        </div>
    </div>
</div>
