import {TaggedSurveyResponse} from '../../models/survey-responses/TaggedSurveyResponse.interface';
import {SurveyResponsePayload} from '../../models/survey-responses/SurveyResponsePayload.interface';

export function buildSurveyResponsesPayload(responses: TaggedSurveyResponse[]): SurveyResponsePayload[] {
    if (!responses || !responses.length) return [];

    return responses.map(
        (response: TaggedSurveyResponse): SurveyResponsePayload => ({
            questionId: response.questionId,
            answerId: response.answerId || response.id,
            value: response.value,
            answerTags: response.tags,
            createdAt: response.created_at
        })
    );
}
