import {SurveysBlocksState} from './blocks.interfaces';

export const blocksInitialState: SurveysBlocksState = {
    ids: [],
    entities: null,
    idOfSelectedEntity: null,
    idOfPreviouslySelectedEntity: null,
    hidden: [],
    seen: [],
    completed: [],
    ready: true
};
