import {Observable} from 'rxjs';
import {ErrorObservable} from 'rxjs-compat/observable/ErrorObservable';

import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {IdvService} from '@clients/member-direct/idv';

@Injectable()
export class IdvNotRequiredOrCompleteGuard implements CanActivate {
    constructor(private idvService: IdvService) {}

    canActivate(): boolean | Observable<any> {
        // if the survey does not require IDV or the user has already
        // verified themselves, then load the route.
        const requiresIdv = this.idvService.isRequired();
        const idvVerificationComplete = this.idvService.isVerified();
        if (!requiresIdv || idvVerificationComplete) return true;

        return ErrorObservable.create('Navigation blocked: requires IDV verification');
    }
}
