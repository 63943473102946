<div class="member-direct-privacy">
    <p>
        Revel respects the privacy of all users who visit or use this website (the “Site”), and is committed to
        protecting users’ privacy through compliance with this privacy policy. Revel maintains this Site on behalf of
        our Customers (the “Program Sponsor”) as part of a member outreach program (the “Program”). This privacy policy
        is designed to inform you, as a user of the Site, about the types of information that Revel may gather from
        visitors to the Site and about our practices for collecting, using, maintaining, and disclosing that
        information.
    </p>
    <h2>Scope of Policy</h2>
    <p>
        This policy applies to information we collect on the Site, and to e-mail, SMS text messages or other electronic
        messages between you and the Site related to the Program. By using the Site, you agree to the collection and use
        of information in accordance with this policy.
    </p>
    <h2>Information You Provide to Us</h2>
    <p>
        While using our Site, we may ask you to provide us with certain personally identifiable information that can be
        used to contact or identify you. Personally identifiable information may include, but is not limited to your
        name, phone, mailing address or email address (“Personal Information”).
    </p>
    <p>
        Any information we obtain from you may be used to deliver information about the Program or to communicate with
        you for the purposes of the Program. We may also use information obtained from you for internal purposes, such
        as troubleshooting the Site, providing customer support, or to provide the Program Sponsor with feedback about
        Program. We may provide your information to the Program Sponsor as part of the Program.
    </p>
    <h2>Information We Collect Through Automatic Technologies</h2>
    <p>
        Like many website operators, we may collect information that your browser sends whenever you visit our Site
        (“Log Data”).
    </p>
    <p>
        This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type,
        browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those
        pages and other statistics.
    </p>
    <p>
        In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this
        information to improve our Site’s content and performance.
    </p>
    <p>
        Like many websites, our Site use “cookies” to collect information. Cookies are files with small amount of data,
        which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on
        your computer’s hard drive. You can instruct your browser to refuse all cookies or to indicate when a cookie is
        being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.
    </p>
    <h2>Use of Information</h2>
    <p>
        We may disclose aggregated information about our users, and information that does not identify any individual,
        without restriction. We only use Personal Information collected by the Site for the purposes of supporting the
        Program. Personal Information may be provided to the Program Sponsor for purposes related to the Program.
        Security The security of your Personal Information is important to us, but remember that no method of
        transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use all
        commercially reasonable means to protect your Personal Information, we cannot guarantee its absolute security.
    </p>
    <h2>Children</h2>
    <p>
        We do not knowingly collect any information at all from children under age 13. Our Site is not intended for
        children. No one under age 13 may provide any information to our Site. If we learn we have collected any
        information from a child under age 13 without verification of parental consent, we will delete that information.
        If you believe we might have any information from or about a child under age 13, please contact us. It is our
        policy to comply as applicable with the Children’s Online Privacy Protection Act of 1998 and all other
        applicable laws.
    </p>
    <h2>Changes to This Privacy Policy</h2>
    <p>
        This Privacy Policy is effective as of June 5, 2019 and will remain in effect except with respect to any changes
        in its provisions in the future, which will be in effect immediately after being posted on this page.
    </p>
    <p>
        We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy
        periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this
        page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the
        modified Privacy Policy.
    </p>
    <h2>Contact Us</h2>
    <p>
        If you have any questions about this privacy policy or about Revel’s collection and storage of data, please
        contact us. You can also contact us at any time to request, correct, or delete any information that Revel has
        about you.
    </p>
    <h2>Revel</h2>
    <p>Email: <a href="mailto:go@revel-health.com">go@revel-health.com</a></p>
</div>
