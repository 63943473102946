import {IQuoteSchema, MemberDirectConfigReducerState} from './config.interfaces';
import {MemberDirectConfigActionTypes} from './config.actions';
import {memberDirectConfigInitialState} from './config.init';
import {MultilingualData} from '@clients/member-direct/translation';

export function memberDirectConfigReducer(
    state: MemberDirectConfigReducerState,
    action: any
): MemberDirectConfigReducerState {
    switch (action.type) {
        case MemberDirectConfigActionTypes.RESET:
            return {...memberDirectConfigInitialState};

        case MemberDirectConfigActionTypes.SET_CONFIG:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            };

        case MemberDirectConfigActionTypes.SET_DEFAULT_LANGUAGE:
            return {...state, defaultLanguage: action.payload};

        case MemberDirectConfigActionTypes.SET_LANGUAGE:
            return {...state, language: action.payload};

        default: {
            return state;
        }
    }
}

export const getCompanyName = (state: MemberDirectConfigReducerState): MultilingualData<string> => state.companyName;
export const getLogo = (state: MemberDirectConfigReducerState): MultilingualData<string> => state.logo;
export const getLanguage = (state: MemberDirectConfigReducerState): string => state.language;
export const getDefaultLanguage = (state: MemberDirectConfigReducerState): string => state.defaultLanguage;
export const getQuote = (state: MemberDirectConfigReducerState): MultilingualData<IQuoteSchema> => state.quote;
