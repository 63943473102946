import {Observable} from 'rxjs';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable, Injector} from '@angular/core';
import {ENV_SERVICE, EnvService} from '@clients/shared/config';
import {LogErrorAction} from '@clients/shared/logging';

import {AuthStartedAction} from '../+state/auth.actions';
import {AUTH_LOG_NAMES} from '../errors/auth-log-names';
import {SKIP_AUTH_HEADER} from '../interceptors/auth-token.interceptor';
import {IdvLoginPayload} from '../models/IdvLoginData.interface';
import {AuthProvider} from './auth.provider';

export const idvEndpoints = (api: string) => ({
    verify: () => `${api}/token`
});

//todo implement methods https://healthtel.atlassian.net/browse/SB-978
@Injectable()
export class IdvService extends AuthProvider {
    private api: string;
    constructor(injector: Injector, @Inject(ENV_SERVICE) envService: EnvService, private http: HttpClient) {
        super(injector);
        this.api = envService.idvServiceApi;
    }

    /* istanbul ignore next */
    public isAuthenticated(): boolean {
        return true;
    }

    /* istanbul ignore next */
    public renewSession(): Promise<string> {
        return new Promise<string>((resolve) => {
            resolve(this.getToken());
        });
    }

    /* istanbul ignore next */
    public revokeSession() {}

    public authorize() {}

    /**
     * IdvData can be blank in this case because IDV may not be
     * required by a programSummary. Either way, we'll post it.
     *
     * @param {IdvLoginPayload} idvData
     */
    public login(idvData: IdvLoginPayload = null): void {
        this.store.dispatch(AuthStartedAction());
        this.handleAuthentication(idvData).subscribe(
            this.handleAuthenticationNext,
            this.handleAuthenticationError(idvData)
        );
    }

    public handleAuthentication(idvData: IdvLoginPayload): Observable<Object> {
        const endpoint = idvEndpoints(this.api).verify();
        const headers = new HttpHeaders({
            [SKIP_AUTH_HEADER]: ''
        });
        return this.http.post(endpoint, idvData, {headers: headers});
    }

    /* istanbul ignore next */
    public getTokenExpirationDate(): null | Date {
        return null;
    }

    public isTokenExpired(token: string): boolean {
        let isTokenExpired;
        try {
            isTokenExpired = this.jwtHelper.isTokenExpired(token);
        } catch (e) {
            isTokenExpired = true;
        }
        return isTokenExpired;
    }

    /* istanbul ignore next */
    public logout() {}

    /* istanbul ignore next */
    public handle401Error() {}
    public changePassword() {}

    private handleAuthenticationNext = (res: {token: string}) => {
        this.success(res.token, null, []);
    };

    private handleAuthenticationError = (idvData: IdvLoginPayload) => (err: any) => {
        const actions = [
            new LogErrorAction({
                type: AUTH_LOG_NAMES.IDV_FAILED,
                message: 'idv failed',
                options: {
                    err,
                    data: idvData
                }
            })
        ];

        this.fail(
            {
                title: err.status,
                description: err.message,
                state: this.getIdvError(err)
            },
            actions
        );
    };

    private getIdvError(err: any): string {
        if (err.error) {
            return err.error.error ? err.error.error : 'FAILED';
        } else return null;
    }
}
