import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Injector, Input, OnDestroy, OnInit, Directive} from '@angular/core';
import {MemoizedSelector, select, Store} from '@ngrx/store';

import {selectActive} from '../+state';

@Directive()
export abstract class Loader implements OnInit, OnDestroy {
    @Input()
    public fullScreen: boolean;
    @Input()
    public selector: MemoizedSelector<any, any>;
    @Input()
    public active: boolean;

    private store: Store<any>;
    private destroyed$: Subject<boolean> = new Subject<boolean>();

    protected constructor(private injector: Injector) {
        this.store = this.injector.get(Store);
    }

    ngOnInit() {
        // the loader can be triggered by passing something for the
        // active prop, by specifying a selector or by letting the
        // default selector for the loading feature's slice of the
        // store be the thing that triggers the loader.
        if (this.active === undefined) {
            // either use the global loading state selector or whichever one
            // the component has specified
            const selector = this.selector ? this.selector : selectActive;
            this.store.pipe(select(selector), takeUntil(this.destroyed$)).subscribe(this.subscribeCallback.bind(this));
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    private subscribeCallback(val: any) {
        this.active = val;
    }
}
