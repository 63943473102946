import {Injectable} from '@angular/core';
import {ISurveyBlock} from '../../models/survey/ISurveyBlock';
import {BlockQuestionsService} from './block-questions.service';
import {BlockStateService} from './block-state.service';
import {get} from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class BlockService {
    constructor(private blockQuestionsService: BlockQuestionsService, private blockStateService: BlockStateService) {}

    // Actions to be taken when a block is activated
    public blockActivated(block: ISurveyBlock): void {
        // Blocks can be seen and complete and that state is used to
        // styles various elements in our app. The underlying function
        // update the state of the block to make sure we know which ones
        // are complete and which ones have been seen.
        this.blockStateService.update();

        // process behaviors dictated by questions config
        if (this.blockHasQuestionsConfig(block)) this.blockQuestionsService.blockActivated(block);
    }

    // Actions to be taken when a question is answered.
    public questionAnswered(block: ISurveyBlock, questionId: string): void {
        // process behaviors dictated by questions config
        if (this.blockHasQuestionsConfig(block)) this.blockQuestionsService.questionAnswered(block, questionId);
    }

    // Actions to be taken when single question navigation is used
    public nextQuestion(block: ISurveyBlock, questionId: string): void {
        this.blockQuestionsService.nextQuestion(block, questionId);
    }

    // Actions to be taken when single question navigation is used
    public prevQuestion(block: ISurveyBlock, questionId: string): void {
        this.blockQuestionsService.prevQuestion(block, questionId);
    }

    // Actions to be taken when an element is highlighted.
    public elementHighlighted(block: ISurveyBlock, id: string) {
        // process behaviors dictated by questions config
        if (this.blockHasQuestionsConfig(block)) this.blockQuestionsService.elementHighlighted(block, id);
    }

    private blockHasQuestionsConfig(block: ISurveyBlock): boolean {
        return get(block, 'config.questions');
    }
}
