import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CloseLoaderAction, LoadingActionTypes} from './loading.actions';
import {delay, flatMap, map} from 'rxjs/operators';
import {LoadingFacade} from './loading.facade';
import {of} from 'rxjs';
import {loadingConfig} from '../loading.config';

@Injectable()
export class LoadingEffects {
    doneLoading = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadingActionTypes.DONE_LOADING),
            map(() => this.loadingFacade.getLoaderCanCloseAt()),
            flatMap((loaderCanCloseAt: number) => {
                // if no minimum time was set, close the loader now
                if (!loaderCanCloseAt) return of(new CloseLoaderAction());

                // subtract the current time in epoch format from the time at which the
                // loader is allowed to be closed. If that result is positive, the minimum time
                // has not yet been exceeded so delay the close by that amount.
                // otherwise, the delay has been exceeded and the delay should be 0.
                const remainingTimeBeforeCloseIsAllowed = loaderCanCloseAt - this.getCurrentEpochTime();
                let timeToWait = remainingTimeBeforeCloseIsAllowed > 0 ? remainingTimeBeforeCloseIsAllowed : 0;
                // just in case something crazy happens
                if (timeToWait > loadingConfig.maximumLoaderDelayInMs)
                    timeToWait = loadingConfig.maximumLoaderDelayInMs;

                // this works and delays as expected but tests do not work
                return of(new CloseLoaderAction()).pipe(delay(timeToWait));
            })
        )
    );

    constructor(private actions$: Actions, private loadingFacade: LoadingFacade) {}

    // abstracted into a standalone function for testability
    private getCurrentEpochTime() {
        return new Date().getTime();
    }
}
