import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';
import {GeoJsonDataPoint} from '../../../models/mapping/GeoJsonDataPoint.interface';

export const PharmaciesActionTypes = {
    RESET: type('[pharmacies] reset'),

    LOAD_PHARMACIES: type('[pharmacies] load pharmacies'),
    PHARMACIES_LOADED: type('[pharmacies] pharmacies loaded'),
    ERROR_LOADING_PHARMACIES: type('[pharmacies] error loading pharmacies'),

    REMOVE_PHARMACY_LOCATION_DATA: type('[pharmacies] remove pharmacies')
};

export class ResetPharmaciesAction implements Action {
    public type = PharmaciesActionTypes.RESET;
}

export interface LoadPharmaciesActionPayload {
    lat: number;
    lng: number;
}

export class LoadPharmaciesAction implements Action<LoadPharmaciesActionPayload> {
    public type = PharmaciesActionTypes.LOAD_PHARMACIES;

    constructor(public payload: LoadPharmaciesActionPayload) {}
}

export class PharmaciesLoadedAction implements Action<GeoJsonDataPoint[]> {
    public type = PharmaciesActionTypes.PHARMACIES_LOADED;

    constructor(public payload: GeoJsonDataPoint[]) {}
}

export class ErrorLoadingPharmaciesAction implements Action<GeoJsonDataPoint[]> {
    public type = PharmaciesActionTypes.ERROR_LOADING_PHARMACIES;

    constructor(public payload: any) {}
}

export class RemovePharmacyLocationDataAction implements Action<GeoJsonDataPoint[]> {
    public type = PharmaciesActionTypes.REMOVE_PHARMACY_LOCATION_DATA;

    constructor(public payload: GeoJsonDataPoint[]) {}
}
