import {MemberDirectLocale} from './Locale.interface';

export const memberDirectFeatureLocalePrefix = 'memberDirect';

export const memberDirectLocale: MemberDirectLocale = {
    feature: memberDirectFeatureLocalePrefix,
    data: {
        languages: {
            ar: 'Select language', // right-to-left language. not officially supported.
            hy: 'Ընտրել լեզուն',
            km: 'ជ្រើសរើស​ភាសា',
            zh: '选择语言',
            yue: '选择语言',
            cmn: '選擇語言',
            ch: '选择语言',
            cs: 'Zvolte jazyk',
            da: 'Vælg sprog',
            nl: 'Selecteer taal',
            en: 'Select language',
            fa: 'Languages', // right-to-left language. not officially supported.
            fr: 'Choisir la langue',
            de: 'Sprache auswählen',
            hi: 'भाषा चुने',
            hmn: 'Xaiv hom lus',
            is: 'Veldu tungumál',
            ilo: 'Pilien ti pagsasao',
            it: 'Seleziona la lingua',
            ja: '言語を選択する',
            ko: '언어 선택',
            nb: 'Velg språk',
            pl: 'Wybierz język',
            pt: 'Selecione o idioma',
            ro: 'Selecteaza limba',
            ru: 'Выберите язык',
            so: 'Dooro luqadda',
            es: 'Seleccione el idioma',
            sv: 'Välj språk',
            tl: 'Piliin ang wika',
            tr: 'Dil Seçin',
            vi: 'Chọn ngôn ngữ',
            vt: 'Chọn ngôn ngữ',
            cy: 'Dewiswch iaith'
        },
        languageNames: {
            ar: 'عربى', // right-to-left language. not officially supported.
            hy: 'Հայերեն',
            km: 'ខ្មែរ',
            zh: '中文',
            yue: '繁體中文',
            cmn: '简体中文',
            ch: '中文',
            cs: 'čeština',
            da: 'Dansk',
            nl: 'Nederlands',
            en: 'English',
            fa: 'Farsi', // right-to-left language. not officially supported.
            fr: 'Français',
            de: 'Deutsch',
            hi: 'हिन्दी',
            hmn: 'Hmoob',
            is: 'íslenska',
            ilo: 'Pagsasao nga Ilokano',
            it: 'Italiano',
            ja: '日本語',
            ko: '한국어',
            nb: 'Norsk',
            pl: 'Polski',
            pt: 'Português',
            ro: 'Română',
            ru: 'Русский',
            so: 'Soomaali',
            es: 'Español',
            sv: 'Svenska',
            tl: 'Tagalog',
            tr: 'Türkçe',
            vi: 'Tiếng Việt',
            vt: 'Tiếng Việt',
            cy: 'Cymraeg'
        },
        skipToMain: {
            ar: 'تخطي إلى المحتوى الأساسي', // right-to-left language. not officially supported.
            hy: 'անցնել հիմնական բովանդակությանը',
            km: 'រំលងទៅមាតិកាសំខាន់',
            zh: '跳到主要内容',
            yue: '跳到主要内容',
            cmn: '跳到主内容',
            ch: '跳至主要内容',
            cs: 'Přeskočit do hlavního obsahu',
            da: 'gå til hovedindholdet',
            nl: 'Ga direct naar hoofdinhoud',
            en: 'Skip to main content',
            fa: 'رفتن به محتوای اصلی', // right-to-left language. not officially supported.
            fr: 'Aller au contenu principal',
            de: 'Direkt zum Hauptinhalt',
            hi: 'मुख्य विषयवस्तु पर जाएँ',
            hmn: 'hla mus rau lub ntsiab lus  tseem ceeb',
            is: 'fara í aðalefni',
            ilo: 'mapan ti kangrunaan a linaon',
            it: 'vai ai contenuti principali',
            ja: 'メインコンテンツにスキップ',
            ko: '주요 콘텐츠로 넘어가기',
            nb: 'hopp over og gå til hovedinnhold',
            pl: 'Przejdź do treści głównej',
            pt: 'Saltar para o conteúdo principal',
            ro: 'salt la conținutul principal',
            ru: 'Пропустить и перейти к основному контенту',
            so: 'u bood waxa kujira ee ugu muhiimsan',
            es: 'saltar al contenido principal',
            sv: 'hoppa till huvudinnehåll',
            tl: 'lumaktaw papunta sa pangunahing nilalaman',
            tr: 'Ana içeriğe geç',
            vi: 'Chuyển đến nội dung chính',
            vt: 'Chuyển đến nội dung chính',
            cy: "ymlaen i'r prif gynnwys"
        },
        selectLanguage: {
            ar: 'Select Language', // right-to-left language. not officially supported.
            hy: 'Select Language',
            km: 'Select Language',
            zh: 'Select Language',
            yue: 'Select Language',
            cmn: 'Select Language',
            ch: 'Select Language',
            cs: 'Select Language',
            da: 'Select Language',
            nl: 'Select Language',
            en: 'Select Language',
            fa: 'Select Language', // right-to-left language. not officially supported.
            fr: 'Select Language',
            de: 'Select Language',
            hi: 'Select Language',
            hmn: 'Select Language',
            is: 'Select Language',
            ilo: 'Select Language',
            it: 'Select Language',
            ja: 'Select Language',
            ko: 'Select Language',
            nb: 'Select Language',
            pl: 'Select Language',
            pt: 'Select Language',
            ro: 'Select Language',
            ru: 'Select Language',
            so: 'Select Language',
            es: 'Select Language',
            sv: 'Select Language',
            tl: 'Select Language',
            tr: 'Select Language',
            vi: 'Select Language',
            vt: 'Select Language',
            cy: 'Select Language'
        }
    }
};
