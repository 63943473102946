import {SurveysLocaleData} from './Locale.interface';

export const surveysLocale: SurveysLocaleData = {
    feature: 'surveys',
    data: {
        elements: {
            multiSelect: {
                instructions: {
                    selectAll: {
                        ar: 'Please select any that apply.', // right-to-left language. not officially supported.
                        hy: 'Խնդրում ենք ընտրել բոլոր կիրառելիները',
                        km: 'សូមជ្រើសរើសអ្វីដែលត្រឹមត្រូវ',
                        zh: '請選擇所有適用的項目。',
                        yue: '請選擇所有適用的項目。',
                        cmn: '请选择所有适用项目。',
                        ch: '請選擇所有適用的項目。',
                        cs: 'Vyberte vše, co platí',
                        da: 'Vælg en, der gælder',
                        nl: 'Selecteer alles dat van toepassing is',
                        en: 'Please select any that apply.',
                        fa: 'Please select any that apply.', // right-to-left language. not officially supported.
                        fr: 'Veuillez sélectionner toutes les réponses pertinentes.',
                        de: 'Bitte alle zutreffenden Antworten auswählen',
                        hi: 'कृपया लागू होने वाले को चुनें',
                        hmn: 'Thov xaiv ib qho uas siv tau',
                        is: 'Vinsamlegast veldu allt sem við á',
                        ilo: 'Paki-pilim amin nga maiaplikar',
                        it: 'Selezionate tutte le voci pertinenti',
                        ja: '該当する項目をすべて選択してください',
                        ko: '적용되는 경우 모두 선택하십시오.',
                        nb: 'Velg alt som gjelder',
                        pl: 'Wybierz odpowiednie',
                        pt: 'Wybierz odpowiednie',
                        ro: 'Selectați oricare se aplică',
                        ru: 'Пожалуйста, выберите все, что применимо.',
                        so: 'Fadlan xulo mid kasta oo ku habboon',
                        es: 'Seleccione lo que sea pertinente.',
                        sv: 'Välj alla som stämmer',
                        tl: 'Pakipili ang anumang naaangkop',
                        tr: 'Lütfen geçerli olanları seçin',
                        vi: 'Vui lòng chọn bất kỳ đáp án nào phù hợp.',
                        vt: 'Vui lòng chọn bất kỳ đáp án nào phù hợp.',
                        cy: "Dewiswch unrhyw un sy'n berthnasol"
                    }
                }
            }
        },
        mapping: {
            enterZipCode: {
                ar: 'Enter your zip code to find locations near you.', // right-to-left language. not officially supported.
                hy: 'Մուտքագրեք Ձեր փոստային թվանիշը՝ Ձեզ մոտակա վայրերը գտնելու համար',
                km: 'បញ្ចូលលេខកូដតំបន់របស់អ្នក ដើម្បីស្វែងរកទីតាំងដែលនៅជិតអ្នក',
                zh: '輸入您的郵遞區號查詢您附近的地點。',
                yue: '輸入您的郵遞區號查詢您附近的地點。',
                cmn: '请输入您的邮政编码以查找附近的地点。',
                ch: '輸入您的郵遞區號查詢您附近的地點。',
                cs: 'Zadejte PSČ pro nalezení míst v blízkosti',
                da: 'Indtast dit postnummer for at finde steder i nærheden af dig',
                nl: 'Voer je postcode in om locaties in de buurt te vinden',
                en: 'Enter your zip code to find locations near you.',
                fa: 'Enter your zip code to find locations near you.', // right-to-left language. not officially supported.
                fr: 'Saisissez votre code postal pour trouver les endroits près de chez vous.',
                de: 'Bitte geben Sie Ihre Postleitzahl, um Standorte in Ihrer Nähe zu suchen.',
                hi: 'अपने आस-पास के स्थानों को खोजने के लिए अपना ज़िप कोड दर्ज करें',
                hmn: 'Ntaus koj tus zis qhauj los nrhiav tej chaw nyob ze ntawm koj.',
                is: 'Færðu inn póstnúmerið þitt til að finna staði nálægt þér',
                ilo: 'Iserrek mo ti zip code tapno masapul mo dagiti lokasyon nga asideg kaniam',
                it: 'Inserite il vostro CAP per trovare le sedi a voi più vicine',
                ja: 'お近くのロケーションを見つけるためにZipコード(郵便番号）を入力してください',
                ko: '귀하와 가까운 장소를 찾기 위해 우편번호를 입력하십시오.',
                nb: 'Oppgi postnummeret ditt for å finne steder i nærheten av deg',
                pl: 'Wprowadź kod pocztowy ZIP, aby znaleźć lokalizacje w pobliżu',
                pt: 'Introduza o seu código postal para encontrar localizações próximas de si',
                ro: 'Introduceți codul dvs. poștal pentru a găsi locații în apropiere',
                ru: 'Введите почтовый индекс для поиска мест рядом с вами.',
                so: 'Geli baaqa cinwaankaaga si aad u ogaato goobaha kuu dhow',
                es: 'Introduzca su codigo postal para encontrar una pharmacia cerca de Ud.',
                sv: 'Geli baaqa cinwaankaaga si aad u ogaato goobaha kuu dhow',
                tl: 'Ilagay ang iyong zip code para mahanap ang mga lokasyong malapit sa iyo',
                tr: 'Yakınınızdaki yerleri bulmak için posta kodunuzu girin',
                vi: 'Nhập mã zip của quý vị để tìm các địa điểm gần quý vị.',
                vt: 'Nhập mã zip của quý vị để tìm các địa điểm gần quý vị.',
                cy: 'Rhowch eich cod post i chwilio am leoliadau gerllaw'
            },
            changeLocations: {
                ar: '<span class="link">Wrong location? Enter your Zip Code.</span>', // right-to-left language. not officially supported.
                hy: '<span class="link">Սխա՞լ վայր: Մուտքագրեք Ձեր փոստային թվանիշը:</span>',
                km: '<span class="link">ខុសទីតាំងឬ? បញ្ចូលលេខកូដតំបន់របស់អ្នក។</span>',
                zh: '<span class="link">地點錯誤？輸入您的郵遞區號。</span>',
                yue: '<span class="link">地點錯誤？輸入您的郵遞區號。</span>',
                cmn: '<span class="link">地点错误？请输入您的邮政编码。</span>',
                ch: '<span class="link">地點錯誤？輸入您的郵遞區號。</span>',
                cs: '<span class="link">Nesprávné místo? Zadejte PSČ.</span>',
                da: '<span class="link">Forkert lokalisering? Indtast dit postnummer.</span>',
                nl: '<span class="link">Verkeerde locatie? Voer je postcode in.</span>',
                en: '<span class="link">Wrong location? Enter your Zip Code.</span>',
                fa: '<span class="link">Wrong location? Enter your Zip Code.</span>', // right-to-left language. not officially supported.
                fr: '<span class="link">Mauvais endroit? Saisissez votre code postal.</span>',
                de: '<span class="link">Falscher Standort? Bitte geben Sie Ihre Postleitzahl einmal.</span>',
                hi: '<span class="link">गलत स्थान? अपना ज़िप कोड दर्ज करें।</span>',
                hmn: '<span class="link">Thaj chaw puas yuam kev lawm? Ntaus koj Tus Zis Qhauj rau.</span>',
                is: '<span class="link">Rangur staður? Færðu inn póstnúmerið þitt</span>',
                ilo: '<span class="link">Kamali nga lokasyon? Iserrek mo ti zip code mo.</span>',
                it: '<span class="link">Sede errata? Inserite il CAP.</span>',
                ja: '<span class="link">ロケーションが違いますか？Zipコード(郵便番号）を入力してください。</span>',
                ko: '<span class="link">잘못된 장소입니까? 귀하의 우편번호를 입력하십시오.</span>',
                nb: '<span class="link">Feil sted? Oppgi postnummeret ditt.</span>',
                pl: '<span class="link">Zły adres? Wprowadź kod pocztowy ZIP.</span>',
                pt: '<span class="link">Localização errada? Introduza o seu código postal.</span>',
                ro: '<span class="link">Locație greșită? Introduceți codul dvs. poștal.</span>',
                ru: '<span class="link">Неправильное местоположение? Введите свой почтовый индекс.</span>',
                so: '<span class="link">Goob khaldan? Geli Baaqa Cinwaankaaga.</span>',
                es: '<span class="link">Ubicacion incorrecta? Cambiela ahora.</span>',
                sv: '<span class="link">Fel plats? Ange ditt postnummer.</span>',
                tl: '<span class="link">Mali ang lokasyon? Ilagay ang iyong Zip Code.</span>',
                tr: '<span class="link">Yer yanlış mı? Posta Kodunuzu girin.</span>',
                vi: '<span class="link">Sai địa điểm? Nhập Mã zip của quý vị.</span>',
                vt: '<span class="link">Sai địa điểm? Nhập Mã zip của quý vị.</span>',
                cy: '<span class="link">Lleoliad anghywir? Rhowch eich Cod Post.</span>'
            },
            lastZipCodeLookupFailed: {
                ar: 'Sorry, we could not find your zip code. Please try again!', // right-to-left language. not officially supported.
                hy: 'Ներեցեք, մենք չկարողացանք գտնել Ձեր փոստային թվանիշը: Խնդրում ենք նորի՛ց փորձել:',
                km: 'សូមអភ័យទោស យើងរកមិនឃើញលេខកូដតំបន់របស់អ្នកទេ។ សូមព្យាយាមម្តងទៀត!',
                zh: '很抱歉，我們找不到您的郵遞區號。請再試一次！',
                yue: '很抱歉，我們找不到您的郵遞區號。請再試一次！',
                cmn: '抱歉，我们找不到您的邮政编码。请重试！',
                ch: '很抱歉，我們找不到您的郵遞區號。請再試一次！',
                cs: 'Omlouvám se, nemohli jsme najít vaše PSČ. Zkuste to znovu!',
                da: 'Beklager, vi kunne ikke finde dit postnummer. Prøv venligst igen!',
                nl: 'Helaas kunnen we je postcode niet vinden. Probeer het opnieuw!',
                en: 'Sorry, we could not find your zip code. Please try again!',
                fa: 'Sorry, we could not find your zip code. Please try again!', // right-to-left language. not officially supported.
                fr: 'Désolé, nous n’avons pas trouvé votre code postal. Veuillez réessayer!',
                de: 'Leider wurde Ihre Postleitzahl nicht gefunden. Bitte versuchen Sie es noch einmal!',
                hi: 'क्षमा करें, हमें आपका ज़िप कोड नहीं मिला। कृपया पुन: प्रयास करें!',
                hmn: 'Thov txim, peb tsis tuaj yeem nrhiav koj tus zis qhauj. Thov sim dua ntxiv!',
                is: 'Því miður gátum við ekki fundið póstnúmerið þitt. Vinsamlegast reyndu aftur!',
                ilo: 'Pakawanem, madi mi nga masapul ti zip code mo. Paki-padasem ulit!',
                it: 'Spiacenti, non è stato possibile trovare il CAP indicato. Riprovare!',
                ja: '申し訳ありません。入力された郵便番号は見つかりませんでした。もう一度入力してください。',
                ko: '죄송합니다. 귀하의 우편번호를 찾을 수 없습니다. 다시 시도해주십시오!',
                nb: '申し訳ありません。入力された郵便番号は見つかりませんでした。もう一度入力してください。',
                pl: 'Przepraszamy, nie mogliśmy znaleźć tego kodu ZIP. Spróbuj ponownie!',
                pt: 'Lamentamos, mas não foi possível encontrar o seu código postal. Tente de novo!',
                ro: 'Ne pare rău, nu am putut găsi codul dvs. poștal. Încercați din nou!',
                ru: 'Извините, мы не смогли найти ваш почтовый индекс. Повторите попытку!',
                so: 'Waan ka xunnahay, waan heli waynay baaqa cinwaankaaga. Fadlan isku day mar kale!',
                es: 'Disculpa, no pudimos encontrar su codigo postal. Por favor, intente de nuevo.',
                sv: 'Vi kunde tyvärr inte hitta ditt postnummer. Försök igen!',
                tl: 'Paumanhin, hindi namin mahanap ang zip code mo. Pakisubukan ulit!',
                tr: 'Üzgünüz, posta kodunuzu bulamadık. Lütfen tekrar deneyin!',
                vi: 'Xin lỗi, chúng tôi không thể tìm thấy mã zip của quý vị. Vui lòng thử lại!',
                vt: 'Xin lỗi, chúng tôi không thể tìm thấy mã zip của quý vị. Vui lòng thử lại!',
                cy: 'Mae’n ddrwg gennym, nid oedd modd canfod eich cod post. Rhowch gynnig arall arni!'
            }
        },
        completeSurvey: {
            ar: 'Complete Assessment', // right-to-left language. not officially supported.
            hy: 'Լրացրեք գնահատումը',
            km: 'ការវាយតម្លៃពេញលេញ',
            zh: '完成評估',
            yue: '完成評估',
            cmn: '完成评估',
            ch: '完成評估',
            cs: 'Dokončit hodnocení',
            da: 'Komplet vurdering',
            nl: 'Beoordeling voltooien',
            en: 'Complete Assessment',
            fa: 'Complete Assessment', // right-to-left language. not officially supported.
            fr: 'Remplir l’évaluation',
            de: 'Beurteilung durchführen',
            hi: 'पूरा आकलन',
            hmn: 'Kev Tshuaj Ntsuam Tiav Lawm',
            is: 'Ljúka mati',
            ilo: 'Kumpletuen ti Asesment',
            it: 'Valutazione completata',
            ja: 'アセスメントを完了',
            ko: '완전한 평가',
            nb: 'Fullfør vurdering',
            pl: 'Zakończ ocenę',
            pt: 'Concluir avaliação',
            ro: 'Finalizați evaluarea',
            ru: 'Полная оценка',
            so: 'Bog Qiimeynta',
            es: 'Evaluación completa',
            sv: 'Slutför utvärdering',
            tl: 'Tapusin ang Pagtatasa',
            tr: 'Değerlendirmeyi Tamamlayın',
            vi: 'Đánh giá hoàn thành',
            vt: 'Đánh giá hoàn thành',
            cy: "Cwblhau'r Asesiad"
        },
        navigation: {
            next: {
                ar: 'Next', // right-to-left language. not officially supported.
                hy: 'Հաջորդ',
                km: 'បន្ទាប់',
                zh: '下一個',
                yue: '下一個',
                cmn: '下一页',
                ch: '下一個',
                cs: 'Další',
                da: 'Další',
                nl: 'Volgende',
                en: 'Next',
                fa: 'Next', // right-to-left language. not officially supported.
                fr: 'Suivant',
                de: 'Weiter',
                hi: 'अगला',
                hmn: 'Mus Tom Ntej',
                is: 'Næsta',
                ilo: 'Sumaruno',
                it: 'Avanti',
                ja: '次へ',
                ko: '다음 것',
                nb: 'Neste',
                pl: 'Następne',
                pt: 'Seguinte',
                ro: 'Următorul',
                ru: 'следующий',
                so: 'Xiga',
                es: 'Siguiente',
                sv: 'Nästa',
                tl: 'Susunod',
                tr: 'İleri',
                vi: 'Kế tiếp',
                vt: 'Kế tiếp',
                cy: 'Nesaf'
            },
            back: {
                ar: 'Back', // right-to-left language. not officially supported.
                hy: 'Նախորդ',
                km: 'ថយក្រោយ',
                zh: '返回',
                yue: '返回',
                cmn: '上一页',
                ch: '返回',
                cs: 'Zpět',
                da: 'Tilbage',
                nl: 'Terug',
                en: 'Back',
                fa: 'Back', // right-to-left language. not officially supported.
                fr: 'Retour',
                de: 'Zurück',
                hi: 'वापस',
                hmn: 'Rov Qab',
                is: 'Til baka',
                ilo: 'Isubli',
                it: 'Indietro',
                ja: '戻る',
                ko: '뒤로',
                nb: 'Tilbake',
                pl: 'Wstecz',
                pt: 'Retroceder',
                ro: 'Înapoi',
                ru: 'Назад',
                so: 'Noqo',
                es: 'Atras',
                sv: 'Tillbaka',
                tl: 'Bumalik',
                tr: 'Geri',
                vi: 'Trở về',
                vt: 'Trở về',
                cy: 'Yn ôl'
            },
            continue: {
                ar: 'Continue', // right-to-left language. not officially supported.
                hy: 'Շարունակեք',
                km: 'បន្ត',
                zh: '續',
                yue: '續',
                cmn: '继续',
                ch: '繼續',
                cs: 'Pokračovat',
                da: 'Fortsætte',
                nl: 'Doorgaan',
                en: 'Continue',
                fa: 'Continue', // right-to-left language. not officially supported.
                fr: 'Continuer',
                de: 'Fortfahren',
                hi: 'जारी रखें',
                hmn: 'Txuas Ntxiv',
                is: 'Halda áfram',
                ilo: 'Agpatuloy',
                it: 'Continua',
                ja: '続ける',
                ko: '잇다',
                nb: 'Fortsett',
                pl: 'Dalej',
                pt: 'Continuar',
                ro: 'Continuare',
                ru: 'Продолжить',
                so: 'Sii Soco',
                es: 'Continuar',
                sv: 'Fortsätt',
                tl: 'Magpatuloy',
                tr: 'Devam',
                vi: 'Tiếp tục',
                vt: 'Tiếp tục',
                cy: 'Parhau'
            }
        }
    }
};
