import {Validators} from '@angular/forms';
import {INPUT_TYPE_ENUM} from '@clients/shared/common-forms';

import {IDV_TYPE_ENUM} from '../../../enum/IdvType.enum';
import {IVerificationFieldConfig} from '../../../models/verificationFields/IVerificationFieldConfig';

export const inputConfigMap: {[key: string]: IVerificationFieldConfig} = {
    [IDV_TYPE_ENUM.DOB]: {
        type: INPUT_TYPE_ENUM.DOB,
        validators: [Validators.required],
        editableProp: 'value',
        label: {
            en: 'What is your birth date?',
            es: '¿Cuál es su fecha de nacimiento?',
            ch: '您的出生日期是？',
            vt: 'Ngày sinh của quý vị là gì?',
            ko: '귀하의 생년월일은 언제입니까?',
            ru: 'Какова дата Вашего рождения?'
        }
    }
};
