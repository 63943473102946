import {Observable} from 'rxjs';
import {ErrorObservable} from 'rxjs-compat/observable/ErrorObservable';

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {IdvService} from '@clients/member-direct/idv';

export const idvNotYetCompleteError = ErrorObservable.create(
    'Navigation blocked: IDV is not required or already complete'
);

@Injectable()
export class IdvNotYetCompleteGuard implements CanActivate {
    constructor(private idvService: IdvService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | Observable<any> {
        // if IDV is required and not yet complete, allow the component
        // to load.
        const requiresIdv = this.idvService.isRequired();
        const idvVerificationComplete = this.idvService.isVerified();
        if (requiresIdv && !idvVerificationComplete) return true;

        const surveyContext = route.paramMap.get('context');
        this.router.navigate([`/survey/${surveyContext}/start`]);

        return idvNotYetCompleteError;
    }
}
