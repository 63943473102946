<member-direct-skip-button (click)="skipToContent()"></member-direct-skip-button>
<clients-grid-container>
    <clients-grid-layout
        class="main-client-container"
        [layout]="LAYOUT.ROW"
        [layoutXs]="LAYOUT.COLUMN"
        [layoutSm]="LAYOUT.COLUMN"
    >
        <div class="sidebar"><member-direct-header></member-direct-header></div>
        <div #mainView class="main-view" role="main" tabindex="-1"><router-outlet></router-outlet></div>
    </clients-grid-layout>
</clients-grid-container>

<clients-bar-loader [fullScreen]="true"></clients-bar-loader>
