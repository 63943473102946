/* eslint-disable @typescript-eslint/no-unused-vars */
import {Directive, Injector, Input} from '@angular/core';
import {WithLanguageAbstract} from '@clients/member-direct/translation';

import {selectSelectedLanguage} from './+state';

/*
 * todo deprecate that element https://healthtel.atlassian.net/browse/SB-1011
 * */
@Directive()
export abstract class BaseElement extends WithLanguageAbstract<any> {
    @Input() public multipartQuestionHeader: string;
    @Input() public multipartQuestionId: string;

    protected constructor(injector: Injector) {
        super(injector, selectSelectedLanguage);
    }

    protected languageChanged(language: string) {}
}
