import * as faker from 'faker';

import {getSurveyType} from '../../lib/testing/random-survey-type.function';
/* istanbul ignore next */
import {Survey as ISurvey} from './ISurvey';
import {SurveyBlockCreator} from './ISurveyBlock';
import {SurveySchema} from './ISurveySchema';

const defaultSchema = {
    blocks: [new SurveyBlockCreator(), new SurveyBlockCreator(), new SurveyBlockCreator()],
    disclaimer: {
        en: 'disclaimer',
        es: 'disclaimer español'
    }
};

export class Survey implements ISurvey {
    public id;
    public name;

    /* istanbul ignore next */
    constructor(
        public i: string = faker.datatype.uuid(),
        public n: string = faker.random.word(),
        public schema: SurveySchema = defaultSchema,
        public type = getSurveyType()
    ) {
        this.id = i;
        this.name = n;
    }
}
