import {Component, Input} from '@angular/core';

type TIcons = 'hamburger' | 'language' | 'language-accessible' | 'close';

@Component({
    selector: 'r-svg-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent {
    @Input()
    name: TIcons;

    constructor() {}
}
