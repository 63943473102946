import {map, mergeMap} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {
    IdentityVerifiedAction,
    IdvActionTypes,
    IdvNotRequiredAction,
    IdvResponse,
    VerifyIdentityAction
} from '@clients/member-direct/idv';
import {SurveyFacade} from '@clients/member-direct/surveys';
import {AuthProvider, IdvLoginPayload} from '@clients/shared/auth';
import {PersistenceProvider} from '@clients/shared/persistence';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {RouterNavigateAction} from '../app/app.actions';

@Injectable()
export class IdvEffects {
    idvNotRequired = createEffect(
        () =>
            this.actions$.pipe(
                ofType(IdvActionTypes.IDV_NOT_REQUIRED),
                map((action: IdvNotRequiredAction) => action.payload),
                map(() => {
                    const enrollmentId: string = this.surveyFacade.getEnrollmentId();
                    const tenantId: string = this.surveyFacade.getTenantId();
                    const surveyRecordId: string = this.surveyFacade.getSurveyRecordId();
                    const payload: IdvLoginPayload = {
                        enrollmentId,
                        tenantId,
                        surveyRecordId,
                        values: []
                    };
                    this.idv.login(payload);
                })
            ),
        {dispatch: false}
    );

    verifyIdentity = createEffect(
        () =>
            this.actions$.pipe(
                ofType(IdvActionTypes.VERIFY_IDENTITY),
                map((action: VerifyIdentityAction) => action.payload),
                map((values: IdvResponse[]) => {
                    const enrollmentId: string = this.surveyFacade.getEnrollmentId();
                    const tenantId: string = this.surveyFacade.getTenantId();
                    const surveyRecordId: string = this.surveyFacade.getSurveyRecordId();
                    const payload: IdvLoginPayload = {
                        enrollmentId,
                        tenantId,
                        surveyRecordId,
                        values
                    } as IdvLoginPayload;
                    this.idv.login(payload);
                })
            ),
        {dispatch: false}
    );

    identityVerified = createEffect(() =>
        this.actions$.pipe(
            ofType(IdvActionTypes.IDENTITY_VERIFIED),
            map((action: IdentityVerifiedAction) => action.payload),
            mergeMap((token: string) => {
                // associate token to the survey
                const decoded = this.idv.decodeToken(token);
                if (decoded) {
                    this.persistence.set(decoded.surveyRecordId, token);
                }

                // if there's no valid token in the services at that point, the app would error after hitting start
                return [
                    new RouterNavigateAction({
                        route: `survey/${this.surveyFacade.getSurveyContext()}/start`,
                        options: null
                    })
                ];
            })
        )
    );

    constructor(
        private actions$: Actions,
        private idv: AuthProvider,
        private surveyFacade: SurveyFacade,
        private persistence: PersistenceProvider
    ) {}
}
