import {ScrollToAnimationEasing} from '@nicky-lenaers/ngx-scroll-to';

const easing: ScrollToAnimationEasing = 'easeOutQuad';

export const surveysConfig = {
    elements: {
        scrolling: {
            minimumScrollOffset: -100,
            scrollDuration: 320,
            easing
        }
    }
};
