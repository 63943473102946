import {Component, Input} from '@angular/core';

@Component({
    selector: 'member-direct-survey-name',
    styleUrls: ['./survey-name.component.scss'],
    template: ` <div class="survey-name">{{ name }}</div> `
})
export class SurveyNameComponent {
    @Input()
    public name: string;

    constructor() {}
}
