import {takeUntil} from 'rxjs/operators';

import {Component, Input, OnInit} from '@angular/core';
import {SurveyConfigService} from '@clients/member-direct/surveys';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';

@Component({
    selector: 'member-direct-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['./quote.component.scss']
})
export class QuoteComponent extends UntilComponentDestroyed implements OnInit {
    @Input()
    public quote: string;
    @Input()
    public author: string;
    @Input()
    public authorImage?: string;
    @Input()
    public authorDescription: string;

    public LAYOUT = FLEX_LAYOUT_ENUM;
    imageLocation: string;

    constructor(public surveyConfigService: SurveyConfigService) {
        super();
    }

    ngOnInit() {
        this.surveyConfigService.surveyConfigUpdated.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.imageLocation = this.surveyConfigService.getUse508ComplianceDefaults()
                ? '/assets/images/icon-quote-508.svg'
                : '/assets/images/icon-quote.svg';
        });
    }
}
