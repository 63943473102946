import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';
import {
    ISurveyResponse,
    MultiSelectSurveyResponse,
    SeededSurveyResponse,
    SurveyResponse
} from '../../../models/survey-responses/SurveyResponse.interface';
import {SurveySeededResponse} from '../../../models/survey-responses/SurveySeededResponse.interface';
import {HttpErrorResponse} from '@angular/common/http';

export const ResponsesActionTypes = {
    RESET: type('[responses] reset'),

    SEED_RESPONSES: type('[responses] seed responses'),
    RESPONSE_SEEDED: type('[responses] response seeded'),

    RECORD_RESPONSE: type('[responses] record response'),
    RECORD_RESPONSES: type('[responses] record responses'),

    REMOVE_RESPONSE: type('[responses] remove response'),

    RESPONSE_RECORDED: type('[responses] response recorded'),
    RESPONSES_RECORDED: type('[responses] responses recorded'),
    RESPONSE_RECORDED_ERROR: type('[responses] response recorded error'),

    RESPONSE_RECORDED_MULTI_SELECT: type('[responses] multi-select question response recorded'),
    RESPONSE_RECORDED_MULTI_SELECT_NONE_OPTION: type('[responses] multi-select question response none option recorded'),
    RESPONSE_RECORDED_MULTI_SELECT_REMOVE_NONE_OPTION: type(
        '[responses] multi-select question response - remove none option'
    ),
    RESPONSE_RECORDED_MULTI_SELECT_UPDATED: type('[responses] multi-select question responses have been updated'),
    SAVE_MULTI_SELECT_ANSWERS: type('[responses] save the multi select answers'),

    DELETE_RESPONSE: type('[responses] delete response'),
    DELETE_RESPONSE_RECORDED: type('[responses] delete response recorded'),

    RESPONSE_LOGGED: type('[responses] response logged'),
    RESPONSES_LOGGED: type('[responses] responses logged'),
    RESPONSE_SUBMITTED: type('[responses] response submitted'),

    RESPONSES_COMPLETE: type('[responses] responses complete'),

    QUESTION_ANSWERED: type('[responses] question answered'),
    DELETE_QUESTION_DATA: type('[responses] delete question data'),
    QUESTION_DATA_DELETED: type('[responses] question data deleted'),

    REHYDRATE: type('[responses] rehydrate')
};

/**
 * Seed responses is different from the other response recording actions because it
 * does not expect a fully-formed response but rather expects there to just be a
 * single ID and a single string value.
 */
export class SeedResponsesAction
    implements
        Action<{
            [questionId: string]: string;
        }>
{
    public type = ResponsesActionTypes.SEED_RESPONSES;

    constructor(
        public payload: {
            [questionId: string]: string;
        }
    ) {}
}

export class ResetResponsesAction implements Action {
    public type = ResponsesActionTypes.RESET;
}

export class ResponseSeededAction implements Action<SurveySeededResponse> {
    public type = ResponsesActionTypes.RESPONSE_SEEDED;

    constructor(public payload: SurveySeededResponse) {}
}

export class RecordResponseAction implements Action<SurveyResponse | SeededSurveyResponse> {
    public type = ResponsesActionTypes.RECORD_RESPONSE;

    constructor(public payload: SurveyResponse | SeededSurveyResponse) {}
}

export class RecordResponsesAction implements Action<SurveyResponse[] | SeededSurveyResponse[]> {
    public type = ResponsesActionTypes.RECORD_RESPONSES;

    constructor(public payload: SurveyResponse[] | SeededSurveyResponse[]) {}
}

export class DeleteResponseAction implements Action<SurveyResponse | SeededSurveyResponse> {
    public type = ResponsesActionTypes.DELETE_RESPONSE;

    constructor(public payload: SurveyResponse | SeededSurveyResponse) {}
}

export class DeleteResponseRecordedAction implements Action<SurveyResponse | SeededSurveyResponse> {
    public type = ResponsesActionTypes.DELETE_RESPONSE_RECORDED;

    constructor(public payload: SurveyResponse | SeededSurveyResponse) {}
}

export class RemoveResponseAction implements Action<SurveyResponse | MultiSelectSurveyResponse> {
    public type = ResponsesActionTypes.REMOVE_RESPONSE;

    constructor(public payload: SurveyResponse | MultiSelectSurveyResponse) {}
}

export class ResponseRecordedAction implements Action<SurveyResponse> {
    public type = ResponsesActionTypes.RESPONSE_RECORDED;

    constructor(public payload: SurveyResponse) {}
}

export class ResponsesRecordedAction implements Action<SurveyResponse[]> {
    public type = ResponsesActionTypes.RESPONSES_RECORDED;

    constructor(public payload: SurveyResponse[]) {}
}

export class ResponseRecordedErrorAction implements Action<HttpErrorResponse> {
    public type = ResponsesActionTypes.RESPONSE_RECORDED_ERROR;

    constructor(public payload: HttpErrorResponse) {}
}

export class MultiSelectResponseRecordedAction implements Action<MultiSelectSurveyResponse> {
    public type = ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT;

    constructor(public payload: MultiSelectSurveyResponse) {}
}

export class MultiSelectNoneOptionResponseRecordedAction implements Action<MultiSelectSurveyResponse> {
    public type = ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT_NONE_OPTION;

    constructor(public payload: MultiSelectSurveyResponse) {}
}

export class MultiSelectResponseRemoveNoneAction implements Action<string> {
    public type = ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT_REMOVE_NONE_OPTION;

    // the questionId for which to remove the none response
    constructor(public payload: string) {}
}

export class MultiSelectResponsesUpdatedAction implements Action<string> {
    public type = ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT_UPDATED;

    // the questionId
    constructor(public payload: string) {}
}

export class SaveMultiSelectAnswersAction implements Action<ISurveyResponse[]> {
    public type = ResponsesActionTypes.SAVE_MULTI_SELECT_ANSWERS;

    constructor(public payload: ISurveyResponse[]) {}
}

export class ResponseLoggedAction implements Action<SurveyResponse | MultiSelectSurveyResponse> {
    public type = ResponsesActionTypes.RESPONSE_LOGGED;

    constructor(public payload: SurveyResponse | MultiSelectSurveyResponse) {}
}

export class ResponsesLoggedAction implements Action<SurveyResponse[] | MultiSelectSurveyResponse[]> {
    public type = ResponsesActionTypes.RESPONSES_LOGGED;

    constructor(public payload: SurveyResponse[] | MultiSelectSurveyResponse[]) {}
}

export class ResponsesCompleteAction implements Action {
    public type = ResponsesActionTypes.RESPONSES_COMPLETE;
}

export class ResponseSubmittedAction implements Action {
    public type = ResponsesActionTypes.RESPONSE_SUBMITTED;
}

export class QuestionAnsweredAction implements Action<string> {
    public readonly type: string = ResponsesActionTypes.QUESTION_ANSWERED;

    constructor(public payload: string) {}
}

export class DeleteQuestionDataAction implements Action<string> {
    public type = ResponsesActionTypes.DELETE_QUESTION_DATA;

    // questionId
    constructor(public payload: string) {}
}

export class QuestionDataDeleted implements Action<string> {
    public type = ResponsesActionTypes.QUESTION_DATA_DELETED;

    // questionId
    constructor(public payload: string) {}
}

export class RehydrateResponsesAction implements Action<any> {
    public type = ResponsesActionTypes.REHYDRATE;

    constructor(public payload: any) {}
}
