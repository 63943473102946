import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';

@Component({
    selector: 'member-direct-navigation-mobile',
    templateUrl: './navigation-mobile.component.html',
    styleUrls: ['./navigation-mobile.component.scss']
})
export class NavigationMobileComponent implements OnInit {
    @Input()
    display: boolean;

    @Input()
    isOpen: boolean;

    @Input()
    name: string;

    @Input()
    showName: boolean;

    @Input()
    instructions?: string;

    @Input()
    layoutGap? = '20px';

    @Output()
    closeHandler: EventEmitter<any> = new EventEmitter();

    public LAYOUT = FLEX_LAYOUT_ENUM;

    constructor() {}

    ngOnInit() {
        if (!this.showName) this.name = '';
    }

    public onClose() {
        this.closeHandler.emit();
    }
}
