import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    DisableSurveyResumeAction,
    formatResponseQueryParams,
    SeedResponsesAction,
    ValidateSurveyAction
} from '@clients/member-direct/surveys';
import {ENV_SERVICE} from '@clients/shared/config';
import {LoadingAction} from '@clients/shared/loading';
import {Store} from '@ngrx/store';

import {SurveyIsNotPreauthorizedAction, SurveyIsPreauthorizedAction} from '../../+state/surveys/surveys.actions';
import {AdminEnvService} from '@clients/admin/admin-common';

@Component({
    selector: 'member-direct-bootstrap',
    templateUrl: './bootstrap.component.html',
    styleUrls: ['./bootstrap.component.scss']
})
export class BootstrapComponent implements OnInit {
    public loading = true;
    private retryLimit: number;
    constructor(
        @Inject(ENV_SERVICE) envService: AdminEnvService,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<any>
    ) {
        this.retryLimit = envService.surveySettings.idvRetryLimit;
    }

    ngOnInit() {
        // launch loader
        this.store.dispatch(new LoadingAction());

        // handle our query params
        const queryParams = this.route.snapshot.queryParams;
        /* istanbul ignore else  */
        if (queryParams) this.processQueryParams(queryParams);

        // get the survey context (the short link) and validate
        const context: string = this.route.snapshot.paramMap.get('context');
        this.store.dispatch(new ValidateSurveyAction(context));
    }

    private processQueryParams(queryParams: any): void {
        this.processSeededData(queryParams);
        this.processToken(queryParams);
        this.processDisableResume(queryParams);

        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {}
        });
    }

    /**
     * If seeded data was passed into the URL, we need to format those seeded responses
     * and get them in to the store. Seeded data has the following format:
     * http://localhost:4200/survey/6K30icPQ?data=19c152d7-cb98-4890-8001-b2ec9b65af0c:true
     * the important part being:
     * ?data=19c152d7-cb98-4890-8001-b2ec9b65af0c:true
     * If you wanted to pass multiple seeded responses it would look like this:
     * ?data=19c152d7-cb98-4890-8001-b2ec9b65af0c:true,1234567-cb98-4890-8001-b2ec9b65af0c:false
     * and each id:value would be separated by a comma.
     */
    private processSeededData(queryParams: any): void {
        if (queryParams.data) {
            const formattedSeedData = formatResponseQueryParams(queryParams.data);
            this.store.dispatch(new SeedResponsesAction(formattedSeedData));
        }
    }

    /**
     * This checks for the existence of a token in the URL and, if found, sets
     * our survey to preauthorized mode. This means that the token that was passed
     * in *must* be used and if it is invalid, the user will get an error.
     */
    private processToken(queryParams: any): void {
        // check for the existence of a token in query params
        const tokenFromQueryParams: string = queryParams.token;
        const action = tokenFromQueryParams
            ? new SurveyIsPreauthorizedAction(tokenFromQueryParams)
            : new SurveyIsNotPreauthorizedAction();
        this.store.dispatch(action);
    }

    /**
     * If the survey link contains `?disableResume=true` then we want to disable
     * our re-hydration of the client state. This is used when someone wants to
     * be able to use the same link over and over and start at the beginning of
     * the survey any time they want to. NOTE: since we update the client state
     * when a block is loaded, once `?disableResume=true` is passed one time, that
     * will, in essence, completely clear the client state.
     */
    private processDisableResume(queryParams: any): void {
        if (queryParams.disableResume === 'true') {
            this.store.dispatch(new DisableSurveyResumeAction());
        }
    }
}
