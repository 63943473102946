<div class="container" *ngIf="ready">
    <div *ngIf="locked | async">
        <h2 class="error" data-e2e="idv-message">{{ lockedMessage }}</h2>
    </div>

    <div *ngIf="(locked | async) === false">
        <h2 data-e2e="idv-message">{{ mainMessage }}</h2>

        <div class="error" data-e2e="idv-error" *ngIf="failed | async">
            {{ retryMessage }}
        </div>

        <div class="verifying" *ngIf="posting | async">
            <clients-circle-loader [fullScreen]="false" [active]="true"></clients-circle-loader>
        </div>

        <form (ngSubmit)="submit()">
            <ng-container *ngFor="let field of viewModel">
                <idv-render-verification-field [field]="field" [language]="language" [datePipeFormat]="dobUiFormat">
                </idv-render-verification-field>
            </ng-container>
            <r-button [disabled]="form.invalid" type="submit" class="submit" data-e2e="idv-button-submit">
                {{ submitButtonText }}
            </r-button>
        </form>
    </div>
</div>
