import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'surveys-element-question-text',
    template: `
        <div
            class="surveys-element-question-text"
            [attr.id]="id"
            [ngClass]="cssClasses"
            [innerHTML]="text ? textVariable : ('' | trustHtml)"
        ></div>
    `,
    styleUrls: ['./element-question-text.component.scss']
})
export class ElementQuestionTextComponent implements OnInit, OnChanges {
    @Input() public text: string;
    @Input() public textSize: 'small' | 'large' = 'large';
    @Input() public classes: string[];
    @Input() public required: boolean;
    @Input() public id: string;

    public textSafe: SafeHtml;
    public cssClasses: string[];
    textVariable: any;

    constructor(private sanitizer: DomSanitizer) {}

    public ngOnInit(): void {
        this.cssClasses = this.buildCssClasses();
        this.astriskRequired();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // update css classes when the textSize or classes has been changed after the initial load
        const shouldCssClassesBeUpdated = this.shouldCssClassesBeUpdated(changes);
        shouldCssClassesBeUpdated && this.buildCssClasses();
        this.astriskRequired();
    }

    astriskRequired() {
        const astrisk =
            this.text +
            ' <span style="color: #ff859f!important;vertical-align: super!important; font-size: 0.8em!important;">*</span>';
        this.textVariable = this.required ? this.sanitizer.bypassSecurityTrustHtml(astrisk) : this.text;
    }

    public buildCssClasses() {
        const defaultClasses = ['text', this.textSize];
        return this.classes ? defaultClasses.concat(this.classes) : defaultClasses;
    }

    private shouldCssClassesBeUpdated(changes: SimpleChanges): boolean {
        const {textSize, classes} = changes;
        const textSizeUpdated = textSize && !textSize.firstChange && textSize.previousValue !== textSize.currentValue;
        const classesUpdated = classes && !classes.firstChange && classes.previousValue !== classes.currentValue;
        return !!(textSizeUpdated || classesUpdated);
    }
}
