import {find as _find} from 'lodash';
import {combineLatest as observableCombineLatest} from 'rxjs';

import {select, Store} from '@ngrx/store';

interface Data {
    ids: Array<string>;
    entities: any;
    array: Array<any>;
    total: number;
}

export abstract class StoreDataFacade<T> {
    private data;

    protected constructor(
        protected store: Store<T>,
        private selectIdsSelector,
        private selectEntitiesSelector,
        private selectAllSelector,
        private selectTotalSelector
    ) {
        observableCombineLatest(
            this.store.pipe(select(selectIdsSelector)),
            this.store.pipe(select(selectEntitiesSelector)),
            this.store.pipe(select(selectAllSelector)),
            this.store.pipe(select(selectTotalSelector))
        ).subscribe(([ids, entities, array, total]) => {
            this.data = {
                ids,
                entities,
                array,
                total
            };
        });
    }

    public getIds(): string[] | number[] {
        return this.data.ids;
    }

    public getEntities(): any {
        return this.data.entities;
    }

    public getArray(): any[] {
        return this.data.array;
    }

    public getTotal(): number {
        return this.data.total;
    }

    public exists(id: string | number): boolean {
        if (!this.data.entities) return false;

        return id in this.data.entities;
    }

    public doesNotExist(id: string | number): boolean {
        if (!this.data.entities) return false;

        return !(id in this.data.entities);
    }

    public get(id): any {
        if (!this.data.entities) return null;

        return this.data.entities[id];
    }

    public getMany(ids: Array<string>): Array<any> {
        if (!this.data.entities) return [];

        return this.data.array.filter((item) => ids.includes(item.id));
    }

    public find(props: any): any {
        return _find(this.getArray(), props);
    }
}
