import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';
import {SurveyElement} from '../../../models/survey/ISurveyElement';

export const ElementsActionTypes = {
    RESET: type('[elements] reset'),

    ELEMENTS_LOADED: type('[elements] elements loaded'),
    HIDE_ELEMENTS: type('[elements] hide elements at load time'),
    SHOW_ELEMENT: type('[elements] show an element'),
    HIDE_ELEMENT: type('[elements] hide an element'),

    HIGHLIGHT_ELEMENT: type('[elements] highlight an element'),
    HIGHLIGHT_ELEMENT_WITHOUT_SCROLL: type('[elements] highlight an element without scroll'),
    ELEMENT_HIGHLIGHTED: type('[elements] an element was highlighted'),
    SCROLL_TO_ELEMENT: type('[elements] scroll to element'),
    ELEMENT_SCROLL_COMPLETED: type('[elements] scrolling to an element complete'),

    REHYDRATE: type('[elements] rehydrate')
};

export class ResetElementsAction implements Action {
    public type = ElementsActionTypes.RESET;
}

export class ElementsLoadedAction implements Action<SurveyElement[]> {
    public type = ElementsActionTypes.ELEMENTS_LOADED;

    constructor(public payload: SurveyElement[]) {}
}

export class HideElementsAction implements Action<string[]> {
    public type = ElementsActionTypes.HIDE_ELEMENTS;

    constructor(public payload: string[]) {}
}

export class ShowElementAction implements Action<string> {
    public type = ElementsActionTypes.SHOW_ELEMENT;

    // element id
    constructor(public payload: string) {}
}

export class HideElementAction implements Action<string> {
    public type = ElementsActionTypes.HIDE_ELEMENT;

    // element id
    constructor(public payload: string) {}
}

export class HighlightElementAction implements Action<string> {
    public type = ElementsActionTypes.HIGHLIGHT_ELEMENT;

    // element id
    constructor(public payload: string) {}
}

// todo | refactor HighlightElementAction to accept a property that determines whether the page should scroll https://healthtel.atlassian.net/browse/SB-992
export class HighlightElementWithoutScrollAction implements Action<string> {
    public type = ElementsActionTypes.HIGHLIGHT_ELEMENT_WITHOUT_SCROLL;

    // element id
    constructor(public payload: string) {}
}

export class ElementHighlightedAction implements Action<string> {
    public type = ElementsActionTypes.ELEMENT_HIGHLIGHTED;

    // element id
    constructor(public payload: string) {}
}

export class ScrollToElementAction implements Action<string> {
    public type = ElementsActionTypes.SCROLL_TO_ELEMENT;

    // element id
    constructor(public payload: string) {}
}

export class ElementScrollCompletedAction implements Action<string> {
    public type = ElementsActionTypes.ELEMENT_SCROLL_COMPLETED;

    // element id
    constructor(public payload: string) {}
}

export class RehydrateElementsAction implements Action<any> {
    public type = ElementsActionTypes.REHYDRATE;

    constructor(public payload: any) {}
}
