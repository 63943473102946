<div
    *ngIf="element"
    class="surveys-accordion-group surveys-element-{{ element.id }}"
    [attr.data-e2e]="'accordion-group-' + element.id"
>
    <div *ngFor="let accordion of accordions" class="accordion-element">
        <surveys-element-accordion
            [element]="accordion"
            (opened)="accordionOpened($event)"
            (closed)="accordionClosed($event)"
        >
        </surveys-element-accordion>
    </div>
</div>
