import {Component, Injector, Input, OnInit} from '@angular/core';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {SurveyFacade} from '../../+state/survey/survey.facade';
import {BaseElement} from '../../base-element.abstract';
import {SurveyElementType} from '../../enum/SurveyElementType';
import {ISurveyAccordion} from '../../../models/survey/ISurveyContent';
import {SurveyService} from '../../services/survey.service';

// @deprecated
@Component({
    selector: 'surveys-element-suggested-care',
    templateUrl: './element-suggested-care.component.html',
    styleUrls: ['./element-suggested-care.component.scss']
})
export class ElementSuggestedCareComponent extends BaseElement implements OnInit {
    @Input()
    public element: SurveyElement;

    public elements: SurveyElement[];

    /* istanbul ignore file */
    constructor(private surveyFacade: SurveyFacade, private surveyService: SurveyService, injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        // todo | make this smarter https://healthtel.atlassian.net/browse/SB-1005
        setTimeout(() => {
            this.loadCareSuggestions();
        }, 500);
    }

    private loadCareSuggestions(): void {
        const surveyRecordId = this.surveyFacade.getSurveyRecordId();
        this.surveyService.careSuggestions(surveyRecordId).subscribe((res: ISurveyAccordion[]) => {
            // wrap the accordions in an accordion group
            const accordionGroup = {
                ...new SurveyElement(SurveyElementType.ACCORDION_GROUP),
                data: {
                    content: res,
                    autoClose: true
                }
            } as SurveyElement;

            this.elements = [accordionGroup];
        });
    }
}
