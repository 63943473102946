import {Injectable} from '@angular/core';
import {get} from 'lodash';
import {ISurveyBlock} from '../../models/survey/ISurveyBlock';
import {select, Store} from '@ngrx/store';
import {selectSelectedBlock} from '../+state';

@Injectable({
    providedIn: 'root'
})
export class BlockConfigService {
    private block: ISurveyBlock;

    constructor(private store: Store<any>) {
        this.subscribe();
    }

    public shouldShowBlockHeader(block: ISurveyBlock = null): boolean {
        return get(block || this.block, 'config.questions.showBlockHeading.enabled');
    }

    public shouldRenderInScrollMode(block: ISurveyBlock = null): boolean {
        return get(block || this.block, 'config.questions.scrollMode.enabled');
    }

    public shouldRenderInSingleQuestionMode(block: ISurveyBlock = null): boolean {
        return get(block || this.block, 'config.questions.singleQuestionMode.enabled');
    }

    public shouldShowQuestionCounter(block: ISurveyBlock = null): boolean {
        return get(block || this.block, 'config.questions.showQuestionCounter.enabled');
    }

    public shouldAlwaysAdvance(block: ISurveyBlock = null): boolean {
        return get(block || this.block, 'config.questions.advanceAlways.enabled');
    }

    /**
     * Returns true if users can scroll and activate questions simply
     * by having them in the "hit region". If this returns false, a user
     * will have to answer every question and the only way to scroll will be
     * for us to do it programmatically.
     *
     * @return {boolean}
     */
    public shouldAllowUsersToScroll(block: ISurveyBlock = null): boolean {
        return !get(block || this.block, 'config.questions.scrollMode.disableQuestionSkipping');
    }

    private subscribe(): void {
        this.store.pipe(select(selectSelectedBlock)).subscribe((block: ISurveyBlock) => (this.block = block));
    }
}
