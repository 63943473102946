import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SurveyFacade} from '../+state/survey/survey.facade';

export const LINK_HEADER_NAME = 'rh-short-code';
export const SKIP_LINK_HEADER = 'x-skip-link-header';

@Injectable()
export class LinkIdInterceptor implements HttpInterceptor {
    constructor(private surveyFacade: SurveyFacade) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const linkId = this.surveyFacade.getSurveyContext();

        let request = req;

        if (request.headers.has(SKIP_LINK_HEADER)) {
            request = req.clone({
                headers: req.headers.delete(SKIP_LINK_HEADER)
            });
        } else if (linkId) {
            request = request.clone({
                setHeaders: {
                    [LINK_HEADER_NAME]: linkId
                }
            });
        }

        return next.handle(request);
    }
}
