<div class="pharmacy">
    <clients-grid-layout [layout]="LAYOUT.ROW" [layoutXs]="LAYOUT.COLUMN" [layoutSm]="LAYOUT.COLUMN" [layoutGap]="0">
        <div class="meta">
            <div class="name">{{ pharmacy.properties.name }}</div>
            <div class="meta">
                <div class="tab green">$0</div>
                <div class="tab blue">In Network</div>
                <div class="tab grey">{{ pharmacy.properties.distanceInMiles }} Miles</div>
            </div>
        </div>

        <div class="address">
            <div class="wrapper">
                <r-address [address]="address" [linkToMap]="true"></r-address>

                <div *ngIf="phoneNumber" class="phone">
                    <a href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a>
                </div>
            </div>
        </div>
    </clients-grid-layout>
</div>
