import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';
import {NavigationEnd, Router} from '@angular/router';
import {AppActions} from '@clients/shared/utilities';
import {BlockActivatedAction} from '@clients/member-direct/surveys';

@Component({
    selector: 'member-direct-page-layout',
    templateUrl: './page-layout.component.html',
    styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent implements OnInit {
    @ViewChild('mainView')
    private mainView: ElementRef;

    public LAYOUT = FLEX_LAYOUT_ENUM;

    constructor(private router: Router, private appActions: AppActions) {}

    ngOnInit() {
        this.initScrollWatchers();
    }

    public skipToContent(element = this.mainView.nativeElement) {
        element.focus();
    }

    private initScrollWatchers() {
        // watch for router changes and scroll to top
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.scrollViewToTop();
            }
        });

        // watch for block changes and scroll to top
        this.appActions.ofType(new BlockActivatedAction(null).type).subscribe(() => this.scrollViewToTop());
    }

    private scrollViewToTop() {
        this.mainView.nativeElement.scrollTop = 0;
        // this is needed for mobile and smaller screens
        window.scrollTo(0, 0);
    }
}
