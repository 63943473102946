import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
    ActivateBlockAction,
    getBlockId,
    selectAllBlocks,
    selectCompletedBlocks,
    selectSeenBlocks,
    selectSelectedBlockId,
    ISurveyBlock,
    SurveyConfigService
} from '@clients/member-direct/surveys';
import {combineLatest, Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';
import {get} from 'lodash';
import {selectApplicationLanguage} from '../../+state';

@Component({
    selector: 'member-direct-sidebar-block-navigation',
    templateUrl: './sidebar-block-navigation.component.html',
    styleUrls: ['./sidebar-block-navigation.component.scss']
})
export class SidebarBlockNavigationComponent extends UntilComponentDestroyed implements OnInit, OnDestroy {
    @Output()
    blockLoaded: EventEmitter<ISurveyBlock> = new EventEmitter<ISurveyBlock>();

    public blocks: Observable<ISurveyBlock[]>;
    public selectedBlockId: Observable<string>;
    public seenBlocks: Observable<string[]>;
    public completedBlocks: Observable<string[]>;

    public appLanguage: string;

    // block states
    public selected: string;
    public seen: string[] = [];
    public completed: string[] = [];
    public unlockedBlockNavigation: boolean;

    constructor(private store: Store<any>, private surveyConfigService: SurveyConfigService) {
        super();
    }

    ngOnInit() {
        this.blocks = this.store.pipe(select(selectAllBlocks), takeUntil(this.destroyed$));

        this.selectedBlockId = this.store.pipe(select(selectSelectedBlockId), takeUntil(this.destroyed$));

        this.seenBlocks = this.store.pipe(select(selectSeenBlocks), takeUntil(this.destroyed$));

        this.completedBlocks = this.store.pipe(select(selectCompletedBlocks), takeUntil(this.destroyed$));

        this.store
            .pipe(select(selectApplicationLanguage), takeUntil(this.destroyed$))
            .subscribe((language: string) => (this.appLanguage = language));

        this.monitorBlockStates();

        this.unlockedBlockNavigation = this.surveyConfigService.unlockedBlockNavigation();
    }

    public loadBlock(block: ISurveyBlock): void {
        if (this.blockHasBeenSeen(block) && this.selected !== getBlockId(block)) {
            this.store.dispatch(new ActivateBlockAction(getBlockId(block)));
            this.blockLoaded.emit(block);
        }
    }

    public blockIsActive(block: ISurveyBlock): boolean {
        return getBlockId(block) === this.selected;
    }

    public blockHasBeenCompleted(block: ISurveyBlock): boolean {
        return this.completed.indexOf(getBlockId(block)) !== -1;
    }

    public blockHasBeenSeen(block: ISurveyBlock): boolean {
        return this.unlockedBlockNavigation || this.seen.indexOf(getBlockId(block)) !== -1;
    }

    public shouldRender(block: ISurveyBlock) {
        return get(block, 'config.block.display.renderInSidebarBlockNavigation', true);
    }

    private monitorBlockStates(): void {
        combineLatest(
            this.selectedBlockId,
            this.seenBlocks,
            this.completedBlocks,
            (selectedBlockId: string, seen: string[], completed: string[]) => ({
                selectedBlockId,
                seen,
                completed
            })
        )
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data: {selectedBlockId: string; seen: string[]; completed: string[]}) => {
                this.seen = data.seen || /* istanbul ignore next */ [];
                this.completed = data.completed || /* istanbul ignore next */ [];
                this.selected = data.selectedBlockId;
            });
    }
}
