import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'r-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() public affirmative: boolean;
    @Input() public classes: string[];
    @Input() public disabled: boolean;
    @Input() public doNotExpand: boolean;
    @Input() public e2e: string;
    @Input() public negative: boolean;
    @Input() public selected: boolean;
    @Input() public selection: boolean;
    @Input() public submitting: boolean;
    @Input() public subtext: string;
    @Input() public type = 'button';
    @Input() public describedby: string;
    @Input() public role: string;

    // eslint-disable-next-line
    @Output() public onClick: EventEmitter<any> = new EventEmitter<any>();
    isBrowserMobile: boolean;
    isMultiSelectQuestion: boolean;

    constructor() {}
    ngOnInit(): void {
        this.isBrowserMobile = this.isMobile();
        this.isMultiSelectQuestion = this.isMultiSelect();
    }

    public isMultiSelect(): boolean {
        if (this.e2e) {
            return this.e2e.includes('multi-select');
        }
    }

    public isMobile(): boolean {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
    public handleClick() {
        if (!this.submitting && !this.disabled) this.onClick.emit();
    }
}
