<div
    *ngIf="element"
    class="surveys-element-accordion surveys-element-{{ element.id }} raised-panel"
    [attr.data-e2e]="'accordion-' + element.id"
>
    <button
        class="header"
        (click)="activate()"
        [attr.aria-expanded]="active ? 'true' : 'false'"
        [attr.aria-controls]="'accordion-content-' + element.id"
    >
        <div class="label" id="accordion-label-{{ element.id }}">{{ element.label[language] }}</div>
        <div class="arrow">
            <img *ngIf="!active" [src]="downArrowImageLocation" alt="" />
            <img *ngIf="active" [src]="upArrowImageLocation" alt="" />
        </div>
    </button>

    <div
        class="content"
        [class.active]="active"
        id="accordion-content-{{ element.id }}"
        [attr.aria-labelledby]="'accordion-label-' + element.id"
        [attr.aria-expanded]="active ? 'true' : 'false'"
        role="region"
        tabindex="-1"
        #contentRegion
    >
        <div class="content-wrapper" *ngIf="active">
            <surveys-render-survey-element
                *ngFor="let element of element?.data?.content"
                [element]="element"
            ></surveys-render-survey-element>
        </div>
    </div>
</div>
