<div
    *ngIf="element"
    class="survey-element surveys-element-yes-no"
    [ngClass]="element?.data?.smallLabel ? 'small' : 'large'"
>
    <surveys-element-question-text
        *ngIf="multipartQuestionHeader"
        [text]="multipartQuestionHeader"
        [textSize]="'small'"
        [classes]="['multipart-label']"
    ></surveys-element-question-text>

    <surveys-element-question-text
        [id]="element.id"
        [required]="element.required"
        [text]="element.label && element.label[language]"
        [textSize]="element?.data?.smallLabel ? 'small' : 'large'"
    ></surveys-element-question-text>

    <div class="buttons {{ buttonsMode }}" [attr.aria-labelledby]="element.id" role="group">
        <r-button
            [selected]="value === true"
            [affirmative]="true"
            [describedby]="element.id"
            [e2e]="'button-' + element.id + '-affirmative'"
            [subtext]="(element.data?.yes)[language]?.subtext"
            (onClick)="execute(true)"
            >{{ (element.data?.yes)[language]?.label || 'Yes' }}
        </r-button>
        <r-button
            [selected]="value === false"
            [subtext]="(element.data?.no)[language]?.subtext"
            [negative]="true"
            [describedby]="element.id"
            [e2e]="'button-' + element.id + '-negative'"
            (onClick)="execute(false)"
            >{{ (element.data?.no)[language]?.label || 'No' }}
        </r-button>
    </div>

    <div *ngIf="element.elements" class="elements">
        <surveys-render-survey-element *ngFor="let element of element.elements" [element]="element">
        </surveys-render-survey-element>
    </div>
</div>
