import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {BlockActivatedAction, BlocksActionTypes} from '../blocks/blocks.actions';
import {ISurveyBlock} from '../../../models/survey/ISurveyBlock';
import {
    ElementHighlightedAction,
    ElementsActionTypes,
    ElementScrollCompletedAction,
    ElementsLoadedAction,
    HideElementsAction,
    HighlightElementAction,
    HighlightElementWithoutScrollAction,
    RehydrateElementsAction,
    ResetElementsAction,
    ScrollToElementAction
} from './elements.actions';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {getElementId} from './elements.reducer';
import {delay, map, mergeMap, tap} from 'rxjs/operators';
import {BlockService} from '../../services/block.service';
import {BlocksFacade} from '../blocks/blocks.facade';
import {surveysConfig} from '../../config/surveys.config';
import {Action} from '@clients/shared/models';

@Injectable()
export class ElementsEffects {
    blockActivated = createEffect(() =>
        this.actions$.pipe(
            ofType(BlocksActionTypes.BLOCK_ACTIVATED),
            map((action: BlockActivatedAction) => action.payload),
            mergeMap((block: ISurveyBlock) => {
                const actions: Action<any>[] = [new ResetElementsAction()];
                if (block && block.content) actions.push(new ElementsLoadedAction(block.content));
                return actions;
            })
        )
    );

    elementsLoaded = createEffect(() =>
        this.actions$.pipe(
            ofType(ElementsActionTypes.ELEMENTS_LOADED),
            map((action: ElementsLoadedAction) => action.payload),
            mergeMap((elements: SurveyElement[]) => {
                const hiddenElementIds: string[] = elements.filter((ele) => ele.hidden).map((ele) => getElementId(ele));

                return [new HideElementsAction(hiddenElementIds)];
            })
        )
    );

    highlightElement = createEffect(() =>
        this.actions$.pipe(
            ofType(ElementsActionTypes.HIGHLIGHT_ELEMENT, ElementsActionTypes.HIGHLIGHT_ELEMENT_WITHOUT_SCROLL),
            map((action: HighlightElementAction | HighlightElementWithoutScrollAction) => {
                const actions: any = [new ElementHighlightedAction(action.payload)];
                return actions;
            })
        )
    );

    scrollToElement = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ElementsActionTypes.SCROLL_TO_ELEMENT, ElementsActionTypes.HIGHLIGHT_ELEMENT),
                tap((action: ElementHighlightedAction) => {
                    setTimeout(() => {
                        this.blockService.elementHighlighted(this.blocksFacade.getActive(), action.payload);
                    }, 10);
                })
            ),
        {dispatch: false}
    );

    scrollToElementDispatchCompletedEvent = createEffect(() =>
        this.actions$.pipe(
            ofType(ElementsActionTypes.SCROLL_TO_ELEMENT),
            delay(surveysConfig.elements.scrolling.scrollDuration),
            map((action: ScrollToElementAction) => action.payload),
            map((elementId: string) => new ElementScrollCompletedAction(elementId))
        )
    );

    rehydrate = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ElementsActionTypes.REHYDRATE),
                tap((action: RehydrateElementsAction) => {
                    if (action.payload.idOfSelectedEntity) {
                        setTimeout(() => {
                            this.blockService.elementHighlighted(
                                this.blocksFacade.getActive(),
                                action.payload.idOfSelectedEntity
                            );
                        }, 100);
                    }
                })
            ),
        {dispatch: false}
    );

    constructor(private actions$: Actions, private blocksFacade: BlocksFacade, private blockService: BlockService) {}
}
