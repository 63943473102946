import {IIdvLocale} from './IIdvLocale';

export const idvFeatureLocalePrefix = 'idv';

export const idvLocale: IIdvLocale = {
    feature: idvFeatureLocalePrefix,
    data: {
        welcome: {
            ar: 'To protect your privacy, we first need to verify your identity.', // right-to-left language. not officially supported.
            hy: 'Ձեր գաղտնիությունը պաշտպանելու համար, նախ պետք է ստուգենք Ձեր ինքնությունը:',
            km: 'ដើម្បីការពារសិទ្ធិឯកជនរបស់អ្នក ជាដំបូងយើងត្រូវផ្ទៀងផ្ទាត់​អត្ត​សញ្ញាណ​របស់អ្នកជាមុនសិន។',
            zh: '為保護您的隱私，我們必須先驗證您的身份。',
            yue: '為保護您的隱私，我們必須先驗證您的身份。',
            cmn: '为了保护您的隐私，我们首先需要验证您的身份。',
            ch: '為保護您的隱私，我們必須先驗證您的身份。',
            cs: 'Abychom mohli chránit vaše soukromí, musíme nejprve ověřit vaši identitu.',
            da: 'Af hensyn til beskyttelsen af dit privatliv, skal vi først bekræfte din identitet.',
            nl: 'Om je privacy te beschermen, moeten we eerst je identiteit verifiëren.',
            en: 'To protect your privacy, we first need to verify your identity.',
            fa: 'To protect your privacy, we first need to verify your identity.', // right-to-left language. not officially supported.
            fr: 'Pour protéger votre vie privée, nous devons d’abord vérifier votre identité.',
            de: 'Zum Schutz Ihrer Privatsphäre müssen wir zunächst Ihre Identität bestätigen.',
            hi: 'आपकी गोपनीयता की सुरक्षा के लिए, हमें पहले आपकी पहचान सत्यापित करनी होगी।',
            hmn: 'Yuav tiv thaiv koj li ntiag tug, peb yuav tsum xub tshuaj xyuas qhov tseeb ntawm koj tus kheej ntiag ua ntej tso.',
            is: 'Til að vernda friðhelgi þína þurfum við fyrst að staðfesta auðkenningu þína.',
            ilo: 'Tapno maprotektaram ti pannakapribadom, masapul nga unaen mi nga iberipika ti identidad mo.',
            it: 'Per proteggere la vostra privacy, dobbiamo verificare innanzitutto la vostra identità.',
            ja: 'プライバシー保護のため、ご本人であることの確認が必要です。',
            ko: '귀하의 개인정보를 보호하기 위해 저희는 귀하의 신원을 먼저 확인해야 합니다',
            nb: 'Av hensyn til personvernet ditt må vi først bekrefte identiteten din.',
            pl: 'Aby chronić Twoją prywatność, najpierw musimy potwierdzić Twoją tożsamość.',
            pt: 'Para proteger a sua privacidade, em primeiro lugar, temos de verificar a sua identidade.',
            ro: 'Pentru a vă proteja confidențialitatea, mai întâi trebuie să vă verificăm identitatea.',
            ru: 'Чтобы защитить вашу конфиденциальность, нам сначала нужно проверить вашу личность.',
            so: 'Si loo ilaaliyo asturnaantaada, waxaan marka hore u baahanahay inaan xaqiijino aqoonsigaaga.',
            es: 'Para proteger su privacidad, primero necesitamos verificar su identidad.',
            sv: 'För att skydda din integritet måste vi först verifiera din identitet.',
            tl: 'Para maprotektahan ang iyong privacy, kailangan muna naming i-verify ang pagkakakilanlan mo.',
            tr: 'Kişisel gizliliğinizi koruyabilmek için önce kimliğinizi doğrulamamız gerekiyor.',
            vi: 'Để bảo vệ quyền riêng tư của quý vị, trước tiên chúng tôi cần xác minh danh tính của quý vị.',
            vt: 'Để bảo vệ quyền riêng tư của quý vị, trước tiên chúng tôi cần xác minh danh tính của quý vị.',
            cy: 'I ddiogelu eich preifatrwydd, rydym angen dilysu pwy ydych chi yn gyntaf.'
        },
        error: {
            retry: {
                ar: 'We’re sorry, the information you entered does not match our records. Please try again', // right-to-left language. not officially supported.
                hy: 'Ներեցեք, Ձեր մուտքագրած տեղեկությունը չի համապատասխանում մեր արձանագրություններին: Խնդրում ենք նորից փորձել',
                km: 'យើងសូមអភ័យទោស ព័ត៌មានដែលអ្នកបានបញ្ចូលមិនត្រូវនឹង​កំណត់​ត្រារបស់យើងទេ។ សូមព្យាយាមម្តងទៀត',
                zh: '很抱歉，您輸入的資訊與資料紀錄不符。請再試一次。',
                yue: '很抱歉，您輸入的資訊與資料紀錄不符。請再試一次。',
                cmn: '很抱歉，您输入的信息与我们的记录不符。请重试。',
                ch: '很抱歉，您輸入的資訊與資料紀錄不符。請再試一次。',
                cs: 'Omlouváme se, informace, které jste zadali, neodpovídají našim záznamům. Zkuste to znovu',
                da: 'Vi beklager. De oplysninger, du har indtastet, matcher ikke dem, vi har registreret. Prøv venligst igen.',
                nl: 'Helaas komt de door jou ingevoerde informatie niet overeen met de informatie die wij hebben. Probeer het opnieuw',
                en: 'We’re sorry, the information you entered does not match our records. Please try again',
                fa: 'We’re sorry, the information you entered does not match our records. Please try again', // right-to-left language. not officially supported.
                fr: 'Désolé, les informations que vous avez saisies ne correspondent pas à nos enregistrements. Veuillez réessayer',
                de: 'Die eingegebenen Informationen stimmen leider nicht mit unseren Unterlagen überein. Bitte versuchen Sie es noch einmal',
                hi: 'हमें खेद है, आपके द्वारा दर्ज की गई जानकारी हमारे रिकॉर्ड से मेल नहीं खाती। कृपया पुन: प्रयास करें',
                hmn: 'Peb thov txim, tej ntaub ntawv uas koj ntaus rau tsis raug raws li peb cov ntaub ntawv teev tseg.Thov sim dua ntxiv',
                is: 'Því miður passa upplýsingarnar sem þú færðir inn ekki við skrár okkar. Vinsamlegast reyndu aftur',
                ilo: 'Pakawanen nakami, dagiti impormasyon nga inserrek mo ket madi nga agpareha dagiti rekord mi. Pakipadasem ulit.',
                it: 'Siamo spiacenti, le informazioni inserite non corrispondono ai nostri registri. Riprovare',
                ja: '申し訳ありません。入力された情報が登録内容に合致しません。もう一度入力してください',
                ko: '죄송합니다. 귀하가 입력하신 정보는 저희 기록과 일치하지 않습니다. 다시 시도해주십시오.',
                nb: 'Vi beklager, men informasjonen du skrev inn, samsvarer ikke med dataene våre. Prøv på nytt',
                pl: 'Przepraszamy, podane informacje nie zgadzają się z naszymi danymi. Spróbuj ponownie',
                pt: 'Lamentamos, mas as informações introduzidas não correspondem aos nossos registos. Tentar de novo',
                ro: 'Ne pare rău, informațiile introduse de dvs. nu corespund înregistrărilor noastre. Încercați din nou',
                ru: 'Извините, введенная вами информация не совпадает с нашими записями. Повторите попытку',
                so: 'Waan ka xunnahay, macluumaadka aad gelisay ma waafaqsana diiwaankayaga. Fadlan isku day mar kale',
                es: 'Lo lamentamos, pero la información que ingresó no coincide con nuestros registros. Por favor, inténtelo de nuevo.',
                sv: 'Informationen du angav stämmer tyvärr inte med våra register. Försök igen',
                tl: 'Paumanhin, hindi tumutugma sa aming mga talaan ang impormasyong inilagay mo. Pakisubukan ulit',
                tr: 'Üzgünüz, girdiğiniz bilgiler kayıtlarımıza uymuyor. Lütfen tekrar deneyin',
                vi: 'Rất tiếc, thông tin quý vị nhập không khớp với hồ sơ của chúng tôi. Vui lòng thử lại',
                vt: 'Rất tiếc, thông tin quý vị nhập không khớp với hồ sơ của chúng tôi. Vui lòng thử lại',
                cy: 'Mae’n ddrwg gennym, nid yw’r wybodaeth a roddoch yn cyd-fynd â’n cofnodion. Rhowch gynnig arall arni'
            },
            locked: {
                ar: 'We’re sorry, the information you entered does not match our records. We will try contacting you again at a later time. If you have questions about your health plan, please call the number on the back of your member ID card.', // right-to-left language. not officially supported.
                hy: 'Ներեցեք, Ձեր մուտքագրած տեղեկությունը չի համապատասխանում մեր արձանագրություններին: Մենք կփորձենք նորից կապ հաստատել Ձեզ հետ ավելի ուշ: Եթե հարցեր ունեք Ձեր առողջապահական ծրագրի մասին, խնդրում ենք զանգահարել Ձեր անդամի ճանաչողական քարտի դարձակողմում գտնվող համարով:',
                km: 'យើងសូមអភ័យទោស ព័ត៌មានដែលអ្នកបានបញ្ចូលមិនត្រូវនឹង​កំណត់​ត្រារបស់យើងទេ។ យើងនឹងព្យាយាមទាក់ទងអ្នកម្តងទៀតនៅពេលក្រោយ។ បើអ្នកមានសំណួរអំពីគម្រោងសុខភាពរបស់អ្នក សូមទូរសព្ទទៅលេខនៅ​លើខ្នងនៃកាតសម្គាល់សមាជិករបស់អ្នក។',
                zh: '很抱歉，您輸入的資訊與資料紀錄不符。我們之後會再試著與您聯絡。如果您有關於健保計劃的疑問，請撥打會員卡背面的電話號碼。',
                yue: '很抱歉，您輸入的資訊與資料紀錄不符。我們之後會再試著與您聯絡。如果您有關於健保計劃的疑問，請撥打會員卡背面的電話號碼。',
                cmn: '很抱歉，您输入的信息与我们的记录不符。我们会尝试稍后再与您联系。如果您对您的健康计划福利存在疑问，请拨打会员 ID 卡背面的号码。',
                ch: '很抱歉，您輸入的資訊與資料紀錄不符。我們之後會再試著與您聯絡。如果您有關於健保計劃的疑問，請撥打會員卡背面的電話號碼。',
                cs: 'Omlouváme se, informace, které jste zadali, neodpovídají našim záznamům. Zkusíme vás znovu kontaktovat později. Pokud máte dotazy týkající se vašeho zdravotního plánu, zavolejte na telefonní číslo na zadní straně ID členské karty.',
                da: 'Vi beklager. De oplysninger, du har indtastet, matcher ikke dem, vi har registreret. Vi vil forsøge at kontakte dig igen senere. Hvis du har spørgsmål til din sundhedsplan, bedes du ringe til nummeret, der er anført på bagsiden af dit ID-medlemskort.',
                nl: 'Helaas komt de door jou ingevoerde informatie niet overeen met de informatie die wij hebben. We proberen later nog eens contact met je op te nemen. Als je vragen heeft over je gezondheidszorg dan bel je het nummer achter op je lidmaatschapskaart.',
                en: 'We’re sorry, the information you entered does not match our records. We will try contacting you again at a later time. If you have questions about your health plan, please call the number on the back of your member ID card.',
                fa: 'We’re sorry, the information you entered does not match our records. We will try contacting you again at a later time. If you have questions about your health plan, please call the number on the back of your member ID card.', // right-to-left language. not officially supported.
                fr: 'Désolé, les informations que vous avez saisies ne correspondent pas à nos enregistrements. Nous essaierons de vous recontacter plus tard. Si vous avez des questions concernant votre régime d’assurance maladie, veuillez appeler le numéro au dos de votre carte d’assurance.',
                de: 'Die eingegebenen Informationen stimmen leider nicht mit unseren Unterlagen überein. Wir werden versuchen, uns zu einem späteren Zeitpunkt erneut mit Ihnen in Verbindung zu setzen. Wenn Sie Fragen zu Ihrem Versicherungsplan haben, rufen Sie bitte die auf der Rückseite Ihrer Versicherungskarte angegebene Nummer an.',
                hi: 'हमें खेद है, आपके द्वारा दर्ज की गई जानकारी हमारे रिकॉर्ड से मेल नहीं खाती। हम आपसे बाद में फिर से संपर्क करने का प्रयास करेंगे। यदि आपके हेल्थ प्लान के बारे में कोई प्रश्न हैं, तो कृपया अपने सदस्य आईडी कार्ड के पीछे नंबर पर कॉल करें।',
                hmn: 'Peb thov txim, tej ntaub ntawv uas koj ntaus rau tsis raug raws li peb cov ntaub ntawv teev tseg. Peb yuav sim hu xov tooj rau koj ntxiv rau lub sij hawm tom qab. Yog koj muaj tej lus nug txog koj qhov kev npaj saib xyuas mob nkeeg, thov hu rau tus xov tooj nyob sab tom qab ntawm koj daim npav ID.',
                is: 'Því miður passa upplýsingarnar sem þú færðir inn ekki við skrár okkar. Við munum reyna að hafa samband við þig seinna. Ef þú ert með spurningar um heilsuáætlun þína skaltu vinsamlegast hringja í númerið aftan á aðildarauðkenniskorti þínu.',
                it: 'Siamo spiacenti, le informazioni inserite non corrispondono ai nostri registri. Proveremo a ricontattarvi in un secondo momento. Se avete delle domande sul vostro piano sanitario, chiamate il numero indicato sul retro della vostra scheda ID di membro.',
                ilo: 'Pakawanen nakami, dagiti impormasyon nga inserrek mo ket madi nga agpareha dagiti rekord mi. Padasenmi nga kontaken daka ulit madamdama. No adda dagiti saludsod mo gapo ti plano ti salun-at mo, paki-awagam ti numero ti likod ti ID kard mo kas miembro.',
                ja: '申し訳ありません。入力された情報が登録内容に合致しません。後ほど再びご連絡いたします。プランについてご質問がおありの場合は、IDカードの裏面に記載されている番号までお問い合わせください。',
                ko: '죄송합니다. 귀하가 입력하신 정보는 저희 기록과 일치하지 않습니다. 저희가 나중에 다시 연락을 드리겠습니다. 귀하의 건강플랜에 대해 질문이 있으신 경우, 귀하의 가입자 ID 뒷면에 있는 번호로 전화해주십시오.',
                nb: 'Vi beklager, men informasjonen du skrev inn, samsvarer ikke med dataene våre. Vi kommer til å prøve å kontakte deg igjen senere. Hvis du har spørsmål om helseforsikringen din, kontakt nummeret på baksiden av medlems-ID-kortet ditt.',
                pl: 'Przepraszamy, podane informacje nie zgadzają się z naszymi danymi. Spróbujemy skontaktować się z Tobą później. Jeśli masz pytania dotyczące Twojego planu zdrowotnego, zadzwoń pod numer znajdujący się na odwrocie karty członkowskiej.',
                pt: 'Lamentamos, mas as informações introduzidas não correspondem aos nossos registos. Tentaremos entrar em contacto consigo novamente mais tarde. Se tiver questões sobre o seu plano de saúde, ligue para o número no verso do seu cartão de ID de membro.',
                ro: 'Ne pare rău, informațiile introduse de dvs. nu corespund înregistrărilor noastre. Vom încerca să vă contactăm din nou mai târziu. Dacă aveți întrebări cu privire la planul dvs. de sănătate, sunați la numărul de pe spatele cardului ID de membru.',
                ru: 'Извините, введенная вами информация не совпадает с нашими записями. Мы постараемся связаться с вами позже. Звоните по номеру, указанному на обратной стороне вашей идентификационной карты участника, если у вас есть вопросы по поводу вашего плана медицинского страхования.',
                so: "Waan ka xunnahay, macluumaadka aad gelisay ma waafaqsana diiwaankayaga. Waxaan isku dayi doonnaa inaan kula soo goor dambe kula soo xiriirno mar kale. Haddii aad qabtid su'aalo kau saabsan qorshaha caafimaadkaaga, fadlan wac lambarka ku yaala dhabarka kaarka aqoonsigaaga xubinnimo.",
                es: 'Lo lamentamos, pero la información que ingresó no coincide con nuestros registros. Intentaremos volver a comunicarnos con usted más tarde. Si tiene alguna pregunta sobre su plan de salud, llame al número que figura al dorso de su tarjeta de identificación de miembro.',
                sv: 'Informationen du angav stämmer tyvärr inte med våra register. Vi försöker kontakta dig igen vid ett senare tillfälle. Om du har frågor om din hälsoplan, vänligen ring numret på baksidan av ditt medlems-ID-kort.',
                tl: 'Paumanhin, hindi tumutugma sa aming mga talaan ang impormasyong inilagay mo. Susubukan naming makipag-ugnayan ulit sa iyo sa ibang pagkakataon. Kung mayroon kang mga tanong tungkol sa iyong planong pangkalusugan, pakitawagan ang numero sa likod ng iyong ID card ng miyembro.',
                tr: 'Üzgünüz, girdiğiniz bilgiler kayıtlarımıza uymuyor. Sizinle daha sonra tekrar iletişime geçmeye çalışacağız. Sağlık planınız hakkında sorularınız olursa lütfen üye kimlik kartınızın arkasındaki numarayı arayın.',
                vi: 'Rất tiếc, thông tin quý vị nhập không khớp với hồ sơ của chúng tôi. Chúng tôi sẽ thử liên hệ lại với quý vị sau. Nếu quý vị có bất kỳ thắc mắc gì về quyền lợi chương trình bảo hiểm sức khỏe của mình, vui lòng gọi tới số của ban Dịch vụ hội viên ở mặt sau thẻ ID hội viên của quý vị.',
                vt: 'Rất tiếc, thông tin quý vị nhập không khớp với hồ sơ của chúng tôi. Chúng tôi sẽ thử liên hệ lại với quý vị sau. Nếu quý vị có bất kỳ thắc mắc gì về quyền lợi chương trình bảo hiểm sức khỏe của mình, vui lòng gọi tới số của ban Dịch vụ hội viên ở mặt sau thẻ ID hội viên của quý vị.',
                cy: 'Mae’n ddrwg gennym, nid yw’r wybodaeth a roddoch yn cyd-fynd â’n cofnodion. Byddwn yn ceisio cysylltu â chi rhywbryd eto. Os oes gennych chi gwestiynau am eich cynllun iechyd, ffoniwch y rhif ar gefn eich cerdyn aelodaeth.'
            }
        },

        forms: {
            submit: {
                ar: 'Submit', // right-to-left language. not officially supported.
                hy: 'Ներկայացրեք',
                km: 'បញ្ជូន',
                zh: '提交',
                yue: '提交',
                cmn: '提交',
                ch: '提交',
                cs: 'Odeslat',
                da: 'Send',
                nl: 'Verzenden',
                en: 'Submit',
                fa: 'Submit', // right-to-left language. not officially supported.
                fr: 'Envoyer',
                de: 'Senden',
                hi: 'सबमिट करें',
                hmn: 'Xa',
                is: 'Senda',
                ilo: 'Isumite',
                it: 'Invia ',
                ja: '提出',
                ko: '제출하다',
                nb: 'Send',
                pl: 'Wyślij',
                pt: 'Submeter',
                ro: 'Introducere',
                ru: 'Отправить',
                so: 'Gudbi',
                es: 'Enviar',
                sv: 'Skicka in',
                tl: 'Isumite',
                tr: 'Gönder',
                vi: 'Gửi đi',
                vt: 'Gửi đi',
                cy: 'Cyflwyno'
            }
        }
    }
};
