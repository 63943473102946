import {Inject, Injectable} from '@angular/core';
import {get} from 'lodash';
import {TRANSLATIONS_PROVIDERS} from '../config/translation.providers';
import {LocaleProvider} from '../models/LocaleProvider.interface';

@Injectable()
export class TranslationService {
    private translation = {};
    private language = 'en';

    constructor(@Inject(TRANSLATIONS_PROVIDERS) private translationProviders: Array<LocaleProvider[]>) {}

    public configure(): void {
        this.translationProviders.forEach((providerArray: LocaleProvider[]) => {
            providerArray.forEach((provider: LocaleProvider) => {
                if (this.translationExists(provider)) {
                    throw new Error(`Cannot redefine translations for ${provider.feature}.`);
                }

                this.translation[provider.feature] = provider.data;
            });
        });
    }

    public translate(path: string): string {
        return get(this.translation, `${path}.${this.language}`);
    }

    public translateWithLanguageProvided(path: string, language: string): string {
        return get(this.translation, `${path}.${language}`);
    }

    public setLanguage(language: string = 'en'): void {
        this.language = language || 'en';
    }

    private translationExists(provider: LocaleProvider): boolean {
        return provider.feature in this.translation;
    }
}
