import {createEffect, ofType} from '@ngrx/effects';
import {Actions} from '@ngrx/effects';
import {get} from 'lodash';
import {from} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';

import {Inject, Injectable, Optional} from '@angular/core';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {ENV_SERVICE} from '@clients/shared/config';
import {LogErrorAction} from '@clients/shared/logging';
import {Action} from '@clients/shared/models';
import {Store} from '@ngrx/store';

import {SurveyInitiateApiResponse} from '../../../models/api-responses/SurveyInitiateResponse.interface';
import {ISurveyValidationSuccessApiResponse} from '../../../models/api-responses/SurveyValidateApiResponse.interface';
import {SeededSurveyResponse, SurveyResponse} from '../../../models/survey-responses/SurveyResponse.interface';
import {Survey} from '../../../models/survey/ISurvey';
import {ISurveyConfig} from '../../../models/survey/ISurveyConfig';
import {SurveyLoadedPayload} from '../../../models/survey/ISurveyLoadedPayload';
import {SurveySchema} from '../../../models/survey/ISurveySchema';
import {LOG_NAMES} from '../../errors/log-names';
import {SurveyInstructionsService} from '../../services/survey-instructions.service';
import {SurveyService} from '../../services/survey.service';
import {SURVEYS_LIFECYCLE_SURVEY_LOADED} from '../../surveys.providers';
import {ResponsesRecordedAction} from '../responses/responses.actions';
import {ResponsesFacade} from '../responses/responses.facade';
import {
    LoadSurveyAction,
    SurveyActionTypes,
    SurveyCompletedAction,
    SurveyConfigLoadedAction,
    SurveyLoadedAction,
    SurveyLoadFailedAction,
    SurveyOnLoadProcessedAction,
    SurveyStartAction,
    SurveyValidationFailureAction,
    SurveyValidationSuccessAction,
    ValidateSurveyAction
} from './survey.actions';
import {SurveyFacade} from './survey.facade';

@Injectable()
export class SurveyEffects {
    validateSurvey = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.VALIDATE_SURVEY),
            map((action: ValidateSurveyAction) => action.payload),
            switchMap((context: string) => {
                // if our app was loaded in dev mode, then treat a validation request
                // as a success, skip everything and just load the specified survey.
                if (this.pureDevMode) {
                    return from([
                        new SurveyLoadedAction({
                            schema: this.activeSurvey.schema,
                            clientState: null
                        }),
                        new SurveyConfigLoadedAction(this.activeSurvey.config)
                    ]);
                }

                return this.surveyService.bootstrap(context).pipe(
                    mergeMap((res: ISurveyValidationSuccessApiResponse) => {
                        const actions: any[] = [new SurveyValidationSuccessAction(res)];

                        // if a config was returned with the bootstrap response, load it.
                        // todo | can we just always do this? https://healthtel.atlassian.net/browse/SB-996
                        if (res.config) actions.push(new SurveyConfigLoadedAction(res.config));

                        return actions;
                    }),
                    catchError((err) =>
                        from([
                            new LogErrorAction({
                                type: LOG_NAMES.SURVEY_VALIDATION_ERROR,
                                message: 'could not validate survey',
                                options: {
                                    context,
                                    error: err
                                }
                            }),
                            new SurveyValidationFailureAction(err)
                        ])
                    )
                );
            })
        )
    );

    loadSurvey = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.LOAD_SURVEY),
            map((action: LoadSurveyAction) => action.payload),
            switchMap((context: string) => {
                // if the app was loaded in dev mode, respond with a survey
                // loaded success action and use the specified survey
                if (this.pureDevMode) {
                    const survey = this.activeSurvey;

                    return from([
                        new SurveyLoadedAction({
                            schema: survey.schema,
                            clientState: null
                        }),
                        new SurveyConfigLoadedAction(survey.config)
                    ]);
                }

                return this.surveyService.show(context).pipe(
                    mergeMap((res: SurveyInitiateApiResponse) => {
                        // for development sometimes we want to use local data rather than the
                        // schema on the API. If that option is set, convert our local data to the
                        // same format that would be returned by an API call.
                        let survey: {
                            clientState: string;
                            flow?: SurveySchema;
                            config?: ISurveyConfig;
                        };
                        if (this.useLocalSurvey) {
                            const devSurvey = this.activeSurvey;
                            // convert to server formatting
                            survey = {
                                flow: devSurvey.schema,
                                config: devSurvey.config,
                                clientState: null
                            };
                        } else {
                            survey = res;
                        }

                        const payload: SurveyLoadedPayload = {
                            schema: survey.flow,
                            clientState: survey.clientState
                        };

                        const actions: any[] = [new SurveyLoadedAction(payload)];

                        // some config may be returned from the /initiate call, so if it's there
                        // then dispatch the SurveyConfigLoaded action as well.
                        if (survey.config) actions.push(new SurveyConfigLoadedAction(survey.config));

                        return actions;
                    }),
                    catchError((err) =>
                        from([
                            new LogErrorAction({
                                type: LOG_NAMES.SURVEY_LOAD_ERROR,
                                message: 'could not load survey',
                                options: {context, err}
                            }),
                            new SurveyLoadFailedAction(err)
                        ])
                    )
                );
            })
        )
    );

    surveyLoaded = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SurveyActionTypes.SURVEY_LOADED),
                map((action: SurveyLoadedAction) => action.payload),
                tap((surveyLoadedResponse: SurveyLoadedPayload) => {
                    // send any seeded responses to the API now before we dispatch any other actions
                    // so that we make sure anything seeded takes priority. since any failed response
                    // recording will lead to the user seeing the error page, we don't really need to
                    // monitor here whether the API call fails.
                    const responses = this.responsesFacade.getArray();
                    if (responses && responses.length) {
                        // filter out any response that's listed as already recorded
                        // then turn that into a response ready for the server.
                        const seededResponses: SeededSurveyResponse[] = responses
                            .filter((response: SurveyResponse) => response.seeded && !response.recorded)
                            .map((response: SurveyResponse) => new SeededSurveyResponse(response.id, response.value));

                        if (seededResponses && seededResponses.length) {
                            this.store.dispatch(new ResponsesRecordedAction(seededResponses));
                        }
                    }

                    // if the parent application has provided a value for SURVEYS_LIFECYCLE_SURVEY_LOADED
                    // then loop through that array and dispatch the specified actions.
                    // if not, then dispatch the SurveyStartAction and continue down the line.
                    if (this.surveyLoadedLifecycleActions && this.surveyLoadedLifecycleActions.length) {
                        this.surveyLoadedLifecycleActions.forEach((action: any) => {
                            this.store.dispatch(new action(surveyLoadedResponse));
                        });
                    } else {
                        this.store.dispatch(new SurveyStartAction(surveyLoadedResponse));
                    }
                })
            ),
        {dispatch: false}
    );

    surveyStart = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SurveyActionTypes.SURVEY_START),
                map((action: SurveyStartAction) => action.payload),
                tap((surveyLoadedResponse: SurveyLoadedPayload) => {
                    const onLoadInstructions = get(surveyLoadedResponse, 'schema.onLoad');

                    if (this.shouldProcessInstructions(surveyLoadedResponse, onLoadInstructions)) {
                        this.surveyInstructionsService.process(surveyLoadedResponse.schema.onLoad);
                    }

                    this.store.dispatch(new SurveyOnLoadProcessedAction());
                })
            ),
        {dispatch: false}
    );

    completeSurvey = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.COMPLETE_SURVEY),
            map(() => new SurveyCompletedAction())
        )
    );

    private useLocalSurvey: boolean;
    private pureDevMode: boolean;
    private activeSurvey: Survey;

    constructor(
        private actions$: Actions,
        private surveyService: SurveyService,
        private surveyFacade: SurveyFacade,
        private responsesFacade: ResponsesFacade,
        private surveyInstructionsService: SurveyInstructionsService,
        private store: Store<any>,
        @Optional()
        @Inject(SURVEYS_LIFECYCLE_SURVEY_LOADED)
        private surveyLoadedLifecycleActions: Action<SurveyLoadedPayload>[],

        @Inject(ENV_SERVICE) envService: MemberDirectEnvService
    ) {
        this.useLocalSurvey = envService.surveySettings?.useLocalSurvey;
        this.activeSurvey = envService.surveySettings?.activeSurvey;
        this.pureDevMode = envService.surveySettings?.pureDevMode;
    }

    // helper functions to determine if we want to run our on-load instructions
    // for a survey or not.
    private shouldProcessInstructions(surveyLoadedResponse: SurveyLoadedPayload, onLoadInstructions: any): boolean {
        const hasNoClientState = !surveyLoadedResponse.clientState || !surveyLoadedResponse.clientState.length;
        const resumeIsDisabled = this.surveyFacade.getDisableResume();
        const hasOnLoadInstructions = onLoadInstructions && onLoadInstructions.length;
        return (hasNoClientState || resumeIsDisabled) && hasOnLoadInstructions;
    }
}
