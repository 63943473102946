import {Component, Injector, OnInit} from '@angular/core';
import {InputBase} from '../../input-base.abstract';
import {inputMasks} from '@clients/shared/common-forms';
import {onlyNumbers} from '@clients/shared/utilities';
import {toNumber} from 'lodash';

@Component({
    selector: 'surveys-element-input-weight',
    templateUrl: './element-input-weight.component.html',
    styleUrls: ['./element-input-weight.component.scss']
})
export class ElementInputWeightComponent extends InputBase<number> implements OnInit {
    public weightMask = inputMasks.weight;
    private minimumWeight = 1;

    /* istanbul ignore next */
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();
        this.enableAutoSave();
    }

    protected transformBeforeDisplay(value: number): string {
        return value ? value.toString() : '';
    }

    protected transformBeforeSave(value: string): number {
        const asNumbers = onlyNumbers(value);
        if (asNumbers && asNumbers.length > 0) {
            return toNumber(asNumbers);
        }

        return undefined;
    }

    protected shouldSave(value: number): boolean {
        return (value && value > this.minimumWeight) || value === undefined;
    }
}
