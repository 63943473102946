import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {SurveyService} from '../../services/survey.service';
import {
    ErrorLoadingPharmaciesAction,
    LoadPharmaciesAction,
    PharmaciesActionTypes,
    PharmaciesLoadedAction,
    RemovePharmacyLocationDataAction
} from './pharmacies.actions';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {SurveyFacade} from '../survey/survey.facade';
import {GeoJsonDataPoint} from '../../../models/mapping/GeoJsonDataPoint.interface';
import {PharmaciesFacade} from './pharmacies.facade';
import {getPharmacyId} from './pharmacies.reducer';
import {of} from 'rxjs';
import {IPharmacyLocationApiResponse} from '../../../models/api-responses/PharmacyLocationResponse.interface';
import {LogErrorAction} from '@clients/shared/logging';
import {LOG_NAMES} from '../../errors/log-names';

@Injectable()
export class PharmaciesEffects {
    loadPharmacies = createEffect(() =>
        this.actions$.pipe(
            ofType(PharmaciesActionTypes.LOAD_PHARMACIES),
            map((action: LoadPharmaciesAction) => action.payload),
            switchMap((payload: {lat: number; lng: number}) => {
                const surveyRecordId = this.surveyFacade.getSurveyRecordId();

                return this.surveyService.pharmacyLocations(surveyRecordId, payload.lat, payload.lng).pipe(
                    mergeMap((res: IPharmacyLocationApiResponse) => {
                        const formattedData = this.formatPharmacies(res.features);
                        const pharmaciesRemovedFromResponse = this.pharmaciesFacade.getListOfRemovedPharmacies(
                            res.features
                        );

                        return [
                            new PharmaciesLoadedAction(formattedData),
                            new RemovePharmacyLocationDataAction(pharmaciesRemovedFromResponse)
                        ];
                    }),
                    catchError((err) =>
                        of(
                            new LogErrorAction({
                                type: LOG_NAMES.PHARMACY_LOAD_ERROR,
                                message: 'loading pharmacies failed',
                                options: {
                                    err,
                                    surveyRecordId,
                                    lat: payload.lat,
                                    lng: payload.lng
                                }
                            }),
                            new ErrorLoadingPharmaciesAction({})
                        )
                    )
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private surveyService: SurveyService,
        private surveyFacade: SurveyFacade,
        private pharmaciesFacade: PharmaciesFacade
    ) {}

    private formatPharmacies(pharmacies: GeoJsonDataPoint[]): GeoJsonDataPoint[] {
        if (!pharmacies || !pharmacies.length) return [];

        let rowOrder: number = this.pharmaciesFacade.getTotal();
        const loadedPharmacies = this.pharmaciesFacade.getEntities();

        return pharmacies.map((p: GeoJsonDataPoint) => {
            const pharmacyId: string = getPharmacyId(p);
            const pharmacyHasBeenLoaded = loadedPharmacies && this.pharmaciesFacade.pharmacyHasBeenLoaded(pharmacyId);

            // if the pharmacy has been loaded then its new row order is the
            // same as it used to be and we do not need to add to the count.
            // if it has not been loaded, then we want to advance our rowOrder
            // object 1 and its new rowOrder is basically after the last pharmacy
            // in the current list.
            let newRowOrder: number;
            if (pharmacyHasBeenLoaded) {
                newRowOrder = loadedPharmacies[pharmacyId].rowOrder;
            } else {
                rowOrder++;
                newRowOrder = rowOrder;
            }

            return {...p, rowOrder: newRowOrder};
        });
    }
}
