import {filter, find, findIndex, get} from 'lodash';
import {combineLatest} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, Injector, Input, OnChanges, OnInit} from '@angular/core';
import {select} from '@ngrx/store';

import {
    selectAllElements,
    selectHidden,
    selectResponseEntities,
    selectResponsesState,
    selectSelectedElementId
} from '../../+state';
import {ActivateBlockAction, NextQuestionAction, PrevQuestionAction} from '../../+state/blocks/blocks.actions';
import {BlocksFacade} from '../../+state/blocks/blocks.facade';
import {getBlockId} from '../../+state/blocks/blocks.reducer';
import {ResponsesFacade} from '../../+state/responses/responses.facade';
import {ISurveyBlock} from '../../../models/survey/ISurveyBlock';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {ELEMENT_VISIBILITY_SURVEY_INSTRUCTIONS} from '../../../models/survey/ISurveyInstruction';
import {BaseElement} from '../../base-element.abstract';
import {SurveyElementType} from '../../enum/SurveyElementType';
import {BlockConfigService} from '../../services/block-config.service';
import {SurveyConfigService} from '../../services/survey-config.service';
import {SurveyContentService} from '../../services/survey-content.service';
import {SurveyInstructionsService} from '../../services/survey-instructions.service';
import {SurveyQuestionService} from '../../services/survey-question.service';
import {HighlightElementAction} from '../../+state/elements/elements.actions';
import {getElementId} from '../../+state/elements/elements.reducer';

interface ElementData {
    elements: SurveyElement[];
    selectedElementId: string;
    hidden: string[];
}

@Component({
    selector: 'surveys-survey-block',
    styleUrls: ['./survey-block.component.scss'],
    templateUrl: './survey-block.component.html'
})
export class SurveyBlockComponent extends BaseElement implements OnInit, OnChanges {
    @Input()
    public elements: SurveyElement[];

    @Input()
    public block: ISurveyBlock;

    public nextBlock: ISurveyBlock;
    public nextBlockIsLastBlock: boolean;
    public elementCount: number;
    public selectedQuestionIndex: number;
    public selectedQuestionNumber: number;
    public selectedElementIsFirstElement: boolean;
    public selectedElementIsLastElement: boolean;
    public selectedQuestionElement: SurveyElement;
    public singleQuestionHeader: string;
    public shouldRenderInSingleQuestionMode: boolean;
    public shouldRenderInScrollMode: boolean;
    public shouldAllowManualScroll: boolean;
    public shouldAllowManualBlockNavigation: boolean;
    public shouldShowBlockHeader: boolean;
    public shouldShowQuestionCounter: boolean;
    public currentResults$: any;
    public results: any;

    constructor(
        public blockConfigService: BlockConfigService,
        public blocksFacade: BlocksFacade,
        public responsesFacade: ResponsesFacade,
        public surveyInstructionsService: SurveyInstructionsService,
        public surveyQuestionService: SurveyQuestionService,
        public surveyConfigService: SurveyConfigService,
        private surveyContentService: SurveyContentService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit() {
        const allElementsSelector = this.store.pipe(select(selectAllElements), takeUntil(this.destroyed$));
        const selectedElementIdSelector = this.store.pipe(select(selectSelectedElementId), takeUntil(this.destroyed$));
        const hiddenElementsSelector = this.store.pipe(select(selectHidden), takeUntil(this.destroyed$));
        const responsesSelector = this.store.pipe(select(selectResponseEntities), takeUntil(this.destroyed$));

        combineLatest(
            allElementsSelector,
            selectedElementIdSelector,
            hiddenElementsSelector,
            (elements: SurveyElement[], selectedElementId: string, hidden: string[]) => ({
                elements,
                selectedElementId,
                hidden
            })
        ).subscribe((data: ElementData) => {
            this.updateQuestionInfo(data);
        });

        combineLatest(allElementsSelector, selectedElementIdSelector, responsesSelector).subscribe(
            ([elements, selectedElementId, responses]) => {
                this.runQuestionsInstructions(elements);
            }
        );
    }

    ngOnChanges(changes) {
        this.shouldAllowManualBlockNavigation = this.surveyConfigService.shouldAllowManualBlockNavigation();
        /* istanbul ignore else */
        if (changes.block) {
            this.nextBlock = this.blocksFacade.getNextBlock(getBlockId(changes.block.currentValue));
            if (this.nextBlock) {
                this.nextBlockIsLastBlock = this.blocksFacade.blockIsLast(getBlockId(this.nextBlock));
            }
            // set config properties when block has changed rather than in template
            this.updateBlockConfigProperties();
        }
    }

    public determineQuestionType(questionId: string) {
        const allElements = this.elements;
        const questionType = allElements.find((element) => element.id === questionId)?.question.type;
        if (questionType !== undefined) {
            return questionType;
        }
    }

    public questionResultExists(questionId: string) {
        const isFireFox = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        this.store.pipe(select(selectResponsesState), takeUntil(this.destroyed$)).subscribe((val) => {
            this.currentResults$ = val;
        });
        this.results = this.currentResults$.responses;
        let sortedResultsForQuestionId;
        if (!isFireFox) {
            sortedResultsForQuestionId = this.results
                .filter((result) => result.questionId === questionId)
                .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
        } else {
            sortedResultsForQuestionId = this.results
                .filter((result) => result.questionId === questionId)
                .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
        }
        if (
            sortedResultsForQuestionId.filter(
                (result) =>
                    result.value === true || result.value === false || result.value === undefined || result.value === ''
            ).length <= 0 &&
            sortedResultsForQuestionId.length > 0
        ) {
            return true;
        } else {
            const questionType = this.determineQuestionType(questionId);
            //check to see if question type is YES_NO. Since YES_NO return False for No responses, the type must be excluded from the multiselect check
            if (questionType === 'YES_NO' && sortedResultsForQuestionId.length > 0) {
                return true;
            }
            const inputTypes = [
                'INPUT_TEXT',
                'INPUT_LONG_TEXT',
                'INPUT_BIRTHDATE',
                'INPUT_EMAIL',
                'INPUT_HEIGHT',
                'INPUT_WEIGHT',
                'INPUT_YEAR',
                'INPUT_MONTH',
                'INPUT_PHONE_NUMBER'
            ];
            //check to see if question type is an input type. If so, check to see if the most recent response has a value. If not, the question should remain required.
            //NOTE: When INPUT_PHONE_NUMBER is cleared, the value changes to '' and not undefined like all other input types.
            if (inputTypes.includes(questionType) && sortedResultsForQuestionId.length > 0) {
                const mostRecentQuestionValue = sortedResultsForQuestionId
                    .filter((result) => result.questionId === questionId)
                    .slice(-1)[0].value;
                if (mostRecentQuestionValue === undefined || mostRecentQuestionValue === '') {
                    return false;
                } else {
                    return true;
                }
            }
            if (questionType === 'MULTIPLE_SELECT' && sortedResultsForQuestionId.length > 0) {
                //contains all multiple select results recorded. If all values of the most recent values are false then no result was recorded and the question should remain required
                //must check to make sure that for each of the results recorded, at least one of the values is true for the most recent response
                //filter out all false values
                const filteredFalseResults = sortedResultsForQuestionId.filter((result) => result.value === false);
                if (filteredFalseResults.length <= 0 && sortedResultsForQuestionId.length > 0) {
                    return true;
                }
                //build list of answerID for false values
                const falseAnswerIds = [];
                filteredFalseResults.forEach((result) => {
                    falseAnswerIds.push(result.answerId);
                });
                //remove duplicates
                const uniqueFalseAnswerIds = falseAnswerIds.filter(
                    (value, index, array) => array.indexOf(value) === index
                );
                //go through all sorted results and check if the last value in the sorted results have is true if none of the answerIds return a most recent value of true return false
                let validResult = false;
                uniqueFalseAnswerIds.forEach((answerId) => {
                    if (
                        sortedResultsForQuestionId.filter((result) => result.answerId === answerId).slice(-1)[0]
                            .value === true
                    ) {
                        validResult = true;
                    }
                });
                if (validResult) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    public getProgress() {
        if (!this.selectedQuestionNumber || !this.elementCount) return '0%';

        const percent: number = (this.selectedQuestionNumber / this.elementCount) * 100;
        const right = 100 - percent;

        return `${right}%`;
    }

    public loadNextBlock(nextBlock: ISurveyBlock): void {
        this.store.dispatch(new ActivateBlockAction(getBlockId(nextBlock)));
    }

    public goToNextQuestion(): void {
        if (!this.selectedElementIsLastElement) {
            const currentQuestionId = get(this.selectedQuestionElement, 'id');

            if (currentQuestionId) {
                this.store.dispatch(new NextQuestionAction(currentQuestionId));
            }
        }
    }

    public goToPrevQuestion(): void {
        if (!this.selectedElementIsFirstElement) {
            const currentQuestionId = get(this.selectedQuestionElement, 'id');

            if (currentQuestionId) {
                this.store.dispatch(new PrevQuestionAction(currentQuestionId));
            }
        }
    }

    public getNextActionButtonText(): string {
        if (this.nextBlockIsLastBlock) {
            return this.translationService.translate('surveys.completeSurvey');
        }

        return `${this.translationService.translate('surveys.navigation.next')}: ${
            this.nextBlock.heading[this.language]
        }`;
    }

    public getNextNavButtonText(): string {
        return this.translationService.translate('surveys.navigation.next');
    }

    public getBackNavButtonText(): string {
        return this.translationService.translate('surveys.navigation.back');
    }

    public shouldShowBlockNav(): boolean {
        let shouldShowBlockNav = false;
        const canNavToNextBlock = this.nextBlock && this.shouldAllowManualBlockNavigation;
        const isSingleQuestionAndNotLastElement =
            this.shouldRenderInSingleQuestionMode && !this.selectedElementIsLastElement;

        if (canNavToNextBlock) {
            shouldShowBlockNav = true;
        }
        if (isSingleQuestionAndNotLastElement) {
            shouldShowBlockNav = false;
        }
        return shouldShowBlockNav;
    }

    private updateQuestionInfo(data: ElementData) {
        this.updateQuestionCountInfo(data);
        this.updateSelectedQuestionInfo(data);
    }

    // used to display single questions and multipart question headers when in single question mode
    private updateSelectedQuestionInfo(data: ElementData) {
        const indexOfSelectedElement = findIndex(data.elements, {id: data.selectedElementId});
        const indexOfCurrentElement = findIndex(data.elements, {id: this.selectedQuestionElement?.id});
        if (
            this.selectedQuestionElement?.question.required &&
            !this.questionResultExists(this.selectedQuestionElement?.id) &&
            indexOfCurrentElement < indexOfSelectedElement
        ) {
            this.store.dispatch(new HighlightElementAction(getElementId(this.selectedQuestionElement)));
        } else {
            if (indexOfSelectedElement !== -1) {
                this.singleQuestionHeader = '';
                this.selectedQuestionElement = data.elements[indexOfSelectedElement];
            } else {
                const allVisibleMultipartElements = this.getVisibleMultipartElements(data.elements, data.hidden);
                let indexInParent: number;

                const multipartElement = find(allVisibleMultipartElements, (element) => {
                    indexInParent = findIndex(element.data, {id: data.selectedElementId});
                    return indexInParent !== -1;
                });

                if (multipartElement) {
                    this.singleQuestionHeader = get(multipartElement, 'label.' + this.language, '');
                    this.selectedQuestionElement = multipartElement.data[indexInParent];
                } // else: how did this element get selected?
            }
        }
    }

    private updateQuestionCountInfo(data: ElementData) {
        const allVisibleQuestionElements = this.getVisibleQuestionElements(data.elements, data.hidden);
        const indexOfSelectedElement = findIndex(allVisibleQuestionElements, {id: data.selectedElementId});

        this.elementCount = allVisibleQuestionElements.length;
        this.selectedElementIsFirstElement = indexOfSelectedElement === 0;
        this.selectedElementIsLastElement = indexOfSelectedElement + 1 === this.elementCount;

        // if we are displaying the question counter then we need to track how many
        // questions we currently have loaded in the store and of those which is
        // currently highlighted
        if (this.blockConfigService.shouldShowQuestionCounter()) {
            this.selectedQuestionNumber = indexOfSelectedElement + 1;
        }
    }

    private runQuestionsInstructions(elements: SurveyElement[]) {
        const surveyQuestions = this.surveyQuestionService.getAllQuestionsFromSurveyElements(elements);
        if (surveyQuestions.length > 0 && this.surveyQuestionService.questionElementsHasInstructions(surveyQuestions)) {
            const instructions = this.surveyQuestionService.getQuestionsInstructionsFilteredByGroup(
                surveyQuestions,
                ELEMENT_VISIBILITY_SURVEY_INSTRUCTIONS
            );
            this.surveyInstructionsService.process(instructions);
        }
    }

    private getVisibleQuestionElements(elements: SurveyElement[], hiddenIds: string[]): SurveyElement[] {
        const questions = this.getFlattenedElementsOfTypes(elements, [SurveyElementType.SURVEY_QUESTION]);
        return this.filterHiddenElements(questions, hiddenIds);
    }

    private getVisibleMultipartElements(elements: SurveyElement[], hiddenIds: string[]): SurveyElement[] {
        const questions = this.getFlattenedElementsOfTypes(elements, [SurveyElementType.MULTIPART_QUESTION]);
        return this.filterHiddenElements(questions, hiddenIds);
    }

    private getFlattenedElementsOfTypes(elements: SurveyElement[], types: SurveyElementType[]): SurveyElement[] {
        return this.surveyContentService.getElementsByType(elements, types);
    }

    private filterHiddenElements(flatElements: SurveyElement[], hiddenIds: string[]): SurveyElement[] {
        if (!hiddenIds || hiddenIds.length === 0) return flatElements;

        return filter(flatElements, ({id}: SurveyElement) => !hiddenIds.includes(id));
    }

    private updateBlockConfigProperties() {
        this.shouldRenderInSingleQuestionMode = this.blockConfigService.shouldRenderInSingleQuestionMode();
        // single question mode takes precedence over scroll mode and they are mutually exclusive
        // this can be done in a better way, especially if we want to add more nav modes
        if (this.shouldRenderInSingleQuestionMode) {
            this.shouldRenderInScrollMode = false;
        } else {
            this.shouldRenderInScrollMode = this.blockConfigService.shouldRenderInScrollMode();
        }
        this.shouldAllowManualScroll = this.blockConfigService.shouldAllowUsersToScroll();
        this.shouldShowBlockHeader = this.blockConfigService.shouldShowBlockHeader();
        this.shouldShowQuestionCounter = this.blockConfigService.shouldShowQuestionCounter();
    }
}
