import {get} from 'lodash';

import {AfterViewInit, Component, ElementRef, Injector, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {EventTrackAction, LinkClicked} from '@clients/shared/analytics';

import {ISurveyHtml} from '../../../models/survey/ISurveyContent';
import {BaseElement} from '../../base-element.abstract';

@Component({
    selector: 'surveys-element-html',
    templateUrl: './element-html.component.html',
    styleUrls: ['./element-html.component.scss']
})
export class ElementHtmlComponent extends BaseElement implements OnChanges, AfterViewInit, OnDestroy {
    @Input()
    public element: ISurveyHtml;
    @Input()
    public useCustomStyles = false;
    @ViewChild('html')
    public htmlWrapper: ElementRef;

    public sanitizedHtml: any;

    private customLinkHandler = this.handleInnerLinkClicked.bind(this);

    constructor(injector: Injector, private sanitizer: DomSanitizer) {
        super(injector);
    }

    ngOnChanges() {
        this.updateHtml();
    }

    ngAfterViewInit() {
        this.addCustomLinkHandler();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.removeCustomLinkHandler();
    }

    public shouldRender(element: ISurveyHtml, language: string): boolean {
        const html = this.getHtml(element, language);
        return html && html.length > 0;
    }

    public getComponentCssClasses(id: string): string {
        return !this.useCustomStyles ? `survey-element surveys-element-html surveys-element-${id}` : '';
    }

    protected languageChanged() {
        this.updateHtml();
    }

    private handleInnerLinkClicked(event) {
        /* istanbul ignore else  */
        if (event.target.localName === 'a') {
            this.store.dispatch(new EventTrackAction(new LinkClicked(event.target.href)));
        }
    }

    private updateHtml() {
        // remove the custom link handler until the new HTML is calculated
        this.removeCustomLinkHandler();

        // calculate new HTML
        const html: string = this.getHtml(this.element, this.language);
        if (!html) return;

        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(html);

        // add the link handler back in
        this.addCustomLinkHandler();
    }

    private getHtml(element, language: string): string {
        return get(element, `data.${language}`);
    }

    private addCustomLinkHandler() {
        if (this.htmlWrapper) {
            this.htmlWrapper.nativeElement.addEventListener('click', this.customLinkHandler, false);
        }
    }

    private removeCustomLinkHandler() {
        if (this.htmlWrapper) {
            this.htmlWrapper.nativeElement.removeEventListener('click', this.customLinkHandler, false);
        }
    }
}
