<div class="dropdown-container" aria-label="Language Selection">
    <div class="aria-live-hidden" aria-live="polite">
        {{ languageChangeText }}
    </div>
    <div
        #languageBtn
        id="languagebutton"
        role="button"
        class="menu-wrapper"
        (keydown.enter)="menuOnClick()"
        (keydown.space)="menuOnClick()"
        (keydown.arrowdown)="menuOnClick()"
        (click)="menuOnClick()"
        tabindex="0"
        [attr.aria-label]="label"
        [attr.aria-haspopup]="true"
        [attr.aria-expanded]="isActive"
        [attr.aria-controls]="'languageswitcher'"
    >
        <r-svg-icon class="r-lang-icon" [name]="languageIconKey"></r-svg-icon>
        <span class="text" *ngIf="label"> {{ label }} </span>
    </div>
    <div
        [ngClass]="{
            dropdown: true,
            top: dropdownPosition === 'top',
            bottom: dropdownPosition === 'bottom',
            'no-label': !label
        }"
        [class.active]="isActive"
    >
        <div (focus)="onFocusFirstGuardian()" tabindex="0"></div>
        <div [attr.aria-labelbedby]="'languagebutton'" class="wrapper">
            <ul role="menu" id="languageswitcher" tabindex="-1" [attr.aria-activedescendant]="activeLanguage">
                <li role="menuitem" *ngFor="let item of languagesData; index as langIndex">
                    <span
                        #languageElements
                        [id]="item.value"
                        [attr.aria-pressed]="activeLanguage === item.value"
                        [class.active]="activeLanguage === item.value"
                        [lang]="item.value"
                        (click)="onChange(item.value)"
                        (keydown)="onKeyDown($event, langIndex)"
                        tabindex="0"
                    >
                        {{ item.label }}
                    </span>
                </li>
            </ul>
            <div (focus)="onFocusLastGuardian()" tabindex="0"></div>
        </div>
    </div>
    <div class="dropdown-underlay" [class.active]="isActive" (click)="toggleMenu()"></div>
</div>
