import {createFeatureSelector, createSelector} from '@ngrx/store';

import {IIdvQuestion} from '../models/IIdvQuestion';
import {IdvReducerState} from './idv.interfaces';

const getEnrollmentId = (state: IdvReducerState): string => state.enrollment_id;
const getQuestions = (state: IdvReducerState): IIdvQuestion[] => state.questions;
const getRequiresVerification = (state: IdvReducerState): boolean => state.requiresVerification;
const getVerified = (state: IdvReducerState): boolean => state.verified;
const getLocked = (state: IdvReducerState): boolean => state.locked;
const getFailed = (state: IdvReducerState): boolean => state.failed;
const getPosting = (state: IdvReducerState): boolean => state.posting;

export const selectIdvState = createFeatureSelector<IdvReducerState>('idv');
export const selectIdv = selectIdvState;
export const selectEnrollmentId = createSelector(selectIdvState, getEnrollmentId);
export const selectQuestions = createSelector(selectIdvState, getQuestions);
export const selectRequiresVerification = createSelector(selectIdvState, getRequiresVerification);
export const selectVerified = createSelector(selectIdvState, getVerified);
export const selectLocked = createSelector(selectIdvState, getLocked);
export const selectFailed = createSelector(selectIdvState, getFailed);
export const selectPosting = createSelector(selectIdvState, getPosting);
