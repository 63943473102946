import {Component, Input, OnInit} from '@angular/core';
import {INPUT_TYPE_ENUM, inputMasks} from '@clients/shared/common-forms';
import {FormControl, FormGroup} from '@angular/forms';
import {get} from 'lodash';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import {IVerificationFieldModel} from '../../models/verificationFields/IVerificationFieldModel';

@Component({
    selector: 'idv-render-verification-field',
    templateUrl: './render-verification-field.component.html',
    styleUrls: ['./render-verification-field.component.scss']
})
export class RenderVerificationFieldComponent implements OnInit {
    @Input()
    field: IVerificationFieldModel;

    @Input()
    language: string;

    @Input()
    datePipeFormat: string;

    public datePipe;
    public inputType = INPUT_TYPE_ENUM;
    public inputMasks = inputMasks;
    public control: FormControl;

    constructor() {}

    ngOnInit() {
        this.datePipe = createAutoCorrectedDatePipe(this.datePipeFormat);
        this.control = this.setControl(this.field.control);
    }

    private setControl = (control: FormGroup): FormControl => get(control, `controls.${this.field.editableProp}`);
}
