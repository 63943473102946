import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {SurveysCombinedReducerState, SurveysFacade} from '@clients/member-direct/surveys';
import {ENV_SERVICE} from '@clients/shared/config';
import {Logger, LogSeverity} from '@clients/shared/logging';
import {Store} from '@ngrx/store';

import {ClientStateSaveFailed} from '../+state/app/app.actions';
import {MEMBER_DIRECT_ERRORS} from '../errors/member-direct-errors';
import {SaveClientStatePayload} from '../models/SaveClientStatePayload.interface';

export const stateServiceEndpoints = (api: string) => ({
    save: (surveyRecordId: string) => `${api}/surveys/${surveyRecordId}/clientState`,
    retrieve: (surveyRecordId: string) => `${api}/surveys/${surveyRecordId}/clientState`
});

interface ClientStateData {
    surveyRecordId: string;
    state: SaveClientStatePayload;
}

@Injectable()
export class StateService {
    private clientState: BehaviorSubject<ClientStateData> = new BehaviorSubject<ClientStateData>(null);

    private api: string;
    constructor(
        @Inject(ENV_SERVICE) envService: MemberDirectEnvService,
        private http: HttpClient,
        private surveysFacade: SurveysFacade,
        private logger: Logger,
        private store: Store<any>
    ) {
        (this.api = envService.surveyServiceApi), this.subscription();
    }
    public save(surveyRecordId: string, state: SaveClientStatePayload) {
        this.clientState.next({
            surveyRecordId,
            state
        });
    }

    public retrieve(surveyRecordId: string): Observable<Object> {
        return this.http.get(stateServiceEndpoints(this.api).retrieve(surveyRecordId));
    }

    public assemblePayload(): SaveClientStatePayload {
        const state: SurveysCombinedReducerState = this.surveysFacade.getSurveysFeatureState();
        return {
            surveys: {
                blocks: {
                    idOfSelectedEntity: state.blocks.idOfSelectedEntity,
                    idOfPreviouslySelectedEntity: state.blocks.idOfPreviouslySelectedEntity,
                    hidden: state.blocks.hidden,
                    seen: state.blocks.seen,
                    completed: state.blocks.completed
                },
                elements: {
                    idOfSelectedEntity: state.elements.idOfSelectedEntity,
                    hidden: state.elements.hidden
                },
                responses: {
                    ids: state.responses.ids,
                    entities: state.responses.entities,
                    complete: state.responses.complete
                }
            }
        };
    }
    /* istanbul ignore next */
    private subscription() {
        this.clientState.pipe(debounceTime(200)).subscribe((data: ClientStateData) => {
            if (!data) return;

            this.saveClientState(data).subscribe(
                () => {},
                (err) => {
                    this.store.dispatch(new ClientStateSaveFailed(err));
                    this.logger.log(
                        'could not save client state',
                        {err, data},
                        LogSeverity.ERROR,
                        MEMBER_DIRECT_ERRORS.SAVE_CLIENT_STATE_FAILED
                    );
                }
            );
        });
    }

    private saveClientState(data: ClientStateData): Observable<Object> {
        const endpoint: string = stateServiceEndpoints(this.api).save(data.surveyRecordId);

        return this.http.post(endpoint, data.state);
    }
}
