import {SurveysResponsesState} from '../../responses/responses.interface';
import {RehydrateResponsesAction} from '../../responses/responses.actions';
import {combineClientStateIdsWithExistingIds} from './combine-client-state-ids-with-existing-ids.function';
import {combineClientStateResponsesWithExistingResponses} from './combine-client-state-responses-with-exiting-responses.function';

export function rehydrateResponsesState(
    action: RehydrateResponsesAction,
    state: SurveysResponsesState
): SurveysResponsesState {
    return {
        ...state,
        ids: combineClientStateIdsWithExistingIds(action.payload.ids, state.ids),
        entities: combineClientStateResponsesWithExistingResponses(action.payload.entities, state.entities)
    } as SurveysResponsesState;
}
