import {toNumber} from 'lodash';
import {debounceTime, startWith, takeUntil} from 'rxjs/operators';

import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SurveyResponse} from '@clients/member-direct/surveys';
import {Dictionary} from '@ngrx/entity';
import {heightInInchesPipe, inputMasks} from '@clients/shared/common-forms';
import {updateControlIfUnique} from '@clients/shared/utilities';

import {InputBase} from '../../input-base.abstract';
import {getElementId} from '../../+state/elements/elements.reducer';

@Component({
    selector: 'surveys-element-input-height',
    templateUrl: './element-input-height.component.html',
    styleUrls: ['./element-input-height.component.scss']
})
export class ElementInputHeightComponent extends InputBase<number> implements OnInit {
    public formGroup: FormGroup;
    public feet: FormControl = new FormControl(null, [Validators.min(0), Validators.max(7)]);
    public inches: FormControl = new FormControl(null, [Validators.min(0), Validators.max(11)]);
    public inputMasks = inputMasks;
    public heightInInchesPipe = heightInInchesPipe;
    public validInput = true;

    private initialValueHasBeenSet: boolean;
    private minimumHeight = 10;

    /* istanbul ignore next */
    constructor(injector: Injector, private formBuilder: FormBuilder) {
        super(injector);
    }

    ngOnInit() {
        this.buildFormGroup();

        super.ngOnInit();

        this.watchStoreChanges();
        this.watchForLocalChanges();
    }

    public handleResponseEntities(responses: Dictionary<SurveyResponse>): void {
        // this needs to be separated out for testing so we don't try to
        // get the ID of an element that doesn't exist.
        const elementId = this.element ? getElementId(this.element) : null;
        const response = responses[elementId];
        const value: number = response ? response.value : undefined;
        const transformedValue = this.transformBeforeDisplay(value);
        if (!this.initialDataLoaded) {
            this.lastValue = value;
            this.initialDataLoaded = true;
        }
        updateControlIfUnique(this.control, transformedValue);
        if (value) {
            this.setHeight(transformedValue);
        } else {
            this.clearHeight();
        }
    }

    public showProceedActions(): boolean {
        if (!this.validInput) {
            return false;
        }
        const value = this.formGroup.value;

        return this.selected && (value.feet || value.inches);
    }

    public checkInches(event: any) {
        const inches = event.target.value;
        if (inches > 11) {
            if (inches.length > 2) {
                this.inches.setValue(event.target.value.slice(0, 2));
            } else {
                this.inches.setValue(event.target.value.slice(0, 1));
            }
        }
    }

    protected transformBeforeSave(value: {feet: number; inches: number}): number {
        if (!value || (!value.feet && !value.inches)) return undefined;

        return toNumber(value.feet) * 12 + toNumber(value.inches);
    }

    protected shouldSave(value: any): boolean {
        return (value && value > this.minimumHeight) || value === undefined;
    }

    protected getFormValue() {
        return this.formGroup.value;
    }

    private buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            feet: this.feet,
            inches: this.inches
        });
    }

    private watchForLocalChanges(): void {
        this.formGroup.valueChanges
            .pipe(takeUntil(this.destroyed$), debounceTime(200))
            .subscribe((value: {feet: number; inches: number}) => this.valueUpdated(value));
    }

    private watchStoreChanges(): void {
        this.control.valueChanges
            .pipe(takeUntil(this.destroyed$), startWith(this.control.value))
            .subscribe((value: number) => {
                /* istanbul ignore if  */
                if (value) this.setHeight(value);

                this.initialValueHasBeenSet = true;
            });
    }

    private setHeight(value: number): void {
        if (this.validInput === false) {
            return;
        }
        const feet = Math.floor(value / 12);
        const inches = value % 12;

        // todo | update both controls at the same time https://healthtel.atlassian.net/browse/SB-1001
        if (feet !== 0 || !this.initialValueHasBeenSet || this.feet.invalid) {
            updateControlIfUnique(this.feet, feet);
        }

        if (inches !== 0 || !this.initialValueHasBeenSet || this.inches.invalid) {
            updateControlIfUnique(this.inches, inches);
        }
    }

    private clearHeight(): void {
        this.feet.reset();
        this.inches.reset();
    }
}
