import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, mergeMap} from 'rxjs/operators';
import {concat, from, of} from 'rxjs';
import {SurveyContentService} from '../../services/survey-content.service';
import {SurveyFacade} from '../survey/survey.facade';
import {LogErrorAction} from '@clients/shared/logging';
import {LOG_NAMES} from '../../errors/log-names';

import * as actions from './survey-content.actions';
import * as blockActions from '../blocks/blocks.actions';
import {
    IDynamicContentResponse,
    IDynamicContentResponseObject
} from '../../../models/api-responses/DynamicContentResponse.interface';
import {Action} from '@clients/shared/models';
import {DoneLoadingAction, LoadingAction} from '@clients/shared/loading';

@Injectable()
export class SurveyContentEffects {
    loadSurveyContent = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.SurveyContentActionTypes.LoadSurveyContents),
            map((action: actions.LoadSurveyContents) => action.payload.ids),
            concatMap((ids: string[]) =>
                concat(
                    of(
                        new LoadingAction({
                            minimumLoadingTimeInMs: 800
                        })
                    ),
                    this.surveyContentService.getCustomContent(this.surveyFacade.getSurveyRecordId(), ids).pipe(
                        mergeMap((res: any) => this.handleGetCustomContentApiResponse(res)),
                        catchError((err: any) =>
                            from([
                                new LogErrorAction({
                                    type: LOG_NAMES.CUSTOM_CONTENT_LOAD_ERROR,
                                    message: 'could not load custom content',
                                    options: {
                                        error: err,
                                        surveyRecordId: this.surveyFacade.getSurveyRecordId(),
                                        customContentGroupIds: ids
                                    }
                                }),
                                new DoneLoadingAction()
                            ])
                        )
                    )
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private surveyFacade: SurveyFacade,
        private surveyContentService: SurveyContentService
    ) {}

    private handleGetCustomContentApiResponse(res): Action<any>[] {
        return [
            new actions.UpsertSurveyContents({surveyContents: this.transformCustomContent(res)}),
            new blockActions.BlockReadyAction(),
            new DoneLoadingAction()
        ];
    }

    private transformCustomContent(res: IDynamicContentResponse): Array<any> {
        return Object.keys(res).reduce((agg, dynamicContentId: string) => {
            agg.push(this.transformResponseObject(dynamicContentId, res[dynamicContentId]));

            return agg;
        }, []);
    }

    private transformResponseObject(dynamicContentId: string, responseObject: IDynamicContentResponseObject[]) {
        return {
            id: dynamicContentId,
            data: responseObject.map(
                (responseObjectWithModel: IDynamicContentResponseObject) => responseObjectWithModel.model
            )
        };
    }
}
