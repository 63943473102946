<div *ngIf="element" class="surveys-elements-custom">
    <div class="custom-element" [ngSwitch]="customElement">
        <surveys-element-pharmacy-finder *ngSwitchCase="surveyCustomElementType.PHARMACY_FINDER" [element]="element">
        </surveys-element-pharmacy-finder>

        <surveys-element-flu-mind-bender *ngSwitchCase="surveyCustomElementType.FLU_MIND_BENDER" [element]="element">
        </surveys-element-flu-mind-bender>

        <surveys-element-suggested-care *ngSwitchCase="surveyCustomElementType.SUGGESTED_CARE" [element]="element">
        </surveys-element-suggested-care>
    </div>
</div>
