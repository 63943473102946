import {Action, ActionsSubject} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {AppActions} from './app-actions.service';

@Injectable()
export class CustomDispatcher extends ActionsSubject {
    /* istanbul ignore next */
    constructor(private actions: AppActions) {
        super();
    }

    next(action: Action) {
        super.next(action);
        this.actions.nextAction(action);
    }
}
