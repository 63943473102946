import {MemberDirectSurveysState} from './surveys.interface';
import {MemberDirectSurveysActions} from './surveys.actions';

export function memberDirectSurveysReducer(state: MemberDirectSurveysState, action: any): MemberDirectSurveysState {
    switch (action.type) {
        case MemberDirectSurveysActions.SURVEY_IS_PRE_AUTHORIZED:
            return {...state, preauthorizedSurvey: true};

        case MemberDirectSurveysActions.SURVEY_IS_NOT_PRE_AUTHORIZED:
            return {...state, preauthorizedSurvey: false};

        default: {
            return state;
        }
    }
}

export const getPreAuthorizedSurvey = (state: MemberDirectSurveysState): boolean => state.preauthorizedSurvey;
