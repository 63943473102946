import {Component, Injector, Input, OnInit} from '@angular/core';
import {inputMasks} from '@clients/shared/common-forms';
import {onlyNumbers} from '@clients/shared/utilities';
import {InputBase} from '../../input-base.abstract';

@Component({
    selector: 'surveys-element-input-phone-number',
    templateUrl: './element-input-phone-number.component.html',
    styleUrls: ['./element-input-phone-number.component.scss']
})
export class ElementInputPhoneNumberComponent extends InputBase<string> implements OnInit {
    @Input() public validForm = true;

    public inputMasks = inputMasks;

    /* istanbul ignore next */
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        this.enableAutoSave();
    }

    protected transformBeforeSave(value: string): string {
        return onlyNumbers(value);
    }
}
