import {Component, Injector, OnInit} from '@angular/core';
import {SurveyResponse} from '../../../models/survey-responses/SurveyResponse.interface';
import {getElementId} from '../../+state/elements/elements.reducer';
import {Dictionary} from '@ngrx/entity/src/models';
import {QuestionBaseAbstract} from '../../question-base.abstract';
import {SurveyConfigService} from '../../services/survey-config.service';
import {YesNoButtonsMode} from '../../enum/YesNoButtonsMode.enum';
import {ISurveyElementYesNo} from '../../../models/survey/ISurveyQuestion';

@Component({
    selector: 'surveys-element-yes-no',
    templateUrl: './element-yes-no.component.html',
    styleUrls: ['./element-yes-no.component.scss']
})
export class ElementYesNoComponent
    extends QuestionBaseAbstract<boolean, Dictionary<SurveyResponse>, ISurveyElementYesNo>
    implements OnInit
{
    public value: boolean;
    public buttonsMode = YesNoButtonsMode.DEFAULT;

    /* istanbul ignore next */
    constructor(injector: Injector, private surveyConfigService: SurveyConfigService) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        this.setVisualMode();
    }

    public handleResponseEntities(responses: Dictionary<SurveyResponse>) {
        const response = responses[getElementId(this.element)];
        this.value = response ? response.value : undefined;
    }

    public execute(value: boolean): void {
        const response: SurveyResponse = new SurveyResponse(this.element, value);
        this.save(response, this.element, value);
    }

    private setVisualMode() {
        const visual = this.surveyConfigService.getVisual();
        if (visual && visual.yesNoButtonsMode) this.buttonsMode = visual.yesNoButtonsMode;
    }
}
