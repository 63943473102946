import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectLoaderCanCloseAt} from './index';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoadingFacade {
    constructor(private store: Store<any>) {}

    public getLoaderCanCloseAt(): number | null {
        let val = null;
        this.store.pipe(select(selectLoaderCanCloseAt), take(1)).subscribe((res: number | null) => (val = res));

        return val;
    }
}
