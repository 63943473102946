import {reduce, kebabCase} from 'lodash';

export function buildCssVariables(config: any, prefix: string): string {
    return reduce(
        config as any,
        (result, value, key: string) => {
            result += `--${prefix}-${kebabCase(key)}: ${value};`;
            return result;
        },
        ''
    );
}
