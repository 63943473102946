import {find, get, isFunction, isUndefined} from 'lodash';
import {takeUntil} from 'rxjs/operators';

import {Directive, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Action} from '@clients/shared/models';
import {select} from '@ngrx/store';

import {RunInstructionsAction} from '../lib/+state/blocks/blocks.actions';
import {DeleteResponseAction, RecordResponseAction} from '../lib/+state/responses/responses.actions';
import {SurveyResponse} from '../models/survey-responses/SurveyResponse.interface';
import {SurveyInstructionType} from '../models/survey/ISurveyInstruction';
import {selectResponseEntities} from './+state';
import {BaseElement} from './base-element.abstract';
import {Observable} from 'rxjs';

@Directive()
export abstract class QuestionBaseAbstract<T, R, E> extends BaseElement implements OnInit {
    public currentEntityResponses: R;
    public responseEntitie$: Observable<any>;

    @Output() public answered: EventEmitter<T> = new EventEmitter<T>(); // eslint-disable-line
    protected _element: E;

    protected constructor(injector: Injector) {
        super(injector);

        this.responseEntitie$ = this.store.pipe(select(selectResponseEntities), takeUntil(this.destroyed$));
    }

    get element() {
        return this._element;
    }

    @Input()
    set element(element: E) {
        this._element = element;
        if (this.currentEntityResponses) {
            this.handleResponseEntities(this.currentEntityResponses);
        }
    }

    ngOnInit() {
        this.responseEntitie$.subscribe((responses) => {
            this.currentEntityResponses = responses;
            this.handleResponseEntities(responses);
        });
    }

    /*
     * @deprecated
     * */
    public save(response: SurveyResponse, element: any, value: T, shouldProceed: boolean = true): void {
        this.handleRelatedQuestions(element, value);
        this.store.dispatch(new RecordResponseAction(response));
        this.store.dispatch(new RunInstructionsAction(element));
        shouldProceed && this.proceed(value);
    }

    public saveWithCustomAction(element: any, value: T, actionCreator: () => Action<any>): void {
        this.handleRelatedQuestions(element, value);
        isFunction(actionCreator) && this.store.dispatch(actionCreator());
    }

    public runInstructions(element: any): void {
        this.store.dispatch(new RunInstructionsAction(element));
    }

    public proceed(value?: T) {
        this.answered.emit(value);
    }

    public handleRelatedQuestions(element: E, newResponse: any) {
        const instructions = get(element, 'instructions');
        if (isUndefined(instructions)) return;

        const hideRelatedQuestionInstruction = find(instructions, [
            'type',
            SurveyInstructionType.HIDE_RELATED_QUESTION
        ]);
        if (isUndefined(hideRelatedQuestionInstruction)) return;

        const shouldReset = this.shouldReset(hideRelatedQuestionInstruction.when.value, newResponse);
        if (shouldReset) {
            // todo | make sure this is right https://healthtel.atlassian.net/browse/SB-1017
            const response = {
                id: hideRelatedQuestionInstruction.data,
                questionId: hideRelatedQuestionInstruction.data,
                answerId: hideRelatedQuestionInstruction.data,
                value: null,
                created_at: new Date().getTime()
            };
            this.store.dispatch(new DeleteResponseAction(response));
        }
    }
    private shouldReset(value: boolean | undefined, response: boolean | undefined | any[]): boolean {
        if (Array.isArray(value)) {
            return value.includes(response);
        }
        return value === response;
    }
    public abstract handleResponseEntities(responses: R): void;
}
