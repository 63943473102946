import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectTopLevelReducer} from '../+state';
import {SurveysCombinedReducerState} from './interface';
import {take} from 'rxjs/operators';

@Injectable()
export class SurveysFacade {
    constructor(private store: Store<SurveysCombinedReducerState>) {}

    public getSurveysFeatureState(): SurveysCombinedReducerState {
        let val: SurveysCombinedReducerState;
        this.store.pipe(select(selectTopLevelReducer), take(1)).subscribe((state) => (val = state));

        return val;
    }
}
