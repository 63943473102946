/* istanbul ignore file */
import {LazyMapsAPILoaderConfigLiteral} from '@agm/core';
import {Inject, Injectable} from '@angular/core';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {ENV_SERVICE} from '@clients/shared/config';

@Injectable()
export class GoogleMapConfigService implements LazyMapsAPILoaderConfigLiteral {
    public libraries: string[];

    public apiKey: string;

    constructor(@Inject(ENV_SERVICE) envService: MemberDirectEnvService) {
        this.apiKey = envService.googleConfig.maps.apiKey;
        this.libraries = ['places'];
    }
}
