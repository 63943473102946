import {Component, Injector, OnInit} from '@angular/core';
import {InputBase} from '../../input-base.abstract';
import {inputMasks} from '@clients/shared/common-forms';
import {toNumber} from 'lodash';
import {onlyNumbers} from '@clients/shared/utilities';

@Component({
    selector: 'surveys-element-input-year',
    templateUrl: './element-input-year.component.html',
    styleUrls: ['./element-input-year.component.scss']
})
export class ElementInputYearComponent extends InputBase<number> implements OnInit {
    public yearMask = inputMasks.year;

    /* istanbul ignore next */
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        this.enableAutoSave();
    }

    protected transformBeforeDisplay(value: number): string {
        return value ? value.toString() : '';
    }

    protected transformBeforeSave(value: string): number {
        const asNumbers = onlyNumbers(value);
        if (asNumbers && asNumbers.length > 0) {
            if (asNumbers.length === 4) {
                return toNumber(asNumbers);
            }

            return null;
        }

        return undefined;
    }

    protected shouldSave(value: number): boolean {
        return value !== null;
    }
}
