import {Injectable} from '@angular/core';
import {
    selectResponseIds,
    selectResponseEntities,
    selectAllResponses,
    selectResponseTotal,
    selectResponsesComplete,
    selectAllResponsesInOrder
} from '../index';
import {StoreDataFacade} from '@clients/shared/utilities';
import {select, Store} from '@ngrx/store';
import {SurveysResponsesState} from './responses.interface';
import {SurveyResponse} from '../../../models/survey-responses/SurveyResponse.interface';
import {take} from 'rxjs/operators';

@Injectable()
export class ResponsesFacade extends StoreDataFacade<SurveysResponsesState> {
    constructor(store: Store<SurveysResponsesState>) {
        super(store, selectResponseIds, selectResponseEntities, selectAllResponses, selectResponseTotal);
    }

    public getResponseValue(id: string): any | undefined {
        const response = this.get(id);

        return response ? response.value : undefined;
    }

    public getComplete(): boolean {
        let val: boolean;
        this.store.pipe(select(selectResponsesComplete), take(1)).subscribe((value) => (val = value));

        return val;
    }

    public getLogs(): SurveyResponse[] {
        let val;
        this.store.pipe(select(selectAllResponsesInOrder), take(1)).subscribe((value) => (val = value));

        return val;
    }
}
