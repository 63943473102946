import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonFormsModule} from '@clients/shared/common-forms';

import {TRANSLATIONS_PROVIDERS} from './config/translation.providers';
import {TranslatePipe} from './pipes/translate.pipe';
import {TranslationService} from './services/translation.service';

const SHARED_PROVIDERS = [TranslationService];

@NgModule({
    imports: [CommonModule, CommonFormsModule],
    declarations: [TranslatePipe],
    exports: [TranslatePipe]
})
export class TranslationModule {
    public static forRoot(localeFiles: any[] = []): ModuleWithProviders<TranslationModule> {
        return {
            ngModule: TranslationModule,
            providers: [SHARED_PROVIDERS, {provide: TRANSLATIONS_PROVIDERS, useValue: localeFiles, multi: true}]
        };
    }

    public static forChild(localeFiles: any[] = []): ModuleWithProviders<TranslationModule> {
        return {
            ngModule: TranslationModule,
            providers: [SHARED_PROVIDERS, {provide: TRANSLATIONS_PROVIDERS, useValue: localeFiles, multi: true}]
        };
    }
}
