import {LoadingReducerState} from './loading.interfaces';
import {LoadingAction, LoadingActionTypes} from './loading.actions';
import {loadingInitialState} from './loading.init';
import {get} from 'lodash';

export function loadingReducer(state: LoadingReducerState = loadingInitialState, action: any): LoadingReducerState {
    switch (action.type) {
        case LoadingActionTypes.LOADING:
            return {
                ...state,
                active: true,
                loaderCanCloseAt: getCloseTime(action)
            };

        case LoadingActionTypes.CLOSE_LOADER:
            return {
                ...state,
                active: false,
                loaderCanCloseAt: null
            };

        default: {
            return state;
        }
    }
}

/**
 * Returns either the time at which the loader can be closed
 */
function getCloseTime(action: LoadingAction): number {
    const minimumLoadingTimeInMs = get(action, 'payload.minimumLoadingTimeInMs');

    return !minimumLoadingTimeInMs ? 0 : new Date().getTime() + minimumLoadingTimeInMs;
}

export const getActive = (state: LoadingReducerState) => state.active;
export const getLoaderCanCloseAt = (state: LoadingReducerState) => state.loaderCanCloseAt;
