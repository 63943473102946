import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RouterModule} from '@angular/router';
import {IdvModule} from '@clients/member-direct/idv';
import {SURVEYS_LIFECYCLE_SURVEY_LOADED, SurveysModule} from '@clients/member-direct/surveys';
import {TranslationModule} from '@clients/member-direct/translation';
import {AnalyticsModule, GoogleAnalyticsService, RevelAnalyticsService} from '@clients/shared/analytics';
import {AuthModule, AuthProvider, IdvService} from '@clients/shared/auth';
import {CommonFormsModule} from '@clients/shared/common-forms';
import {LayoutGridModule} from '@clients/shared/layout-grid';
import {LoadingModule} from '@clients/shared/loading';
import {PERSISTENCE_LOCAL_STORAGE, PersistenceModule} from '@clients/shared/persistence';
import {AppActions} from '@clients/shared/utilities';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

import {appInitialState, appReducers} from './+state';
import {MemberDirectAnalyticsEffects} from './+state/analytics/analytics.effects';
import {MemberDirectEffects} from './+state/app/app.effects';
import {MemberDirectConfigEffects} from './+state/config/config.effects';
import {IdvEffects} from './+state/idv/idv.effects';
import {SurveyLoadedAction} from './+state/surveys/surveys.actions';
import {SurveysEffects} from './+state/surveys/surveys.effects';
import {AppComponent} from './components/app/app.component';
import {BootstrapComponent} from './components/bootstrap/bootstrap.component';
import {CompanyLogoComponent} from './components/company-logo/company-logo.component';
import {DividerComponent} from './components/divider/divider.component';
import {ErrorComponent} from './components/error/error.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {HomeComponent} from './components/home/home.component';
import {IdvComponent} from './components/idv/idv.component';
import {InvalidRouteComponent} from './components/invalid-route/invalid-route.component';
import {LanguageSwitcherComponent} from './components/language-switcher/language-switcher.component';
import {NavigationMobileComponent} from './components/navigation-mobile/navigation-mobile.component';
import {PageLayoutComponent} from './components/page-layout/page-layout.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {QuoteComponent} from './components/quote/quote.component';
import {SidebarBlockNavigationComponent} from './components/sidebar-block-navigation/sidebar-block-navigation.component';
import {SkipButtonComponent} from './components/skip-button/skip-button.component';
import {SurveyNameComponent} from './components/survey-name/survey-name.component';
import {SurveyShortLinkRedirectComponent} from './components/survey-short-link-redirect/survey-short-link-redirect.component';
import {IdvNotRequiredOrCompleteGuard} from './guards/idv-not-required-or-complete.guard';
import {IdvNotYetCompleteGuard} from './guards/idv-not-yet-complete.guard';
import {SurveyContextIsValidGuard} from './guards/survey-context-is-valid.guard';
import {memberDirectLocale} from './locale/locale';
import {StateService} from './services/state.service';

@NgModule({
    imports: [
        CommonModule,
        ///routes goes on app level --> let each app choose it's routes
        RouterModule.forChild([]),

        // ngrx
        StoreModule.forFeature('app', appReducers, {
            initialState: appInitialState
        }),
        EffectsModule.forFeature([
            MemberDirectEffects,
            MemberDirectAnalyticsEffects,

            // features
            IdvEffects,
            SurveysEffects,
            MemberDirectConfigEffects
        ]),

        // vendor
        MatProgressSpinnerModule,

        // features
        AnalyticsModule.forRoot([GoogleAnalyticsService, RevelAnalyticsService], {
            developerMode: false
        }),
        AuthModule,
        CommonFormsModule,
        IdvModule,
        LayoutGridModule,
        LoadingModule,
        PersistenceModule,
        SurveysModule,
        TranslationModule.forChild([memberDirectLocale])
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        PageLayoutComponent,
        HomeComponent,
        PrivacyComponent,
        ErrorComponent,
        BootstrapComponent,
        IdvComponent,
        AppComponent,
        SurveyShortLinkRedirectComponent,
        QuoteComponent,
        DividerComponent,
        SurveyNameComponent,
        CompanyLogoComponent,
        InvalidRouteComponent,
        SidebarBlockNavigationComponent,
        NavigationMobileComponent,
        LanguageSwitcherComponent,
        SkipButtonComponent
    ],
    providers: [
        // services
        StateService,

        // ngrx custom dispatcher
        AppActions,

        // guards
        SurveyContextIsValidGuard,
        IdvNotRequiredOrCompleteGuard,
        IdvNotYetCompleteGuard,

        // auth
        {provide: AuthProvider, useClass: IdvService},

        // persistence
        PERSISTENCE_LOCAL_STORAGE,
        {provide: SURVEYS_LIFECYCLE_SURVEY_LOADED, useValue: [SurveyLoadedAction]}
    ],
    exports: [PageLayoutComponent, AppComponent]
})
export class MemberDirectLibModule {
    /*
     * NOTE
     * Angular build process is optimizing destructuring in prod build and that could lead to errors.
     * e.g. public static forRoot(): ModuleWithProviders {...}
     * was passing down the whole config object.
     * We should be careful when using that type of syntax.
     * */
    public static forRoot(): ModuleWithProviders<MemberDirectLibModule> {
        return {
            ngModule: MemberDirectLibModule
        };
    }
}
