/* istanbul ignore file */
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {TextMaskConfig} from 'angular2-text-mask';
import {Element} from '../element.abstract';

@Component({
    selector: 'r-masked-textarea',
    templateUrl: './masked-textarea.component.html',
    styleUrls: ['./masked-textarea.component.scss']
})
export class MaskedTextAreaComponent extends Element implements OnInit, OnChanges {
    @Output() public keyUp: EventEmitter<any> = new EventEmitter<any>();
    @Input() public type: string;
    @Input() public autocomplete: string;
    @Input() public shouldFocus: boolean;
    @Input() public maxlength: number;
    @Input() public mask: any;
    @Input() public pipe: any;
    @Input() public guide = true;
    @Input() public showMask = false;
    @ViewChild('textArea') private textArea: ElementRef;
    public maskConfig: TextMaskConfig;
    public textLength = 0;
    public _labeledby: string;
    public _describedby: string;

    @Input()
    public get labeledby() {
        return this._labeledby;
    }

    @Input()
    public get describedby() {
        return [this.labeledby, this._describedby].join(' ');
    }

    public set describedby(value: string) {
        this._describedby = value;
    }

    // eslint-disable-next-line
    public set labeledby(value: string) {
        this._labeledby = value;
    }

    ngOnInit() {
        this.maskConfig = {
            mask: this.mask,
            pipe: this.pipe,
            guide: this.guide,
            showMask: this.showMask
        };
        super.ngOnInit();
    }

    ngOnChanges(changes) {
        if (changes.shouldFocus && !changes.shouldFocus.previousValue && changes.shouldFocus.currentValue === true) {
            this.textArea.nativeElement.focus();
        }
    }

    updateElement(element) {
        this.control.setValue(element.target.value);
        element.target.style.height = '0px';
        element.target.style.height = element.target.scrollHeight + 2 + 'px';
        const value = element.target.value;
        this.textLength = value.length;
    }
}
