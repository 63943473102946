<div class="member-direct-page-error">
    <div class="error-box">
        <div class="image">
            <img src="https://revel.imgix.net/clients/humana/web/images/bandage.svg" alt="Bandage" />
        </div>
        <div class="meta">
            <h1>Oops! Something went wrong.</h1>
            <h2 class="message">{{ (message | async) || defaultErrorMessage }}</h2>
            <div *ngIf="linkId" class="actions">
                <r-button (onClick)="bootstrapApplication()">Select to Retry</r-button>
            </div>
        </div>
    </div>
</div>
