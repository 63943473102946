import {EventTrackPayload} from '../+state/analytics.actions';

export class LinkClicked implements EventTrackPayload {
    readonly eventName = 'Link Clicked';

    public properties;

    constructor(href: string) {
        this.properties = {
            href
        };
    }
}
