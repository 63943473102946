/* istanbul ignore file */
import ExtendoError from 'extendo-error';

export class SurveyContextInvalidError extends ExtendoError {
    readonly type = 'survey_context_invalid';
    public message = '';
    public options: any;

    constructor(context: string) {
        super();

        this.message = `survey context could not be validated`;
        this.options = {context};
    }
}
