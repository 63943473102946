import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';

export const MemberDirectConfigActionTypes = {
    RESET: type('[member-direct-config] reset'),

    SET_CONFIG: type('[member-direct-config] set config prop'),

    SET_DEFAULT_LANGUAGE: type('[member-direct-config] set default language'),
    SET_LANGUAGE: type('[member-direct-config] set language')
};

export class ResetMemberDirectConfigAction implements Action {
    public type = MemberDirectConfigActionTypes.RESET;
}

interface SetConfigPropActionPayload {
    prop: string;
    value: any;
}

export class SetConfigAction implements Action<SetConfigPropActionPayload> {
    public type = MemberDirectConfigActionTypes.SET_CONFIG;

    constructor(public payload: SetConfigPropActionPayload) {}
}

export class SetDefaultLanguageAction implements Action<string> {
    public type = MemberDirectConfigActionTypes.SET_DEFAULT_LANGUAGE;

    constructor(public payload: string) {}
}

export class SetAppLanguageAction implements Action<string> {
    public type = MemberDirectConfigActionTypes.SET_LANGUAGE;

    constructor(public payload: string) {}
}
