import {Action} from '@clients/shared/models';
import {MultiSelectSurveyResponse, SurveyResponse} from '../../../../models/survey-responses/SurveyResponse.interface';
import {SurveysResponsesState} from '../responses.interface';
import {OptionType} from '../../../enum/OptionType.enum';
import {filter, flow, map} from 'lodash';
import {Dictionary} from '@ngrx/entity';

export function handleNoneOption(
    responsesAdapter,
    entities: Dictionary<SurveyResponse>,
    action: Action<MultiSelectSurveyResponse>,
    state: SurveysResponsesState
) {
    const {
        payload: {questionId}
    } = action;
    const ids = flow([
        (collection: Array<MultiSelectSurveyResponse>) =>
            filter(
                collection,
                (entity: MultiSelectSurveyResponse) =>
                    entity.questionId === questionId && entity.optionType !== OptionType.NONE
            ),
        (collection: Array<MultiSelectSurveyResponse>) =>
            map(collection, (entity: MultiSelectSurveyResponse) => entity.id)
    ])(entities);

    return responsesAdapter.removeMany(ids, state);
}
