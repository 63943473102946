<div class="mobile-menu-underlay" [class.open]="isOpen" (click)="onClose()"></div>

<clients-grid-layout
    *ngIf="display"
    [hideMd]="true"
    [hideLg]="true"
    class="mobile-block-nav"
    [align]="'center center'"
    [layoutGap]="layoutGap"
    [layout]="LAYOUT.COLUMN"
    [class.open]="isOpen"
>
    <clients-grid-layout style="max-width: 100%" [layout]="LAYOUT.COLUMN">
        <clients-grid-layout
            style="max-width: 100%"
            [alignSm]="'space-between center'"
            [alignXs]="'space-between center'"
            [layout]="LAYOUT.ROW"
            [layoutSm]="LAYOUT.ROW"
            [layoutXs]="LAYOUT.ROW"
        >
            <member-direct-survey-name [name]="name"> </member-direct-survey-name>

            <div class="close" (click)="onClose()"><r-svg-icon [name]="'close'"></r-svg-icon></div>
        </clients-grid-layout>

        <member-direct-divider></member-direct-divider>

        <div class="mobile-instructions" *ngIf="instructions">{{ instructions }}</div>

        <ng-content></ng-content>
    </clients-grid-layout>
</clients-grid-layout>
