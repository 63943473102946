import {Component, Injector, Input, OnInit} from '@angular/core';
import {InputBase} from '../../input-base.abstract';
import {inputMasks} from '@clients/shared/common-forms';
import {isValidDate} from '@clients/shared/utilities';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import {toUtc} from '@clients/shared/utilities';

@Component({
    selector: 'surveys-element-input-birthdate',
    templateUrl: './element-input-birthdate.component.html',
    styleUrls: ['./element-input-birthdate.component.scss']
})
export class ElementInputBirthdateComponent extends InputBase<string> implements OnInit {
    @Input() public validForm: boolean;
    public inputMasks = inputMasks;
    public datePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');

    /* istanbul ignore next */
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        this.enableAutoSave();
    }
    protected shouldSave(value: string): boolean {
        return value !== null;
    }
    protected transformBeforeDisplay(value: string): string {
        const date = new Date(value);
        if (!isValidDate(date)) return '';

        const numericDay = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);

        return `${month}/${numericDay}/${date.getFullYear()}`;
    }

    protected transformBeforeSave(value: string): string {
        // if value is blank (user deleted input) or null
        // or undefined or whatever then we want to remove
        // the result from our results set.
        if (!value || !value.length) {
            return undefined;
        }

        // if the value is a valid date then we want to
        // save it.
        if (value.length > 4 && value.slice(-4).search('/') === -1) {
            const date = toUtc(new Date(value));
            if (isValidDate(new Date(date))) {
                return date;
            }
        }

        // if there is something there, but it's not a valid
        // date then we want to return null which in the above
        // function basically means we do not save nor delete
        // the value in our results, we just wait for it to change
        // to either be blank or a valid date.
        return null;
    }
}
