import {Component, OnInit} from '@angular/core';
import {PersistenceService} from '@clients/shared/persistence';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {MemberDirectReducerState} from '../../+state/app/app.interface';
import {selectErrorText} from '../../+state';

@Component({
    selector: 'member-direct-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    public message: Observable<string>;
    public linkId: string;
    public defaultErrorMessage =
        'An unknown error has occurred. Please try your request again or contact your provider for assistance.';

    constructor(
        private persistence: PersistenceService,
        private router: Router,
        private store: Store<MemberDirectReducerState>
    ) {}

    ngOnInit() {
        this.linkId = this.persistence.get('linkId');
        this.message = this.store.pipe(select(selectErrorText));
    }

    public bootstrapApplication() {
        this.router.navigateByUrl(`/${this.linkId}`);
    }
}
