import {filter, find, get} from 'lodash';
import {takeUntil} from 'rxjs/operators';

import {AfterViewInit, Component, Injector, Input, OnInit} from '@angular/core';
import {or} from '@clients/shared/utilities';
import {select} from '@ngrx/store';

import {selectMultipleSelectQuestionEntities} from '../../+state';
import {MultiSelectResponseRecordedAction} from '../../+state/responses/responses.actions';
import {IResponseBase} from '../../../models/survey-responses/IResponseBase';
import {MultiSelectSurveyResponse} from '../../../models/survey-responses/SurveyResponse.interface';
import {IMultilingualDataOption, ISurveyMultipleChoice} from '../../../models/survey/ISurveyQuestion';
import {OptionModeEnum} from '../../enum/OptionMode.enum';
import {QuestionBaseAbstract} from '../../question-base.abstract';

@Component({
    selector: 'surveys-element-multiple-select',
    templateUrl: './element-multiple-select.component.html',
    styleUrls: ['./element-multiple-select.component.scss']
})
export class ElementMultipleSelectComponent
    extends QuestionBaseAbstract<IMultilingualDataOption, Array<IResponseBase>, ISurveyMultipleChoice>
    implements OnInit, AfterViewInit
{
    public allMultiSelectResponses: Array<MultiSelectSurveyResponse> = [];
    public responses: Array<MultiSelectSurveyResponse> = [];
    public selectedMin = 0;
    public selectedMax = 30;
    public maxSelectionReached = false;
    private currentMode: OptionModeEnum;

    /* istanbul ignore next */
    constructor(injector: Injector) {
        super(injector);

        this.responseEntitie$ = this.store.pipe(
            select(selectMultipleSelectQuestionEntities()),
            takeUntil(this.destroyed$)
        );
    }

    get element() {
        return this._element;
    }

    @Input()
    set element(element: ISurveyMultipleChoice) {
        this._element = element;
        this.handleResponseEntities(this.allMultiSelectResponses);
    }

    ngOnInit() {
        this.responseEntitie$.subscribe((responses) => {
            this.allMultiSelectResponses = responses;
            this.handleResponseEntities(responses);
        });
    }

    ngAfterViewInit() {
        this.processRules();
    }

    public handleResponseEntities(responses: Array<any>) {
        if (responses) {
            this.responses = this.filterCurrentQuestionResponses(responses);
        }
        /*
         * recalculate maxSelectionReached only if an item has been deselected or selected
         * */
        if (this.currentMode) {
            this.currentMode !== OptionModeEnum.save && (this.maxSelectionReached = this.isMaxSelectionReached());
        }
    }

    public execute({option, mode}: {option: IMultilingualDataOption; mode: OptionModeEnum}): void {
        this.currentMode = mode;
        if (!this.shouldRecordResponse(option)) return;

        const value = mode === OptionModeEnum.select;

        // hide or show any related questions
        this.handleRelatedQuestions(this.element, value);

        // save the multiselect survey response
        const response: MultiSelectSurveyResponse = new MultiSelectSurveyResponse(
            option.id,
            this.element.id,
            value,
            option.type
        );
        this.saveOption(response);
    }

    public activateNext() {
        this.proceed();
    }

    private processRules(): void {
        const selectionRules = get(this.element, 'rules.selection');
        if (selectionRules) {
            this.selectedMin = selectionRules.min;
            this.selectedMax = selectionRules.max;
        }
    }

    private saveOption(response: MultiSelectSurveyResponse) {
        this.store.dispatch(new MultiSelectResponseRecordedAction(response));
        this.runInstructions(this.element);
    }

    private shouldRecordResponse(option: IMultilingualDataOption) {
        /*
         * the amount of selected options should be less than the max from the config
         * */
        const selected = this.getSelectedResponses();
        return or(selected.length + 1 <= this.selectedMax, !!find(selected, ['id', option.id]));
    }

    private isMaxSelectionReached(): boolean {
        return this.getSelectedResponses().length >= this.selectedMax;
    }

    private getSelectedResponses() {
        return filter(this.currentEntityResponses, ['value', true]);
    }

    private filterCurrentQuestionResponses(responses: Array<any>): Array<any> {
        if (this.element && responses) {
            return filter(responses, ['questionId', this.element.id]);
        }
        return [];
    }
}
