<div
    *ngIf="element"
    class="survey-element surveys-element-multiple-select"
    [class.has-instructions]="element.config?.showInstructions"
    role="group"
>
    <surveys-element-question-text
        *ngIf="multipartQuestionHeader"
        [text]="multipartQuestionHeader"
        [textSize]="'small'"
        [classes]="['multipart-label']"
    ></surveys-element-question-text>

    <surveys-element-question-text
        [id]="element.id"
        [required]="element.required"
        [text]="element.label[language]"
        [textSize]="element?.data?.smallLabel ? 'small' : 'large'"
    ></surveys-element-question-text>

    <div *ngIf="element.config?.showInstructions" class="instructions">
        {{ 'surveys.elements.multiSelect.instructions.selectAll' | translate }}
    </div>

    <surveys-element-options-block
        *ngIf="element.data?.options"
        [options]="element.data?.options"
        [language]="language"
        [selection]="true"
        [responses]="responses"
        [questionType]="element.type"
        [maxSelectionReached]="maxSelectionReached"
        [labeledby]="element.id"
        (clickHandler)="execute($event)"
    ></surveys-element-options-block>

    <r-button
        class="continue-button"
        [e2e]="'button-' + element.id + '-continue'"
        [affirmative]="true"
        [negative]="false"
        (onClick)="activateNext()"
    >
        {{ 'surveys.navigation.continue' | translate }}
    </r-button>

    <div *ngIf="element.elements" class="nested-content">
        <!-- render nested content -->
        <div class="elements">
            <surveys-render-survey-element *ngFor="let element of element.elements" [element]="element">
            </surveys-render-survey-element>
        </div>
    </div>
</div>
