import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';

@Component({
    selector: 'surveys-element-action-block',
    template: `
        <clients-grid-layout
            [layoutGap]="0"
            [layout]="LAYOUT.ROW"
            [layoutXs]="LAYOUT.COLUMN"
            [layoutSm]="LAYOUT.COLUMN"
            [align]="'space-between center'"
            [alignXs]="'center flex-start'"
            [alignSm]="'center flex-start'"
        >
            <div class="info">
                <h2>{{ title }}</h2>
                <h3>{{ description }}</h3>
            </div>
            <div class="action">
                <r-button (click)="clicked.emit()"> {{ actionLabel }} </r-button>
            </div>
        </clients-grid-layout>
    `,
    styleUrls: ['./element-action-block.component.scss']
})
export class ElementActionBlockComponent {
    @Input()
    public title;
    @Input()
    public description;
    @Input()
    public actionLabel;

    @Output()
    public clicked: EventEmitter<any> = new EventEmitter<any>();

    public LAYOUT = FLEX_LAYOUT_ENUM;

    constructor() {}
}
