<div *ngIf="element" class="surveys-render-survey-element" [style.height]="matchChildHeight ? '100%' : 'auto'">
    <surveys-element-dynamic-content *ngIf="elementIsDynamic" [element]="element"></surveys-element-dynamic-content>

    <div
        *ngIf="!elementIsDynamic"
        [ngSwitch]="element.type"
        class="element element-id-{{ element.id }}"
        id="element-id-{{ element.id }}"
        [attr.data-e2e]="'element-id-' + element.id"
        [style.height]="matchChildHeight ? '100%' : 'auto'"
    >
        <surveys-element-accordion *ngSwitchCase="types.ACCORDION" [element]="element"></surveys-element-accordion>

        <surveys-element-accordion-group
            *ngSwitchCase="types.ACCORDION_GROUP"
            [element]="element"
        ></surveys-element-accordion-group>

        <surveys-element-button *ngSwitchCase="types.BUTTON" [element]="element"></surveys-element-button>

        <surveys-element-container *ngSwitchCase="types.CONTAINER" [element]="element"></surveys-element-container>

        <surveys-element-custom *ngSwitchCase="types.CUSTOM" [element]="element"></surveys-element-custom>

        <surveys-element-footnote *ngSwitchCase="types.FOOTNOTE" [element]="element"></surveys-element-footnote>

        <surveys-element-html *ngSwitchCase="types.HTML" [element]="element"></surveys-element-html>

        <surveys-element-image *ngSwitchCase="types.IMAGE" [element]="element"></surveys-element-image>

        <surveys-element-multiple-choice
            *ngSwitchCase="types.MULTIPLE_CHOICE"
            [element]="element"
        ></surveys-element-multiple-choice>

        <surveys-element-multiple-select *ngSwitchCase="types.MULTIPLE_SELECT" [element]="element">
        </surveys-element-multiple-select>

        <surveys-element-multipart-question
            *ngSwitchCase="types.MULTIPART_QUESTION"
            [element]="element"
        ></surveys-element-multipart-question>

        <surveys-element-panel *ngSwitchCase="types.PANEL" [element]="element"></surveys-element-panel>

        <surveys-element-row *ngSwitchCase="types.ROW" [element]="element"></surveys-element-row>

        <surveys-element-stats *ngSwitchCase="types.STATS" [element]="element"></surveys-element-stats>

        <surveys-element-survey-question
            *ngSwitchCase="types.SURVEY_QUESTION"
            [element]="element"
            [multipartQuestionHeader]="multipartQuestionHeader"
            [multipartQuestionId]="multipartQuestionId"
        >
        </surveys-element-survey-question>

        <surveys-element-yes-no *ngSwitchCase="types.YES_NO" [element]="element"></surveys-element-yes-no>
    </div>
</div>
