import {Component, Injector} from '@angular/core';
import {ISurveyMultipleChoice} from '../../../models/survey/ISurveyQuestion';
import {Dictionary} from '@ngrx/entity';
import {SurveyResponse} from '../../../models/survey-responses/SurveyResponse.interface';
import {getElementId} from '../../+state/elements/elements.reducer';
import {QuestionBaseAbstract} from '../../question-base.abstract';

@Component({
    selector: 'surveys-element-multiple-choice',
    templateUrl: './element-multiple-choice.component.html',
    styleUrls: ['./element-multiple-choice.component.scss']
})
export class ElementMultipleChoiceComponent extends QuestionBaseAbstract<
    string,
    Dictionary<SurveyResponse>,
    ISurveyMultipleChoice
> {
    public selectedOptionId: string;

    /* istanbul ignore next */
    constructor(injector: Injector) {
        super(injector);
    }

    public handleResponseEntities(responses: Dictionary<SurveyResponse>) {
        if (this.element) {
            const response = responses[getElementId(this.element)];
            this.selectedOptionId = response ? response.value : null;
        }
    }

    public execute({option}) {
        // in the case of a multiple choice component the value is
        // the ID of the option that was selected.
        const value: string = option.id;
        const response: SurveyResponse = new SurveyResponse(this.element, value);
        this.save(response, this.element, value);
    }
}
