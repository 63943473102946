import {Injectable} from '@angular/core';
import {ISurveyResponse} from '../../models/survey-responses/SurveyResponse.interface';
import {SurveyResponseTagClause} from '../../models/survey-responses/SurveyResponseTagClause.interface';
import {TaggedSurveyResponse} from '../../models/survey-responses/TaggedSurveyResponse.interface';
import {Dictionary} from '@ngrx/entity';
import {IDefaultResponseTag} from '../../models/survey-responses/IGlobalResponseTag.interface';
import {arrayIsNullUndefinedOrEmpty, objectIsNullUndefinedOrEmpty} from '@clients/shared/utilities';

@Injectable()
export class SurveyResponseService {
    constructor() {}

    public tagSurveyResponse(
        response: ISurveyResponse,
        responseTags: Dictionary<SurveyResponseTagClause[]>,
        defaultResponseTags?: IDefaultResponseTag[]
    ): TaggedSurveyResponse {
        return assembleTaggedSurveyResponse(response, responseTags, defaultResponseTags);
    }
}

// todo | combine with top and make static function https://healthtel.atlassian.net/browse/SB-1008
// todo | add coverage for that function
export function assembleTaggedSurveyResponse(
    response: ISurveyResponse,
    responseTags: Dictionary<SurveyResponseTagClause[]>,
    defaultResponseTags?: IDefaultResponseTag[]
): TaggedSurveyResponse {
    // initiate a blank object
    let tags: string[] = [];
    if (shouldApplyTags(response.id, responseTags, defaultResponseTags)) {
        const clauses = responseTags[response.id];
        let responseSpecificTags = [];

        if (clauses && clauses.length) {
            responseSpecificTags = clauses
                .filter((c: SurveyResponseTagClause): boolean => c.value === response.value)
                .reduce((r, e) => {
                    e.tags.forEach((t) => r.push(t));
                    return r;
                }, []);
        }

        const defaultTags = calculateDefaultTags(response.id, defaultResponseTags);

        tags = [...defaultTags, ...responseSpecificTags];
    }

    return new TaggedSurveyResponse(response, tags);
}

export function shouldApplyTags(
    responseId: string,
    responseTags: Dictionary<SurveyResponseTagClause[]>,
    defaultResponseTags: IDefaultResponseTag[]
) {
    const responseTagsAreBlank = objectIsNullUndefinedOrEmpty(responseTags);
    const defaultTagsAreBlank = arrayIsNullUndefinedOrEmpty(defaultResponseTags);

    // if both response tags and default tags are blank, there's nothing to apply
    if (responseTagsAreBlank && defaultTagsAreBlank) return false;

    // if we have default tags, we must try to apply them.
    if (!defaultTagsAreBlank) return true;

    // return true if there is a response tag specific to this response id
    return responseId in responseTags;
}

export function calculateDefaultTags(responseId: string, defaultResponseTags: IDefaultResponseTag[]): string[] {
    if (arrayIsNullUndefinedOrEmpty(defaultResponseTags)) return [];

    return defaultResponseTags
        .filter((defaultResponseTag: IDefaultResponseTag) =>
            responseIsNotExcludedFromDefaultTag(responseId, defaultResponseTag.exclude)
        )
        .reduce((agg: string[], defaultResponseTag: IDefaultResponseTag) => {
            agg.push(defaultResponseTag.tag);

            return agg;
        }, []);
}

export function responseIsNotExcludedFromDefaultTag(responseId: string, excludedIds: string[]): boolean {
    // no ids are excluded and therefore the default tag is applied
    if (arrayIsNullUndefinedOrEmpty(excludedIds)) return true;

    return excludedIds.indexOf(responseId) === -1;
}
