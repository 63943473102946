import {EventTrackPayload} from '@clients/shared/analytics';

const EVENT_NAMES = {
    LOCATION_SERVICES_AVAILABLE: 'Location Services Available',
    LOCATION_SERVICES_NOT_AVAILABLE: 'Location Services Not Available',
    LOCATION_SERVICES_ALLOWED: 'Location Services Allowed',
    LOCATION_SERVICES_BLOCKED: 'Location Services Blocked',
    USER_LOCATION_CALCULATED: 'User Location Calculated',
    LOCATION_WAS_WRONG: 'Location Was Wrong',
    ZIP_CODE_ENTERED: 'Zip Code Entered',
    ZIP_CODE_LOOKUP_FAILED: 'Zip Code Lookup Failed',
    MAP_RECENTERED: 'Map Re-centered'
};

export class LocationServicesAvailable implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.LOCATION_SERVICES_AVAILABLE;
}

export class LocationServicesNotAvialable implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.LOCATION_SERVICES_NOT_AVAILABLE;
}

export class LocationServicesAllowed implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.LOCATION_SERVICES_ALLOWED;
}

export class LocationServicesBlocked implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.LOCATION_SERVICES_BLOCKED;
}

export class UserLocationCalculated implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.USER_LOCATION_CALCULATED;
    public properties;

    constructor(lat: number, lng: number) {
        this.properties = {
            latitude: lat,
            longitude: lng
        };
    }
}

export class LocationWasWrong implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.LOCATION_WAS_WRONG;
}

export class ZipCodeEntered implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.ZIP_CODE_ENTERED;
    public properties;

    constructor(zipCode: string) {
        this.properties = {
            zipCode
        };
    }
}

export class ZipCodeLookupFailed implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.ZIP_CODE_LOOKUP_FAILED;
    public properties;

    constructor(zipCode: string) {
        this.properties = {
            zipCode
        };
    }
}

export class MapRecentered implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.MAP_RECENTERED;
    public properties;

    constructor(lat: number, lng: number) {
        this.properties = {
            latitude: lat,
            longitude: lng
        };
    }
}
