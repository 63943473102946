import {Injectable} from '@angular/core';
import {selectSelectedSurvey, selectSurveyState} from '../index';
import {select, Store} from '@ngrx/store';
import {SurveyReducerState} from './survey.interfaces';
import {Survey} from '../../../models/survey/ISurvey';
import {take} from 'rxjs/operators';

@Injectable()
export class SurveyFacade {
    constructor(private store: Store<SurveyReducerState>) {}

    public getActive(): Survey {
        let survey = null;
        this.store.pipe(select(selectSelectedSurvey), take(1)).subscribe((_survey: Survey) => {
            survey = _survey;
        });

        return survey;
    }

    public getSurveyState(): SurveyReducerState {
        let state: SurveyReducerState;
        this.store.pipe(select(selectSurveyState), take(1)).subscribe((val) => (state = val));

        return state;
    }

    public getEnrollmentId(): string {
        return this.getSurveyState().enrollmentId;
    }

    public getTenantId(): string {
        return this.getSurveyState().tenantId;
    }

    public getSurveyRecordId(): string {
        return this.getSurveyState().surveyRecordId;
    }

    public getSurveyContext(): string {
        return this.getSurveyState().context;
    }

    public getSurveyLanguage(): string {
        const state = this.getSurveyState();

        return state.language || state.defaultLanguage;
    }

    public getSurveyConfiguration(): any {
        return this.getSurveyState().surveyConfiguration;
    }

    public getDisableResume(): any {
        return this.getSurveyState().disableResume;
    }

    public getActiveId(): string {
        const survey = this.getActive();
        return survey ? survey._id : null;
    }
}
