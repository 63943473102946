import {select, Store} from '@ngrx/store';
import {
    selectAllElements,
    selectElementEntities,
    selectElementIds,
    selectElementTotal,
    selectSelectedElement,
    selectSelectedElementId,
    selectHidden
} from '../';
import {StoreDataFacade} from '@clients/shared/utilities';
import {take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {SurveysElementsState} from './elements.interfaces';

@Injectable()
export class ElementsFacade extends StoreDataFacade<SurveysElementsState> {
    constructor(store: Store<SurveysElementsState>) {
        super(store, selectElementIds, selectElementEntities, selectAllElements, selectElementTotal);
    }

    public getActive(): SurveyElement {
        let element = null;
        this.store.pipe(select(selectSelectedElement), take(1)).subscribe((_element: SurveyElement) => {
            element = _element;
        });

        return element;
    }

    public getActiveId(): string {
        let id = null;
        this.store.pipe(select(selectSelectedElementId), take(1)).subscribe((_id: string) => {
            id = _id;
        });

        return id;
    }

    public getHidden() {
        let elements = [];
        this.store.pipe(select(selectHidden)).subscribe((hidden: Array<string>) => (elements = hidden));
        return elements;
    }
}
