import {SurveyReducerState} from './survey.interfaces';
import {SurveyActionTypes} from './survey.actions';
import {surveyInitialState} from './survey.init';

export function surveyReducer(state: SurveyReducerState, action: any): SurveyReducerState {
    switch (action.type) {
        case SurveyActionTypes.RESET:
            return surveyInitialState;

        case SurveyActionTypes.VALIDATE_SURVEY:
            return {...state, context: action.payload as string};

        case SurveyActionTypes.SURVEY_VALIDATION_SUCCESS:
            return {
                ...state,
                defaultLanguage: action.payload.defaultLanguage,
                enrollmentId: action.payload.enrollmentId,
                surveyConfiguration: {
                    ...state.surveyConfiguration,
                    _id: action.payload.surveyConfigId,
                    name: action.payload.surveyName
                },
                surveyRecordId: action.payload.surveyRecordId,
                tenantId: action.payload.tenantId,
                valid: true
            } as SurveyReducerState;

        case SurveyActionTypes.SURVEY_VALIDATION_FAILURE:
            return {
                ...state,
                valid: false
            } as SurveyReducerState;

        case SurveyActionTypes.SET_LANGUAGE:
            return {...state, language: action.payload as string};

        case SurveyActionTypes.SURVEY_LOADED:
            return {
                ...state,
                valid: true,
                surveyConfiguration: {
                    ...state.surveyConfiguration,
                    schema: action.payload.schema
                }
            };

        case SurveyActionTypes.SURVEY_CONFIG_LOADED:
            return {
                ...state,
                config: action.payload
            };

        case SurveyActionTypes.DISABLE_RESUME:
            return {...state, disableResume: true};

        default: {
            return state;
        }
    }
}

export const getContext = (state: SurveyReducerState) => state.context;
export const getSurvey = (state: SurveyReducerState) => state.surveyConfiguration;
export const getValid = (state: SurveyReducerState) => state.valid;
export const getConfig = (state: SurveyReducerState) => state.config;
export const getLanguage = (state: SurveyReducerState) => state.language;
export const getDefaultLanguage = (state: SurveyReducerState) => state.defaultLanguage;
