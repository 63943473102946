<div class="surveys-element-pharmacy-finder" *ngIf="element">
    <div class="map" *ngIf="renderMap">
        <div *ngIf="enteringZipCode" class="zip-code-overlay" (click)="cancelLocationChange()">
            <div class="zip-code-input-container" (click)="$event.stopPropagation()">
                <div class="title">{{ translate('surveys.mapping.enterZipCode') }}</div>
                <div *ngIf="lastZipCodeLookupFailed" class="error">
                    {{ 'surveys.mapping.lastZipCodeLookupFailed' | translate }}
                </div>
                <div class="inputs">
                    <r-input [control]="zipCode" [mask]="'zip'" (keyUp)="zipCodeKeyUp($event)"></r-input>
                    <r-button (onClick)="centerOnZipCode()"> Submit </r-button>
                </div>
            </div>
        </div>

        <agm-map
            [latitude]="lat"
            [longitude]="lng"
            [styles]="mapOptions.styles"
            [zoom]="mapOptions.zoom"
            [disableDefaultUI]="mapOptions.disableDefaultUI"
            [streetViewControl]="mapOptions.streetViewControl"
            [ngClass]="{'add-blur': enteringZipCode}"
            (centerChange)="centerChanged($event)"
        >
            <agm-marker
                *ngFor="let m of markers; trackBy: markerTrackByFn"
                [iconUrl]="m.icon"
                [latitude]="m.lat"
                [longitude]="m.lng"
                [markerDraggable]="false"
                (markerClick)="markerClicked(agmInfoWindow)"
            >
                <agm-info-window #agmInfoWindow [disableAutoPan]="false" [maxWidth]="300">
                    <div class="marker-info-window-content">
                        <h2 style="margin-bottom: 2px">{{ m.label }}</h2>
                        <r-address [address]="m.formattedAddress" [linkToMap]="true"></r-address>
                    </div>
                </agm-info-window>
            </agm-marker>
        </agm-map>
    </div>

    <div
        *ngIf="geolocationEnabled || zipCodeEntered"
        class="change-location"
        [innerHtml]="translationService.translate('surveys.mapping.changeLocations')"
        (click)="changeLocation()"
    ></div>

    <surveys-element-pharmacy-list
        [pharmacies]="closestPharmacies"
        [dataLoaded]="pharmaciesLoadedOnce | async"
        (enteringZipCode)="changeLocation()"
    ></surveys-element-pharmacy-list>

    <surveys-element-action-block
        *ngIf="element.data.text[language]"
        [title]="element.data.text[language]"
        [description]="element.data.subText[language]"
        [actionLabel]="element.data.linkText[language]"
        (clicked)="findADoctorClicked()"
    ></surveys-element-action-block>
</div>
