import * as faker from 'faker';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-unused-vars */
import {Injectable} from '@angular/core';
import {IDV_MODE_ENUM} from '@clients/member-direct/idv';
import {MockService} from '@clients/shared/testing';
import {select, Store} from '@ngrx/store';

import {selectSurveyValid} from '../+state';
import {ISurveyValidationSuccessApiResponse} from '../../models/api-responses/SurveyValidateApiResponse.interface';
import {GeoJsonDataPoint} from '../../models/mapping/GeoJsonDataPoint.interface';
import {SurveySchema} from '../../models/survey/ISurveySchema';

const validateSuccessResponse: ISurveyValidationSuccessApiResponse = {
    config: null,
    defaultLanguage: 'es',
    enrollmentId: faker.datatype.uuid(),
    idv: IDV_MODE_ENUM.NONE,
    idvQuestions: [],
    options: [],
    surveyConfigId: faker.datatype.uuid(),
    surveyName: faker.random.words(2),
    surveyRecordId: faker.datatype.uuid(),
    tenantId: faker.datatype.uuid()
};

export const surveyServiceMockResponses = {
    bootstrap: {
        success: validateSuccessResponse,
        config: {
            testing: true
        },
        error: {
            error: true,
            message: 'broken'
        }
    },
    show: {
        success: {
            clientState: '',
            flow: {
                blocks: [
                    {
                        id: 'dffbf99c-b5e8-11e8-96f8-529269fb1459',
                        label: 'Flu Question National Stats',
                        content: [
                            {
                                id: 'dffbff3c-b5e8-11e8-96f8-529269fb1459',
                                type: 'IMAGE',
                                data: {
                                    en: 'https://revel.imgix.net/clients/humana/web/images/web/en/Web_NationalStats_En.jpg',
                                    es: 'https://revel.imgix.net/clients/humana/web/images/web/es/Web_NationalStats_Es.jpg'
                                }
                            },
                            {
                                id: 'dffc055e-b5e8-11e8-96f8-529269fb1459',
                                type: 'HTML',
                                data: {
                                    en: "<p>As many as 1 in 5 Americans will get the flu this season.<sup>1</sup> That's millions of people. They pass it to friends, coworkers, and family members. All of them suffer the same symptoms: fevers, chills, cough, congestion, sore throat, aches. In a word, misery. Many will miss work or school or important events with family and friends. Some will be hospitalized. <sup>2</sup></p>",
                                    es: '<p>Tantos como 1 de cada 5 estadounidenses se contagia con la gripe esta temporada.<sup>1</sup> Esto equivale a millones de personas, que contagian la enfermedad a amigos, compañeros de trabajo y familiares. Es probable que todos ellos sufran los mismos síntomas: fiebre, escalofríos, tos, congestión, dolor de garganta y dolor general. En pocas palabras: un terrible malestar. Muchos tendrán que faltar a su trabajo, a la escuela o a eventos importantes con sus familiares y amigos. Algunos serán hospitalizados. <sup>2</sup></p>'
                                }
                            },
                            {
                                id: 'dffc07d4-b5e8-11e8-96f8-529269fb1459',
                                type: 'STATS',
                                label: {
                                    en: 'Will you be getting the flu shot this year?',
                                    es: '¿Se vacunará contra la gripe este año?'
                                },
                                data: [
                                    {
                                        count: 26000,
                                        description: {
                                            data: {
                                                en: '<p>Average number of Americans that die every year from the flu.<sup>3</sup></p>',
                                                es: '<p>Cantidad promedio de estadounidenses que mueren cada año a causa de la gripe.<sup>3</sup></p>'
                                            }
                                        }
                                    },
                                    {
                                        count: 172,
                                        description: {
                                            data: {
                                                en: '<p>Were children.<sup>4</sup></p>',
                                                es: '<p>Fueron niños.<sup>4</sup></p>'
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                id: 'dffc0a72-b5e8-11e8-96f8-529269fb1459',
                                type: 'HTML',
                                data: {
                                    en: '<p>But this year you can protect yourself. Getting a flu shot can dramatically reduce your risk of getting sick and spreading the flu to others.</p>',
                                    es: '<p>Pero este año, usted puede ayudar a protegerse. Vacunarse contra la gripe puede reducir drásticamente su riesgo de enfermarse y contagiar la gripe a otras personas.</p>'
                                }
                            },
                            {
                                id: 'dffc0cca-b5e8-11e8-96f8-529269fb1459',
                                type: 'YES_NO',
                                label: {
                                    en: 'Will you be getting the flu shot this year?',
                                    es: '¿Se vacunará contra la gripe este año?'
                                },
                                data: {
                                    yes: {
                                        en: {
                                            label: 'Yes',
                                            subtext:
                                                'I’ll get a flu shot and reduce my risk of getting and spreading the flu.'
                                        },
                                        es: {
                                            label: 'Si',
                                            subtext:
                                                'Me vacunaré contra la gripe para ayudar a reducir mi riesgo de enfermarme y contagiar la enfermedad.'
                                        }
                                    },
                                    no: {
                                        en: {
                                            label: 'No',
                                            subtext:
                                                'I won’t get a flu shot even if it means failing to protect myself and others from the flu.'
                                        },
                                        es: {
                                            label: 'No',
                                            subtext:
                                                'No me vacunaré contra la gripe aunque implique no ayudar a protegerme ni a proteger a los demás.'
                                        }
                                    }
                                },
                                instructions: [
                                    {
                                        type: 'LOAD_BLOCK_BY_ID',
                                        data: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                                        when: true
                                    },
                                    {
                                        type: 'LOAD_BLOCK_BY_ID',
                                        data: 'a48bf43c-b5ea-11e8-96f8-529269fb1459',
                                        when: false
                                    },
                                    {
                                        type: 'SET_DATA_PROP',
                                        data: {
                                            prop: 'a48c0505-b5ea-11e8-96f8-529269fb1459',
                                            value: false
                                        }
                                    }
                                ],
                                elements: [
                                    {
                                        id: 'a48c0505-b5ea-11e8-96f8-529269fb1459',
                                        type: 'PANEL',
                                        data: {
                                            en: '<p>Already got your flu shot? <a href="javascript:void(0)">Click Here.</a>',
                                            es: '<p>¿Ya se vacunó contra la gripe? <a href="javascript:void(0)">Haga clic aquí.</a>'
                                        },
                                        instructions: [
                                            {
                                                type: 'SET_DATA_PROP',
                                                data: {
                                                    prop: 'a48c0505-b5ea-11e8-96f8-529269fb1459',
                                                    value: true
                                                }
                                            },
                                            {
                                                type: 'LOAD_BLOCK_BY_ID',
                                                data: 'sd9sd8b0-b5eb-11e8-96f8-529269fb1459'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                id: 'dffc0fa4-b5e8-11e8-96f8-529269fb1459',
                                type: 'FOOTNOTE',
                                data: {
                                    en: ' <ol> <li> <a href="https://www.cdcfoundation.org/businesspulse/flu-prevention-infographic" target="_blank"> https://www.cdcfoundation.org/businesspulse/flu-prevention-infographic </a> </li> <li> <a href="https://www.cdc.gov/flu/about/qa/hospital.htm" target="_blank"> https://www.cdc.gov/flu/about/qa/hospital.htm </a> </li> <li> <a href="https://www.cdc.gov/flu/spotlights/reported-flu-children-deaths.htm" target="_blank"> https://www.cdc.gov/flu/spotlights/reported-flu-children-deaths.htm </a> </li> <li> <a href="https://www.cdc.gov/flu/about/disease/us_flu-related_deaths.htm" target="_blank"> https://www.cdc.gov/flu/about/disease/us_flu-related_deaths.htm </a> </li> </ol> ',
                                    es: ' <ol> <li> <a href="https://www.cdcfoundation.org/businesspulse/flu-prevention-infographic" target="_blank"> https://www.cdcfoundation.org/businesspulse/flu-prevention-infographic </a> </li> <li> <a href="https://www.cdc.gov/flu/about/qa/hospital.htm" target="_blank"> https://www.cdc.gov/flu/about/qa/hospital.htm </a> </li> <li> <a href="https://www.cdc.gov/flu/spotlights/reported-flu-children-deaths.htm" target="_blank"> https://www.cdc.gov/flu/spotlights/reported-flu-children-deaths.htm </a> </li> <li> <a href="https://www.cdc.gov/flu/about/disease/us_flu-related_deaths.htm" target="_blank"> https://www.cdc.gov/flu/about/disease/us_flu-related_deaths.htm </a> </li> </ol> '
                                }
                            }
                        ],
                        intro: true
                    },
                    {
                        id: 'a48bf43c-b5ea-11e8-96f8-529269fb1459',
                        label: 'Try to convince them',
                        content: [
                            {
                                id: 'a48bf81a-b5ea-11e8-96f8-529269fb1459',
                                type: 'HTML',
                                label: {
                                    en: 'asda',
                                    es: 'asda'
                                },
                                data: {
                                    en: '<h1>Why will you not be getting your flu shot?</h1>',
                                    es: '<h1>¿Por qué no va a recibir su vacuna contra la gripe?</h1>'
                                }
                            },
                            {
                                id: 'f78f6697-e003-41cd-8230-d20665d4672f',
                                type: 'ACCORDION_GROUP',
                                label: {
                                    en: 'Mind bender accordion group',
                                    es: 'Mind bender accordion group'
                                },
                                data: {
                                    randomize: true,
                                    autoClose: true,
                                    content: [
                                        {
                                            id: 'a48bfb58-b5ea-11e8-96f8-529269fb1459',
                                            type: 'ACCORDION',
                                            label: {
                                                en: 'I don’t believe I need a flu shot.',
                                                es: 'No necesito vacunarme contra la gripe.'
                                            },
                                            data: {
                                                content: [
                                                    {
                                                        id: 'a48c0102-b5ea-11e8-96f8-529269fb1459',
                                                        type: 'IMAGE',
                                                        data: {
                                                            en: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_01.jpg',
                                                            es: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_01.jpg'
                                                        }
                                                    },
                                                    {
                                                        id: 'a48c036e-b5ea-11e8-96f8-529269fb1459',
                                                        type: 'HTML',
                                                        data: {
                                                            en: "<p>When flu season strikes, anyone without a flu shot risks getting the flu. And the flu isn't just miserably inconvenient. It can also be expensive and dangerous. Suffering the flu means missing work and cancelling meaningful events with friends and family. And while you might not mind being confined to your couch for a week, others may not be so lucky. If you pass the flu to neighbors or loved ones, it can put them at risk of costly hospitalization or even death. You might not think you need a flu shot, but it's the first and most important step to preventing the flu and keeping you and your community safe this season.</p>",
                                                            es: '<p>Cuando la temporada de gripe azota, cualquiera que no se vacune contra la gripe corre el riesgo de contagiarse. Y la gripe no solo es terriblemente molesta, también puede ser costosa y peligrosa. Sufrir gripe implica faltar al trabajo y cancelar eventos importantes con amigos y familiares. Y aunque quizás a usted no le importe estar tirado en el sofá por una semana, otras personas podrían ser menos afortunadas. Si contagia la gripe a sus vecinos o seres queridos, podrían estar en riesgo de enfrentar una hospitalización costosa o incluso la muerte. Quizás piense que no necesita vacunarse contra la gripe, pero es la primera y más importante medida para prevenir la gripe, protegerse y proteger a su comunidad esta temporada.</p>'
                                                        }
                                                    },
                                                    {
                                                        id: 'a48c05a8-b5ea-11e8-96f8-529269fb1459',
                                                        type: 'YES_NO',
                                                        label: {
                                                            en: 'Will you reconsider and get your flu shot this year??',
                                                            es: '¿Lo reconsiderará y se vacunará contra la gripe este año?'
                                                        },
                                                        data: {
                                                            smallLabel: true,
                                                            yes: {
                                                                en: {
                                                                    label: 'Yes'
                                                                },
                                                                es: {
                                                                    label: 'Si'
                                                                }
                                                            },
                                                            no: {
                                                                en: {
                                                                    label: 'No'
                                                                },
                                                                es: {
                                                                    label: 'No'
                                                                }
                                                            }
                                                        },
                                                        instructions: [
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                                                                when: true
                                                            },
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '9a0b78b0-b5eb-11e8-96f8-529269fb1459',
                                                                when: false
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        id: '1353738f-f5fe-4c36-9559-f7bfb3c5e762',
                                                        type: 'HTML',
                                                        data: {
                                                            en: '<a href="https://www.cdc.gov/flu/about/qa/misconceptions.htm" style="color: inherit;font-size: 13px;" target="_blank">https://www.cdc.gov/flu/about/qa/misconceptions.htm</a>',
                                                            es: '<a href="https://www.cdc.gov/flu/about/qa/misconceptions.htm" style="color: inherit;font-size: 13px;" target="_blank">https://www.cdc.gov/flu/about/qa/misconceptions.htm</a>'
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            id: 'bfd28f86-188a-492c-9f1d-f88a6091efb2',
                                            type: 'ACCORDION',
                                            label: {
                                                en: 'The flu shot can cause the flu and other side effects.',
                                                es: 'La vacuna antigripal puede provocar gripe y otros efectos secundarios.'
                                            },
                                            data: {
                                                content: [
                                                    {
                                                        id: '9d722e66-5e35-4946-b8f7-5739cf4edcfc',
                                                        type: 'IMAGE',
                                                        data: {
                                                            en: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_02.jpg',
                                                            es: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_02.jpg'
                                                        }
                                                    },
                                                    {
                                                        id: 'ba440a3e-9ecb-44d5-b605-9f1d15b3216a',
                                                        type: 'HTML',
                                                        data: {
                                                            en: '<p>One common misconception about the flu shot is that it can cause the flu. Because of the way the flu shot is created, it cannot give you the flu. It contains only inactive viruses that are not infectious. You might briefly experience a low grade fever or some redness or swelling at the site of the shot, but these are preferable to the flu itself. Any other serious side effects are extremely rare. The flu shot is safe and worth the tiny bandaid.</p>',
                                                            es: '<p>Una idea errónea común acerca de la vacuna antigripal es que puede provocar gripe. Debido a la forma en que se crea la vacuna antigripal, no puede provocar gripe, ya que contiene solo virus inactivos que no son infecciosos. Quizás experimente un breve episodio de fiebre baja o algo de enrojecimiento o inflamación en el lugar de la inyección, pero esto es preferible antes que la gripe. Es extremadamente infrecuente que se produzcan otros efectos secundarios graves. La vacuna antigripal es segura, y vale la pena tener que usar una diminuta tira adhesiva.</p>'
                                                        }
                                                    },
                                                    {
                                                        id: '5cc4daa7-f1bf-445c-bfdb-537ff64ac05f',
                                                        type: 'YES_NO',
                                                        label: {
                                                            en: 'Will you reconsider and get your flu shot this year??',
                                                            es: '¿Lo reconsiderará y se vacunará contra la gripe este año?'
                                                        },
                                                        data: {
                                                            smallLabel: true,
                                                            yes: {
                                                                en: {
                                                                    label: 'Yes'
                                                                },
                                                                es: {
                                                                    label: 'Si'
                                                                }
                                                            },
                                                            no: {
                                                                en: {
                                                                    label: 'No'
                                                                },
                                                                es: {
                                                                    label: 'No'
                                                                }
                                                            }
                                                        },
                                                        instructions: [
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                                                                when: true
                                                            },
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '9a0b78b0-b5eb-11e8-96f8-529269fb1459',
                                                                when: false
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        id: '1353738f-f5fe-4c36-9559-f7bfb3c5e762',
                                                        type: 'HTML',
                                                        data: {
                                                            en: '<a href="https://www.cdc.gov/flu/about/qa/misconceptions.htm" style="color: inherit;font-size: 13px;" target="_blank">https://www.cdc.gov/flu/about/qa/misconceptions.htm</a>',
                                                            es: '<a href="https://www.cdc.gov/flu/about/qa/misconceptions.htm" style="color: inherit;font-size: 13px;" target="_blank">https://www.cdc.gov/flu/about/qa/misconceptions.htm</a>'
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            id: '1821b3e4-2a3c-46c5-80dd-5a95fa5d6361',
                                            type: 'ACCORDION',
                                            label: {
                                                en: 'The flu shot doesn’t prevent the flu.',
                                                es: 'La vacuna antigripal no previene la gripe.'
                                            },
                                            data: {
                                                content: [
                                                    {
                                                        id: '83538f1a-89ce-4d69-b7ae-707e2af742bd',
                                                        type: 'IMAGE',
                                                        data: {
                                                            en: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_03.jpg',
                                                            es: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_03.jpg'
                                                        }
                                                    },
                                                    {
                                                        id: '75842a82-b024-40a0-8ed7-6d5777d21af5',
                                                        type: 'HTML',
                                                        data: {
                                                            en: "<p>True, unfortunately the flu vaccine isn't 100% foolproof. It's possible to contract one of the strains of the flu that the vaccine can't protect you against. That would be unfortunate, but if you don't get the flu shot at all, then you’re vulnerable to every strain. That's much riskier. And if you do get the flu after receiving the flu shot, your symptoms are often shorter and less severe. So, a significantly lower risk of catching the flu and a less miserable experience if you do contract it? Seems like a no-brainer.</p>",
                                                            es: '<p>Es verdad, desafortunadamente, la vacuna antigripal no es 100% infalible. Es posible contagiarse una de las cepas de la gripe que la vacuna no puede combatir. Esto sería desafortunado, pero si directamente no se vacuna contra la gripe, será vulnerable a todas las cepas, lo cual sería mucho más riesgoso. Y si efectivamente se contagia la gripe después de recibir la vacuna antigripal, los síntomas generalmente son más leves y duran menos. Por lo tanto, ante la certeza de que el riesgo de contagiarse la gripe será mucho menor y el malestar será mucho más leve si se contagia, parece que la decisión es obvia.'
                                                        }
                                                    },
                                                    {
                                                        id: '03439b00-4628-4abe-b7a3-aaf33c5dcc80',
                                                        type: 'YES_NO',
                                                        label: {
                                                            en: 'Will you reconsider and get your flu shot this year??',
                                                            es: '¿Lo reconsiderará y se vacunará contra la gripe este año?'
                                                        },
                                                        data: {
                                                            smallLabel: true,
                                                            yes: {
                                                                en: {
                                                                    label: 'Yes'
                                                                },
                                                                es: {
                                                                    label: 'Si'
                                                                }
                                                            },
                                                            no: {
                                                                en: {
                                                                    label: 'No'
                                                                },
                                                                es: {
                                                                    label: 'No'
                                                                }
                                                            }
                                                        },
                                                        instructions: [
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                                                                when: true
                                                            },
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '9a0b78b0-b5eb-11e8-96f8-529269fb1459',
                                                                when: false
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        id: '1353738f-f5fe-4c36-9559-f7bfb3c5e762',
                                                        type: 'HTML',
                                                        data: {
                                                            en: '<a href="https://www.cdc.gov/flu/about/qa/misconceptions.htm" style="color: inherit;font-size: 13px;" target="_blank">https://www.cdc.gov/flu/about/qa/misconceptions.htm</a>',
                                                            es: '<a href="https://www.cdc.gov/flu/about/qa/misconceptions.htm" style="color: inherit;font-size: 13px;" target="_blank">https://www.cdc.gov/flu/about/qa/misconceptions.htm</a>'
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            id: 'e028b7eb-28d5-453b-8706-c713a08562ed',
                                            type: 'ACCORDION',
                                            label: {
                                                en: 'I do other things to stay healthy and prevent the flu.',
                                                es: 'Hago otras cosas para mantenerme saludable y prevenir la gripe.'
                                            },
                                            data: {
                                                content: [
                                                    {
                                                        id: '744639b2-0a39-4357-ac65-94385133583e',
                                                        type: 'IMAGE',
                                                        data: {
                                                            en: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_04.jpg',
                                                            es: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_04.jpg'
                                                        }
                                                    },
                                                    {
                                                        id: '8d817d5a-16f7-43ad-b0f6-981235207077',
                                                        type: 'HTML',
                                                        data: {
                                                            en: "<p>That's great. Whatever steps you take to strengthen your immune system and protect yourself from catching the flu can definitely help. However, the flu shot remains one of the strongest defenses because it's specifically designed to anticipate and prevent this season's flu virus. Year to year, nothing else has proven as effective at keeping you and the ones you love free from the flu, and it deserves to be a part of your health routine.</p>",
                                                            es: '<p>Fantástico. Todas las medidas que tome para fortalecer su sistema inmunológico y protegerse del contagio de la gripe definitivamente pueden ayudar. No obstante, la vacuna antigripal sigue siendo la defensa más poderosa, ya que está específicamente diseñada para anticiparse al virus de la gripe de esta temporada y prevenirlo. Año a año, ningún otro método ha demostrado ser tan eficaz para protegerse y proteger a sus seres queridos contra la gripe, y merece ser parte de su rutina a la hora de cuidar su salud.</p>'
                                                        }
                                                    },
                                                    {
                                                        id: 'fce83fa9-29aa-43ad-9347-a50280f4bdbc',
                                                        type: 'YES_NO',
                                                        label: {
                                                            en: 'Will you reconsider and get your flu shot this year??',
                                                            es: '¿Lo reconsiderará y se vacunará contra la gripe este año?'
                                                        },
                                                        data: {
                                                            smallLabel: true,
                                                            yes: {
                                                                en: {
                                                                    label: 'Yes'
                                                                },
                                                                es: {
                                                                    label: 'Si'
                                                                }
                                                            },
                                                            no: {
                                                                en: {
                                                                    label: 'No'
                                                                },
                                                                es: {
                                                                    label: 'No'
                                                                }
                                                            }
                                                        },
                                                        instructions: [
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                                                                when: true
                                                            },
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '9a0b78b0-b5eb-11e8-96f8-529269fb1459',
                                                                when: false
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        id: '1353738f-f5fe-4c36-9559-f7bfb3c5e762',
                                                        type: 'HTML',
                                                        data: {
                                                            en: '<a href="https://www.cdc.gov/flu/about/qa/misconceptions.htm" style="color: inherit;font-size: 13px;" target="_blank">https://www.cdc.gov/flu/about/qa/misconceptions.htm</a>',
                                                            es: '<a href="https://www.cdc.gov/flu/about/qa/misconceptions.htm" style="color: inherit;font-size: 13px;" target="_blank">https://www.cdc.gov/flu/about/qa/misconceptions.htm</a>'
                                                        }
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            id: '45610d7b-facd-4b92-b7e1-d3d8d8eb4d13',
                                            type: 'ACCORDION',
                                            label: {
                                                en: 'Getting the flu shot is inconvenient and I forget to make time for it.',
                                                es: 'Vacunarme contra la gripe me resulta incómodo, y olvido hacerme un tiempo para vacunarme.'
                                            },
                                            data: {
                                                content: [
                                                    {
                                                        id: '5a866899-2c0b-4136-bfb2-4b6b04c8d29b',
                                                        type: 'IMAGE',
                                                        data: {
                                                            en: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_06.jpg',
                                                            es: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_06.jpg'
                                                        }
                                                    },
                                                    {
                                                        id: 'eb3ab037-825c-4482-b4b9-acb255d5e738',
                                                        type: 'HTML',
                                                        data: {
                                                            en: '<p>The flu shot may be easier to get than you think, but it will require a brief visit to your doctor or a pharmacy or even a grocery store. And if you think the flu shot is inconvenient, imagine if you get the flu itself. You may miss work or important events, you may have to care for loved ones if you pass the disease to them, and you may still have to get to the doctor, but this time for a potentially much longer visit. You should get your flu shot today. Consider this your reminder.</p>',
                                                            es: '<p>Vacunarse contra la gripe puede ser más sencillo de lo que cree, pero deberá hacer una visita breve a su médico o una farmacia, o incluso una tienda de comestibles. Y si cree que la vacuna antigripal es incómoda, imagine si se contagia la gripe. Quizás tenga que faltar al trabajo o a eventos importantes, quizás tenga que cuidar de sus seres queridos si les contagia la enfermedad y quizás tenga que ir al médico de todos modos, pero es posible que la visita sea mucho más larga. Debería vacunarse contra la gripe hoy mismo. Considere esto un recordatorio.</p>'
                                                        }
                                                    },
                                                    {
                                                        id: '968f2d8f-1158-4eeb-b9b4-3bab36b3713a',
                                                        type: 'YES_NO',
                                                        label: {
                                                            en: 'Will you reconsider and get your flu shot this year??',
                                                            es: '¿Lo reconsiderará y se vacunará contra la gripe este año?'
                                                        },
                                                        data: {
                                                            smallLabel: true,
                                                            yes: {
                                                                en: {
                                                                    label: 'Yes'
                                                                },
                                                                es: {
                                                                    label: 'Si'
                                                                }
                                                            },
                                                            no: {
                                                                en: {
                                                                    label: 'No'
                                                                },
                                                                es: {
                                                                    label: 'No'
                                                                }
                                                            }
                                                        },
                                                        instructions: [
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                                                                when: true
                                                            },
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '9a0b78b0-b5eb-11e8-96f8-529269fb1459',
                                                                when: false
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        },
                                        {
                                            id: '7b971b2b-6e97-4eec-9f87-e39b229ac87b',
                                            type: 'ACCORDION',
                                            label: {
                                                en: "I don't know how much the flu shot costs or where to get one.",
                                                es: 'No sé cuánto cuesta la vacuna antigripal o dónde vacunarme.'
                                            },
                                            data: {
                                                content: [
                                                    {
                                                        id: '9b4000a0-3621-44e0-a8c3-1758790bb786',
                                                        type: 'IMAGE',
                                                        data: {
                                                            en: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_05.jpg',
                                                            es: 'https://revel.imgix.net/clients/humana/web/images/conversation/Conversation_05.jpg'
                                                        }
                                                    },
                                                    {
                                                        id: '37444e5c-6af1-42c7-b245-f5aa94eb76d6',
                                                        type: 'HTML',
                                                        data: {
                                                            en: "<p>Just stop by a pharmacy, retail clinic, or even a grocery store. You can also schedule a brief visit with your doctor. The cost will depend on your plan. For most, it's a $0 copay. If you're on Medicare or Medicaid, you’re likely covered, but there may be a small fee. Either way, the flu shot is a small price to pay to prevent the flu, which can cost you much more in lost wages or higher copays for regular office visits, not to mention all the extra boxes of tissues you'll need. </p>",
                                                            es: '<p>Simplemente vaya a una farmacia, una clínica minorista o incluso una tienda de comestibles. También puede coordinar una breve visita a su médico. El costo dependerá de su plan. En la mayoría de los casos, el copago es de $0. Si tiene Medicare o Medicaid, es muy probable que tenga cobertura, pero quizás deba pagar una pequeña tarifa. De cualquier modo, la vacuna antigripal es un pequeño precio por prevenir la gripe, que puede costarle mucho más en sueldos perdidos o copagos más altos por las visitas regulares al consultorio, sin mencionar todas las cajas adicionales de pañuelos de papel que necesitará.</p>'
                                                        }
                                                    },
                                                    {
                                                        id: 'b760af5b-dc31-4c2d-98a9-22abb898b028',
                                                        type: 'YES_NO',
                                                        label: {
                                                            en: 'Will you reconsider and get your flu shot this year??',
                                                            es: '¿Lo reconsiderará y se vacunará contra la gripe este año?'
                                                        },
                                                        data: {
                                                            smallLabel: true,
                                                            yes: {
                                                                en: {
                                                                    label: 'Yes'
                                                                },
                                                                es: {
                                                                    label: 'Si'
                                                                }
                                                            },
                                                            no: {
                                                                en: {
                                                                    label: 'No'
                                                                },
                                                                es: {
                                                                    label: 'No'
                                                                }
                                                            }
                                                        },
                                                        instructions: [
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                                                                when: true
                                                            },
                                                            {
                                                                type: 'LOAD_BLOCK_BY_ID',
                                                                data: '9a0b78b0-b5eb-11e8-96f8-529269fb1459',
                                                                when: false
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        id: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                        label: 'Thank you',
                        content: [
                            {
                                id: '67d36772-b5eb-11e8-96f8-529269fb1459',
                                type: 'HTML',
                                data: {
                                    en: '<h1>Great! Here are some locations near you where you can get your flu shot.</h1>',
                                    es: '<h1>¡Excelente! A continuación podrá encontrar algunos lugares que le queden cerca para vacunarse contra la gripe.</h1>'
                                }
                            },
                            {
                                id: '67d36cc2-b5eb-11e8-96f8-529269fb1459',
                                type: 'CUSTOM',
                                data: {
                                    type: 'PHARMACY_FINDER',
                                    findADoctor: {
                                        en: {
                                            text: 'Need Help?',
                                            subtext: 'Let Humana help find a location near you',
                                            linkText: 'Find More Locations',
                                            link: 'https://www.humana.com/provider/'
                                        },
                                        es: {
                                            text: '¿Necesitas Ayuda?',
                                            subtext: 'Humana puede ayudarlo a encontrar una ubicación cerca de usted.',
                                            linkText: 'Encuentra Más Ubicaciones',
                                            link: 'https://www.humana.com/provider/'
                                        }
                                    }
                                }
                            }
                        ],
                        completed: true
                    },
                    {
                        id: '9a0b78b0-b5eb-11e8-96f8-529269fb1459',
                        label: 'Shoot',
                        content: [
                            {
                                id: '9a0b7b62-b5eb-11e8-96f8-529269fb1459',
                                type: 'HTML',
                                data: {
                                    en: '<h1>Ok. Thanks for letting us know. If you change your mind, here’s where you can get your flu shot.</h1>',
                                    es: '<h1>Ok. Gracias por dejarnos saber. Si cambia de opinión, aquí es donde puede vacunarse contra la gripe.</h1>'
                                }
                            },
                            {
                                id: '9a0b7cac-b5eb-11e8-96f8-529269fb1459',
                                type: 'CUSTOM',
                                data: {
                                    type: 'PHARMACY_FINDER',
                                    findADoctor: {
                                        en: {
                                            text: 'Need Help?',
                                            subtext: 'Let Humana help find a location near you',
                                            linkText: 'Find More Locations',
                                            link: 'https://www.humana.com/provider/'
                                        },
                                        es: {
                                            text: '¿Necesitas Ayuda?',
                                            subtext: 'Humana puede ayudarlo a encontrar una ubicación cerca de usted.',
                                            linkText: 'Encuentra Más Ubicaciones',
                                            link: 'https://www.humana.com/provider/'
                                        }
                                    }
                                }
                            }
                        ],
                        completed: true
                    },
                    {
                        id: 'sd9sd8b0-b5eb-11e8-96f8-529269fb1459',
                        label: 'Already Got Flu Shot',
                        completed: true,
                        content: [
                            {
                                id: '9a0b7d0f-b5eb-11e8-96f8-529269fb1459',
                                type: 'HTML',
                                data: {
                                    en: '<h1>Wonderful! Thank you for doing your part to keep you and your community healthy.</h1>',
                                    es: '<h1>¡Maravilloso! Gracias por hacer su parte para mantener a usted y a su comunidad saludables.</h1>'
                                }
                            },
                            {
                                id: '9a0b7333-b5eb-11e8-96f8-529269fb1459',
                                type: 'IMAGE',
                                data: {
                                    en: 'https://revel.imgix.net/clients/humana/web/images/AlreadyGotOne.jpg',
                                    es: 'https://revel.imgix.net/clients/humana/web/images/AlreadyGotOne.jpg'
                                }
                            },
                            {
                                id: '9as90444-b5eb-11e8-96f8-529269fb1459',
                                type: 'HTML',
                                data: {
                                    en: '<h2 style="margin: 0;">If you have any questions about your health plan benefits please call the Customer Service number on the back of your member ID card.</h2>',
                                    es: '<h2 style="margin: 0;">Si tiene alguna pregunta sobre los beneficios de su plan de salud, llame al número de Servicio al Cliente que se encuentra en el reverso de su tarjeta de identificación de miembro.</h2>'
                                }
                            }
                        ]
                    }
                ],
                onLoad: [
                    {
                        type: 'LOAD_BLOCK_BY_ID',
                        data: '67d364fc-b5eb-11e8-96f8-529269fb1459',
                        when: {
                            prop: 'dffc0cca-b5e8-11e8-96f8-529269fb1459',
                            value: true
                        }
                    },
                    {
                        type: 'LOAD_BLOCK_BY_ID',
                        data: 'a48bf43c-b5ea-11e8-96f8-529269fb1459',
                        when: {
                            prop: 'dffc0cca-b5e8-11e8-96f8-529269fb1459',
                            value: false
                        }
                    },
                    {
                        type: 'LOAD_BLOCK_BY_ID',
                        data: 'sd9sd8b0-b5eb-11e8-96f8-529269fb1459',
                        when: {
                            prop: 'a48c0505-b5ea-11e8-96f8-529269fb1459',
                            value: true
                        }
                    }
                ],
                responseTags: {
                    'dffc0cca-b5e8-11e8-96f8-529269fb1459': [
                        {
                            value: true,
                            tags: ['WillGet']
                        },
                        {
                            value: false,
                            tags: ['NoGet']
                        }
                    ],
                    'a48c05a8-b5ea-11e8-96f8-529269fb1459': [
                        {
                            value: true,
                            tags: ['WillChange']
                        },
                        {
                            value: false,
                            tags: ['NoChange']
                        }
                    ],
                    '5cc4daa7-f1bf-445c-bfdb-537ff64ac05f': [
                        {
                            value: true,
                            tags: ['WillChange']
                        },
                        {
                            value: false,
                            tags: ['NoChange']
                        }
                    ],
                    '03439b00-4628-4abe-b7a3-aaf33c5dcc80': [
                        {
                            value: true,
                            tags: ['WillChange']
                        },
                        {
                            value: false,
                            tags: ['NoChange']
                        }
                    ],
                    'fce83fa9-29aa-43ad-9347-a50280f4bdbc': [
                        {
                            value: true,
                            tags: ['WillChange']
                        },
                        {
                            value: false,
                            tags: ['NoChange']
                        }
                    ],
                    '968f2d8f-1158-4eeb-b9b4-3bab36b3713a': [
                        {
                            value: true,
                            tags: ['WillChange']
                        },
                        {
                            value: false,
                            tags: ['NoChange']
                        }
                    ],
                    'b760af5b-dc31-4c2d-98a9-22abb898b028': [
                        {
                            value: true,
                            tags: ['WillChange']
                        },
                        {
                            value: false,
                            tags: ['NoChange']
                        }
                    ],
                    'a48c0505-b5ea-11e8-96f8-529269fb1459': [
                        {
                            value: true,
                            tags: ['HaveIt']
                        }
                    ]
                },
                disclaimer: {
                    en: '<p>“Humana” is the brand name for plans, products, and services provided by one or more of the subsidiaries and affiliate companies of Humana Inc. (“Humana Entities”). Plans, products, and services are solely and only provided by one or more Humana Entities specified on the plan, product, or service contract, not Humana Inc. Not all plans, products, and services are available in each state.</p> <p>Links to various other websites from this site are provided for your convenience only and do not constitute or imply endorsement by Humana of these sites, any products or services described on these sites, or of any other material contained therein. Humana disclaims responsibility for their content and accuracy.</p> <p>This material is provided for informational use only and should not be construed as medical advice or used in place of consulting a licensed medical professional. You should consult with your doctor to determine what is right for you.</p><p>Y0040_GHHK9JNEN_C</p><p>GCHK9JREN</p>',
                    es: '"Humana" es la marca comercial de los planes, productos y servicios ofrecidos por una o más de las subsidiarias y compañías afiliadas de Humana Inc. ("Entidades de Humana"). Únicamente una o más de las entidades de Humana, y no Humana. Inc, ofrecen los planes, productos y servicios especificados en el contrato del plan, de los productos o los servicios. No todos los planes, productos y servicios están disponibles en todos los estados.</p><p>Los enlaces a diferentes sitios Web se proporcionan en este sitio para su mayor comodidad únicamente y esto no constituye ni implica que Humana apruebe estos sitios ni cualquier producto, servicio o material descrito o incluido en dichos sitios. Humana tampoco asume responsabilidad alguna por el contenido y exactitud de tales sitios.</p><p>Este material se ofrece con fines informativos únicamente y no debería ser considerado como asesoramiento médico ni utilizado como sustituto de una consulta a un profesional médico con licencia. Debe consultar con su médico para determinar qué es lo adecuado para usted.</p><p>Y0040_GHHK9JNSP_C</p><p>GCHK9JRSP</p>'
                },
                id: 'remote-id'
            } as SurveySchema
        },
        error: {
            error: true,
            message: 'broken'
        }
    },
    pharmacyLocations: {
        features: <GeoJsonDataPoint[]>[
            {
                id: faker.datatype.uuid(),
                geometry: {
                    type: 'POINT',
                    coordinates: [44, 77]
                },
                properties: {
                    distanceInMiles: 0.45,
                    name: faker.random.words(2),
                    primaryPhoneNumber: '+15555555555',
                    streetAddress: '700 Washington Ave. N #214, Minneapolis, MN, 55401'
                }
            },
            {
                id: faker.datatype.uuid(),
                geometry: {
                    type: 'POINT',
                    coordinates: [54, 87]
                },
                properties: {
                    distanceInMiles: 1,
                    name: faker.random.words(2),
                    primaryPhoneNumber: '+15555555555',
                    streetAddress: '701 Washington Ave. N #214, Minneapolis, MN, 55401'
                }
            }
        ]
    },
    zipCodeToLatLng: {
        status: 'SUCCESS',
        results: [
            {
                geometry: {
                    location: {
                        lat: () => 123,
                        lng: () => 456
                    }
                }
            }
        ]
    }
};

@Injectable()
export class SurveyServiceMock extends MockService {
    private valid: boolean;

    constructor(private store: Store<any>) {
        super();
    }

    public bootstrap(context: string): Observable<Object> {
        return this.respond(
            surveyServiceMockResponses.bootstrap.success,
            'bootstrap',
            surveyServiceMockResponses.bootstrap.error
        );
    }

    public show(context: string): Observable<Object> {
        return this.respond(surveyServiceMockResponses.show.success, 'show', surveyServiceMockResponses.show.error);
    }

    public isValid() {
        let valid = false;
        this.store.pipe(select(selectSurveyValid), take(1)).subscribe((val) => (valid = val));

        return valid;
    }

    public pharmacyLocations() {
        if (this.shouldSucceed) {
            return this.respond(surveyServiceMockResponses.pharmacyLocations, 'pharmacyLocations');
        }

        return this.respond(null, 'pharmacyLocations', {
            error: 'Undefined'
        });
    }

    public recordResponse() {
        return this.respond({});
    }

    public recordResponses() {
        return this.respond({});
    }

    public deleteResponse() {
        return this.respond({});
    }

    public careSuggestions() {
        return this.respond([]);
    }

    public deleteQuestionData() {}

    public zipCodeToLatAndLong(zip) {
        if (this.shouldSucceed) {
            return this.respondWithPromise(surveyServiceMockResponses.zipCodeToLatLng, 'zipCodeToLatAndLong');
        }

        return this.respondWithPromise(null, 'zipCodeToLatAndLong', null);
    }
}
