import {toString} from 'lodash';
import moment from 'moment';

export function currentTimeUnix(): number {
    return toMilliseconds(moment().unix());
}

export function toMilliseconds(time: number | string | Date): number {
    if (typeof time !== 'number') {
        return 0;
    }
    return time * 1000;
}

export function toUtc(date: Date): string {
    return moment.utc(date).format();
}

export function addDigit(value: number): string {
    if (value < 10) {
        return `0${value}`;
    }
    return toString(value);
}
