import {Component, Injector, Input} from '@angular/core';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';

import {ISurveyStats} from '../../../models/survey/ISurveyContent';
import {BaseElement} from '../../base-element.abstract';

@Component({
    selector: 'surveys-element-stats',
    styleUrls: ['./element-stats.component.scss'],
    template: `
        <clients-grid-layout [layoutGap]="0" [layout]="LAYOUT.ROW" [layoutXs]="LAYOUT.ROW" [layoutSm]="LAYOUT.ROW">
            <clients-grid-layout
                [layoutGap]="0"
                [layout]="LAYOUT.ROW"
                [layoutXs]="LAYOUT.ROW"
                [layoutSm]="LAYOUT.ROW"
                *ngFor="let data of element.data; let last = last"
            >
                <div>
                    <surveys-element-counter [count]="data.count"></surveys-element-counter>
                    <surveys-element-html [element]="data.description" [useCustomStyles]="true"></surveys-element-html>
                </div>
                <surveys-element-separator *ngIf="!last" [char]="'/'"></surveys-element-separator>
            </clients-grid-layout>
        </clients-grid-layout>
    `
})
export class ElementStatsComponent extends BaseElement {
    @Input()
    public element: ISurveyStats;
    public LAYOUT = FLEX_LAYOUT_ENUM;

    /* istanbul ignore file */
    constructor(injector: Injector) {
        super(injector);
    }
}
