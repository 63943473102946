import {Action} from '@clients/shared/models';
import {type} from '@clients/shared/utilities';

import {IdvResponse} from '../models/IdvResponse.interface';
import {IIdvQuestion} from '../models/IIdvQuestion';

export const IdvActionTypes = {
    ENROLLMENT_ID_LOADED: type('[idv] enrollment id loaded'),
    IDV_FIELDS_LOADED: type('[idv] questions loaded'),

    IDV_REQUIRED: type('[idv] idv required'),
    IDV_NOT_REQUIRED: type('[idv] idv not required'),

    VERIFY_IDENTITY: type('[idv] verify identity'),
    IDENTITY_VERIFIED: type('[idv] identity verified'),
    IDENTITY_VERIFICATION_FAILED: type('[idv] identity verification failed'),
    IDENTITY_VERIFICATION_LOCKED: type('[idv] identity verification locked')
};

export class EnrollmentIdLoadedAction implements Action<string> {
    public readonly type: string = IdvActionTypes.ENROLLMENT_ID_LOADED;

    // enrollment id
    constructor(public payload: string) {}
}

export class IdvQuestionsLoadedAction implements Action<IIdvQuestion[]> {
    public readonly type: string = IdvActionTypes.IDV_FIELDS_LOADED;

    // enrollment id
    constructor(public payload: IIdvQuestion[]) {}
}

export class IdvRequiredAction implements Action {
    public readonly type: string = IdvActionTypes.IDV_REQUIRED;
}

export class IdvNotRequiredAction implements Action<string> {
    public readonly type: string = IdvActionTypes.IDV_NOT_REQUIRED;

    // next route after getting the token for free
    constructor(public payload?: string) {}
}

export class VerifyIdentityAction implements Action<IdvResponse[]> {
    public readonly type: string = IdvActionTypes.VERIFY_IDENTITY;

    constructor(public payload: IdvResponse[]) {}
}

export class IdentityVerifiedAction implements Action<string> {
    public readonly type: string = IdvActionTypes.IDENTITY_VERIFIED;

    constructor(public payload: string) {}
}

export class IdentityVerificationFailed implements Action {
    public readonly type: string = IdvActionTypes.IDENTITY_VERIFICATION_FAILED;
}

export class IdentityVerificationLocked implements Action {
    public readonly type: string = IdvActionTypes.IDENTITY_VERIFICATION_LOCKED;
}
