import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectSelectedBlock, selectSurveyConfig} from '../+state';
import {IIdvConfig, ISurveyConfig} from '../../models/survey/ISurveyConfig';
import {get} from 'lodash';
import {ISurveyBlock, SurveyBlockConfig} from '../../models/survey/ISurveyBlock';
import {ILanguage} from '@clients/member-direct/translation';
import {BehaviorSubject} from 'rxjs';
import {COMPANY_LOGO_SIZE} from '../enum/CompanyLogoSize.enum';

@Injectable({
    providedIn: 'root'
})
export class SurveyConfigService {
    public surveyConfigUpdated: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    private blockConfig: SurveyBlockConfig;
    private surveyConfig: ISurveyConfig;

    constructor(private store: Store<any>) {
        this.store.pipe(select(selectSurveyConfig)).subscribe((config: ISurveyConfig) => {
            this.surveyConfig = config;
            this.surveyConfigUpdated.next();
        });
        this.store
            .pipe(select(selectSelectedBlock))
            .subscribe((block: ISurveyBlock) => (this.blockConfig = block?.config));
    }

    public shouldShowSidebarBlockNavigation(): boolean {
        return get(this.surveyConfig, 'sidebar.showBlockNavigationMenu.enabled') === true;
    }

    public shouldShowNameInSidebar(): boolean {
        // Defaulting to false in case the config is not loaded yet.
        const config = this.surveyConfig;
        if (config) {
            return get(config, 'sidebar.showName.enabled', true) === true;
        } else return false;
    }

    public getSideBarCompanyLogoSize(): COMPANY_LOGO_SIZE {
        return this.surveyConfig?.sidebar?.logoSize ?? COMPANY_LOGO_SIZE.small;
    }

    /**
     * Unlocked Block Navigation allows a user to move from any block to any other block with out
     * any block being complete.
     */
    public unlockedBlockNavigation(): boolean {
        return get(this.surveyConfig, 'survey.unlockedBlockNavigation.enabled') === true;
    }

    /**
     * Manual block navigation can be enabled on the survey level or the block level. The
     * block-level config always get preference.
     * @returns {boolean}
     */
    public shouldAllowManualBlockNavigation(): boolean {
        const blockAllowsManualBlockNav: boolean = get(this.blockConfig, 'block.manualBlockNavigation.enabled');
        if (blockAllowsManualBlockNav === false) return false;

        const surveyAllowsManualBlockNav: boolean =
            get(this.surveyConfig, 'survey.manualBlockNavigation.enabled') === true;

        return blockAllowsManualBlockNav === true || surveyAllowsManualBlockNav;
    }

    public getAvailableLanguages(): ILanguage[] {
        return get(this.surveyConfig, 'languages') || [];
    }

    public getLogo(): string {
        return get(this.surveyConfig, 'logo');
    }

    public getBannerAltText(language: string = 'en'): string {
        const defaultAltText = 'Banner';
        const altTextMultilingualData = get(this.getIdvConfig(), 'bannerAltText');
        if (!altTextMultilingualData) {
            return defaultAltText;
        }

        return altTextMultilingualData[language] || defaultAltText;
    }

    public getLogoAltText(language: string = 'en'): string {
        const defaultAltText = 'Company logo';
        const altTextMultilingualData = get(this.surveyConfig, 'logoAltText');
        if (!altTextMultilingualData) {
            return defaultAltText;
        }

        return altTextMultilingualData[language] || defaultAltText;
    }

    public getUse508ComplianceDefaults(): boolean {
        return get(this.surveyConfig, 'use508ComplianceDefaults', false);
    }

    public getIdvConfig(): IIdvConfig {
        return get(this.surveyConfig, 'idv');
    }

    public getVisual() {
        return get(this.surveyConfig, 'visual');
    }
}
