import {SurveyReducerState} from './survey.interfaces';

export const surveyInitialState: SurveyReducerState = {
    enrollmentId: null,
    context: null,
    surveyConfiguration: null,
    config: null,
    surveyRecordId: null,
    tenantId: null,
    valid: false,
    language: null,
    defaultLanguage: 'en',
    disableResume: false
};
