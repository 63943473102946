import {Component} from '@angular/core';

@Component({
    selector: 'member-direct-divider',
    styleUrls: ['./divider.component.scss'],
    template: ` <div class="divider"><div class="bar"></div></div> `
})
export class DividerComponent {
    constructor() {}
}
