import {Component, HostBinding, Inject, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {ISurveyContainer} from '../../../models/survey/ISurveyContent';
import {get} from 'lodash';
import {IContainerCustomStyles} from '../../../models/theme/IContainerCustomStyles';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';
import {BUILD_CSS_VARIABLES} from '../../surveys.providers';

@Component({
    selector: 'surveys-element-container',
    template: `
        <div
            *ngIf="element"
            class="survey-element surveys-element-container element-id-{{ element.id }}"
            [fxLayout]="layoutType"
            [ngStyle]="styles"
            [fxLayoutAlign]="align"
            [class.center]="element.config?.layout?.center"
        >
            <div class="container-inner" fxFlex="auto" fxFlex.xs="auto" fxFlex.sm="auto" fxFlex.md="auto">
                <div *ngFor="let ele of element.data">
                    <surveys-render-survey-element
                        [element]="ele"
                        [matchChildHeight]="matchChildHeight"
                    ></surveys-render-survey-element>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./element-container.component.scss']
})
export class ElementContainerComponent implements OnInit {
    @Input()
    element: ISurveyContainer;

    @HostBinding('style')
    private customStyles: SafeStyle = '';

    public styles: {[key: string]: string};
    public matchChildHeight = false;
    public display = false;
    public layoutType: FLEX_LAYOUT_ENUM = FLEX_LAYOUT_ENUM.COLUMN;
    public align = 'stretch';

    constructor(private sanitizer: DomSanitizer, @Inject(BUILD_CSS_VARIABLES) private buildCssVariables) {}

    ngOnInit(): void {
        const customStyles = get(this.element, 'config.layout.customStyles');
        const matchChildHeight = get(this.element, 'config.matchChildHeight');
        matchChildHeight && (this.matchChildHeight = matchChildHeight);

        // todo isIe -> build styles -> get the variable value from the theme!!! https://healthtel.atlassian.net/browse/SB-999
        if (customStyles) {
            this.customStyles = this.setCustomStyles(customStyles);
            this.styles = customStyles;
        }

        /*
         * we could delay the displaying of the container if we have to
         * (due to FOUC while we set the custom styles to the component)
         * */
        // setTimeout(() => {
        //     this.display = true;
        // }, 300);
    }

    private setCustomStyles(customStyles: IContainerCustomStyles): SafeStyle {
        const styles = this.buildCssVariables(customStyles, 'elements-container');

        return this.sanitizer.bypassSecurityTrustStyle(styles);
    }
}
