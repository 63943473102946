import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {MemberDirectSurveysState} from './surveys.interface';
import {take} from 'rxjs/operators';
import {selectPreauthorizedSurvey} from '../index';

@Injectable({
    providedIn: 'root'
})
export class MemberDirectSurveysFacade {
    constructor(private store: Store<MemberDirectSurveysState>) {}

    public preauthorized(): boolean {
        let res: boolean;
        this.store.pipe(take(1), select(selectPreauthorizedSurvey)).subscribe((value: boolean) => (res = value));

        return res;
    }
}
