import {EventTrackPayload} from '@clients/shared/analytics';
import {ISurveyAccordion} from '../../models/survey/ISurveyContent';
import {getElementId} from '../+state/elements/elements.reducer';
import {get} from 'lodash';

const EVENT_NAMES = {
    ACCORDION_OPEN: 'Accordion Opened'
};

export class AccordionOpenEvent implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.ACCORDION_OPEN;
    public properties;

    constructor(element: ISurveyAccordion) {
        this.properties = {
            id: getElementId(element),
            heading: get(element, 'label.en')
        };
    }
}
