/* istanbul ignore file */
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {TextMaskConfig} from 'angular2-text-mask';
import {Element} from '../element.abstract';

@Component({
    selector: 'r-masked-input',
    templateUrl: './masked-input.component.html',
    styleUrls: ['./masked-input.component.scss']
})
export class MaskedInputComponent extends Element implements OnInit, OnChanges {
    @Input() public type: string;
    @Input() public autocomplete: string;
    @Output() public validInput: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public shouldFocus: boolean;
    @Input() public maxlength: number;
    @Input() public mask: any;
    @Input() public pipe: any;
    @Input() public guide = true;
    @Input() public showMask = false;
    @Output() public keyUp: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('input') private input: ElementRef;
    public _labeledby: string;
    public _describedby: string;
    public textLength = 0;
    public maskConfig: TextMaskConfig;
    public inputType;

    @Input()
    public get labeledby() {
        return this._labeledby;
    }
    @Input()
    public get describedby() {
        return [this.labeledby, this._describedby].join(' ');
    }
    // eslint-disable-next-line
    public set labeledby(value: string) {
        this._labeledby = value;
    }
    // eslint-disable-next-line
    public set describedby(value: string) {
        this._describedby = value;
    }

    ngOnInit() {
        this.maskConfig = {
            mask: this.mask,
            pipe: this.pipe,
            guide: this.guide,
            showMask: this.showMask
        };
        super.ngOnInit();
        this.validInput.emit(false);
    }

    ngOnChanges(changes) {
        if (changes.shouldFocus && !changes.shouldFocus.previousValue && changes.shouldFocus.currentValue === true) {
            this.input.nativeElement.focus();
        }
    }

    updateElement(event: any) {
        this.textLength = event.target.value.length;
    }

    checkEmail(event: any) {
        const email = event.target.value;
        if (email.length === 0) {
            this.validInput.emit(true);
        } else {
            this.validInput = email.match(
                // eslint-disable-next-line
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
    }

    checkPhone(event: any) {
        const phoneNumber = event.target.value;
        if (phoneNumber.length === 0) {
            this.validInput.emit(true);
        } else {
            this.validInput = phoneNumber.match(/[(]\d{3}[)] \d{3}[-]\d{4}/);
        }
    }

    checkYear(event: any) {
        const year = event.target.value;
        if (year.length === 0) {
            this.validInput.emit(true);
        } else {
            this.validInput = year.match(
                // eslint-disable-next-line
                /[1-9][0-9][0-9][0-9]/
            );
        }
    }

    checkBirthdate(event: any) {
        const birthdate = event.target.value;
        if (birthdate.length === 0) {
            this.validInput.emit(true);
        } else {
            if (birthdate.length > 7 || birthdate.slice(-4).search('/') === -1) {
                this.validInput = birthdate.slice(-4).match(/\d{4}/);
            }
        }
    }
}
