/* istabul ignore file */
import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';

import {ISurveyBlock} from '../../../models/survey/ISurveyBlock';
import {BlocksActionTypes} from './blocks.actions';
import {blocksInitialState} from './blocks.init';
import {SurveysBlocksState} from './blocks.interfaces';

export const getBlockId = (block: ISurveyBlock): string => block.id;
export const blocksAdapter: EntityAdapter<ISurveyBlock> = createEntityAdapter<ISurveyBlock>({
    selectId: getBlockId
});

export function blocksReducer(state: SurveysBlocksState, action: any): SurveysBlocksState {
    switch (action.type) {
        case BlocksActionTypes.RESET:
            return blocksInitialState;

        case BlocksActionTypes.BLOCKS_LOADED:
            return blocksAdapter.addMany(action.payload, state);

        case BlocksActionTypes.ACTIVATE_BLOCK:
            return {
                ...state,
                idOfPreviouslySelectedEntity: state.idOfSelectedEntity,
                idOfSelectedEntity: action.payload as string
            } as SurveysBlocksState;

        case BlocksActionTypes.BLOCK_HAS_BEEN_SEEN:
            return {
                ...state,
                seen: [...state.seen, action.payload]
            } as SurveysBlocksState;

        case BlocksActionTypes.BLOCK_QUESTIONS_COMPLETE: {
            return {
                ...state,
                completed: [...state.completed, action.payload]
            } as SurveysBlocksState;
        }

        case BlocksActionTypes.BLOCK_QUESTIONS_INCOMPLETE:
            return {
                ...state,
                completed: [...state.completed].filter((blockId: string) => blockId !== action.payload)
            } as SurveysBlocksState;

        case BlocksActionTypes.REHYDRATE:
            return {
                ...state,
                idOfSelectedEntity: action.payload.idOfSelectedEntity || blocksInitialState.idOfSelectedEntity,
                idOfPreviouslySelectedEntity:
                    action.payload.idOfPreviouslySelectedEntity || blocksInitialState.idOfPreviouslySelectedEntity,
                seen: action.payload.seen || blocksInitialState.seen,
                hidden: action.payload.hidden || blocksInitialState.hidden,
                completed: action.payload.completed || blocksInitialState.completed
            } as SurveysBlocksState;

        case BlocksActionTypes.BLOCK_READY:
            return {...state, ready: true} as SurveysBlocksState;

        case BlocksActionTypes.BLOCK_NOT_READY:
            return {...state, ready: false} as SurveysBlocksState;

        default:
            return state;
    }
}

export const getIdOfSelectedEntity = (state: SurveysBlocksState) => state.idOfSelectedEntity;
export const getIdOfPreviouslySelectedEntity = (state: SurveysBlocksState) => state.idOfPreviouslySelectedEntity;
export const getSeen = (state: SurveysBlocksState) => state.seen;
export const getCompleted = (state: SurveysBlocksState) => state.completed;
export const getReady = (state: SurveysBlocksState) => state.ready;
