import {Component, Input} from '@angular/core';

@Component({
    selector: 'surveys-element-separator',
    styleUrls: ['./element-separator.component.scss'],
    template: ` <div>{{ char }}</div> `
})
export class ElementSeparatorComponent {
    @Input()
    public char = '/';

    constructor() {}
}
