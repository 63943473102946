<div *ngIf="element" class="survey-element surveys-element-multiple-choice" role="group">
    <surveys-element-question-text
        *ngIf="multipartQuestionHeader"
        [text]="multipartQuestionHeader"
        [textSize]="'small'"
        [classes]="['multipart-label']"
        [id]="'multipart-question-' + element.id"
    ></surveys-element-question-text>

    <surveys-element-question-text
        [id]="element.id"
        [text]="element.label[language]"
        [required]="element.required"
        [textSize]="element?.data?.smallLabel ? 'small' : 'large'"
    ></surveys-element-question-text>

    <surveys-element-options-block
        *ngIf="element.data?.options"
        [options]="element.data?.options"
        [questionType]="element.type"
        [language]="language"
        [selectedOptionId]="selectedOptionId"
        [labeledby]="element.id"
        [describedby]="element.id"
        (clickHandler)="execute($event)"
    ></surveys-element-options-block>
</div>
