import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {SurveyConfigService} from '@clients/member-direct/surveys';
import {TranslationService} from '@clients/member-direct/translation';
import {DROPDOWN_POSITION_ENUM, IDropdownData, UntilComponentDestroyed} from '@clients/shared/common-forms';
import {takeUntil} from 'rxjs/operators';

// todo create app level language-member-direct-app-language https://healthtel.atlassian.net/browse/SB-980

@Component({
    selector: 'member-direct-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent extends UntilComponentDestroyed implements OnInit {
    @ViewChildren('languageElements')
    languageElements: QueryList<ElementRef>;

    @ViewChild('languageBtn')
    languageBtn: ElementRef;

    @Input()
    public languagesData: Array<IDropdownData>;

    @Input()
    activeLanguage: string;

    @Input()
    dropdownPosition?: DROPDOWN_POSITION_ENUM = DROPDOWN_POSITION_ENUM.BOTTOM;

    @Output()
    changeHandler: EventEmitter<string> = new EventEmitter<string>();

    languageIconKey: string;
    public isActive = false;
    public label: string;
    public languageChangeText: string;
    public dialogAriaLabel: string;

    constructor(public translationService: TranslationService, public surveyConfigService: SurveyConfigService) {
        super();
    }
    ngOnInit() {
        this.surveyConfigService.surveyConfigUpdated.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.languageIconKey = this.surveyConfigService.getUse508ComplianceDefaults()
                ? 'language-accessible'
                : 'language';
        });

        this.languageChangeText = this.languageLabelByCode(this.activeLanguage);
        this.label = this.translationService.translate('memberDirect.languages');
        this.dialogAriaLabel = this.translationService.translate('memberDirect.selectLanguage');
    }

    public menuOnClick() {
        this.toggleMenu();
        if (this.isActive) {
            const firstLang = this.languageUiElement(0);
            this.focusLanguageElement(firstLang);
        }
    }

    public toggleMenu() {
        this.isActive = !this.isActive;
    }

    public closeAndFocusBack() {
        this.toggleMenu();
        this.focusLanguageElement(this.languageBtn.nativeElement);
    }

    public onChange(value: string) {
        this.closeAndFocusBack();
        this.changeHandler.emit(value);
        this.languageChangeText = this.languageLabelByCode(value);
        this.label = this.translationService.translate('memberDirect.languages');
        this.dialogAriaLabel = this.translationService.translate('memberDirect.selectLanguage');
    }
    //istanbul ignore next
    public onFocusFirstGuardian() {
        if (this.isActive) {
            const lastIndex = this.languageElements.length - 1;
            const lastLang = this.languageUiElement(lastIndex);
            this.focusLanguageElement(lastLang);
        }
    }
    //istanbul ignore next
    public onFocusLastGuardian() {
        if (this.isActive) {
            const firstLang = this.languageUiElement(0);
            this.focusLanguageElement(firstLang);
        }
    }

    //istanbul ignore next
    public onArrowDown(index: number) {
        const nextIndex = index + 1;
        if (nextIndex < this.languageElements.length) {
            const nextLang = this.languageUiElement(nextIndex);
            this.focusLanguageElement(nextLang);
        } else {
            const nextLang = this.languageUiElement(0);
            this.focusLanguageElement(nextLang);
        }
    }

    //istanbul ignore next
    public onArrowUp(index: number) {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
            const prevLang = this.languageUiElement(prevIndex);
            this.focusLanguageElement(prevLang);
        } else {
            const nextLang = this.languageUiElement(this.languageElements.length - 1);
            this.focusLanguageElement(nextLang);
        }
    }

    //istanbul ignore next
    public onHome() {
        const nextLang = this.languageUiElement(0);
        this.focusLanguageElement(nextLang);
    }

    //istanbul ignore next
    public onEnd() {
        const nextLang = this.languageUiElement(this.languageElements.length - 1);
        this.focusLanguageElement(nextLang);
    }

    public onKeyDown(event, index) {
        switch (event.key) {
            case 'ArrowDown':
                this.onArrowDown(index);
                break;
            case 'ArrowUp':
                this.onArrowUp(index);
                break;
            case 'Home':
                this.onHome();
                break;
            case 'End':
                this.onEnd();
                break;
            case 'Enter':
            case ' ':
                // ' ' = space
                this.onChange(this.languagesData[index].value);
                break;
            case 'Escape':
            case 'Tab':
                this.closeAndFocusBack();
                break;
            default:
                if (event.key.match(/[A-Za-z]/i)) {
                    const nextLanguages = this.languagesData.slice(index + 1);
                    const matchingRemainingLanguages = nextLanguages.filter((lang) =>
                        lang.label.toLowerCase().startsWith(event.key.toLowerCase())
                    );
                    if (matchingRemainingLanguages.length > 0) {
                        const nextLang = this.languageUiElement(
                            this.languagesData.indexOf(matchingRemainingLanguages[0])
                        );
                        this.focusLanguageElement(nextLang);
                    } else {
                        const matchingLanguages = this.languagesData.filter((lang) =>
                            lang.label.toLowerCase().startsWith(event.key.toLowerCase())
                        );
                        if (matchingLanguages.length > 0) {
                            const nextLang = this.languageUiElement(this.languagesData.indexOf(matchingLanguages[0]));
                            this.focusLanguageElement(nextLang);
                        }
                    }
                }
        }
    }

    // Get's the language item of the drop down based on the index
    //istanbul ignore next
    private languageUiElement(index: number) {
        const language_children = this.languageElements.get(index);
        return language_children.nativeElement;
    }

    //istanbul ignore next
    private focusLanguageElement(element: HTMLElement) {
        // Open a new task in the render lifecycle for making sure the dropdown is rendered
        // before focusing the element
        setTimeout(() => {
            element?.focus();
        });
    }

    //istanbul ignore next
    private languageLabelByCode(languageCode: String) {
        const language = this.languagesData.find((lang) => lang.value === languageCode);
        return language?.label;
    }
}
