import {Observable} from 'rxjs';
import {ErrorObservable} from 'rxjs-compat/observable/ErrorObservable';

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {SurveyContextInvalidError, SurveyService} from '@clients/member-direct/surveys';

/**
 * This route guard checks whether we have validated the survey and
 * if that process has not yet been completed, we navigate back to
 * the survey bootstrap route to restart the process.
 */
@Injectable()
export class SurveyContextIsValidGuard implements CanActivate {
    constructor(private surveyService: SurveyService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean | Observable<any> {
        // if the survey does not require IDV or the user has already
        // verified themselves, then load the route.
        if (this.surveyService.isValid()) return true;

        // todo | verify context is set and error out if not https://healthtel.atlassian.net/browse/SB-991
        this.router.navigate([`survey/${route.paramMap.get('context')}`]);

        return ErrorObservable.create(new SurveyContextInvalidError(route.paramMap.get('context')));
    }
}
