import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';

import {GeoJsonDataPoint} from '../../../models/mapping/GeoJsonDataPoint.interface';
import {PharmaciesActionTypes} from './pharmacies.actions';
import {pharmaciesInitialState} from './pharmacies.init';
import {SurveysPharmaciesState} from './pharmacies.interfaces';

export const pharmaciesSortComparer = (a: GeoJsonDataPoint, b: GeoJsonDataPoint) => (a.rowOrder < b.rowOrder ? -1 : 1);

export const getPharmacyId = (pharmacy: GeoJsonDataPoint): string => pharmacy.id;
export const pharmaciesAdapter: EntityAdapter<GeoJsonDataPoint> = createEntityAdapter<GeoJsonDataPoint>({
    selectId: getPharmacyId,
    sortComparer: pharmaciesSortComparer
});

export function pharmaciesReducer(state: SurveysPharmaciesState, action: any): SurveysPharmaciesState {
    switch (action.type) {
        case PharmaciesActionTypes.RESET:
            return pharmaciesInitialState;

        case PharmaciesActionTypes.PHARMACIES_LOADED: {
            const s = pharmaciesAdapter.upsertMany(action.payload, state);
            return {
                ...s,
                entities: {...s.entities},
                pharmaciesLoadedOnce: true
            } as SurveysPharmaciesState;
        }
        case PharmaciesActionTypes.REMOVE_PHARMACY_LOCATION_DATA: {
            const pharmaciesWithoutLocation = action.payload.map((pharmacy) => ({
                ...pharmacy,
                properties: {
                    ...pharmacy.properties,
                    distanceInMiles: null
                }
            }));

            return pharmaciesAdapter.upsertMany(pharmaciesWithoutLocation, state);
        }
        default:
            return state;
    }
}

export const getPharmaciesLoadedOnce = (state: SurveysPharmaciesState) => state.pharmaciesLoadedOnce;
