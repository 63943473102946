import {Address} from '@clients/shared/common-forms';

export function formatPharmacyAddress(address): Address {
    const addressToArray = address.split(', ');

    const formattedAddress: Address = {
        line1: null,
        line2: null,
        city: null,
        state: null,
        zip: null
    };

    const props = addressToArray.length;
    switch (props) {
        case 3:
            formattedAddress.line1 = addressToArray[0];
            formattedAddress.city = addressToArray[1];
            {
                const stateZip = addressToArray[2].split(' ');
                formattedAddress.state = stateZip[0];
                formattedAddress.zip = stateZip[1];
            }
            break;
        case 4:
            formattedAddress.line1 = addressToArray[0];
            formattedAddress.line2 = addressToArray[1];
            formattedAddress.city = addressToArray[2];
            {
                const stateZip = addressToArray[3].split(' ');
                formattedAddress.state = stateZip[0];
                formattedAddress.zip = stateZip[1];
            }
            break;
        default:
            return null;

        // todo | log rather than throw https://healthtel.atlassian.net/browse/SB-1010
        // throw new Error(
        //     'Cannot format Pharmacy address -- invalid number of props. Must be 3 or 4 items when split by the comma.'
        // );
    }

    return formattedAddress;
}
