import {EventTrackPayload} from '@clients/shared/analytics';

const EVENT_NAMES = {
    CONTENT_BLOCK_VIEWED: 'Content Block Viewed'
};

export class ContentBlockViewed implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.CONTENT_BLOCK_VIEWED;
    public properties;

    constructor(id: string, time: number) {
        this.properties = {
            ['Block Id']: id,
            ['Time']: time,
            ['Time Unit']: 'seconds'
        };
    }
}
