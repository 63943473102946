/* eslint-disable @typescript-eslint/no-unused-vars */
import {debounceTime, map, takeUntil} from 'rxjs/operators';

import {DOCUMENT} from '@angular/common';
import {Directive, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, ValidatorFn, Validators} from '@angular/forms';
import {Action} from '@clients/shared/models';
import {AppActions, isNotNullOrUndefined, updateControlIfUnique} from '@clients/shared/utilities';
import {Dictionary} from '@ngrx/entity';

import {SurveyResponse} from '../models/survey-responses/SurveyResponse.interface';
import {ISurveyInputBirthdate} from '../models/survey/ISurveyContent';
import {ISurveyValidator} from '../models/survey/ISurveyValidator';
import {ElementsActionTypes, ElementScrollCompletedAction} from './+state/elements/elements.actions';
import {getElementId} from './+state/elements/elements.reducer';
import {DeleteResponseAction, RecordResponseAction} from './+state/responses/responses.actions';
import {ResponsesFacade} from './+state/responses/responses.facade';
import {ValidatorsEnum} from './enum/Validators.enum';
import {QuestionBaseAbstract} from './question-base.abstract';
import {BlockConfigService} from './services/block-config.service';

@Directive()
export abstract class InputBase<T>
    extends QuestionBaseAbstract<Date, Dictionary<SurveyResponse>, ISurveyInputBirthdate>
    implements OnInit, OnDestroy
{
    @Input() public selected: boolean;

    // tag and input with #firstFocus if you want it to be focused
    // when this element is selected

    @ViewChild('firstFocus') protected firstFocus: ElementRef;

    // tag an input with #lastFocus if you want to know that input
    // is the last one in the set so you can move to the next
    // question.
    @ViewChild('lastFocus') protected lastFocus: ElementRef;

    // this control will be updated with the value from the store. sometimes
    // your component can just use this control directly, whereas other times
    // you might want to watch for changes in it and then transform the value
    // to something else for display.
    public control: FormControl;
    public shouldFocus: boolean;
    public shouldAllowAdvanceOnTab: boolean;

    // the last value of the form post-transformation
    protected lastValue: any;
    protected initialDataLoaded: boolean;
    // manual DI
    private responsesFacade: ResponsesFacade;
    private blockConfigService: BlockConfigService;
    private actions: AppActions;
    private document: Document;

    /* istanbul ignore next */
    protected constructor(injector: Injector) {
        super(injector);

        // manual DI
        this.responsesFacade = injector.get(ResponsesFacade);
        this.blockConfigService = injector.get(BlockConfigService);
        this.actions = injector.get(AppActions);
        this.document = injector.get(DOCUMENT);
    }

    ngOnInit(): void {
        this.control = new FormControl('', this.getValidators(this.element));
        this.shouldAllowAdvanceOnTab = !this.blockConfigService.shouldRenderInSingleQuestionMode();
        this.listenForFocusingActions();

        super.ngOnInit();
    }

    /**
     * You may override this function if you need to do something between when the
     * data is fetched from the store and when you display it on the screen. Be careful
     * to not update your local controls every time or you might create a loop.
     */
    public handleResponseEntities(responses: Dictionary<SurveyResponse>): void {
        // this needs to be separated out for testing so we don't try to
        // get the ID of an element that doesn't exist.
        const elementId = this.element ? getElementId(this.element) : null;
        const response = responses[elementId];
        const value: T = response ? response.value : undefined;
        const transformedValue = this.transformBeforeDisplay(value);
        if (!this.initialDataLoaded) {
            this.lastValue = value;
            this.initialDataLoaded = true;
        }
        updateControlIfUnique(this.control, transformedValue);
    }

    /**
     * You may override this function in child classes in order show the
     * proceed actions at a different time.
     */
    public showProceedActions(): boolean {
        return this.selected === true && isNotNullOrUndefined(this.control.value);
    }

    // watcher for keyPress events from our components
    public keyMonitor($event: KeyboardEvent): void {
        switch ($event.keyCode) {
            case 13:
                this.enterKey($event);
                break;
            case 9:
                this.tabKey($event);
                break;
        }
    }

    public advance() {
        const value = this.getFormValue();
        const transformed: any = this.transformBeforeSave(value);

        if (this.shouldProceedWithSave(transformed)) {
            //save the response
            this.lastValue = transformed;
            this.saveWithCustomAction(this.element, transformed, this.actionCreator(transformed));
        }
        //run the instructions and proceed
        this.runInstructions(this.element);
        this.proceed(transformed);
    }

    public shouldAdvanceOnTab(): boolean {
        return !this.lastFocus || this.lastFocus.nativeElement === this.document.activeElement;
    }

    public getTabIndex(tabIfEnabled: number): number {
        return this.selected ? tabIfEnabled : -1;
    }

    protected getFormValue() {
        return this.control.value;
    }

    /**
     * You may override this function in child classes in order to prevent
     * saving until certain criteria are true.
     */
    protected shouldSave(value: any): boolean {
        return true;
    }

    /**
     * You may override this function in child classes in order to transform
     * the data before writing a response.
     */
    protected transformBeforeSave(value: any): T {
        return value;
    }

    /**
     * You may override this function in child classes in order to transform
     * the data before updating the control.
     */
    protected transformBeforeDisplay(value: any): any {
        return value;
    }

    /**
     * You may override this function in child classes in order to change the
     * behavior for the enter key.
     */
    protected enterKey($event: KeyboardEvent): void {
        this.advance();
    }

    /**
     * You may override this function in child classes in order to change the
     * behavior for the tab key.
     */
    protected tabKey($event: KeyboardEvent): void {
        if (this.shouldAllowAdvanceOnTab) {
            if (this.shouldAdvanceOnTab()) {
                this.advance();
            }
        }
    }

    /**
     * You may override this function to determine what happens when this element
     * receives focus.
     */
    protected elementIsFocused() {
        if (this.firstFocus) this.firstFocus.nativeElement.focus();
        this.shouldFocus = true;
    }

    /**
     * You may override this function to determine what happens when this element
     * is out of focus.
     */
    protected elementIsNotFocused() {
        this.shouldFocus = false;
    }

    protected enableAutoSave() {
        this.control.valueChanges
            .pipe(takeUntil(this.destroyed$), debounceTime(300))
            .subscribe((value: any) => this.valueUpdated(value));
    }

    protected valueUpdated(value: any): void {
        const transformed = this.transformBeforeSave(value);
        if (this.shouldProceedWithSave(transformed)) {
            this.lastValue = transformed;
            const action = this.createResponseAction(transformed);
            this.store.dispatch(action);
        }
    }

    protected shouldProceedWithSave(value: any): boolean {
        const valueHasChanged = value !== this.lastValue;
        return valueHasChanged && this.shouldSave(value);
    }

    protected createResponseAction(value: any): Action<any> {
        /*
         * todo how we would want to handle the null value? https://healthtel.atlassian.net/browse/SB-1012
         * */
        const surveyResponse = new SurveyResponse(this.element, value);
        return surveyResponse.value !== undefined
            ? new RecordResponseAction(surveyResponse)
            : new DeleteResponseAction(surveyResponse);
    }

    private actionCreator(transformed: any) {
        return () => this.createResponseAction(transformed);
    }

    //
    // Matt Blum determined this function isn't really worth testing because it's more
    // trouble than it's worth.
    //
    // todo | cover in e2e tests https://healthtel.atlassian.net/browse/SB-1015
    //
    /* istanbul ignore next */
    private listenForFocusingActions(): void {
        // any time we see that an element scroll has completed, check to see if
        // it is this element to which the user has been scrolled.
        this.actions
            .ofType(ElementsActionTypes.ELEMENT_SCROLL_COMPLETED)
            .pipe(
                takeUntil(this.destroyed$),
                map((action: ElementScrollCompletedAction) => action.payload)
            )
            .subscribe((elementId: string) => this.checkFocus(elementId));

        // anytime we see that an element was highlighted without scroll check to see
        // if this element is now active and should focus its first input.
        this.actions
            .ofType(ElementsActionTypes.HIGHLIGHT_ELEMENT_WITHOUT_SCROLL)
            .pipe(
                takeUntil(this.destroyed$),
                map((action: ElementScrollCompletedAction) => action.payload)
            )
            .subscribe((elementId: string) => this.checkFocus(elementId));
    }

    //
    // Matt Blum determined this function isn't really worth testing because it's more
    // trouble than it's worth.
    //
    // todo | cover in e2e tests https://healthtel.atlassian.net/browse/SB-1016
    //
    /* istanbul ignore next */
    private getValidators(element: any): ValidatorFn[] {
        if (!element || !element.validators || !element.validators.length) return [];

        const validatorMap: {[key: string]: {validator: any; acceptsFormControl?: boolean}} = {
            [ValidatorsEnum.REQUIRED]: {
                validator: Validators.required,
                acceptsFormControl: true
            },
            [ValidatorsEnum.MAX]: {
                validator: Validators.max
            },
            [ValidatorsEnum.MAX_LENGTH]: {
                validator: Validators.maxLength
            },
            [ValidatorsEnum.MIN]: {
                validator: Validators.min
            },
            [ValidatorsEnum.MIN_LENGTH]: {
                validator: Validators.minLength
            }
        };

        return element.validators.map((validator: ISurveyValidator) => {
            const validatorItem = validatorMap[validator.type];
            if (!validatorItem) throw new Error('Invalid validator type. Please check the schema.');

            return validatorItem.acceptsFormControl ? validatorItem.validator : validatorItem.validator(validator.data);
        });
    }

    private checkFocus(elementId: string): void {
        if (getElementId(this.element) === elementId) {
            this.elementIsFocused();
        } else {
            this.elementIsNotFocused();
        }
    }

    /**
     * Override this to get a different form value if form is more complex than single control.
     */
}
