import {map, tap} from 'rxjs/operators';

import {HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
    IdentityVerificationFailed,
    IdentityVerificationLocked,
    IdentityVerifiedAction,
    IDV_ERROR_CODE
} from '@clients/member-direct/idv';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {
    BlocksActionTypes,
    ElementsActionTypes,
    ResponseRecordedErrorAction,
    ResponsesActionTypes,
    SurveyFacade
} from '@clients/member-direct/surveys';
import {AuthFailureAction, AuthProviderError, AuthSuccessAction} from '@clients/shared/auth';
import {ENV_SERVICE} from '@clients/shared/config';
import {RouterNavigatePayload} from '@clients/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {StateService} from '../../services/state.service';
import {ClientStateSaveFailed, MemberDirectActionTypes, MemberDirectError, RouterNavigateAction} from './app.actions';

export const errorMessages = {
    responseRecordedFailed: {
        '401': 'You no longer appear to be authorized to take this assessment. Please try launching it again or contact your provider for assistance.',
        default:
            'We were not able to save your response. Please try launching the assessment again, or for further assistance please contact your provider'
    },
    saveClientStateFailed: {
        '401': 'You no longer appear to be authorized to take this assessment. Please try launching it again or contact your provider for assistance.',
        default:
            'We were not able to save your assessment progress. Please try launching the assessment again, or for further assistance please contact your provider'
    }
};

@Injectable()
export class MemberDirectEffects {
    routerNavigate = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MemberDirectActionTypes.ROUTER_NAVIGATE),
                map((action: RouterNavigateAction) => action.payload),
                tap((payload: RouterNavigatePayload) => this.router.navigateByUrl(payload.route, payload.options || {}))
            ),
        {dispatch: false}
    );

    error = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MemberDirectActionTypes.ERROR),
                map((action: MemberDirectError) => action.payload),
                tap(() => this.router.navigateByUrl('error'))
            ),
        {dispatch: false}
    );

    authSuccessful = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthSuccessAction),
            map((action) => new IdentityVerifiedAction(action.token))
        )
    );

    authFailure = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthFailureAction),

            map((action) => action.error),
            map((error: AuthProviderError) => {
                if (error.state === IDV_ERROR_CODE.LOCKED) {
                    return new IdentityVerificationLocked();
                }
                if (error.state === IDV_ERROR_CODE.FAILED) {
                    return new IdentityVerificationFailed();
                }
                return new MemberDirectError(error.description);
            })
        )
    );

    /**
     * The save client state effect fires when any qualifying action is dispatched.
     * If you need to track data at a new time, simply add an action to the `ofType`
     * function.
     *
     * @type {Observable<any>}
     */
    saveClientState = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    // blocks
                    BlocksActionTypes.BLOCK_ACTIVATED,
                    BlocksActionTypes.BLOCK_HAS_BEEN_SEEN,
                    BlocksActionTypes.BLOCK_QUESTIONS_COMPLETE,
                    BlocksActionTypes.BLOCK_QUESTIONS_INCOMPLETE,

                    // responses
                    ResponsesActionTypes.RESPONSE_RECORDED,
                    ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT_UPDATED,

                    // elements
                    ElementsActionTypes.ELEMENT_HIGHLIGHTED
                ),
                tap(() => {
                    if (this.pureDevMode) return;

                    const surveyRecordId = this.surveyFacade.getSurveyRecordId();
                    const payload = this.stateService.assemblePayload();

                    this.stateService.save(surveyRecordId, payload);
                })
            ),
        {dispatch: false}
    );

    responseRecordedError = createEffect(() =>
        this.actions$.pipe(
            ofType(ResponsesActionTypes.RESPONSE_RECORDED_ERROR),
            map((action: ResponseRecordedErrorAction) => action.payload),
            map((response: HttpErrorResponse) => {
                let error: string;
                switch (response.status) {
                    case 401:
                        error = errorMessages.responseRecordedFailed['401'];
                        break;
                    default:
                        error = errorMessages.responseRecordedFailed.default;
                        break;
                }

                return new MemberDirectError(error);
            })
        )
    );

    saveClientStateFailed = createEffect(() =>
        this.actions$.pipe(
            ofType(MemberDirectActionTypes.CLIENT_STATE_SAVE_FAILED),
            map((action: ClientStateSaveFailed) => action.payload),
            map((response: HttpErrorResponse) => {
                let error: string;
                switch (response.status) {
                    case 401:
                        error = errorMessages.saveClientStateFailed['401'];
                        break;
                    default:
                        error = errorMessages.saveClientStateFailed.default;
                        break;
                }

                return new MemberDirectError(error);
            })
        )
    );

    private pureDevMode: boolean;
    constructor(
        private actions$: Actions,
        private router: Router,
        private surveyFacade: SurveyFacade,
        private stateService: StateService,
        @Inject(ENV_SERVICE) envService: MemberDirectEnvService
    ) {
        this.pureDevMode = envService.surveySettings.pureDevMode;
    }
}
