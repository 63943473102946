import {
    BootstrapComponent,
    PrivacyComponent,
    ErrorComponent,
    HomeComponent,
    IdvComponent,
    IdvNotRequiredOrCompleteGuard,
    IdvNotYetCompleteGuard,
    InvalidRouteComponent,
    PageLayoutComponent,
    SurveyContextIsValidGuard,
    SurveyShortLinkRedirectComponent
} from '@clients/member-direct/member-direct-lib';

export const memberDirectRoutes: any = [
    {
        path: '',
        pathMatch: 'full',
        component: InvalidRouteComponent
    },
    {
        path: 'survey/:context/verify',
        component: IdvComponent,
        canActivate: [SurveyContextIsValidGuard, IdvNotYetCompleteGuard]
    },
    {
        path: '',
        pathMatch: 'prefix',
        component: PageLayoutComponent,
        children: [
            {
                path: 'survey/:context',
                component: BootstrapComponent
            },
            {
                path: 'survey/:context/start',
                component: HomeComponent,
                canActivate: [SurveyContextIsValidGuard, IdvNotRequiredOrCompleteGuard]
            }
        ]
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    },

    // route to handle our shortest-possible-link redirect
    {
        path: ':context',
        pathMatch: 'full',
        component: SurveyShortLinkRedirectComponent
    },

    // error handling
    {
        path: '**',
        component: InvalidRouteComponent
    }
];
