export function arrayIsNullUndefinedOrEmpty(arr: any): boolean {
    const isNull = arr === null;
    const isUndefined = arr === undefined;
    const isTypeArray = Array.isArray(arr);

    // return true if defined as an array but with 0 objects
    if (!isNull && !isUndefined && isTypeArray) return arr.length === 0;

    return isNull || isUndefined;
}
