import {GeoJsonDataPoint} from '../../models/mapping/GeoJsonDataPoint.interface';
import {AgmMarker} from '../../models/mapping/AgmMarker.interface';

export function geoJsonToMarkerConverter(data: GeoJsonDataPoint[], limit: number = null): AgmMarker[] {
    if (!data || !data.length) return [];

    const convertedData = data.map((dataPoint: GeoJsonDataPoint): AgmMarker => {
        if (!dataPoint.geometry || !dataPoint.geometry.coordinates) return null;

        return {
            id: dataPoint.id,
            lat: dataPoint.geometry.coordinates[1],
            lng: dataPoint.geometry.coordinates[0],
            label: dataPoint.properties.name,
            address: dataPoint.properties.streetAddress,
            draggable: false
        };
    });

    return limit ? convertedData.slice(0, limit) : convertedData;
}
