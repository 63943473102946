import {filter, flatten, flow, map, some} from 'lodash';

import {Injectable} from '@angular/core';

import {SurveyElement} from '../../models/survey/ISurveyElement';
import {SurveyInstruction} from '../../models/survey/ISurveyInstruction';
import {SurveyElementType} from '../enum/SurveyElementType';

@Injectable()
export class SurveyQuestionService {
    constructor() {}

    public getAllQuestionsFromSurveyElements(elements: SurveyElement[]): any[] {
        return elements
            .filter((element: SurveyElement) => element.type === SurveyElementType.SURVEY_QUESTION)
            .map((element: SurveyElement) => element.question);
    }

    public questionElementsHasInstructions(questions: any[]): boolean {
        return some(questions, (question: any) => !!question.instructions);
    }

    public getQuestionsInstructionsFilteredByGroup(questions: any[], instructionsGroup: string[]): SurveyInstruction[] {
        return flow(
            (collection: any[]) => filter(collection, (question: any) => !!question.instructions),
            (result: any[]) => map(result, (question: any) => question.instructions),
            (instructions: any) => flatten(instructions),
            (instructions: SurveyInstruction[]) =>
                filter(instructions, (instruction: SurveyInstruction) => instructionsGroup.includes(instruction.type))
        )(questions);
    }
}
