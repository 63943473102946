import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {LoadSurveyAction, SurveyFacade} from '@clients/member-direct/surveys';

@Component({
    selector: 'member-direct-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    constructor(private store: Store<any>, private surveyFacade: SurveyFacade) {}

    ngOnInit() {
        const surveyRecordId = this.surveyFacade.getSurveyRecordId();
        this.store.dispatch(new LoadSurveyAction(surveyRecordId));
    }
}
