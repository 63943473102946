import {map} from 'lodash';
import moment from 'moment';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MultilingualData, TranslationService} from '@clients/member-direct/translation';
import {DROPDOWN_POSITION_ENUM, UntilComponentDestroyed} from '@clients/shared/common-forms';
import {select, Store} from '@ngrx/store';

import {selectQuestions} from '../../+state';
import {VerifyIdentityAction} from '../../+state/idv.actions';
import {IdvFacade} from '../../+state/idv.facade';
import {IDV_TYPE_ENUM} from '../../enum/IdvType.enum';
import {idvFeatureLocalePrefix} from '../../locale/locale';
import {IdvResponse} from '../../models/IdvResponse.interface';
import {IIdvQuestion} from '../../models/IIdvQuestion';
import {IVerificationFieldModel} from '../../models/verificationFields/IVerificationFieldModel';
import {IdvService} from '../../services/idv.service';
import {inputConfigMap} from './config/input.config';

export const fallBackButtonTextDefault = 'Try Again';

@Component({
    selector: 'idv-verification',
    templateUrl: './idv-verification.component.html',
    styleUrls: ['./idv-verification.component.scss']
})
export class IdvVerificationComponent extends UntilComponentDestroyed implements OnInit, OnChanges {
    @Input()
    language: string;

    @Input()
    fallbackButtonText: MultilingualData<string> | string = fallBackButtonTextDefault;
    public ready = false;
    public form: FormGroup;
    public viewModel: IVerificationFieldModel[];
    public dropdownPosition: DROPDOWN_POSITION_ENUM = DROPDOWN_POSITION_ENUM.TOP;
    public mainMessage: string;
    public retryMessage: string;
    public lockedMessage: string;
    public submitButtonText: string;
    public dobUiFormat = 'mm/dd/yyyy';
    public posting: Observable<boolean>;
    public locked: Observable<boolean>;
    public failed: Observable<boolean>;
    private dobApiFormat = 'YYYY-MM-DD';

    constructor(
        private formBuilder: FormBuilder,
        private store: Store<any>,
        private idvService: IdvService,
        private idvFacade: IdvFacade,
        private translationService: TranslationService
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(select(selectQuestions), takeUntil(this.destroyed$)).subscribe((questions: IIdvQuestion[]) => {
            if (!questions || !questions.length) return;
            this.buildFormGroup(questions);
        });

        this.posting = this.idvFacade.posting();
        this.locked = this.idvFacade.locked();
        this.failed = this.idvFacade.failed();

        /*set initial messages*/
        this.setTranslatedMessages(this.language);
    }

    ngOnChanges(changes: SimpleChanges): void {
        changes.language && this.setTranslatedMessages(changes.language.currentValue);
    }

    public submit() {
        const responses: IdvResponse[] = this.formatResponses(this.form.value);
        this.store.dispatch(new VerifyIdentityAction(responses));
    }

    protected formatDobInput(value: string): string {
        const formatted = moment(moment(value).valueOf()).format(this.dobApiFormat);
        if (formatted === 'Invalid date') return '';
        return formatted;
    }

    protected filterControls = (c) => map(c, (x) => x.control);

    private buildFormGroup(questions: IIdvQuestion[]) {
        this.viewModel = this.idvService.buildVerificationFieldsModel(questions, inputConfigMap);
        this.form = this.formBuilder.group(this.filterControls(this.viewModel));
        this.ready = true;
    }

    private setTranslatedMessages(language: string) {
        this.mainMessage = this.translationService.translateWithLanguageProvided(
            `${idvFeatureLocalePrefix}.${'welcome'}`,
            language
        );

        this.retryMessage = this.translationService.translateWithLanguageProvided(
            `${idvFeatureLocalePrefix}.${'error.retry'}`,
            language
        );

        this.lockedMessage = this.translationService.translateWithLanguageProvided(
            `${idvFeatureLocalePrefix}.${'error.locked'}`,
            language
        );

        this.submitButtonText = this.translationService.translateWithLanguageProvided('idv.forms.submit', language);
    }

    private formatResponses(responses: IdvResponse[]): IdvResponse[] {
        return map(responses, (response: IdvResponse): IdvResponse => {
            const {type, value} = response;
            const updated: IdvResponse = {type: type.toUpperCase() as IDV_TYPE_ENUM, value};

            switch (updated.type) {
                case IDV_TYPE_ENUM.DOB:
                    updated.value = this.formatDobInput(response.value);
                    break;
            }

            return updated;
        });
    }
}
