<div *ngIf="element" class="surveys-element-input-month" role="group">
    <surveys-element-question-text
        *ngIf="multipartQuestionHeader"
        [text]="multipartQuestionHeader"
        [textSize]="'small'"
        [classes]="['multipart-label']"
    ></surveys-element-question-text>
    <label for="element.id">
        <div class="label">
            <surveys-element-question-text
                [id]="element.id"
                [text]="element.label[language]"
                [required]="element.required"
            ></surveys-element-question-text>
        </div>

        <div class="inputs">
            <div class="input-element month">
                <r-masked-input
                    [control]="control"
                    [type]="'month'"
                    [placeholder]="'MM'"
                    [labeledby]="element.id"
                    [describedby]="multipartQuestionId ? multipartQuestionId : null"
                    [shouldFocus]="shouldFocus"
                    (keyUp)="keyMonitor($event)"
                >
                </r-masked-input>
            </div>
        </div>
    </label>
    <div class="button-element">
        <r-button
            *ngIf="showProceedActions()"
            class="continue-button"
            [e2e]="'button-' + element.id + '-continue'"
            [affirmative]="true"
            [negative]="false"
            [describedby]="element.id"
            (onClick)="advance()"
        >
            {{ 'surveys.navigation.continue' | translate }}
        </r-button>
    </div>
</div>
