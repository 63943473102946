/* eslint-disable @typescript-eslint/no-shadow */
/* istanbul ignore file */

import {Update} from '@ngrx/entity';
import {Action} from '@ngrx/store';

import {SurveyContent} from './survey-content.model';

export enum SurveyContentActionTypes {
    LoadSurveyContents = '[SurveyContent] Load SurveyContents',
    AddSurveyContent = '[SurveyContent] Add SurveyContent',
    UpsertSurveyContent = '[SurveyContent] Upsert SurveyContent',
    AddSurveyContents = '[SurveyContent] Add SurveyContents',
    UpsertSurveyContents = '[SurveyContent] Upsert SurveyContents',
    UpdateSurveyContent = '[SurveyContent] Update SurveyContent',
    UpdateSurveyContents = '[SurveyContent] Update SurveyContents',
    DeleteSurveyContent = '[SurveyContent] Delete SurveyContent',
    DeleteSurveyContents = '[SurveyContent] Delete SurveyContents',
    ClearSurveyContents = '[SurveyContent] Clear SurveyContents'
}

export class LoadSurveyContents implements Action {
    readonly type = SurveyContentActionTypes.LoadSurveyContents;

    constructor(public payload: {ids: string[]}) {}
}

export class AddSurveyContent implements Action {
    readonly type = SurveyContentActionTypes.AddSurveyContent;

    constructor(public payload: {surveyContent: SurveyContent}) {}
}

export class UpsertSurveyContent implements Action {
    readonly type = SurveyContentActionTypes.UpsertSurveyContent;

    constructor(public payload: {surveyContent: SurveyContent}) {}
}

export class AddSurveyContents implements Action {
    readonly type = SurveyContentActionTypes.AddSurveyContents;

    constructor(public payload: {surveyContents: SurveyContent[]}) {}
}

export class UpsertSurveyContents implements Action {
    readonly type = SurveyContentActionTypes.UpsertSurveyContents;

    constructor(public payload: {surveyContents: SurveyContent[]}) {}
}

export class UpdateSurveyContent implements Action {
    readonly type = SurveyContentActionTypes.UpdateSurveyContent;

    constructor(public payload: {surveyContent: Update<SurveyContent>}) {}
}

export class UpdateSurveyContents implements Action {
    readonly type = SurveyContentActionTypes.UpdateSurveyContents;

    constructor(public payload: {surveyContents: Update<SurveyContent>[]}) {}
}

export class DeleteSurveyContent implements Action {
    readonly type = SurveyContentActionTypes.DeleteSurveyContent;

    constructor(public payload: {id: string}) {}
}

export class DeleteSurveyContents implements Action {
    readonly type = SurveyContentActionTypes.DeleteSurveyContents;

    constructor(public payload: {ids: string[]}) {}
}

export class ClearSurveyContents implements Action {
    readonly type = SurveyContentActionTypes.ClearSurveyContents;
}

export type SurveyContentActions =
    | LoadSurveyContents
    | AddSurveyContent
    | UpsertSurveyContent
    | AddSurveyContents
    | UpsertSurveyContents
    | UpdateSurveyContent
    | UpdateSurveyContents
    | DeleteSurveyContent
    | DeleteSurveyContents
    | ClearSurveyContents;
