import {Component, Injector, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {inputMasks} from '@clients/shared/common-forms';
import {InputBase} from '../../input-base.abstract';

@Component({
    selector: 'surveys-element-input-email',
    templateUrl: './element-input-email.component.html',
    styleUrls: ['./element-input-email.component.scss']
})
export class ElementInputEmailComponent extends InputBase<string> implements OnInit {
    public inputMasks = inputMasks;

    /* istanbul ignore next */
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        this.enableAutoSave();

        // add an email validator to our control
        this.control.setValidators([Validators.email]);
    }

    protected transformBeforeSave(value: string): string | undefined {
        // delete the response if the string is blank
        if (!value || !value.length) return undefined;

        return value;
    }

    protected shouldSave(value: string | undefined): boolean {
        // this removes the response from the DB if there is one
        if (value === undefined) return true;

        return this.control.valid;
    }
}
