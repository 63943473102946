import {isString} from 'ngx-cookie';
import {Custom, load} from 'webfontloader';

import {Inject, Injectable} from '@angular/core';
import {LogWarningAction} from '@clients/shared/logging';
import {camelCaseToDashCase, isInternetExplorer, isObject} from '@clients/shared/utilities';
import {Store} from '@ngrx/store';

import {Fonts, Theme} from '../../models/theme/Theme.interface';
import {LOG_NAMES} from '../errors/log-names';
import {CSS_VARS_POLYFILL} from '../surveys.providers';

const variableIndicator = '--var-';

@Injectable({
    providedIn: 'root'
})
export class SurveyThemeService {
    private styles: {[varName: string]: string} = {};
    private themeSet: boolean;
    private isIe = isInternetExplorer;

    constructor(@Inject(CSS_VARS_POLYFILL) private cssVars, private store: Store<any>) {}

    public loadTheme(theme: Theme) {
        // in the case of a survey error, we may redirect to an error page. if when
        // the user tries to recover we load the theme again, it'll break. for that
        // reason we need to keep track of whether we've already set the theme.
        if (this.themeSet) return;

        // pluck out the variables, and fonts then remove them from the theme
        const {variables, fonts, ...themeCopy} = {...theme};

        this.parseNestedItem(null, themeCopy);
        this.setStyles(this.styles, variables);

        this.loadFonts(fonts);
    }

    private loadFonts(fonts?: Fonts) {
        if (this.fontsAreDefined(fonts)) {
            const fontRequest = this.buildFontRequest(fonts);

            load({
                classes: false,
                inactive: () => {
                    this.store.dispatch(
                        new LogWarningAction({
                            type: LOG_NAMES.THEME_FONT_LOAD_FAILED,
                            message: 'all custom fonts failed to load for survey',
                            options: {}
                        })
                    );
                },
                fontinactive: (familyName, fvd) => {
                    this.store.dispatch(
                        new LogWarningAction({
                            type: LOG_NAMES.THEME_FONT_LOAD_FAILED,
                            message: `custom font failed to load for survey.  family: ${familyName}, variant: ${fvd}`,
                            options: {}
                        })
                    );
                },
                custom: fontRequest
            });
        }
    }

    private fontsAreDefined(fonts?: Fonts): boolean {
        return !!(
            fonts &&
            Array.isArray(fonts.families) &&
            fonts.families.length &&
            Array.isArray(fonts.urls) &&
            fonts.urls.length
        );
    }

    private buildFontRequest(fonts: Fonts): Custom {
        return {
            families: fonts.families,
            urls: fonts.urls
        };
    }

    private parseNestedItem(base: string, value: any): void {
        Object.keys(value).forEach((key: string) => {
            const keyWithSeparator = `${!base ? '--' : `${base}-`}${camelCaseToDashCase(key)}`;

            if (isObject(value[key], true)) {
                this.parseNestedItem(keyWithSeparator, value[key]);
            } else {
                this.styles[keyWithSeparator] = value[key];
            }
        });
    }

    private setStyles(styles, variables: any) {
        if (!styles) return;

        const processed = {};
        Object.keys(styles).forEach((key: string) => {
            const processedKey = this.parseKeyName(key);
            processed[processedKey] = this.parseValue(styles[key], variables);
        });

        if (window.navigator && this.isIe(window.navigator.userAgent)) {
            setTimeout(() => {
                this.writeStylesToDom(processed);
            }, 1200);
        } else {
            this.writeStylesToDom(processed);
        }
    }

    private writeStylesToDom(styles: any) {
        this.cssVars({
            shadowDOM: true,
            variables: styles,
            watch: true
        });

        this.themeSet = true;
    }

    /**
     * Remove any special characters, prefixes or anything else we
     * use to make the theme props more readable.
     *
     * Example:
     * buttons: {
     *      __hover: {}
     * }
     * would become --buttons-hover
     *
     * @param {string} key
     * @return {string}
     */
    private parseKeyName(key: string): string {
        return key.replace('__', '');
    }

    /**
     * Checks if the value is actually a variable ref and if so,
     * returns the variable instead of the string.
     * @param value
     * @param variables
     * @returns {any}
     */
    private parseValue(value: any, variables: any): any {
        if (!isString(value)) return value;

        // if variables is not defined, null or not an object, return the value as-is
        if (!variables || !isObject(variables, true)) return value;

        let processed = value;
        let hasVariableRef = true;
        while (hasVariableRef) {
            hasVariableRef = processed.includes(variableIndicator);
            if (hasVariableRef) {
                processed = processed
                    .split(' ')
                    .map((s: string) => {
                        if (!s.includes(variableIndicator)) return s;

                        s = s.replace(variableIndicator, '');

                        return variables[s];
                    })
                    .join(' ');
            }
        }

        return processed;
    }
}
