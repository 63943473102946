import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';

export const LoadingActionTypes = {
    LOADING: type('[loading] begin loading'),
    DONE_LOADING: type('[loading] done loading'),
    CLOSE_LOADER: type('[loading] close loader')
};

export class LoadingAction
    implements
        Action<{
            minimumLoadingTimeInMs?: number;
        }>
{
    public readonly type: string = LoadingActionTypes.LOADING;

    constructor(
        public payload?: {
            // in ms, maximum delay is set in loading.config.ts
            minimumLoadingTimeInMs?: number;
        }
    ) {}
}

export class DoneLoadingAction implements Action {
    public readonly type: string = LoadingActionTypes.DONE_LOADING;
}

export class CloseLoaderAction implements Action {
    public readonly type: string = LoadingActionTypes.CLOSE_LOADER;
}
