import {Component} from '@angular/core';
import {TranslationService} from '@clients/member-direct/translation';

@Component({
    selector: 'member-direct-skip-button',
    styleUrls: ['./skip-button.component.scss'],
    template: ` <button class="skiptocontent">
        {{ translationService.translate('memberDirect.skipToMain') }}
    </button>`
})
export class SkipButtonComponent {
    constructor(public translationService: TranslationService) {}
}
