export enum VALUE_TYPES_ENUM {
    ARRAY = 'array',
    OBJECT = 'object'
}

const values = {
    [VALUE_TYPES_ENUM.ARRAY]: [],
    [VALUE_TYPES_ENUM.OBJECT]: {}
};

export function withValue(expression, valueType: VALUE_TYPES_ENUM) {
    const defaultValue = values[valueType];
    const result = expression();
    /*
     * we want loose equal because that way we are handling null and undefined
     * */
    /* eslint-disable-next-line */
    if (result == undefined) {
        return defaultValue;
    }
    return result;
}
