import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';
import {SurveyLoadedPayload} from '@clients/member-direct/surveys';

export const MemberDirectSurveysActions = {
    SURVEY_IS_PRE_AUTHORIZED: type('[member-direct-surveys] survey is pre-authorized'),
    SURVEY_IS_NOT_PRE_AUTHORIZED: type('[member-direct-surveys] survey is not pre-authorized'),

    SURVEY_LOADED: type('[member-direct-surveys] survey has been loaded')
};

export class SurveyIsPreauthorizedAction implements Action<string> {
    readonly type = MemberDirectSurveysActions.SURVEY_IS_PRE_AUTHORIZED;

    // the JWT
    constructor(public payload: string) {}
}

export class SurveyIsNotPreauthorizedAction implements Action {
    readonly type = MemberDirectSurveysActions.SURVEY_IS_NOT_PRE_AUTHORIZED;
}

export class SurveyLoadedAction implements Action<SurveyLoadedPayload> {
    readonly type = MemberDirectSurveysActions.SURVEY_LOADED;

    constructor(public payload: SurveyLoadedPayload) {}
}
