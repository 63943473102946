<ng-container [ngSwitch]="field.type">
    <div *ngSwitchCase="inputType.DOB" class="input-element item">
        <p>{{ field.label[language] }}</p>

        <div class="inputs">
            <div class="input-element" data-e2e="idv-input-dob">
                <r-masked-input
                    [control]="control"
                    [type]="'text'"
                    [autocomplete]="'bday'"
                    [mask]="inputMasks.date"
                    [pipe]="datePipe"
                    [placeholder]="'MM/DD/YYYY'"
                >
                </r-masked-input>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="inputType.PHONE" class="input-element item">
        <p>{{ field.label[language] }}</p>

        <div class="inputs">
            <div class="input-element" data-e2e="idv-input-phone">
                <r-masked-input
                    [control]="control"
                    [type]="'tel'"
                    [mask]="inputMasks.phone"
                    [placeholder]="'(555) 555-5555'"
                >
                </r-masked-input>
            </div>
        </div>
    </div>
</ng-container>
