import {get} from 'lodash';

import {Component, Input, OnInit} from '@angular/core';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';
import {arrayIsNullUndefinedOrEmpty} from '@clients/shared/utilities';

import {ISurveyRow, ISurveyRowElement} from '../../../models/survey/ISurveyContent';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {SurveyContentService} from '../../services/survey-content.service';

export const defaultWidth = 100;

@Component({
    selector: 'surveys-element-row',
    template: `
        <clients-grid-layout
            [layout]="LAYOUT.ROW"
            [layoutXs]="mobile"
            [layoutSm]="mobile"
            [layoutMd]="LAYOUT.ROW"
            [align]="align"
            [layoutGap]="gap"
        >
            <div
                *ngFor="let ele of elements"
                [fxFlex]="ele.desktopWidth"
                fxFlex.xs="100"
                fxFlex.sm="100"
                fxFlex.md="ele.desktopWidth"
            >
                <surveys-render-survey-element
                    [element]="ele.legacy ? ele : ele.element"
                    [matchChildHeight]="matchChildHeight"
                ></surveys-render-survey-element>
            </div>
        </clients-grid-layout>
    `
})
export class ElementRowComponent implements OnInit {
    /*istanbul ignore next*/
    @Input()
    element: ISurveyRow;
    public LAYOUT = FLEX_LAYOUT_ENUM;

    public align = 'space-between stretch';
    public mobile: FLEX_LAYOUT_ENUM = FLEX_LAYOUT_ENUM.COLUMN;
    public gap = '20px';
    public matchChildHeight = false;
    public elements: SurveyElement[] | ISurveyRowElement[] = [];

    constructor(public surveyContentService: SurveyContentService) {}

    ngOnInit() {
        const {element} = this;
        const align = get(element, 'config.layout.align');
        const mobile = get(element, 'config.layout.mobile');
        const gap = get(element, 'config.layout.gap');
        const matchChildHeight = get(element, 'config.matchChildHeight');

        align && (this.align = align);
        mobile && (this.mobile = mobile);
        gap && (this.gap = gap);
        matchChildHeight && (this.matchChildHeight = matchChildHeight);

        this.processSubElements(this.element);
    }

    /**
     * This function iterates over our row's elements and adds a legacy property
     * to them so we know if it is a new or old style RowElement.
     */
    private processSubElements(element: ISurveyRow): void {
        if (element && !arrayIsNullUndefinedOrEmpty(element.data)) {
            this.elements = element.data.map((ele: ISurveyRowElement | SurveyElement) =>
                this.formatLegacyOrModernSurveyRowElement(ele)
            );
        }
    }

    /**
     * Add some properties to the row's sub-elements.
     */
    private formatLegacyOrModernSurveyRowElement(element: ISurveyRowElement | SurveyElement): any {
        return {
            ...element,
            legacy: this.surveyContentService.isLegacyRowElement(element),
            desktopWidth: this.getElementDesktopWidth(element)
        } as any;
    }

    private getElementDesktopWidth(element: ISurveyRowElement | SurveyElement): number {
        /*
         * @deprecated
         * Using desktopWidth directly attached to the data would be deprecated.
         * It should be part of the 'config' object.
         * Next line of code was added for the sake of compatibility.
         * */
        const legacyDesktopWidth = get(element, 'desktopWidth');
        if (legacyDesktopWidth) return legacyDesktopWidth;
        const desktopWidth = get(element, 'config.layout.desktopWidth');
        return desktopWidth ? desktopWidth : defaultWidth;
    }
}
