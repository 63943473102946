<div *ngIf="blocks | async as blocks" class="sidebar-block-navigation">
    <div class="blocks" role="navigation">
        <ng-container *ngFor="let block of blocks">
            <div
                *ngIf="shouldRender(block)"
                class="block"
                [class.touched]="blockHasBeenSeen(block)"
                [class.unseen]="!blockHasBeenSeen(block) && !blockIsActive(block)"
                [class.active]="blockIsActive(block)"
                [attr.data-e2e]="'sidebar-block-navigation-' + block.id"
                (click)="loadBlock(block)"
                (keydown.enter)="loadBlock(block)"
                [attr.tabindex]="blockHasBeenSeen(block) || blockIsActive(block) ? 0 : null"
                role="button"
                [attr.aria-pressed]="blockIsActive(block)"
            >
                <div class="icon-wrapper">
                    <div class="icon">
                        <div class="check">
                            <img
                                [src]="
                                    blockHasBeenCompleted(block)
                                        ? '/assets/images/icon_whiteCheck.svg'
                                        : '/assets/images/icon_incomplete.svg'
                                "
                                [alt]="blockHasBeenCompleted(block) ? 'Complete' : 'Incomplete'"
                            />
                        </div>
                    </div>
                </div>

                <div *ngIf="block.heading" class="heading">
                    <h4>{{ block.heading[appLanguage] }}</h4>
                </div>
            </div>
        </ng-container>
    </div>
</div>
