import {type} from '@clients/shared/utilities';
import {Action, RouterNavigatePayload} from '@clients/shared/models';
import {SaveClientStatePayload} from '../../models/SaveClientStatePayload.interface';
import {HttpErrorResponse} from '@angular/common/http';

export const MemberDirectActionTypes = {
    RESET: type('[member-direct] reset'),

    ROUTER_NAVIGATE: type('[member-direct] navigate'),

    CLIENT_STATE_SAVED: type('[member-direct] client state saved'),
    CLIENT_STATE_SAVE_FAILED: type('[member-direct] client state save failed'),

    ERROR: type('[member-direct] an error has occurred')
};

export class ResetMemberDirectAction implements Action {
    public readonly type = MemberDirectActionTypes.RESET;
}

export class RouterNavigateAction implements Action<RouterNavigatePayload> {
    public readonly type = MemberDirectActionTypes.ROUTER_NAVIGATE;

    constructor(public payload: RouterNavigatePayload) {}
}

/* istanbul ignore next */
export class ClientStateSavedAction implements Action<SaveClientStatePayload> {
    public readonly type = MemberDirectActionTypes.CLIENT_STATE_SAVED;

    constructor(public payload: SaveClientStatePayload) {}
}

export class ClientStateSaveFailed implements Action<HttpErrorResponse> {
    public readonly type = MemberDirectActionTypes.CLIENT_STATE_SAVE_FAILED;

    constructor(public payload: HttpErrorResponse) {}
}

export class MemberDirectError implements Action<string> {
    public readonly type = MemberDirectActionTypes.ERROR;

    // the error text
    constructor(public payload: string) {}
}
