import {get} from 'lodash';
import {from, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';

import {Inject, Injectable} from '@angular/core';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {ENV_SERVICE} from '@clients/shared/config';
import {LogErrorAction} from '@clients/shared/logging';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';

import {
    ISurveyResponse,
    MultiSelectSurveyResponse,
    SeededSurveyResponse,
    SurveyResponse
} from '../../../models/survey-responses/SurveyResponse.interface';
import {SurveySeededResponse} from '../../../models/survey-responses/SurveySeededResponse.interface';
import {TaggedSurveyResponse} from '../../../models/survey-responses/TaggedSurveyResponse.interface';
import {OptionType} from '../../enum/OptionType.enum';
import {LOG_NAMES} from '../../errors/log-names';
import {BlockService} from '../../services/block.service';
import {SurveyResponseService} from '../../services/survey-response.service';
import {SurveyService} from '../../services/survey.service';
import {getMultipleChoiceAnswersFromOptions} from '../../utilities/get-mutiple-choice-answers-from-options.function';
import {BlocksFacade} from '../blocks/blocks.facade';
import {ElementsFacade} from '../elements/elements.facade';
import {SurveyActionTypes} from '../survey/survey.actions';
import {SurveyFacade} from '../survey/survey.facade';
import {
    DeleteQuestionDataAction,
    DeleteResponseAction,
    DeleteResponseRecordedAction,
    MultiSelectNoneOptionResponseRecordedAction,
    MultiSelectResponseRemoveNoneAction,
    MultiSelectResponsesUpdatedAction,
    QuestionAnsweredAction,
    QuestionDataDeleted,
    RecordResponseAction,
    RecordResponsesAction,
    RemoveResponseAction,
    ResponseLoggedAction,
    ResponseRecordedAction,
    ResponseRecordedErrorAction,
    ResponsesActionTypes,
    ResponsesCompleteAction,
    ResponseSeededAction,
    ResponsesLoggedAction,
    ResponsesRecordedAction,
    ResponseSubmittedAction,
    SaveMultiSelectAnswersAction,
    SeedResponsesAction
} from './responses.actions';
import {ResponsesFacade} from './responses.facade';
import {SurveysResponsesState} from './responses.interface';

@Injectable()
export class ResponsesEffects {
    seedResponses = createEffect(() =>
        //@ts-ignore
        this.actions.pipe(
            ofType(ResponsesActionTypes.SEED_RESPONSES),
            map((action: SeedResponsesAction) => action.payload),
            mergeMap((responses: any) => {
                const actions: any = [];

                Object.keys(responses).forEach((key: string) => {
                    const seededResponse = new SurveySeededResponse(key, responses[key]);
                    actions.push(new ResponseSeededAction(seededResponse));
                });
                return actions;
            })
        )
    );

    // RECORD RESPONSE PIPELINE
    recordResponse = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.RECORD_RESPONSE),
            map((action: RecordResponseAction) => action.payload),
            mergeMap((response: SurveyResponse) => [
                new ResponseRecordedAction(response),
                new ResponseLoggedAction(response)
            ])
        )
    );

    responseRecorded = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.RESPONSE_RECORDED),
            map((action: ResponseRecordedAction) => action.payload),
            map((response: ISurveyResponse) => {
                const activeSurvey = this.surveyFacade.getActive();
                const tags = get(activeSurvey, 'schema.responseTags');
                const defaultResponseTags = get(activeSurvey, 'schema.defaultResponseTags');
                return this.responseService.tagSurveyResponse(response, tags, defaultResponseTags);
            }),
            mergeMap((response: TaggedSurveyResponse) => {
                if (this.pureDevMode) return of(new ResponseSubmittedAction());
                const surveyRecordId = this.surveyFacade.getSurveyRecordId();
                return this.api.recordResponse(surveyRecordId, response).pipe(
                    map(() => new ResponseSubmittedAction()),
                    catchError((err) =>
                        from([
                            new LogErrorAction({
                                type: LOG_NAMES.RESPONSE_SAVE_ERROR,
                                message: 'could not log survey response',
                                options: {surveyRecordId, response, err}
                            }),
                            new ResponseRecordedErrorAction(err)
                        ])
                    )
                );
            })
        )
    );

    // RECORD RESPONSES PIPELINE
    recordResponses = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.RECORD_RESPONSES),
            map((action: RecordResponsesAction) => action.payload),
            mergeMap((responses: SurveyResponse[] | SeededSurveyResponse[]) => [
                new ResponsesRecordedAction(responses),
                new ResponsesLoggedAction(responses)
            ])
        )
    );

    responsesRecorded = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.RESPONSES_RECORDED),
            map((action: ResponsesRecordedAction) => action.payload),
            map((responses: SurveyResponse[]) => {
                const activeSurvey = this.surveyFacade.getActive();
                const tags = get(activeSurvey, 'schema.responseTags');
                const defaultResponseTags = get(activeSurvey, 'schema.defaultResponseTags');

                return responses.map((response: ISurveyResponse) =>
                    this.responseService.tagSurveyResponse(response, tags, defaultResponseTags)
                );
            }),
            mergeMap((responses: TaggedSurveyResponse[]) => {
                if (this.pureDevMode) return of(new ResponseSubmittedAction());

                const surveyRecordId = this.surveyFacade.getSurveyRecordId();

                return this.api.recordResponses(surveyRecordId, responses).pipe(
                    map(() => new ResponseSubmittedAction()),
                    catchError((err) =>
                        from([
                            new LogErrorAction({
                                type: LOG_NAMES.RESPONSE_SAVE_ERROR,
                                message: 'could not log survey responses',
                                options: {surveyRecordId, responses, err}
                            }),
                            new ResponseRecordedErrorAction(err)
                        ])
                    )
                );
            })
        )
    );

    // DELETE RESPONSES PIPELINE
    deleteResponse = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.DELETE_RESPONSE),
            map((action: DeleteResponseAction) => action.payload),
            mergeMap((response: SurveyResponse) => [
                new DeleteResponseRecordedAction(response),
                new ResponseLoggedAction(response)
            ])
        )
    );

    deleteResponseRecorded = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.DELETE_RESPONSE_RECORDED),
            map((action: DeleteResponseRecordedAction) => action.payload),
            mergeMap((response: SurveyResponse) => {
                if (this.pureDevMode) return of(new ResponseSubmittedAction());

                const surveyRecordId = this.surveyFacade.getSurveyRecordId();
                return this.api.deleteResponse(surveyRecordId, response).pipe(
                    map(() => new ResponseSubmittedAction()),
                    catchError((err) =>
                        of(
                            new LogErrorAction({
                                type: LOG_NAMES.RESPONSE_SAVE_ERROR,
                                message: 'could not log survey response',
                                options: {surveyRecordId, response, err}
                            })
                        )
                    )
                );
            })
        )
    );

    // MULTI-SELECT RESPONSES PIPELINE
    multiSelectResponseRecorded = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT),
            map((action: RecordResponseAction) => action.payload),
            mergeMap((response: MultiSelectSurveyResponse) => {
                const actions: any = [new ResponseLoggedAction(response)];
                const {optionType} = response;
                optionType === OptionType.NONE
                    ? actions.push(new MultiSelectNoneOptionResponseRecordedAction(response))
                    : actions.push(new MultiSelectResponseRemoveNoneAction(response.questionId));

                // a response where the user de-selected an option from a multi-select is
                // still something we need to record, but we handle that deselection by
                // removing that response from the responses state. that way, when the
                // responses get submitted, they are overwritten with the correct ones and
                // it is as if the user never answered the way they originally did.
                const shouldRemoveResponse = response.value !== true;
                if (shouldRemoveResponse) actions.push(new RemoveResponseAction(response));

                return [
                    ...actions,
                    // this final action tells any listeners that the state of the answers has
                    // now been updated.
                    new MultiSelectResponsesUpdatedAction(response.questionId)
                ];
            })
        )
    );

    multiSelectResponsesUpdated = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT_UPDATED),
            map((action: MultiSelectResponsesUpdatedAction) => action.payload),
            map((questionId: string) => {
                const responses = this.responsesFacade.getEntities();
                const element = this.elementsFacade.get(questionId);
                const answers = getMultipleChoiceAnswersFromOptions(responses, element);

                return answers && answers.length
                    ? new SaveMultiSelectAnswersAction(answers)
                    : new DeleteQuestionDataAction(questionId);
            })
        )
    );

    /**
     * todo | deprecate in favor of ResponsesRecordedAction https://healthtel.atlassian.net/browse/SB-994
     * @deprecated
     */
    saveMultiselectResponses = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.SAVE_MULTI_SELECT_ANSWERS),
            map((action: SaveMultiSelectAnswersAction) => action.payload),
            map((responses: ISurveyResponse[]) => {
                const activeSurvey = this.surveyFacade.getActive();
                const tags = get(activeSurvey, 'schema.responseTags');
                const defaultResponseTags = get(activeSurvey, 'schema.defaultResponseTags');

                return responses.map((response: ISurveyResponse) =>
                    this.responseService.tagSurveyResponse(response, tags, defaultResponseTags)
                );
            }),
            switchMap((responses: TaggedSurveyResponse[]) => {
                if (this.pureDevMode) return of(new ResponseSubmittedAction());

                const surveyRecordId = this.surveyFacade.getSurveyRecordId();
                const context = this.surveyFacade.getSurveyContext();

                return this.api.recordResponses(surveyRecordId, responses).pipe(
                    map(() => new ResponseSubmittedAction()),
                    catchError((err) =>
                        from([
                            new LogErrorAction({
                                type: LOG_NAMES.RESPONSE_SAVE_ERROR,
                                message: 'could not log survey responses',
                                options: {surveyRecordId, context, responses, err}
                            }),
                            new ResponseRecordedErrorAction(err)
                        ])
                    )
                );
            })
        )
    );

    deleteQuestionData = createEffect(() =>
        this.actions.pipe(
            ofType(ResponsesActionTypes.DELETE_QUESTION_DATA),
            map((action: DeleteQuestionDataAction) => action.payload),
            switchMap((questionId: string) => {
                const surveyRecordId = this.surveyFacade.getSurveyRecordId();
                return this.api.deleteQuestionData(surveyRecordId, questionId).pipe(
                    map(() => new QuestionDataDeleted(questionId)),
                    catchError((err) =>
                        of(
                            new LogErrorAction({
                                type: LOG_NAMES.DELETE_QUESTION_DATA_ERROR,
                                message: 'could not delete question data',
                                options: {surveyRecordId, questionId, err}
                            })
                        )
                    )
                );
            })
        )
    );

    questionAnswered = createEffect(
        () =>
            this.actions.pipe(
                ofType(ResponsesActionTypes.QUESTION_ANSWERED),
                map((action: QuestionAnsweredAction) => action.payload),
                tap((questionId: string) =>
                    this.blockService.questionAnswered(this.blocksFacade.getActive(), questionId)
                )
            ),
        {dispatch: false}
    );

    surveyCompleted = createEffect(() =>
        this.actions.pipe(
            ofType(SurveyActionTypes.SURVEY_COMPLETED),
            map(() => new ResponsesCompleteAction())
        )
    );

    private pureDevMode: boolean;

    constructor(
        private actions: Actions,
        private store: Store<SurveysResponsesState>,
        private api: SurveyService,
        private surveyFacade: SurveyFacade,
        private responsesFacade: ResponsesFacade,
        private responseService: SurveyResponseService,
        private blockService: BlockService,
        private blocksFacade: BlocksFacade,
        private elementsFacade: ElementsFacade,
        @Inject(ENV_SERVICE) envService: MemberDirectEnvService
    ) {
        this.pureDevMode = envService.surveySettings?.pureDevMode;
    }
}
