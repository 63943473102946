import {SurveyElement} from '../survey/ISurveyElement';
import {getElementId} from '../../lib/+state/elements/elements.reducer';
import {OptionType} from '../../lib/enum/OptionType.enum';

export interface ISurveyResponse {
    id: string;
    answerId: string;
    questionId: string;
    value: any;
    created_at: number;
    seeded?: boolean;
}

export class SurveyResponse implements ISurveyResponse {
    public id: string;
    public answerId: string;
    public questionId: string;
    public created_at: number;
    public recorded?: boolean;
    public seeded?: boolean;

    constructor(element: SurveyElement, public value: any, answerId: string = null, createdAt: number = null) {
        this.questionId = getElementId(element);
        // answer id is either the one provided or the question id
        this.answerId = answerId || getElementId(element);
        this.created_at = createdAt || new Date().getTime();

        this.id = this.answerId;
    }
}

export class SeededSurveyResponse implements ISurveyResponse {
    public created_at: number;
    public questionId: string;
    public answerId: string;

    constructor(public id: string, public value: any) {
        this.created_at = new Date().getTime();
        this.questionId = id;
        this.answerId = id;
    }
}

export class MultiSelectSurveyResponse implements ISurveyResponse {
    public questionId: string;
    public answerId: string;
    public optionType: OptionType;
    public created_at: number;

    constructor(public id: string, questionId: string, public value: any, optionType: OptionType) {
        this.answerId = id;
        this.questionId = questionId;
        this.optionType = optionType;
        this.created_at = new Date().getTime();
    }
}
