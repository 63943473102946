/* istanbul ignore file */
import {takeUntil} from 'rxjs/operators';

import {
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {EventTrackAction} from '@clients/shared/analytics';

import {SurveyConfigService} from '../../../lib/services/survey-config.service';
import {ISurveyAccordion} from '../../../models/survey/ISurveyContent';
import {AccordionOpenEvent} from '../../analytics/accordion.events';
import {BaseElement} from '../../base-element.abstract';

@Component({
    selector: 'surveys-element-accordion',
    templateUrl: './element-accordion.component.html',
    styleUrls: ['./element-accordion.component.scss']
})
export class ElementAccordionComponent extends BaseElement implements OnChanges, OnInit {
    @Input()
    public element: ISurveyAccordion;

    @Output()
    public opened: EventEmitter<ISurveyAccordion> = new EventEmitter<ISurveyAccordion>();

    @Output()
    public closed: EventEmitter<ISurveyAccordion> = new EventEmitter<ISurveyAccordion>();

    @ViewChild('contentRegion')
    private contentRegion: ElementRef;

    public active = false;
    public upArrowImageLocation: string;
    public downArrowImageLocation: string;

    constructor(injector: Injector, public surveyConfigService: SurveyConfigService) {
        super(injector);
    }

    ngOnInit() {
        this.surveyConfigService.surveyConfigUpdated.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.upArrowImageLocation = this.surveyConfigService.getUse508ComplianceDefaults()
                ? '/assets/images/arrow_up_508.png'
                : '/assets/images/arrow_up.png';
            this.downArrowImageLocation = this.surveyConfigService.getUse508ComplianceDefaults()
                ? '/assets/images/arrow_down_508.png'
                : '/assets/images/arrow_down.png';
        });
    }
    ngOnChanges() {
        this.active = this.element.active;
    }

    public activate(contentRef = this.contentRegion.nativeElement) {
        this.active = !this.active;
        this.active ? this.opened.emit(this.element) : this.closed.emit(this.element);

        if (this.active) {
            this.dispatchEvent();
        }
        // contentRef.focus();
    }

    private dispatchEvent() {
        const event = new AccordionOpenEvent(this.element);
        this.store.dispatch(new EventTrackAction(event));
    }
}
