/* istanbul ignore file */
import {copyEnvFileValuesTo, ENV_SERVICE, EnvService} from '@clients/shared/config';

import {ISurveySettings} from '@clients/member-direct/survey-common';
import {IIvr} from '../models/IIvr';

export class AdminEnvService extends EnvService {
    public adminGateway: string;
    public apiGateway: string;
    public dhpLiveAgentGateway: string;
    public ivr: IIvr;
    public surveySettings: ISurveySettings;
    public surveyServiceApi: string;

    get adminCompanyApi(): string {
        return `${this.apiGateway}/company-service`;
    }

    get channelServiceVoiceApi(): string {
        return `${this.apiGateway}/channel-service/voice`;
    }

    get liveAgentApi(): string {
        return `${this.dhpLiveAgentGateway}`;
    }

    get programsApi(): string {
        return `${this.adminGateway}/program-service/trusted`;
    }

    get recommenderApi(): string {
        return `${this.adminGateway}/recommender-service/recommender`;
    }

    get personalizationApi(): string {
        return `${this.adminGateway}/program-service/trusted`;
    }

    get ivrApi(): string {
        return `${this.apiGateway}/ivr-service/trusted`;
    }

    get emailApi(): string {
        return `${this.apiGateway}/email-service`;
    }

    get smsApi(): string {
        return `${this.apiGateway}/sms-service`;
    }

    get surveyApi(): string {
        return `${this.apiGateway}/survey-service`;
    }
    get mailApi(): string {
        return `${this.adminGateway}/channel-service/mail/config/`;
    }
    get reportingApi(): string {
        return `${this.apiGateway}/reporting-service/`; //from enrollment-service repo
    }
}

export function adminEnvServiceFactory() {
    // Create env
    const envService = new AdminEnvService();

    copyEnvFileValuesTo(envService);

    return envService;
}

export const AdminEnvServiceProvider = {
    provide: ENV_SERVICE,
    useFactory: adminEnvServiceFactory,
    deps: []
};
