import {ISurveyResponse} from './SurveyResponse.interface';

export class TaggedSurveyResponse {
    // the question id
    id: string;
    // the answer id (usually the same as the question id)
    answerId: string;
    questionId: string;
    value: any;
    created_at: number;
    tags: string[];

    constructor(response: ISurveyResponse, tags: string[]) {
        this.tags = tags;
        this.id = response.id;
        this.answerId = response.answerId || response.id;
        this.questionId = response.questionId;
        this.value = response.value;
        this.created_at = response.created_at;
    }
}
