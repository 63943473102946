import {takeUntil} from 'rxjs/operators';

import {DOCUMENT} from '@angular/common';
import {Component, Inject, Injector, Input, OnChanges} from '@angular/core';
import {select} from '@ngrx/store';

import {selectSelectedElementId} from '../../+state';
import {HighlightElementAction, HighlightElementWithoutScrollAction} from '../../+state/elements/elements.actions';
import {getElementId} from '../../+state/elements/elements.reducer';
import {QuestionAnsweredAction} from '../../+state/responses/responses.actions';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {ISurveyQuestion} from '../../../models/survey/ISurveyQuestion';
import {BaseElement} from '../../base-element.abstract';
import {SurveyElementType} from '../../enum/SurveyElementType';
import {BlockConfigService} from '../../services/block-config.service';

@Component({
    selector: 'surveys-element-survey-question',
    templateUrl: './element-survey-question.component.html',
    styleUrls: ['./element-survey-question.component.scss']
})
export class ElementSurveyQuestionComponent extends BaseElement implements OnChanges {
    @Input() public element: ISurveyQuestion<any>;

    public valid: boolean;
    public questionElement: any;
    public types = SurveyElementType;

    // if true, sub-elements will either get a `selected` or `deselected` class
    public hasSelectedState: boolean;
    public selected: boolean;
    // always display the element as selected when the block is in single question mode
    // work-around for focus issues in single-question display mode
    public shouldAlwaysDisplayAsSelected: boolean;
    /*
     * rootMargin - the sum for top and bottom should be less than 100 -> those 3% are enough to cover the gap that we
     * need to have a smooth transition between the questions.
     * threshold - since the Observer API uses thresholds the API only reports changes to visibility which
     * cross these thresholds.
     * So, what those numbers indicate is that an event will be fired when the target's visibility passes backward or
     * forward through the beginning, 50% mark and the end of the target's visibility.
     * That way we can be confident that a selected question would always appear on the screen.
     * */
    public inViewportOptions = {
        rootMargin: '-30% 0px -68% 0px',
        threshold: [0, 0.0001, 0.5, 0.9999, 1]
    };
    public render = false;

    constructor(
        injector: Injector,
        private blockConfigService: BlockConfigService,
        @Inject(DOCUMENT) private document
    ) {
        super(injector);
    }

    ngOnChanges() {
        // make sure we have what we need to continue
        if (!this.element || !this.element.question) {
            this.valid = false;
            return;
        }

        // first we want to make sure that the `question` prop of the SurveyQuestionElement
        // is one of the accepted types.
        this.valid = this.elementIsValid(this.element.question);

        this.shouldAlwaysDisplayAsSelected = this.blockConfigService.shouldRenderInSingleQuestionMode() || false;

        // if so, build up a new questionElement with the data from element.question but
        // using the SurveyQuestionElement's id
        if (this.valid) {
            this.questionElement = {
                ...this.element.question,
                id: getElementId(this.element)
            };

            this.store.pipe(select(selectSelectedElementId), takeUntil(this.destroyed$)).subscribe((val) => {
                this.hasSelectedState = val && val.length > 0;
                if (this.hasSelectedState) {
                    this.selected = val === getElementId(this.element);
                }
            });
        }
    }

    public questionAnswered() {
        // I don't like this, but I couldn't figure out how else to do it.
        // since answering a question will often trigger the advance to the next question,
        // but clicking on a deselected-question will also select that question, we have a
        // race condition between question answered which fires first due to its position
        // in the dom. since it fires first, it technically selects the next question before
        // the `activateQuestion` function runs. that means that if we check if this question
        // is active before highlighting it again, that check will come back false since it's
        // not technically active.
        //
        // the resulting behavior is that a question is answered, the scroll event begins to scroll
        // to the next question but then the same element is activated by the highlightElement action
        // and the user sees the question they just answered highlighted. by delaying the questionAnswered
        // event we can get the real state of which element is highlighted. this is gross, but it works.

        setTimeout(() => {
            this.store.dispatch(new QuestionAnsweredAction(getElementId(this.element)));
        }, 0);
    }

    public activateQuestion() {
        if (this.hasSelectedState && !this.selected) {
            this.store.dispatch(new HighlightElementAction(getElementId(this.element)));
        }
    }
    public onInViewportChange(inViewport: boolean) {
        if (
            this.selected ||
            !this.blockConfigService.shouldRenderInScrollMode() ||
            !this.blockConfigService.shouldAllowUsersToScroll() ||
            !inViewport
        ) {
            return;
        }

        this.store.dispatch(new HighlightElementWithoutScrollAction(getElementId(this.element)));
    }

    private elementIsValid(element: SurveyElement): boolean {
        if (!element) return false;

        const validTypes = [
            SurveyElementType.MULTIPLE_CHOICE,
            SurveyElementType.MULTIPLE_SELECT,
            SurveyElementType.YES_NO,
            SurveyElementType.INPUT_BIRTHDATE,
            SurveyElementType.INPUT_EMAIL,
            SurveyElementType.INPUT_HEIGHT,
            SurveyElementType.INPUT_WEIGHT,
            SurveyElementType.INPUT_YEAR,
            SurveyElementType.INPUT_PHONE_NUMBER,
            SurveyElementType.INPUT_MONTH,
            SurveyElementType.INPUT_TEXT,
            SurveyElementType.INPUT_LONG_TEXT
        ];

        return validTypes.includes(element.type);
    }
}
