import cssVars from 'css-vars-ponyfill';
// import {InlineSVGModule} from 'ng-inline-svg';

import {LAZY_MAPS_API_CONFIG} from '@agm/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {TranslationModule} from '@clients/member-direct/translation';
import {CommonFormsModule} from '@clients/shared/common-forms';
import {LayoutGridModule} from '@clients/shared/layout-grid';
import {buildCssVariables} from '@clients/shared/utilities';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ScrollToModule, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {InViewportModule} from 'ng-in-viewport';

import {surveyFeatureReducers, surveyFeatureReducerState} from './+state';
import {BlocksEffects} from './+state/blocks/blocks.effects';
import {BlocksFacade} from './+state/blocks/blocks.facade';
import {ElementsEffects} from './+state/elements/elements.effects';
import {ElementsFacade} from './+state/elements/elements.facade';
import {PharmaciesEffects} from './+state/pharmacies/pharmacies.effects';
import {PharmaciesFacade} from './+state/pharmacies/pharmacies.facade';
import {ResponsesEffects} from './+state/responses/responses.effects';
import {ResponsesFacade} from './+state/responses/responses.facade';
import {SurveyContentEffects} from './+state/survey-content/survey-content.effects';
import {SurveyEffects} from './+state/survey/survey.effects';
import {SurveyFacade} from './+state/survey/survey.facade';
import {SurveysFacade} from './+state/surveys.facade';
import {ElementAccordionGroupComponent} from './components-core/element-accordion-group/element-accordion-group.component';
import {ElementAccordionComponent} from './components-core/element-accordion/element-accordion.component';
import {ElementActionBlockComponent} from './components-core/element-action-block/element-action-block.component';
import {ElementButtonComponent} from './components-core/element-button/element-button.component';
import {ElementContainerComponent} from './components-core/element-container/element-container.component';
import {ElementContentIdComponent} from './components-core/element-content-id/element-content-id.component';
import {ElementCounterComponent} from './components-core/element-counter/element-counter.component';
import {ElementCustomComponent} from './components-core/element-custom/element-custom.component';
import {ElementDisclaimerComponent} from './components-core/element-disclaimer/element-disclaimer.component';
import {ElementDynamicContentComponent} from './components-core/element-dynamic-content/element-dynamic-content.component';
import {ElementFootnoteComponent} from './components-core/element-footnote/element-footnote.component';
import {ElementHtmlComponent} from './components-core/element-html/element-html.component';
import {ElementImageComponent} from './components-core/element-image/element-image.component';
import {ElementInputBirthdateComponent} from './components-core/element-input-birthdate/element-input-birthdate.component';
import {ElementInputEmailComponent} from './components-core/element-input-email/element-input-email.component';
import {ElementInputHeightComponent} from './components-core/element-input-height/element-input-height.component';
import {ElementInputMonthComponent} from './components-core/element-input-month/element-input-month.component';
import {ElementInputPhoneNumberComponent} from './components-core/element-input-phone-number/element-input-phone-number.component';
import {ElementInputWeightComponent} from './components-core/element-input-weight/element-input-weight.component';
import {ElementInputYearComponent} from './components-core/element-input-year/element-input-year.component';
import {ElementInputTextComponent} from './components-core/element-input-text/element-input-text.component';
import {ElementInputLongTextComponent} from './components-core/element-input-long-text/element-input-long-text.component';
import {ElementMultipartQuestionComponent} from './components-core/element-multipart-question/element-multipart-question.component';
import {ElementMultipleChoiceComponent} from './components-core/element-multiple-choice/element-multiple-choice.component';
import {ElementMultipleSelectComponent} from './components-core/element-multiple-select-component/element-multiple-select.component';
import {ElementOptionsBlockComponent} from './components-core/element-options-block/element-options-block.component';
import {ElementPanelComponent} from './components-core/element-panel/element-panel.component';
import {ElementQuestionTextComponent} from './components-core/element-question-text/element-question-text.component';
import {ElementRowComponent} from './components-core/element-row/element-row.component';
import {ElementSeparatorComponent} from './components-core/element-separator/element-separator.component';
import {ElementStatsComponent} from './components-core/element-stats/element-stats.component';
import {ElementSurveyQuestionComponent} from './components-core/element-survey-question/element-survey-question.component';
import {ElementYesNoComponent} from './components-core/element-yes-no/element-yes-no.component';
import {RenderSurveyElementComponent} from './components-core/render-survey-element/render-survey-element.component';
import {RenderSurveyComponent} from './components-core/render-survey/render-survey.component';
import {SurveyBlockComponent} from './components-core/survey-block/survey-block.component';
import {ElementFluMindBenderComponent} from './components-custom/element-flu-mind-bender/element-flu-mind-bender.component';
import {ElementPharmacyFinderComponent} from './components-custom/element-pharmacy-finder/element-pharmacy-finder.component';
import {ElementPharmacyListComponent} from './components-custom/element-pharmacy-list/element-pharmacy-list.component';
import {ElementPharmacyPlaceholderComponent} from './components-custom/element-pharmacy-placeholder/element-pharmacy-placeholder.component';
import {ElementPharmacyComponent} from './components-custom/element-pharmacy/element-pharmacy.component';
import {ElementSuggestedCareComponent} from './components-custom/element-suggested-care/element-suggested-care.component';
import {LinkIdInterceptor} from './interceptors/link-id.interceptor';
import {surveysLocale} from './locale/locale';
import {GoogleMapConfigService} from './services/google-map-config.service';
import {SurveyContentService} from './services/survey-content.service';
import {SurveyInstructionsService} from './services/survey-instructions.service';
import {SurveyQuestionService} from './services/survey-question.service';
import {SurveyResponseService} from './services/survey-response.service';
import {SurveyThemeService} from './services/survey-theme.service';
import {SurveyService} from './services/survey.service';
// we provide the following functions to facilitate testing
import {BUILD_CSS_VARIABLES, CSS_VARS_POLYFILL} from './surveys.providers';

@NgModule({
    imports: [
        CommonModule,
        CommonFormsModule,
        StoreModule.forFeature('surveys', surveyFeatureReducers, {
            initialState: surveyFeatureReducerState
        }),
        EffectsModule.forFeature([
            SurveyEffects,
            BlocksEffects,
            ElementsEffects,
            ResponsesEffects,
            PharmaciesEffects,
            SurveyContentEffects
        ]),
        ScrollToModule,
        HttpClientModule,
        LayoutGridModule,
        TranslationModule.forChild([surveysLocale]),
        ScrollToModule,
        InViewportModule,
        MatAutocompleteModule
        // AgmCoreModule.forRoot({})
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        SurveyService,
        SurveyContentService,
        SurveyInstructionsService,
        SurveyResponseService,
        SurveyThemeService,
        SurveyQuestionService,
        ScrollToService,

        SurveyEffects,
        BlocksEffects,
        ElementsEffects,

        SurveysFacade,
        SurveyFacade,
        ElementsFacade,
        BlocksFacade,
        ResponsesFacade,
        PharmaciesFacade,

        // interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LinkIdInterceptor,
            multi: true
        },
        {provide: LOCALE_ID, useValue: 'en-US'},

        // Angular Google Maps
        {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfigService},

        // We provide the following functions via Angular's DI to facilitate
        // easier testing.
        {provide: CSS_VARS_POLYFILL, useValue: cssVars},
        {provide: BUILD_CSS_VARIABLES, useValue: buildCssVariables}
    ],
    declarations: [
        // this is the entry component for all surveys and the only
        // one which should be invoked by a parent app
        RenderSurveyComponent,
        SurveyBlockComponent,
        RenderSurveyElementComponent,

        // inputs
        ElementMultipleChoiceComponent,
        ElementYesNoComponent,

        // content
        ElementHtmlComponent,
        ElementImageComponent,
        ElementButtonComponent,
        ElementContainerComponent,
        ElementCustomComponent,
        ElementPharmacyFinderComponent,
        ElementFluMindBenderComponent,
        ElementAccordionComponent,
        ElementCounterComponent,
        ElementStatsComponent,
        ElementSeparatorComponent,
        ElementFootnoteComponent,
        ElementRowComponent,
        ElementPharmacyListComponent,
        ElementActionBlockComponent,
        ElementPanelComponent,
        ElementDisclaimerComponent,
        ElementContentIdComponent,
        ElementPharmacyComponent,
        ElementAccordionGroupComponent,
        ElementPharmacyPlaceholderComponent,
        ElementSurveyQuestionComponent,
        ElementSuggestedCareComponent,
        ElementQuestionTextComponent,
        ElementMultipleSelectComponent,
        ElementOptionsBlockComponent,
        ElementInputBirthdateComponent,
        ElementInputEmailComponent,
        ElementInputHeightComponent,
        ElementInputWeightComponent,
        ElementInputYearComponent,
        ElementInputPhoneNumberComponent,
        ElementInputMonthComponent,
        ElementInputTextComponent,
        ElementInputLongTextComponent,
        ElementMultipartQuestionComponent,
        ElementDynamicContentComponent
    ],
    exports: [RenderSurveyComponent, RenderSurveyElementComponent]
})
export class SurveysModule {}
