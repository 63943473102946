/* istanbul ignore file */
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {EventEmitter, Input, OnDestroy, OnInit, Output, Directive} from '@angular/core';
import {FormControl} from '@angular/forms';

import {isRequired} from '../utilities/is-required.function';

@Directive()
export abstract class Element implements OnInit, OnDestroy {
    @Input()
    public control: FormControl;

    @Input()
    public label: string;
    @Input()
    public labelPos = 'top';
    @Input()
    public labelClasses: Array<string>;
    @Input()
    public disabled = false;
    @Input()
    public placeholder: string;
    @Input()
    public delay = 0;
    @Input()
    public required = false;
    @Input()
    public e2e: string;

    @Output()
    public update: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit(): void {
        if (this.control) {
            this.parseRequired();
            this.subscribeToChanges();
        }
    }

    private destroyed$: Subject<boolean> = new Subject<boolean>(); // eslint-disable-line

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    private parseRequired() {
        this.required = this.required || isRequired(this.control);
    }

    private subscribeToChanges() {
        this.control.valueChanges
            .pipe(takeUntil(this.destroyed$), debounceTime(this.delay))
            .subscribe((val: any) => this.update.emit(val));
    }
}
