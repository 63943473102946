import {Component, Input} from '@angular/core';

@Component({
    selector: 'r-label',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss']
})
export class LabelComponent {
    @Input()
    public label: string;
    @Input()
    public labelPos = 'left';
    @Input()
    public required = false;
    @Input()
    public e2e: string;

    constructor() {}
}
