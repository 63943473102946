import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {SurveyResponse} from '../../../models/survey-responses/SurveyResponse.interface';
import {SurveysResponsesState} from './responses.interface';

import {ResponsesActionTypes} from './responses.actions';
import {responsesInitialState} from './responses.init';
// reducer functions
import {removeNoneOptionHandler} from './reducer-functions/remove-none-option-handler.function';
import {handleNoneOption} from './reducer-functions/handle-none-option.function';
import {rehydrateResponsesState} from './reducer-functions/rehydrate-responses-state.function';

///////
export const getResponseId = (response: SurveyResponse): string => response.id;
export const responsesAdapter: EntityAdapter<SurveyResponse> = createEntityAdapter<SurveyResponse>({
    selectId: getResponseId
});

export function responsesReducer(state: SurveysResponsesState, action: any): SurveysResponsesState {
    switch (action.type) {
        case ResponsesActionTypes.RESET:
            return responsesInitialState;

        case ResponsesActionTypes.RESPONSE_RECORDED:
        case ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT:
        case ResponsesActionTypes.DELETE_RESPONSE_RECORDED:
        case ResponsesActionTypes.RESPONSE_SEEDED:
            return responsesAdapter.upsertOne(action.payload, state);

        case ResponsesActionTypes.RESPONSES_RECORDED:
            return responsesAdapter.upsertMany(action.payload, state);

        case ResponsesActionTypes.REMOVE_RESPONSE:
            return responsesAdapter.removeOne(getResponseId(action.payload), state);

        case ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT_NONE_OPTION:
            return handleNoneOption(responsesAdapter, state.entities, action, state);

        case ResponsesActionTypes.RESPONSE_RECORDED_MULTI_SELECT_REMOVE_NONE_OPTION:
            return removeNoneOptionHandler(responsesAdapter, state.entities, action, state);

        case ResponsesActionTypes.RESPONSE_LOGGED:
            return {...state, responses: [...state.responses, action.payload]} as SurveysResponsesState;

        case ResponsesActionTypes.RESPONSES_LOGGED:
            return {...state, responses: [...state.responses, ...action.payload]} as SurveysResponsesState;

        case ResponsesActionTypes.RESPONSES_COMPLETE:
            return {...state, complete: true};

        case ResponsesActionTypes.REHYDRATE:
            return rehydrateResponsesState(action, state);

        default:
            return state;
    }
}

export const getComplete = (state: SurveysResponsesState) => state.complete;
export const getAllResponsesInOrder = (state: SurveysResponsesState) => state.responses;
