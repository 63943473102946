import {copyEnvFileValuesTo, ENV_SERVICE} from '@clients/shared/config';
import {AdminEnvService} from '@clients/admin/admin-common';

export class MemberDirectEnvService extends AdminEnvService {
    public surveyServiceApi = '';
    public idvServiceApi = '';
}

export function memberDirectEnvServiceFactory() {
    // Create env
    const envService = new MemberDirectEnvService();

    copyEnvFileValuesTo(envService);

    //SET LOCAL DEV SETTINGS HERE

    // envService.surveySettings.pureDevMode = true;

    // envService.surveySettings.useLocalSurvey = true;
    // envService.surveySettings.activeSurvey = multipartQuestionHra;

    // envService.surveySettings.useLocalTheme = true;
    // envService.surveySettings.activeTheme = humanaNewMemberTheme;

    // envService.surveySettings.activeBlock =  '23e21631-a6e8-4295-a19d-1b62de5c0ab7';

    return envService;
}

export const memberDirectEnvServiceProvider = {
    provide: ENV_SERVICE,
    useFactory: memberDirectEnvServiceFactory,
    deps: []
};
