import {Component} from '@angular/core';
import {SKELETON_SIZE} from '@clients/shared/common-forms';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';

@Component({
    selector: 'surveys-element-pharmacy-placeholder',
    templateUrl: './element-pharmacy-placeholder.component.html',
    styleUrls: ['./element-pharmacy-placeholder.component.scss']
})
export class ElementPharmacyPlaceholderComponent {
    public LAYOUT = FLEX_LAYOUT_ENUM;
    public SKELETON_SIZE = SKELETON_SIZE;

    constructor() {}
}
