import {Component, Injector, Input, OnInit} from '@angular/core';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {BaseElement} from '../../base-element.abstract';
import {select} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Dictionary} from '@ngrx/entity';
import {SurveyContent} from '../../+state/survey-content/survey-content.model';
import {selectSurveyContentEntities} from '../../+state';
import {SurveyContentService} from '../../services/survey-content.service';

@Component({
    selector: 'surveys-element-dynamic-content',
    templateUrl: './element-dynamic-content.component.html',
    styleUrls: ['./element-dynamic-content.component.scss']
})
export class ElementDynamicContentComponent extends BaseElement implements OnInit {
    @Input() public element: SurveyElement;

    // the resulting element should be the same as the input element but with
    // its content replaced by the dynamic content.
    public transformedElement: SurveyElement;

    constructor(injector: Injector, private surveyContentService: SurveyContentService) {
        super(injector);
    }

    ngOnInit() {
        this.validateInputData(this.element);
        this.initializeSubscription(this.element);
    }

    /**
     * Make sure we have a replacement function for the type of element
     * that was used.
     * @throws
     */
    private validateInputData(element: SurveyElement) {
        // element must have a dynamicContentId
        const dynamicContentId = element.dynamicContentId;
        if (!dynamicContentId) {
            throw new Error(
                'Invalid element provided to ElementDynamicContentComponent. No dynamicContentId was listed.'
            );
        }

        // element type must be valid
        const dynamicElementTypeIsValid = this.surveyContentService.dynamicElementTypeIsValid(element.type);
        if (!dynamicElementTypeIsValid)
            throw new Error(
                `No content replacement function specified for element type ${element.type} in ElementDynamicContentComponent`
            );
    }

    private initializeSubscription(element: SurveyElement): void {
        this.store
            .pipe(select(selectSurveyContentEntities), takeUntil(this.destroyed$))
            .subscribe((entities: Dictionary<SurveyContent>) => {
                const surveyContent: SurveyContent = entities[element.dynamicContentId];
                if (surveyContent) {
                    this.transformedElement = this.surveyContentService.replaceElementContent(element, surveyContent);
                }
            });
    }
}
