<div
    *ngIf="element && element.data"
    class="survey-element survey-element-image survey-element-{{ element.id }}"
    [ngClass]="{background: element?.config?.renderAsBackground}"
>
    <h1 class="screenreader-only" *ngIf="element.message && element.message[language]">
        {{ element.message[language] }}
    </h1>
    <div class="image-wrapper" [ngStyle]="wrapperStyles">
        <img
            *ngIf="!element.config?.renderAsBackground"
            [src]="element.data[language]"
            [ngStyle]="imageStyles"
            alt="{{ element.altText ? element.altText[language] : 'Image' }}"
        />
    </div>
</div>
