/* istanbul ignore file */
import {orderBy} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {TranslationService} from '@clients/member-direct/translation';
import {EventTrackAction} from '@clients/shared/analytics';
import {validZipCode} from '@clients/shared/common-forms';
import {select} from '@ngrx/store';

import {selectAllPharmacies, selectPharmaciesLoadedOnce} from '../../+state';
import {LoadPharmaciesAction} from '../../+state/pharmacies/pharmacies.actions';
import {AgmMarker} from '../../../models/mapping/AgmMarker.interface';
import {GeoJsonDataPoint} from '../../../models/mapping/GeoJsonDataPoint.interface';
import {PharmacyFinderElement} from '../../../models/survey/elements/IPharmacyFinderElement';
import {
    LocationServicesAllowed,
    LocationServicesAvailable,
    LocationServicesBlocked,
    LocationServicesNotAvialable,
    LocationWasWrong,
    MapRecentered,
    UserLocationCalculated,
    ZipCodeEntered,
    ZipCodeLookupFailed
} from '../../analytics/pharmacy-finder.events';
import {BaseElement} from '../../base-element.abstract';
import {SurveyService} from '../../services/survey.service';
import {formatPharmacyAddress} from '../../utilities/format-pharmacy-address.function';
import {geoJsonToMarkerConverter} from '../../utilities/geojson-to-marker-converter.function';

export const DELAY_BEFORE_FETCHING_LOCATIONS = 800;
const FAIL = 'ZERO_RESULTS';
export const defaultIcon = '/assets/images/pharmacy-default.png';
export const closestIcon = '/assets/images/pharmacy-closest.png';

@Component({
    selector: 'surveys-element-pharmacy-finder',
    templateUrl: './element-pharmacy-finder.component.html',
    styleUrls: ['./element-pharmacy-finder.component.scss']
})
export class ElementPharmacyFinderComponent extends BaseElement implements OnInit {
    @Input()
    public element: PharmacyFinderElement;

    public pharmaciesLoadedOnce: Observable<boolean>;

    // set to true when the map is ready. swaps map out with skeleton screen.
    public renderMap = true;
    // set to true when the user enables browser location services so that we
    // know to ignore future reports from the service that their location
    // has changed. this is different than supporting geolocation.
    public browserLocationSet = false;
    // set to true when the zip code pop up box is active
    public enteringZipCode = true;
    // set to true when the user's browser supports location services
    public geolocationEnabled = false;
    // set to true when the user's browser does not support location services
    public geolocationNotSupported = false;
    // set to true once the user has manually entered their zip code
    public zipCodeEntered = false;
    // set to the most-recently-opened info window
    public openInfoWindow = null;
    // whether the most recent zip code lookup failed
    public lastZipCodeLookupFailed = false;

    // map rendering options
    public mapOptions = {
        zoom: 14,
        styles: [
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            }
        ],
        disableDefaultUI: true,
        streetViewControl: false
    };

    public currentLocation: {
        lat: number;
        lng: number;
    };

    // our active markers on the map
    public markers: AgmMarker[];
    // our list of the closest pharmacies to the user
    public closestPharmacies;

    // form control for manual zip code input
    public zipCode = new FormControl('', [Validators.required, validZipCode]);

    private pharmacies: Observable<GeoJsonDataPoint[]>;

    // set's default map location to 1 Pierrepont in Bk Heights
    private default = {
        lat: 40.6965458,
        lng: -73.9991505
    };

    // initial center position for the map
    public lat: number = this.default.lat; // eslint-disable-line
    public lng: number = this.default.lng; // eslint-disable-line

    // the center of our map. updated when the map is dragged.
    private center: BehaviorSubject<{
        lat: number;
        lng: number;
    }> = new BehaviorSubject<{lat: number; lng: number}>(null);

    constructor(
        injector: Injector,
        private surveyService: SurveyService,
        public translationService: TranslationService
    ) {
        super(injector);
    }

    ngOnInit() {
        // subscribe to the pharmacies first load
        this.pharmaciesLoadedOnce = this.store.pipe(select(selectPharmaciesLoadedOnce));

        // subscribe to the list of pharmacies
        this.pharmacies = this.store.pipe(select(selectAllPharmacies));

        // update markers when the pharmacies change
        this.pharmacies.pipe(takeUntil(this.destroyed$)).subscribe(this.updateMarkers.bind(this));

        this.center.pipe(debounceTime(DELAY_BEFORE_FETCHING_LOCATIONS)).subscribe(() => {
            const data = this.center.getValue();
            if (!data) return;

            this.store.dispatch(new EventTrackAction(new MapRecentered(data.lat, data.lng)));

            this.loadPharmacies(data.lat, data.lng);
        });

        this.enableLocationServices();
    }

    public centerChanged($event) {
        this.center.next({
            lat: $event.lat,
            lng: $event.lng
        });
    }

    public changeLocation() {
        this.enteringZipCode = true;
        this.store.dispatch(new EventTrackAction(new LocationWasWrong()));
    }

    public cancelLocationChange() {
        if (this.geolocationEnabled || this.zipCodeEntered) {
            this.enteringZipCode = false;
        }
    }

    public centerOnZipCode(): void {
        this.updateZipCodeIfValid();
    }

    public zipCodeKeyUp($event) {
        switch ($event.keyCode) {
            case 13:
                this.updateZipCodeIfValid();
                break;
        }
    }

    public findADoctorClicked() {
        window.open(this.element.data.link[this.language], '_blank');
    }

    public markerClicked(infoWindow) {
        if (this.openInfoWindow) {
            // we need to catch errors in case the close event was
            // fired on an info window that no longer exists
            try {
                this.openInfoWindow.close();
            } catch (e) {
                this.openInfoWindow = null;
            }
        }

        if (infoWindow !== this.openInfoWindow) {
            this.openInfoWindow = infoWindow;
        }
    }

    public markerTrackByFn(marker) {
        return marker.id;
    }

    private updateMarkers(data: GeoJsonDataPoint[]) {
        const orderedByClosest: GeoJsonDataPoint[] = orderBy(data, ['properties.distanceInMiles'], ['asc']);
        const convertedMarkers: AgmMarker[] = geoJsonToMarkerConverter(data);

        // remove any pharmacies with a distanceInMiles of null and take the closest
        // three to the user's current location
        this.closestPharmacies = orderedByClosest.filter((p) => p.properties.distanceInMiles !== null).slice(0, 3);
        const idsOfClosestMarkers = this.closestPharmacies.map((m) => m.id);

        this.markers = convertedMarkers.map((marker: AgmMarker) => ({
            ...marker,
            icon: this.getMarkerIcon(marker, idsOfClosestMarkers),
            formattedAddress: formatPharmacyAddress(marker.address)
        }));
    }

    private getMarkerIcon(marker: AgmMarker, idsOfClosestMarkers: string[]): string {
        return idsOfClosestMarkers.includes(marker.id) ? closestIcon : defaultIcon;
    }

    private updateZipCodeIfValid() {
        if (this.zipCode.invalid) return;

        const zipCode: string = this.zipCode.value;
        this.updateLocationBasedOnZipCode(zipCode);
        this.store.dispatch(new EventTrackAction(new ZipCodeEntered(zipCode)));
    }

    private updateLocationBasedOnZipCode(zipCode: string) {
        this.surveyService.zipCodeToLatAndLong(zipCode).then(
            (res: any) => {
                if (res.status === FAIL) {
                    this.zipCodeLookupFailed(zipCode);
                    return;
                }

                const lat = res.results[0].geometry.location.lat();
                const lng = res.results[0].geometry.location.lng();
                this.enteringZipCode = false;
                this.zipCodeEntered = true;
                this.resetMap(lat, lng);
                this.setCurrentLocation(lat, lng);
                this.zipCode.reset();
                this.lastZipCodeLookupFailed = false;
            },
            () => {
                this.zipCodeLookupFailed(zipCode);
            }
        );
    }

    private zipCodeLookupFailed(zipCode) {
        this.lastZipCodeLookupFailed = true;
        this.store.dispatch(new EventTrackAction(new ZipCodeLookupFailed(zipCode)));
    }

    private resetMap(lat: number, lng: number) {
        if (lat && lng) {
            this.lat = lat;
            this.lng = lng;
            this.loadPharmacies(lat, lng);
        }
    }

    private setCurrentLocation(lat: number, lng: number) {
        if (lat && lng) this.currentLocation = {lat, lng};
    }

    private loadPharmacies(lat, lng) {
        this.store.dispatch(new LoadPharmaciesAction({lat, lng}));
    }

    private enableLocationServices() {
        if (navigator.geolocation) {
            this.store.dispatch(new EventTrackAction(new LocationServicesAvailable()));

            this.geolocationNotSupported = false;
            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    this.enteringZipCode = false;
                    this.geolocationEnabled = true;
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                    this.browserLocationSet = true;
                    this.loadPharmacies(this.lat, this.lng);
                    this.setCurrentLocation(this.lat, this.lng);
                    this.store.dispatch(new EventTrackAction(new LocationServicesAllowed()));
                    this.store.dispatch(new EventTrackAction(new UserLocationCalculated(this.lat, this.lng)));
                },
                () => {
                    this.geolocationEnabled = false;
                    this.enteringZipCode = true;
                    this.resetMapToDefaults();
                    this.store.dispatch(new EventTrackAction(new LocationServicesBlocked()));
                }
            );
        } else {
            this.store.dispatch(new EventTrackAction(new LocationServicesNotAvialable()));
            this.enteringZipCode = true;
            this.geolocationNotSupported = true;
            this.resetMapToDefaults();
        }
    }

    private resetMapToDefaults() {
        this.lat = this.default.lat;
        this.lng = this.default.lng;
        this.renderMap = true;
    }
}
