import {Component, Input} from '@angular/core';
import {ElementBaseComponent} from '../elements-base/element-base.component';
import {FLEX_LAYOUT_ENUM} from '../../enum/layout.enum';

/*
 * Flex-layout angular
 * The max-width is 100
 * */
@Component({
    styles: ['.fb-auto { flex-basis: auto!important; }'], // fixing IE 11 issue with flex width
    selector: 'clients-grid-layout',
    template: `
        <div
            fxLayout="{{ layout }}"
            fxLayoutAlign="{{ align }}"
            fxLayoutAlign.xs="{{ alignXs }}"
            fxLayoutAlign.sm="{{ alignSm }}"
            fxLayout.xs="{{ layoutXs }}"
            fxLayout.sm="{{ layoutSm }}"
            fxLayoutGap="{{ layoutGap }}"
            fxFlexFill
            fxFlex.xs="{{ xsWidth }}"
            fxFlex.sm="{{ smWidth }}"
            fxFlex.md="{{ mdWidth }}"
            fxFlex.lg="{{ lgWidth }}"
            fxHide.xs="{{ hideXs }}"
            fxHide.sm="{{ hideSm }}"
            fxHide.md="{{ hideMd }}"
            fxHide.lg="{{ hideLg }}"
            ngClass="fb-auto"
            [ngStyle]="customStyles"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class LayoutComponent extends ElementBaseComponent {
    @Input()
    public layout: FLEX_LAYOUT_ENUM;
    @Input()
    public align?: string;
    @Input()
    public alignXs?: string;
    @Input()
    public alignSm?: string;
    @Input()
    public layoutXs?: FLEX_LAYOUT_ENUM = FLEX_LAYOUT_ENUM.COLUMN;
    @Input()
    public layoutSm?: FLEX_LAYOUT_ENUM = FLEX_LAYOUT_ENUM.COLUMN;
    @Input()
    public layoutMd?: FLEX_LAYOUT_ENUM = FLEX_LAYOUT_ENUM.ROW;
    @Input()
    public layoutGap? = '20px';
    @Input()
    public customStyles? = '';

    @Input()
    public xsWidth? = 100;
    @Input()
    public smWidth? = 100;
    @Input()
    public mdWidth? = 100;
    @Input()
    public lgWidth? = 100;
    @Input()
    public xlgWidth? = 100;

    @Input()
    public hideXs? = false;
    @Input()
    public hideSm? = false;
    @Input()
    public hideMd? = false;
    @Input()
    public hideLg? = false;

    constructor() {
        super();
    }
}
