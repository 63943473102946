/* istanbul ignore file */
export class SurveySeededResponse {
    public created_at: number;
    public seeded = true;

    constructor(public id: string, public value: any) {
        if (value === 'true') this.value = true;
        if (value === 'false') this.value = false;

        this.created_at = new Date().getTime();
    }
}
