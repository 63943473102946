import {IMultilingualDataOption, ISurveyMultipleChoiceQuestion} from '../../models/survey/ISurveyQuestion';
import {Dictionary} from '@ngrx/entity';
import {ISurveyResponse, SurveyResponse} from '../../models/survey-responses/SurveyResponse.interface';
import {getMultipleChoiceQuestionOptions} from './get-multiple-choice-question-options.function';

export function getMultipleChoiceAnswersFromOptions(
    responses: Dictionary<ISurveyResponse>,
    element: ISurveyMultipleChoiceQuestion
): SurveyResponse[] {
    const options = getMultipleChoiceQuestionOptions(element);
    if (!options || !options.length) return [];

    return options
        .filter((option: IMultilingualDataOption) => option.id in responses)
        .map((option: IMultilingualDataOption) => {
            const response = responses[option.id];

            return new SurveyResponse(element, response.value, option.id, response.created_at);
        });
}
