import {IdvReducerState} from './idv.interfaces';

export const idvInitialState: IdvReducerState = {
    enrollment_id: null,
    questions: null,
    requiresVerification: false,
    verified: false,
    posting: false,
    locked: false,
    failed: false
};
