import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'member-direct-survey-short-link-redirect',
    templateUrl: './survey-short-link-redirect.component.html',
    styleUrls: ['./survey-short-link-redirect.component.css']
})
export class SurveyShortLinkRedirectComponent implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        const context = this.route.snapshot.paramMap.get('context');
        context ? this.redirect(context) : this.fail();
    }

    private redirect(context: string) {
        const queryParams = this.route.snapshot.queryParams;
        this.router.navigate([`/survey/${context}`], queryParams ? {queryParams} : {});
    }

    private fail() {
        this.router.navigateByUrl('error');
    }
}
