import {createFeatureSelector, createSelector} from '@ngrx/store';
import {LoadingReducerState} from './loading.interfaces';

export const selectLoadingState = createFeatureSelector<LoadingReducerState>('loading');

import * as fromLoading from './loading.reducer';

// selectors
export const selectActive = createSelector(selectLoadingState, fromLoading.getActive);

export const selectLoaderCanCloseAt = createSelector(selectLoadingState, fromLoading.getLoaderCanCloseAt);
