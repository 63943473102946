<div *ngIf="element" class="surveys-element-input-height" role="group">
    <surveys-element-question-text
        *ngIf="multipartQuestionHeader"
        [text]="multipartQuestionHeader"
        [textSize]="'small'"
        [classes]="['multipart-label']"
    ></surveys-element-question-text>
    <label for="element.id">
        <div class="label">
            <surveys-element-question-text
                [id]="element.id"
                [required]="element.required"
                [text]="element.label[language]"
            ></surveys-element-question-text>
        </div>

        <div class="inputs">
            <div class="input-element has-suffix feet">
                <input
                    #firstFocus
                    [class.invalid]="feet.touched && feet.invalid"
                    onkeypress="return /[0-7]/i.test(event.key)"
                    mask="0"
                    [attr.aria-describedby]="multipartQuestionId ? multipartQuestionId : null"
                    tabindex="{{ getTabIndex(0) }}"
                    [formControl]="feet"
                    (keydown)="keyMonitor($event)"
                />
                <div class="input-suffix"><p>ft</p></div>
            </div>

            <div class="input-element has-suffix inches">
                <input
                    #lastFocus
                    [class.invalid]="inches.touched && inches.invalid"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    (input)="checkInches($event)"
                    [attr.aria-describedby]="multipartQuestionId ? multipartQuestionId : null"
                    tabindex="{{ getTabIndex(0) }}"
                    [formControl]="inches"
                    (keydown)="keyMonitor($event)"
                />
                <div class="input-suffix"><p>in</p></div>
            </div>
        </div>
    </label>
    <div class="button-element">
        <r-button
            *ngIf="showProceedActions()"
            class="continue-button"
            [e2e]="'button-' + element.id + '-continue'"
            [affirmative]="true"
            [negative]="false"
            [labeledby]="element.id"
            [describedby]="element.id"
            (onClick)="advance()"
        >
            {{ 'surveys.navigation.continue' | translate }}
        </r-button>
    </div>
</div>
