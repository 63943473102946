/* istanbul ignore file */
import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseElement} from '../../base-element.abstract';
import {ISurveyCustom} from '../../../models/survey/ISurveyContent';
import {SurveyCustomElementType} from '../../enum/SurveyCustomElementType.enum';
import {isObject} from '@clients/shared/utilities';

@Component({
    selector: 'surveys-element-custom',
    templateUrl: './element-custom.component.html',
    styleUrls: ['./element-custom.component.scss']
})
export class ElementCustomComponent extends BaseElement implements OnInit {
    @Input()
    public element: ISurveyCustom;

    public customElement: SurveyCustomElementType;
    public surveyCustomElementType = SurveyCustomElementType;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.customElement = isObject(this.element.data, true)
            ? (this.element.data as {type: SurveyCustomElementType}).type
            : (this.element.data as SurveyCustomElementType);
    }
}
