import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {COMPANY_LOGO_SIZE} from '@clients/member-direct/surveys';

@Component({
    selector: 'member-direct-company-logo',
    template: `
        <img
            *ngIf="source || backupSource"
            [src]="source || backupSource"
            [alt]="logoAltText"
            [class.svg]="logoIsSvg"
            [ngClass]="logoSize"
        />
    `,
    styleUrls: ['./company-logo.component.scss']
})
export class CompanyLogoComponent implements OnInit, OnChanges {
    @Input()
    public source: string;

    @Input()
    public logoAltText: string;

    // todo | get rid of this once we fully deprecate options https://healthtel.atlassian.net/browse/SB-989
    // @deprecated
    @Input()
    public backupSource?: string;

    @Input()
    public logoSize: COMPANY_LOGO_SIZE;

    public logoIsSvg: boolean;

    constructor() {}

    ngOnInit() {
        this.logoSize = this.logoSize || COMPANY_LOGO_SIZE.small;
    }

    ngOnChanges() {
        this.setStyles();
    }

    private setStyles() {
        const src = this.source || this.backupSource;

        /* istanbul ignore else */
        if (src) this.logoIsSvg = src.indexOf('.svg') !== -1;
    }
}
