<div class="quote">
    <clients-grid-layout
        style="max-width: 100%"
        [layoutGap]="0"
        [align]="'center center'"
        [layout]="LAYOUT.COLUMN"
        [xsWidth]="100"
        [smWidth]="100"
    >
        <div class="symbol"><img [src]="imageLocation" alt="Quote" style="width: 40px" /></div>
        <div class="text">
            <p>{{ quote }}</p>
        </div>
        <div class="profile-image" *ngIf="authorImage"><img [src]="authorImage" [alt]="authorDescription" /></div>
        <div class="profile-data">
            <h2>{{ author }}</h2>
            <h3>{{ authorDescription }}</h3>
        </div>
    </clients-grid-layout>
</div>
