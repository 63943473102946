import {Injectable} from '@angular/core';
import {selectPharmacyIds, selectPharmacyEntities, selectAllPharmacies, selectPharmacyTotal} from '../index';
import {Store} from '@ngrx/store';
import {SurveysPharmaciesState} from './pharmacies.interfaces';
import {StoreDataFacade} from '@clients/shared/utilities';
import {GeoJsonDataPoint} from '../../../models/mapping/GeoJsonDataPoint.interface';

@Injectable()
export class PharmaciesFacade extends StoreDataFacade<SurveysPharmaciesState> {
    constructor(store: Store<SurveysPharmaciesState>) {
        super(store, selectPharmacyIds, selectPharmacyEntities, selectAllPharmacies, selectPharmacyTotal);
    }

    public pharmacyHasBeenLoaded(id: string | number): boolean {
        const entities = this.getEntities();
        if (!entities) return false;

        return id in entities;
    }

    public getListOfRemovedPharmacies(data: GeoJsonDataPoint[]) {
        const responseIds = data.map((p) => p.id);

        return this.getArray().filter((p) => !responseIds.includes(p.id));
    }
}
