<div class="r-element r-input" *ngIf="control">
    <r-label [label]="label" [required]="required" [labelPos]="labelPos">
        <r-masked-input
            *ngIf="parsedMask"
            #input
            class="r-input-input {{ e2e ? '--js-element-' + e2e : '' }}"
            [class.invalid]="control.touched && control.invalid"
            title="{{ label || 'Text Input' }}"
            type="{{ parsedType }}"
            tabindex="{{ tabEnabled ? '0' : '-1' }}"
            [class.ssn]="mask === 'ssn'"
            [class.phone]="mask === 'phone'"
            [mask]="parsedMask"
            [formControl]="control"
            (keydown)="keyUp.emit($event)"
        />

        <input
            *ngIf="!parsedMask"
            #input
            class="r-input-input {{ e2e ? '--js-element-' + e2e : '' }}"
            title="{{ label || 'Text Input' }}"
            type="{{ parsedType }}"
            tabindex="{{ tabEnabled ? '0' : '-1' }}"
            [formControl]="control"
            (keydown)="keyUp.emit($event)"
        />
    </r-label>
</div>
