import {Inject, Injectable} from '@angular/core';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {DisableSurveyResumeAction} from '@clients/member-direct/surveys';
import {TranslationService} from '@clients/member-direct/translation';
import {ENV_SERVICE} from '@clients/shared/config';
import {Store} from '@ngrx/store';

@Injectable()
export class AppService {
    private surveyResumeDisabled = false;
    constructor(
        private store: Store<any>,
        private translationService: TranslationService,
        /* istanbul ignore if  */
        @Inject(ENV_SERVICE) envService: MemberDirectEnvService
    ) {
        this.surveyResumeDisabled = !envService.surveySettings.resumeSurvey;
    }

    public bootstrap() {
        if (this.surveyResumeDisabled) {
            this.store.dispatch(new DisableSurveyResumeAction());
        }

        this.translationService.configure();
    }
}
