import {markResponseEntitiesAsRecorded} from '../../../utilities/mark-response-entities-as-recorded.function';
import {SurveyResponse} from '../../../../models/survey-responses/SurveyResponse.interface';
import {Dictionary} from '@ngrx/entity';

export function combineClientStateResponsesWithExistingResponses(
    clientStateResponses: Dictionary<SurveyResponse>,
    seededResponses: Dictionary<SurveyResponse>
): Dictionary<SurveyResponse> {
    // our responses are those which were saved in the client state
    // and if that doesn't exist, an empty object.
    const entities = clientStateResponses || {};

    // we want to overwrite any responses that have the same ID with
    // the value from the store which came from a seeded response.
    if (seededResponses) {
        const seededKeys = Object.keys(seededResponses);
        if (seededKeys && seededKeys.length) {
            seededKeys.forEach((id: string) => {
                entities[id] = seededResponses[id];
            });
        }
    }

    return markResponseEntitiesAsRecorded(entities);
}
