<member-direct-skip-button (click)="skipToContent()"></member-direct-skip-button>
<div class="member-direct-page member-direct-idv">
    <div class="logo">
        <member-direct-company-logo [source]="logo | async" [logoSize]="'medium'" [logoAltText]="logoAltText">
        </member-direct-company-logo>
    </div>

    <div #mainView id="maincontent" tabindex="-1" class="raised-panel">
        <r-banner [imageSrc]="bannerUrl" [imageAltText]="bannerAltText"></r-banner>
        <idv-verification [language]="language"> </idv-verification>
    </div>

    <div class="language" *ngIf="languageDropdownData.getValue().length > 1">
        <member-direct-language-switcher
            [languagesData]="languageDropdownData.getValue()"
            [activeLanguage]="language$ | async"
            (changeHandler)="onChangeHandler($event)"
            [dropdownPosition]="dropdownPosition"
        ></member-direct-language-switcher>
    </div>
</div>
