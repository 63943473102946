import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Element} from '../element.abstract';
import {INPUT_MASKS, inputMasks} from '../../enum/input-masks';

// todo | we probably don't want to support all of these https://healthtel.atlassian.net/browse/SB-983
const validTypes = [
    'color',
    'date',
    'datetime',
    'datetime-local',
    'email',
    'month',
    'number',
    'range',
    'search',
    'tel',
    'time',
    'url',
    'week'
];
const defaultType = 'text';

@Component({
    selector: 'r-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent extends Element implements OnInit, OnChanges {
    @Input()
    public type: string;
    @Input()
    public mask: string;
    @Input()
    public shouldFocus = false;
    @Input()
    public tabEnabled = false;
    @Output()
    public keyUp: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('input') private input: ElementRef;

    public parsedMask: {mask: any} = null;
    public parsedType;

    ngOnInit() {
        super.ngOnInit();

        this.parseType();
        if (this.mask) this.parseMask();
    }

    ngOnChanges(changes) {
        if (changes.shouldFocus && !changes.shouldFocus.previousValue && changes.shouldFocus.currentValue === true) {
            this.input.nativeElement.focus();
        }
    }

    private parseType() {
        this.parsedType = validTypes.includes(this.type) ? this.type : defaultType;
    }

    private parseMask() {
        let mask;

        // strings are @deprecated
        switch (this.mask) {
            case INPUT_MASKS.PHONE:
            case 'phone':
                mask = inputMasks.phone;
                break;
            case INPUT_MASKS.SSN:
            case 'ssn':
                mask = inputMasks.ssn;
                break;
            case INPUT_MASKS.ZIP:
            case 'zip':
                mask = inputMasks.zip;
                break;
            case INPUT_MASKS.EMAIL:
            case 'email':
                mask = inputMasks.email;
                break;
            default:
                console.error('Invalid mask type');
                mask = null;
                break;
        }

        if (mask) this.parsedMask = {mask};
    }
}
