import {get} from 'lodash';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {SKIP_AUTH_HEADER} from '@clients/shared/auth';
import {ENV_SERVICE} from '@clients/shared/config';
import {select, Store} from '@ngrx/store';

import {selectSurveyValid} from '../+state';
import {SurveysCombinedReducerState} from '../+state/interface';
import * as careSuggestions from '../../data/care-suggestions.v1.json';
import {ISurveyResponse} from '../../models/survey-responses/SurveyResponse.interface';
import {SurveyResponsePayload} from '../../models/survey-responses/SurveyResponsePayload.interface';
import {TaggedSurveyResponse} from '../../models/survey-responses/TaggedSurveyResponse.interface';
import {buildSurveyResponsesPayload} from '../utilities/build-survey-responses-payload.function';
import {MockService} from '@clients/shared/testing';

export const surveyEndpoints = (api?: string) => ({
    bootstrap: (context: string) => `${api}/bootstrap/${context}`,
    show: (surveyRecordId: string) => `${api}/surveys/${surveyRecordId}/initiate`,
    recordResponse: (surveyConfigId: string) => `${api}/surveys/${surveyConfigId}/results`,
    deleteResponse: (surveyConfigId: string, questionId) => `${api}/surveys/${surveyConfigId}/results/${questionId}`,
    pharmacyLocations: (surveyRecordId: string) => `${api}/surveys/${surveyRecordId}/locations`,
    suggestedCare: (surveyRecordId: string) => `${api}/surveys/${surveyRecordId}/recommendations`
});

@Injectable()
export class SurveyService {
    private geocoder;
    private geocoderOkResponse;
    private geocoderLoaded: boolean;

    private api: string;
    private geocodeApiKey: string;
    private pureDevMode: boolean;
    private useLocalSuggestionData: boolean;
    constructor(
        @Inject(ENV_SERVICE) envService: MemberDirectEnvService,
        private store: Store<SurveysCombinedReducerState>,
        private http: HttpClient
    ) {
        this.api = envService.surveyServiceApi ? envService.surveyServiceApi : '';
        this.geocodeApiKey = envService.googleConfig.maps.apiKey;
        this.pureDevMode = envService.surveySettings.pureDevMode;
        this.useLocalSuggestionData = envService.surveySettings.useLocalCareSuggestions;
        this.setGeocoder();
    }

    /**
     * Validate and bootstrap a survey with some basic metadata,
     * company options, enrollment ID, etc...
     *
     * @param {string} context
     * @returns {Observable<Object>}
     */
    public bootstrap(context: string): Observable<Object> {
        const headers = new HttpHeaders({
            [SKIP_AUTH_HEADER]: ''
        });
        return this.http.get(surveyEndpoints(this.api).bootstrap(context), {headers: headers});
    }

    public show(surveyRecordID: string): Observable<Object> {
        return this.http.get(surveyEndpoints(this.api).show(surveyRecordID));
    }

    /**
     * Record a single response
     * @deprecated
     */
    public recordResponse(surveyRecordId: string, response: TaggedSurveyResponse): Observable<Object> {
        const wrapInArray = [response];
        const payload: SurveyResponsePayload[] = buildSurveyResponsesPayload(wrapInArray);

        return this.submitResponses(surveyRecordId, payload);
    }

    /**
     * Record multiple responses in our database
     */
    public recordResponses(surveyRecordId: string, responses: TaggedSurveyResponse[]): Observable<Object> {
        const payload = buildSurveyResponsesPayload(responses);

        return this.submitResponses(surveyRecordId, payload);
    }

    /**
     * Remove a response from the backend
     */
    public deleteResponse(surveyConfigId: string, response: ISurveyResponse): Observable<Object> {
        const endpoint = surveyEndpoints(this.api).deleteResponse(surveyConfigId, response.id.toString());
        return this.http.delete(endpoint);
    }

    /**
     * Remove all responses associated with a question.
     */
    public deleteQuestionData(surveyConfigId: string, questionId: string): Observable<Object> {
        const endpoint = surveyEndpoints(this.api).deleteResponse(surveyConfigId, questionId);

        return this.http.delete(endpoint);
    }

    /**
     * Given a latitude and longitude, query our Survey Service and retrieve
     * a list of nearby pharmacies.
     */
    public pharmacyLocations(surveyRecordID: string, lat: number, long: number): Observable<Object> {
        return this.http.get(surveyEndpoints(this.api).pharmacyLocations(surveyRecordID), {
            params: {
                startingLatitude: `${lat}`,
                startingLongitude: `${long}`,
                distanceLimitInMiles: '2'
            }
        });
    }

    /**
     * Return care suggestions for a survey record.
     */
    public careSuggestions(surveyRecordId: string): Observable<Object> {
        if (this.pureDevMode || this.useLocalSuggestionData) return of(careSuggestions);

        const endpoint = surveyEndpoints(this.api).suggestedCare(surveyRecordId);

        return this.http.get(endpoint);
    }

    public zipCodeToLatAndLong(zipCode: string): Promise<any> {
        /* istanbul ignore else  */
        if (!this.geocoderLoaded) this.setGeocoder();

        return new Promise<any>((resolve, reject) => {
            const payload = {
                componentRestrictions: {
                    country: 'US',
                    postalCode: zipCode
                }
            };
            this.geocoder.geocode(payload, (results, status) => {
                if (status === this.geocoderOkResponse) {
                    resolve({
                        results,
                        status
                    });
                } else {
                    reject(status);
                }
            });
        });
    }

    /**
     * Needs to be moved to a data access facade
     * @deprecated
     */
    public isValid(): boolean {
        let valid = false;
        this.store.pipe(select(selectSurveyValid), take(1)).subscribe((val: boolean): boolean => (valid = val));

        return valid;
    }

    /**
     * Make the API call to record responses.
     * todo | merge into function once recordResponse is phased out https://healthtel.atlassian.net/browse/SB-1009
     * @deprecated
     */
    private submitResponses(surveyRecordId: string, payload: SurveyResponsePayload[]) {
        const endpoint = surveyEndpoints(this.api).recordResponse(surveyRecordId);

        return this.http.post(endpoint, payload);
    }

    private setGeocoder() {
        /* istanbul ignore else  */
        if (get(window, 'google.maps')) {
            this.geocoder = new (window as any).google.maps.Geocoder();
            this.geocoderOkResponse = (window as any).google.maps.GeocoderStatus.OK;
            this.geocoderLoaded = true;
        }
    }
}
