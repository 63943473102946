import {MemberDirectReducerState} from './app.interface';
import {memberDirectInitialState} from './app.init';
import {MemberDirectActionTypes} from './app.actions';

export function memberDirectReducer(state: MemberDirectReducerState, action: any): MemberDirectReducerState {
    switch (action.type) {
        case MemberDirectActionTypes.RESET:
            return {...memberDirectInitialState};

        case MemberDirectActionTypes.ERROR:
            return {...state, errorText: action.payload};

        default: {
            return state;
        }
    }
}

export const getErrorText = (state: MemberDirectReducerState): string => state.errorText;
