<ng-container *ngIf="name === 'hamburger'">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18">
        <path
            d="M0 3c0-.55.45-1 1-1h22c.55 0 1 .45 1 1s-.45 1-1 1H1c-.55 0-1-.45-1-1zm23 11H1c-.55 0-1 .45-1 1s.45 1 1 1h22c.55 0 1-.45 1-1s-.45-1-1-1zm0-6H1c-.55 0-1 .45-1 1s.45 1 1 1h22c.55 0 1-.45 1-1s-.45-1-1-1z"
        />
    </svg>
</ng-container>

<ng-container *ngIf="name === 'language'">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <defs>
            <style>
                .cls-1 {
                    fill: #bec1c3;
                }
            </style>
        </defs>
        <path
            class="cls-1"
            d="M245,1171a10,10,0,1,1-10,10A10.008,10.008,0,0,1,245,1171Zm.455.966v4.262h3.217a11.068,11.068,0,0,0-.682-1.776A4.21,4.21,0,0,0,245.456,1171.964Zm-.909,0a4.211,4.211,0,0,0-2.536,2.486,11.065,11.065,0,0,0-.682,1.776h3.218Zm3.083.334a7.446,7.446,0,0,1,1.172,1.747,12.3,12.3,0,0,1,.817,2.18h3.125A9.1,9.1,0,0,0,247.629,1172.3Zm-5.256,0a9.1,9.1,0,0,0-5.114,3.928h3.125a12.325,12.325,0,0,1,.817-2.18A7.459,7.459,0,0,1,242.373,1172.3Zm10.86,4.837h-3.4a18.511,18.511,0,0,1,.384,3.409h3.857A9.033,9.033,0,0,0,253.233,1177.135Zm-4.326,0h-3.452v3.409h3.856A17.569,17.569,0,0,0,248.907,1177.135Zm-4.361,0h-3.452a17.593,17.593,0,0,0-.4,3.409h3.857Zm-4.382,0h-3.395a9.031,9.031,0,0,0-.846,3.409h3.857A18.545,18.545,0,0,1,240.164,1177.135Zm13.914,4.319h-3.857a18.433,18.433,0,0,1-.384,3.409h3.4A9.067,9.067,0,0,0,254.078,1181.454Zm-4.766,0h-3.856v3.409h3.452A17.536,17.536,0,0,0,249.312,1181.454Zm-4.766,0h-3.857a17.568,17.568,0,0,0,.4,3.409h3.452Zm-4.766,0h-3.857a9.065,9.065,0,0,0,.846,3.409h3.395A18.466,18.466,0,0,1,239.78,1181.454Zm12.963,4.318h-3.125a12.252,12.252,0,0,1-.817,2.174,7.582,7.582,0,0,1-1.172,1.761A9.108,9.108,0,0,0,252.743,1185.772Zm-4.07,0h-3.217v4.269a4.224,4.224,0,0,0,2.536-2.493A11,11,0,0,0,248.673,1185.772Zm-4.127,0h-3.218a10.978,10.978,0,0,0,.682,1.776,4.224,4.224,0,0,0,2.536,2.493v-4.269Zm-4.162,0h-3.125a9.107,9.107,0,0,0,5.114,3.935,7.6,7.6,0,0,1-1.172-1.761A12.281,12.281,0,0,1,240.384,1185.772Z"
            transform="translate(-235 -1170.999)"
        />
    </svg>
</ng-container>

<ng-container *ngIf="name === 'language-accessible'">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <defs>
            <style>
                .cls-1 {
                    fill: #000000;
                }
            </style>
        </defs>
        <path
            class="cls-1"
            d="M245,1171a10,10,0,1,1-10,10A10.008,10.008,0,0,1,245,1171Zm.455.966v4.262h3.217a11.068,11.068,0,0,0-.682-1.776A4.21,4.21,0,0,0,245.456,1171.964Zm-.909,0a4.211,4.211,0,0,0-2.536,2.486,11.065,11.065,0,0,0-.682,1.776h3.218Zm3.083.334a7.446,7.446,0,0,1,1.172,1.747,12.3,12.3,0,0,1,.817,2.18h3.125A9.1,9.1,0,0,0,247.629,1172.3Zm-5.256,0a9.1,9.1,0,0,0-5.114,3.928h3.125a12.325,12.325,0,0,1,.817-2.18A7.459,7.459,0,0,1,242.373,1172.3Zm10.86,4.837h-3.4a18.511,18.511,0,0,1,.384,3.409h3.857A9.033,9.033,0,0,0,253.233,1177.135Zm-4.326,0h-3.452v3.409h3.856A17.569,17.569,0,0,0,248.907,1177.135Zm-4.361,0h-3.452a17.593,17.593,0,0,0-.4,3.409h3.857Zm-4.382,0h-3.395a9.031,9.031,0,0,0-.846,3.409h3.857A18.545,18.545,0,0,1,240.164,1177.135Zm13.914,4.319h-3.857a18.433,18.433,0,0,1-.384,3.409h3.4A9.067,9.067,0,0,0,254.078,1181.454Zm-4.766,0h-3.856v3.409h3.452A17.536,17.536,0,0,0,249.312,1181.454Zm-4.766,0h-3.857a17.568,17.568,0,0,0,.4,3.409h3.452Zm-4.766,0h-3.857a9.065,9.065,0,0,0,.846,3.409h3.395A18.466,18.466,0,0,1,239.78,1181.454Zm12.963,4.318h-3.125a12.252,12.252,0,0,1-.817,2.174,7.582,7.582,0,0,1-1.172,1.761A9.108,9.108,0,0,0,252.743,1185.772Zm-4.07,0h-3.217v4.269a4.224,4.224,0,0,0,2.536-2.493A11,11,0,0,0,248.673,1185.772Zm-4.127,0h-3.218a10.978,10.978,0,0,0,.682,1.776,4.224,4.224,0,0,0,2.536,2.493v-4.269Zm-4.162,0h-3.125a9.107,9.107,0,0,0,5.114,3.935,7.6,7.6,0,0,1-1.172-1.761A12.281,12.281,0,0,1,240.384,1185.772Z"
            transform="translate(-235 -1170.999)"
        />
    </svg>
</ng-container>

<ng-container *ngIf="name === 'close'">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 18">
        <path
            d="M20.485 16.071a1.003 1.003 0 0 1 0 1.414 1.003 1.003 0 0 1-1.414 0L12 10.414l-7.071 7.071a1.003 1.003 0 0 1-1.414 0 1.003 1.003 0 0 1 0-1.414L10.586 9 3.515 1.929a1.003 1.003 0 0 1 0-1.414 1.003 1.003 0 0 1 1.414 0L12 7.586 19.071.515c.389-.389 1.025-.389 1.414 0s.389 1.025 0 1.414L13.414 9l7.071 7.071z"
        />
    </svg>
</ng-container>
