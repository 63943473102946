import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';
import {ISurveyBlock} from '../../../models/survey/ISurveyBlock';
import {SurveyElement} from '../../../models/survey/ISurveyElement';

export const BlocksActionTypes = {
    RESET: type('[blocks] reset'),

    BLOCKS_LOADED: type('[blocks] blocks loaded'),

    LOAD_INTRO: type('[blocks] load intro block'),
    LOAD_COMPLETED: type('[blocks] load completed block'),

    ACTIVATE_BLOCK: type('[blocks] activate a block'),
    BLOCK_ACTIVATED: type('[blocks] block activated'),
    RUN_INSTRUCTIONS: type('[blocks] run instructions'),

    BLOCK_READY: type('[blocks] block is ready to render'),
    BLOCK_NOT_READY: type('[blocks] block is not ready to render'),

    RECORD_RESPONSE: type('[blocks] record a response'),

    BLOCK_HAS_BEEN_SEEN: type('[blocks] block has been seen'),
    BLOCK_QUESTIONS_COMPLETE: type('[blocks] block questions are complete'),
    BLOCK_QUESTIONS_INCOMPLETE: type('[blocks] block questions are incomplete'),

    REHYDRATE: type('[blocks] rehydrate'),

    NEXT_QUESTION: type('[blocks] manual nav to next question'),
    PREV_QUESTION: type('[blocks] manual nav to prev question')
};

export class ResetBlocksAction implements Action {
    public type = BlocksActionTypes.RESET;
}

export class BlocksLoadedAction implements Action<ISurveyBlock[]> {
    public type = BlocksActionTypes.BLOCKS_LOADED;

    constructor(public payload: ISurveyBlock[]) {}
}

export class LoadIntroBlockAction implements Action {
    public type = BlocksActionTypes.LOAD_INTRO;
}

export class LoadCompletedBlockAction implements Action {
    public type = BlocksActionTypes.LOAD_COMPLETED;
}

export class ActivateBlockAction implements Action<string> {
    public type = BlocksActionTypes.ACTIVATE_BLOCK;

    // block id
    constructor(public payload: string) {}
}

export class BlockActivatedAction implements Action<ISurveyBlock> {
    public type = BlocksActionTypes.BLOCK_ACTIVATED;

    constructor(public payload: ISurveyBlock) {}
}

export class RunInstructionsAction implements Action<SurveyElement> {
    public type = BlocksActionTypes.RUN_INSTRUCTIONS;

    constructor(public payload: SurveyElement) {}
}

export class BlockReadyAction implements Action {
    public type = BlocksActionTypes.BLOCK_READY;
}

export class BlockNotReadyAction implements Action {
    public type = BlocksActionTypes.BLOCK_NOT_READY;
}

export class BlockHasBeenSeenAction implements Action<string> {
    public type = BlocksActionTypes.BLOCK_HAS_BEEN_SEEN;

    // block id
    constructor(public payload: string) {}
}

export class BlockQuestionsCompleteAction implements Action<string> {
    public type = BlocksActionTypes.BLOCK_QUESTIONS_COMPLETE;

    // block id
    constructor(public payload: string) {}
}

export class BlockQuestionsIncompleteAction implements Action<string> {
    public type = BlocksActionTypes.BLOCK_QUESTIONS_INCOMPLETE;

    // block id
    constructor(public payload: string) {}
}

export class RehydrateBlocksAction implements Action<any> {
    public type = BlocksActionTypes.REHYDRATE;

    constructor(public payload: any) {}
}

export class NextQuestionAction implements Action<string> {
    public type = BlocksActionTypes.NEXT_QUESTION;

    constructor(public payload: string) {}
}

export class PrevQuestionAction implements Action<string> {
    public type = BlocksActionTypes.PREV_QUESTION;

    constructor(public payload: string) {}
}
