<div class="member-direct-header" *ngIf="survey | async as survey">
    <clients-grid-layout
        [align]="'center center'"
        [layoutGap]="layoutGap"
        [layout]="LAYOUT.COLUMN"
        [xsWidth]="100"
        [smWidth]="100"
    >
        <member-direct-company-logo
            [source]="logo | async"
            [backupSource]="logoFromConfig"
            [logoAltText]="logoAltText"
            [logoSize]="logoSize"
        ></member-direct-company-logo>

        <clients-grid-layout style="max-width: 100%" [layout]="LAYOUT.COLUMN" [hideXs]="true" [hideSm]="true">
            <member-direct-divider></member-direct-divider>

            <member-direct-survey-name *ngIf="shouldShowNameInSidebar" [name]="survey.name"></member-direct-survey-name>

            <member-direct-quote
                *ngIf="quote | async as quote"
                [author]="quote.author"
                [authorImage]="quote.authorImage"
                [authorDescription]="quote.authorDescription"
                [quote]="quote.quote"
            >
            </member-direct-quote>

            <member-direct-sidebar-block-navigation *ngIf="shouldShowSidebarBlockNavigation">
            </member-direct-sidebar-block-navigation>
        </clients-grid-layout>
    </clients-grid-layout>

    <member-direct-navigation-mobile
        *ngIf="isMobile"
        [isOpen]="mobileMenuOpen"
        [display]="shouldShowSidebarBlockNavigation"
        [name]="survey.name"
        [showName]="shouldShowNameInSidebar"
        [instructions]="mobileMenuInstructions"
        (closeHandler)="closeMobileMenu()"
    >
        <member-direct-sidebar-block-navigation
            *ngIf="shouldShowSidebarBlockNavigation"
            (blockLoaded)="closeMobileMenu()"
        >
        </member-direct-sidebar-block-navigation>
    </member-direct-navigation-mobile>

    <clients-grid-layout
        [align]="'center center'"
        [alignSm]="'center center'"
        [alignXs]="'center center'"
        [layout]="LAYOUT.ROW"
        [layoutSm]="LAYOUT.ROW"
        [layoutXs]="LAYOUT.ROW"
        [xsWidth]="100"
        [smWidth]="100"
    >
        <member-direct-language-switcher
            [languagesData]="languageDropdownData"
            [activeLanguage]="language | async"
            (changeHandler)="onChangeHandler($event)"
            [dropdownPosition]="dropdownPosition"
        ></member-direct-language-switcher>

        <div *ngIf="isMobile && shouldShowSidebarBlockNavigation" class="hamburger" (click)="mobileMenuOpen = true">
            <r-svg-icon [name]="'hamburger'"></r-svg-icon>
        </div>
    </clients-grid-layout>
</div>
