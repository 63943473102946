import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {reduce} from 'lodash';
import {IdvFacade} from '../+state/idv.facade';
import {IIdvQuestion} from '../models/IIdvQuestion';
import {IVerificationFieldConfig} from '../models/verificationFields/IVerificationFieldConfig';
import {IVerificationFieldModel} from '../models/verificationFields/IVerificationFieldModel';

@Injectable()
export class IdvService {
    constructor(private idvFacade: IdvFacade) {}

    public isVerified(): boolean {
        return this.idvFacade.verified();
    }

    public isRequired(): boolean {
        return this.idvFacade.required();
    }

    public buildVerificationFieldsModel(
        idvQuestions: IIdvQuestion[],
        config: {[key: string]: IVerificationFieldConfig}
    ): IVerificationFieldModel[] {
        return reduce(
            idvQuestions,
            (model: any[], question: IIdvQuestion) => {
                try {
                    const {type, validators, editableProp, label} = config[question.type];
                    model.push({
                        type,
                        control: new FormGroup({
                            type: new FormControl(type),
                            [editableProp]: new FormControl('', validators)
                        }),
                        editableProp,
                        label
                    });
                } catch (e) {
                    /* istanbul ignore next */
                    console.error(e);
                }
                return model;
            },
            []
        );
    }
}
