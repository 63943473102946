import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

export const snackbarDefaults: MatSnackBarConfig = {
    duration: 2000,
    verticalPosition: 'top'
};

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    constructor(private snackBar: MatSnackBar) {}

    public notification(message: string, action?: string, options: MatSnackBarConfig = snackbarDefaults) {
        this.snackBar.open(message, action, options);
    }

    public notificationFromComponent(component: any, options: MatSnackBarConfig = snackbarDefaults) {
        this.snackBar.openFromComponent(component, options);
    }
}
