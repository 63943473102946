/* istanbul ignore file */
import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseElement} from '../../base-element.abstract';
import {ISurveyAccordion, ISurveyAccordionGroup} from '../../../models/survey/ISurveyContent';
import {shuffleArray} from '@clients/shared/utilities';
import {get} from 'lodash';

@Component({
    selector: 'surveys-element-accordion-group',
    templateUrl: './element-accordion-group.component.html',
    styleUrls: ['./element-accordion-group.component.scss']
})
export class ElementAccordionGroupComponent extends BaseElement implements OnInit {
    @Input()
    public element: ISurveyAccordionGroup;

    public accordions: ISurveyAccordion[];

    private activeAccordionId: string;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        const shouldRandomize = get(this.element, 'data.randomize');
        if (shouldRandomize) {
            this.accordions = shuffleArray([...this.element.data.content]);
        } else {
            this.accordions = this.element.data.content;
        }
    }

    public accordionOpened(accordion: ISurveyAccordion) {
        if (!this.element.data.autoClose) return;

        if (this.activeAccordionId && this.activeAccordionId !== accordion.id) {
            this.closeAccordion(this.activeAccordionId);
        }

        this.activeAccordionId = accordion.id;
    }

    public accordionClosed(accordion: ISurveyAccordion) {
        if (this.element.data.autoClose && this.activeAccordionId === accordion.id) {
            this.activeAccordionId = null;
        }
    }

    private closeAccordion(id: string) {
        this.accordions = this.accordions.map((acc: ISurveyAccordion): ISurveyAccordion => {
            if (acc.id === id) {
                return {
                    ...acc,
                    active: false
                };
            }

            return acc;
        });
    }
}
