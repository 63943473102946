import {APP_INITIALIZER, NgModule} from '@angular/core';
// third party
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {MemberDirectLibModule} from '@clients/member-direct/member-direct-lib';
import {memberDirectEnvServiceProvider} from '@clients/member-direct/survey-common';
import {TranslationModule, TranslationService} from '@clients/member-direct/translation';
// features
// import {NewRelicServiceInitializationProvider} from '@clients/shared/error-handling';
import {LoggingModule, SumoServiceInitializationProvider} from '@clients/shared/logging';
import {CUSTOM_DISPATCHER} from '@clients/shared/utilities';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {InViewportModule} from 'ng-in-viewport';

// app
import {AppComponent} from './app.component';
import {loggingConfig} from './config/logging.config';
import {memberDirectRoutes} from './member-direct.routes';
import {AppService} from './services/app.service';
import {AdminEnvServiceProvider} from '@clients/admin/admin-common';

export function initApp(appService: AppService) {
    return () => appService.bootstrap();
}

// AOT doesn't like calling functions. Figure out a better way to do this,
const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@NgModule({
    declarations: [AppComponent],
    imports: [
        // vendor
        BrowserModule,
        RouterModule.forRoot(memberDirectRoutes),

        // vendor | ngrx
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 150,
            logOnly: browserWindowEnv.production
        }),

        // features
        LoggingModule.forRoot(loggingConfig),
        MemberDirectLibModule.forRoot(),
        InViewportModule,
        TranslationModule.forRoot()
    ],
    providers: [
        memberDirectEnvServiceProvider,
        AdminEnvServiceProvider,
        AppService,

        // NGRX
        CUSTOM_DISPATCHER,

        // bootstrap function
        {provide: APP_INITIALIZER, useFactory: initApp, deps: [AppService, TranslationService], multi: true},
        // NewRelicServiceInitializationProvider,
        SumoServiceInitializationProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
