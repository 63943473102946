<div *ngIf="element" class="survey-element surveys-element-button">
    <r-button
        [doNotExpand]="element.config?.doNotExpandWidth"
        [affirmative]="element.data?.affirmative"
        [negative]="element.data?.negative"
        [e2e]="'button-' + element.id"
        (onClick)="execute()"
    >
        {{ element.label[language] }}
    </r-button>
</div>
