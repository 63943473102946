import {BehaviorSubject, Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {selectSelectedSurvey, Survey, SurveyConfigService} from '@clients/member-direct/surveys';
import {ILanguage, MultilingualData, WithLanguageAbstract} from '@clients/member-direct/translation';
import {DROPDOWN_POSITION_ENUM, IDropdownData} from '@clients/shared/common-forms';
import {select} from '@ngrx/store';

import {selectApplicationLanguage, selectTranslatedLogo} from '../../+state';
import {SetAppLanguageAction} from '../../+state/config/config.actions';
import {memberDirectFeatureLocalePrefix} from '../../locale/locale';

export const defaultIdvBannerUrl = '/assets/images/idv.png';

@Component({
    selector: 'member-direct-idv',
    templateUrl: './idv.component.html',
    styleUrls: ['./idv.component.scss']
})
export class IdvComponent extends WithLanguageAbstract<any> implements OnInit {
    @ViewChild('mainView')
    public mainView: ElementRef;

    public logo: Observable<string>;
    public logoAltText: string;
    public survey: Observable<Survey>;
    public languageDropdownData: BehaviorSubject<IDropdownData[]> = new BehaviorSubject<IDropdownData[]>([]);
    public dropdownPosition: DROPDOWN_POSITION_ENUM = DROPDOWN_POSITION_ENUM.TOP;
    public bannerUrl: string;
    public bannerAltText: string;
    public fallbackButtonText: MultilingualData<string>;

    /* istanbul ignore next */
    constructor(public surveyConfigService: SurveyConfigService, injector: Injector) {
        super(injector, selectApplicationLanguage);
    }

    ngOnInit() {
        this.logo = this.store.pipe(select(selectTranslatedLogo));
        this.survey = this.store.pipe(select(selectSelectedSurvey));

        this.survey.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.updateAvailableLanguages();
            this.languageChanged(this.language);
        });

        const idvConfig = this.surveyConfigService.getIdvConfig();
        this.bannerUrl = idvConfig ? idvConfig.banner : defaultIdvBannerUrl;
    }

    public onChangeHandler(value: string): void {
        this.store.dispatch(new SetAppLanguageAction(value));
    }

    public skipToContent(element = this.mainView.nativeElement) {
        element.focus();
    }

    protected languageChanged(language: string): void {
        if (this.survey) {
            this.logoAltText = this.surveyConfigService.getLogoAltText(language);
            this.bannerAltText = this.surveyConfigService.getBannerAltText(language);
        }
    }

    private updateAvailableLanguages(): void {
        const languages = this.surveyConfigService.getAvailableLanguages().map((language: ILanguage) => ({
            label: this.translationService.translateWithLanguageProvided(
                `${memberDirectFeatureLocalePrefix}.languageNames`,
                language.code
            ),
            value: language.code
        }));
        this.languageDropdownData.next(languages);
    }
}
