import {IdvActionTypes} from './idv.actions';
import {IdvReducerState} from './idv.interfaces';

export function idvReducer(state: IdvReducerState, action: any): IdvReducerState {
    switch (action.type) {
        case IdvActionTypes.ENROLLMENT_ID_LOADED:
            return {...state, enrollment_id: action.payload};

        case IdvActionTypes.IDV_FIELDS_LOADED:
            return {...state, questions: action.payload};

        case IdvActionTypes.IDV_REQUIRED:
            return {...state, requiresVerification: true};

        case IdvActionTypes.IDV_NOT_REQUIRED:
            return {...state, requiresVerification: false};

        case IdvActionTypes.VERIFY_IDENTITY:
            return {
                ...state,
                posting: true,
                failed: false,
                locked: false
            };

        case IdvActionTypes.IDENTITY_VERIFIED:
            return {
                ...state,
                posting: false,
                verified: true,
                locked: false,
                failed: false
            };

        case IdvActionTypes.IDENTITY_VERIFICATION_FAILED:
            return {
                ...state,
                posting: false,
                verified: false,
                locked: false,
                failed: true
            };

        case IdvActionTypes.IDENTITY_VERIFICATION_LOCKED:
            return {
                ...state,
                posting: false,
                verified: false,
                locked: true
            };

        default: {
            return state;
        }
    }
}
