import {get} from 'lodash';

import {Injectable} from '@angular/core';
import {EventTrackAction, LinkClicked} from '@clients/shared/analytics';
import {Store} from '@ngrx/store';

import {ActivateBlockAction, LoadCompletedBlockAction, LoadIntroBlockAction} from '../+state/blocks/blocks.actions';
import {HideElementAction, HighlightElementAction, ShowElementAction} from '../+state/elements/elements.actions';
import {SurveysCombinedReducerState} from '../+state/interface';
import {RecordResponseAction} from '../+state/responses/responses.actions';
import {ResponsesFacade} from '../+state/responses/responses.facade';
import {SeededSurveyResponse} from '../../models/survey-responses/SurveyResponse.interface';
import {SurveyInstruction, SurveyInstructionType} from '../../models/survey/ISurveyInstruction';
import {instructionShouldRun} from '../utilities/instruction-should-run.function';

interface ISurveyInstructionMap {
    [type: string]: (instruction: SurveyInstruction) => void;
}

@Injectable()
export class SurveyInstructionsService {
    /**
     * Handler functions for all of our instruction types.
     */
    private instructionMap: ISurveyInstructionMap = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [SurveyInstructionType.LOAD_INTRO]: (instruction: SurveyInstruction) =>
            this.store.dispatch(new LoadIntroBlockAction()),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [SurveyInstructionType.LOAD_COMPLETE]: (instruction: SurveyInstruction) =>
            this.store.dispatch(new LoadCompletedBlockAction()),
        [SurveyInstructionType.LOAD_BLOCK_BY_ID]: (instruction: SurveyInstruction) =>
            this.store.dispatch(new ActivateBlockAction(instruction.data)),
        [SurveyInstructionType.OPEN_EXTERNAL_LINK]: (instruction: SurveyInstruction) =>
            this.openExternalLink(instruction),
        [SurveyInstructionType.SET_DATA_PROP]: (instruction: SurveyInstruction) => this.setDataProp(instruction),
        [SurveyInstructionType.SHOW_ELEMENT]: (instruction: SurveyInstruction) =>
            this.store.dispatch(new ShowElementAction(instruction.data)),
        [SurveyInstructionType.DISPLAY_RELATED_QUESTION]: (instruction: SurveyInstruction) =>
            this.store.dispatch(new ShowElementAction(instruction.data)),
        [SurveyInstructionType.HIDE_ELEMENT]: (instruction: SurveyInstruction) =>
            this.store.dispatch(new HideElementAction(instruction.data)),
        [SurveyInstructionType.HIDE_RELATED_QUESTION]: (instruction: SurveyInstruction) =>
            this.store.dispatch(new HideElementAction(instruction.data)),
        [SurveyInstructionType.HIGHLIGHT_ELEMENT]: (instruction: SurveyInstruction) =>
            this.store.dispatch(new HighlightElementAction(instruction.data))
    };

    constructor(private store: Store<SurveysCombinedReducerState>, private responsesFacade: ResponsesFacade) {}

    public process(instructions: SurveyInstruction[], invokingElementId: string | number = null): void {
        if (!instructions || !instructions.length) return;

        const responses = this.responsesFacade.getEntities();

        instructions
            .filter((instruction: SurveyInstruction) => instructionShouldRun(instruction, responses, invokingElementId))
            .forEach((instruction: SurveyInstruction) => this.processInstruction(instruction));
    }

    private processInstruction(instruction: SurveyInstruction): void {
        if (!instruction) throw new Error(`Expected object of type SurveyInstruction and receive ${instruction}`);
        if (!this.instructionMap[instruction.type]) throw new Error(`Invalid instruction type: ${instruction.type}`);

        this.instructionMap[instruction.type](instruction);
    }

    private setDataProp(instruction: SurveyInstruction) {
        const response = new SeededSurveyResponse(instruction.data.prop, instruction.data.value);
        this.store.dispatch(new RecordResponseAction(response));
    }

    private openExternalLink(instruction: SurveyInstruction): void {
        // get the link data
        const link = get(instruction, 'data.link');
        if (link) {
            // dispatch an event to log the clicked link
            const event = new LinkClicked(link);
            this.store.dispatch(new EventTrackAction(event));
            // actually visit the link
            window.open(link, '_blank');
        }
    }
}
