import {find, get, isEmpty, some} from 'lodash';
import {map, mergeMap, tap} from 'rxjs/operators';

import {Inject, Injectable} from '@angular/core';
import {Router, RouterStateSnapshot} from '@angular/router';
import {
    EnrollmentIdLoadedAction,
    IDV_MODE_ENUM,
    IdvNotRequiredAction,
    IdvQuestionsLoadedAction,
    IdvRequiredAction
} from '@clients/member-direct/idv';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {
    ISurveyValidationSuccessApiResponse,
    RehydrateBlocksAction,
    RehydrateElementsAction,
    RehydrateResponsesAction,
    SurveyActionTypes,
    SurveyFacade,
    SurveyLoadedAction,
    SurveyLoadedPayload,
    SurveyLoadFailedAction,
    SurveyStartAction,
    SurveyThemeService,
    SurveyValidationSuccessAction,
    Theme,
    ValidateSurveyAction
} from '@clients/member-direct/surveys';
import {EventTrackAction} from '@clients/shared/analytics';
import {AuthProvider} from '@clients/shared/auth';
import {ENV_SERVICE} from '@clients/shared/config';
import {DoneLoadingAction} from '@clients/shared/loading';
import {LogErrorAction} from '@clients/shared/logging';
import {Action} from '@clients/shared/models';
import {PersistenceProvider} from '@clients/shared/persistence';
import {and, or} from '@clients/shared/utilities';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';

import {memberDirectLibConfig} from '../../config/config';
import {MEMBER_DIRECT_ERRORS} from '../../errors/member-direct-errors';
import {parseMemberDirectErrors} from '../../errors/parse-member-direct-errors.function';
import {SaveClientStatePayload} from '../../models/SaveClientStatePayload.interface';
import {MemberDirectError, RouterNavigateAction} from '../app/app.actions';
import {SetDefaultLanguageAction} from '../config/config.actions';
import {MemberDirectSurveysActions, SurveyIsPreauthorizedAction} from './surveys.actions';
import {MemberDirectSurveysFacade} from './surveys.facade';

@Injectable()
export class SurveysEffects {
    readonly fallbackTheme: Theme;

    surveyIsPreauthorized = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MemberDirectSurveysActions.SURVEY_IS_PRE_AUTHORIZED),
                map((action: SurveyIsPreauthorizedAction) => action.payload),
                tap((token: string) => this.auth.setToken(token))
            ),
        {dispatch: false}
    );

    validateSurvey = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SurveyActionTypes.VALIDATE_SURVEY),
                map((action: ValidateSurveyAction) => action.payload),
                tap((token: string) => this.persistence.set('linkId', token))
            ),
        {dispatch: false}
    );

    surveyValidationSucceededSetTheme = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SurveyActionTypes.SURVEY_VALIDATION_SUCCESS),
                map((action: SurveyValidationSuccessAction) => action.payload),
                tap((payload: ISurveyValidationSuccessApiResponse) => {
                    this.surveyValidationSucceededSetThemeHandler(payload);
                })
            ),
        {dispatch: false}
    );

    surveyValidationSucceeded = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.SURVEY_VALIDATION_SUCCESS),
            map((action: SurveyValidationSuccessAction) => action.payload),
            mergeMap((payload: ISurveyValidationSuccessApiResponse) => this.surveyValidationSucceededHandler(payload))
        )
    );

    surveyValidationFailed = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.SURVEY_VALIDATION_FAILURE),
            map(() => this.surveyValidationFailedHandler())
        )
    );

    surveyLoaded = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MemberDirectSurveysActions.SURVEY_LOADED),
                map((action: SurveyLoadedAction) => action.payload),
                tap((response: SurveyLoadedPayload) => this.surveyLoadedHandler(response))
            ),
        {dispatch: false}
    );

    surveyLoadFailed = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.SURVEY_LOAD_FAILED),
            mergeMap((action: SurveyLoadFailedAction) => this.surveyLoadFailedHandler(action.payload))
        )
    );
    private useLocalTheme: boolean;
    private pureDevMode: boolean;
    private activeTheme: Theme;
    private devModeSurveyLoaded: boolean;

    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store<any>,
        private surveyFacade: SurveyFacade,
        private persistence: PersistenceProvider,
        private themeService: SurveyThemeService,
        private memberDirectSurveysFacade: MemberDirectSurveysFacade,
        private auth: AuthProvider,

        @Inject(ENV_SERVICE) envService: MemberDirectEnvService
    ) {
        this.useLocalTheme = envService.surveySettings.useLocalTheme;
        this.pureDevMode = envService.surveySettings.pureDevMode;
        this.activeTheme = envService.surveySettings.activeTheme;

        this.fallbackTheme = this.activeTheme || memberDirectLibConfig.defaultTheme;
    }

    protected associatedAccessTokenExistsAndNotExpired(surveyRecordId: string): boolean {
        let tokenExists = false;
        let isExpired = true;

        const token = this.persistence.get(surveyRecordId, false);
        if (token) {
            tokenExists = true;
            isExpired = this.auth.isTokenExpired(token);
        }

        return and(tokenExists, !isExpired);
    }

    private surveyValidationSucceededSetThemeHandler(payload: ISurveyValidationSuccessApiResponse): void {
        // if `useLocalTheme` is true, then use either the active or fallback
        // theme. otherwise, parse the theme out of the API response.
        const theme: Theme = this.useLocalTheme
            ? this.activeTheme
            : this.parseThemeFromSurveyValidationResponse(payload);

        this.themeService.loadTheme(theme || this.fallbackTheme);
    }

    private surveyValidationSucceededHandler(payload: ISurveyValidationSuccessApiResponse): Action<any>[] {
        const actions: Action<any>[] = [
            new EnrollmentIdLoadedAction(payload.enrollmentId),
            new IdvQuestionsLoadedAction(payload.idvQuestions),
            new DoneLoadingAction(),
            new SetDefaultLanguageAction(payload.defaultLanguage || /* istanbul ignore next */ 'en')
        ];
        const routerState: RouterStateSnapshot = this.router.routerState.snapshot;
        const {surveyRecordId, idv} = payload;
        const shouldNotDoIdv = this.shouldNotDoIdv(idv, surveyRecordId);

        /*
         * if a survey is pre-authorized OR the payload.idv property is set to EXTERNAL
         * that means that Revel cannot issue a token, therefore either the token was
         * passed in through the link itself or it is stored in local storage associated
         * with the survey record ID.
         * For Internal idv, check if there's a token associated with the survey and continue, otherwise issue a token
         * */
        if (shouldNotDoIdv) {
            const shouldNotIssueToken = this.shouldNotIssueToken(
                idv,
                this.memberDirectSurveysFacade.preauthorized(),
                and(idv === IDV_MODE_ENUM.INTERNAL, this.associatedAccessTokenExistsAndNotExpired(surveyRecordId))
            );

            if (shouldNotIssueToken) {
                /*
                 * if an access token that is associated to the surveyRecordId exists, set it to the auth provider
                 * */
                const token = this.persistence.get(surveyRecordId, false);
                token && this.auth.setToken(token);

                this.router.navigate([`survey/${this.surveyFacade.getSurveyContext()}/start`]);
                return actions;
            }

            /*
             * IdvNotRequiredAction would trigger a call to the api for getting a token
             * */
            actions.push(new IdvNotRequiredAction(`${routerState.url}/start`));
            return actions;
        }

        /*
         * If there are not IdvQuestions provided by the server,
         * throw an error and navigate to the error page.
         * */
        if (or(!payload.idvQuestions, isEmpty(payload.idvQuestions))) {
            return [
                new DoneLoadingAction(),
                new LogErrorAction({
                    type: MEMBER_DIRECT_ERRORS.IDV_QUESTIONS_EMPTY,
                    message: 'IDV Questions have not been returned in the server response.',
                    options: {
                        surveyRecordId: payload.surveyRecordId,
                        err: payload
                    }
                }),
                new MemberDirectError(parseMemberDirectErrors({status: 500} as any))
            ];
        }

        /*
         * if idv is required add value to state and redirect to IDV
         * screen where the user will complete the process.
         * Dev note: surveyService does not validate the access tokens when it runs locally
         * */
        return [
            ...actions,
            new IdvRequiredAction(),
            new RouterNavigateAction({
                route: `${routerState.url}/verify`,
                options: null
            }),
            new EventTrackAction({
                eventName: 'IDV Started'
            })
        ];
    }

    private shouldNotDoIdv(idvType: IDV_MODE_ENUM, surveyRecordId: string): boolean {
        return some([
            idvType === IDV_MODE_ENUM.EXTERNAL,
            idvType === IDV_MODE_ENUM.NONE,
            this.memberDirectSurveysFacade.preauthorized(),
            and(idvType === IDV_MODE_ENUM.INTERNAL, this.associatedAccessTokenExistsAndNotExpired(surveyRecordId))
        ]);
    }

    private shouldNotIssueToken(
        idvType: IDV_MODE_ENUM,
        isPreauthorized: boolean,
        isInternalAndHasValidToken: boolean
    ): boolean {
        return or(or(idvType === IDV_MODE_ENUM.EXTERNAL, isPreauthorized), isInternalAndHasValidToken);
    }

    private surveyValidationFailedHandler(): DoneLoadingAction {
        this.router.navigate(['error']);

        return new DoneLoadingAction();
    }

    private surveyLoadedHandler(response: SurveyLoadedPayload): void {
        // if the app is in development mode and the dev survey has
        // not yet been loaded, then move to the start route and
        // load the theme. this action is sometimes invoked twice
        // due to the hijacking we're doing of the events in order to
        // make dev experience easier.
        // todo | clean up events so this is only invoked once https://healthtel.atlassian.net/browse/SB-988
        if (this.pureDevMode && !this.devModeSurveyLoaded) {
            this.router.navigate(['/survey/devmode/start']);
            this.store.dispatch(new DoneLoadingAction());
            this.themeService.loadTheme(this.fallbackTheme);
            this.devModeSurveyLoaded = true;
            return;
        }

        const clientState = response.clientState;

        // if we have a client state and survey resume disable is false
        // then process the state re-hydration
        /* istanbul ignore else */
        if (clientState && !this.surveyFacade.getDisableResume()) {
            // fetch the saved state and see if there's a `surveys` object. since that's the
            // only slice of the client state that this feature is concerned with.
            const savedState: SaveClientStatePayload = JSON.parse(clientState);
            /* istanbul ignore else */
            if (savedState.surveys) {
                // if we have saved block data, rehydrate
                /* istanbul ignore else */
                if (savedState.surveys.blocks)
                    this.store.dispatch(new RehydrateBlocksAction(savedState.surveys.blocks));
                // if we have saved elements data, rehydrate
                /* istanbul ignore else */
                if (savedState.surveys.elements)
                    this.store.dispatch(new RehydrateElementsAction(savedState.surveys.elements));
                // if we have saved responses data, rehydrate
                /* istanbul ignore else */
                if (savedState.surveys.responses)
                    this.store.dispatch(new RehydrateResponsesAction(savedState.surveys.responses));
            }
        }

        this.store.dispatch(new SurveyStartAction(response));
    }

    private surveyLoadFailedHandler(payload: any): Action<any>[] {
        return [
            new LogErrorAction({
                type: MEMBER_DIRECT_ERRORS.SURVEY_LOAD_FAILED,
                message: 'could not load the survey.',
                options: {
                    err: payload,
                    context: this.surveyFacade.getSurveyContext(),
                    surveyRecordId: this.surveyFacade.getSurveyRecordId()
                }
            }),
            new MemberDirectError(parseMemberDirectErrors(payload))
        ];
    }

    // try to find the theme in the api response either in the `config` prop or in
    // the array of options which is now @deprecated. If a theme is found, use it,
    // if not, use the fallback theme.
    private parseThemeFromSurveyValidationResponse(payload: ISurveyValidationSuccessApiResponse): Theme {
        const themeFromConfig = get(payload, 'config.theme');
        const themeFromOptions = get(find(payload.options, {name: 'theme'}), 'value');
        const themeFromPayload = themeFromConfig || themeFromOptions;

        return themeFromPayload ? themeFromPayload : this.fallbackTheme;
    }

    ///////////
}
