import Timer from 'easytimer.js';
import {Dictionary} from '@ngrx/entity';

export interface TimerObject {
    timer: any;
    callback?: Function;
}

export class ContentTracking {
    public static timers: Dictionary<TimerObject> = {};

    /**
     * Pass a unique ID to begin tracking time viewing that content.
     */
    public static startContentTimer(id: string, beaconCallback: Function = null): void {
        ContentTracking.timers[id] = {
            timer: new Timer(),
            callback: beaconCallback
        };
        ContentTracking.timers[id].timer.start();
    }

    /**
     * Pass an element ID to be done tracking time viewing that content and
     * dispatch an event.
     *
     * @param {string} id
     * @return total time spent viewing that particular content
     */
    public static stopContentTimer(id: string): number | null {
        if (!(id in ContentTracking.timers)) return null;

        const time = ContentTracking.timers[id].timer.getTimeValues().seconds;
        ContentTracking.timers[id].timer.stop();
        delete ContentTracking.timers[id];

        return time;
    }
}
