import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';

import {IdvReducerState} from './idv.interfaces';
import {selectFailed, selectLocked, selectRequiresVerification, selectVerified} from './idv.selectors';
import {selectPosting} from './index';

@Injectable({
    providedIn: 'root'
})
export class IdvFacade {
    constructor(private store: Store<IdvReducerState>) {}

    public verified(): boolean {
        let verified = false;

        this.store.pipe(select(selectVerified), take(1)).subscribe((val: boolean) => (verified = val));

        return verified;
    }

    public required(): boolean {
        let required = false;

        this.store.pipe(select(selectRequiresVerification), take(1)).subscribe((val: boolean) => (required = val));

        return required;
    }

    public posting(): Observable<boolean> {
        return this.store.pipe(select(selectPosting));
    }

    public locked(): Observable<boolean> {
        return this.store.pipe(select(selectLocked));
    }

    public failed(): Observable<boolean> {
        return this.store.pipe(select(selectFailed));
    }
}
