import {Component, Input} from '@angular/core';

import {Address} from '../../models/Address';

export function buildGoogleMapLink(address: Address): string {
    let queryParams = '';
    //@ts-ignore
    Object.values(address).forEach((val) => {
        if (val && queryParams && queryParams.length) queryParams += ' ';
        if (val) queryParams += val;
    });

    return `https://maps.google.com/?q=${queryParams}`;
}

@Component({
    selector: 'r-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent {
    @Input()
    public address: Address;
    @Input()
    public linkToMap: boolean;

    constructor() {}

    public loadGoogleMap() {
        if (!this.linkToMap) return;

        window.open(buildGoogleMapLink(this.address), '_blank');
    }
}
