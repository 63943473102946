/* istanbul ignore file */
import {Component, Injector, Input} from '@angular/core';

import {ISurveyMultipartQuestion} from '../../../models/survey/ISurveyContent';
import {BaseElement} from '../../base-element.abstract';

@Component({
    selector: 'surveys-element-multipart-question',
    templateUrl: './element-multipart-question.component.html',
    styleUrls: ['./element-multipart-question.component.scss']
})
export class ElementMultipartQuestionComponent extends BaseElement {
    @Input() public element: ISurveyMultipartQuestion;

    constructor(injector: Injector) {
        super(injector);
    }
}
