import {Injectable} from '@angular/core';
import {
    selectAllBlocks,
    selectBlockEntities,
    selectBlockIds,
    selectBlockTotal,
    selectCompletedBlocks,
    selectPreviouslySelectedBlockId,
    selectSeenBlocks,
    selectSelectedBlock,
    selectSelectedBlockId
} from '../index';
import {StoreDataFacade} from '@clients/shared/utilities';
import {select, Store} from '@ngrx/store';
import {ISurveyBlock} from '../../../models/survey/ISurveyBlock';
import {SurveysBlocksState} from './blocks.interfaces';
import {take} from 'rxjs/operators';
import {findIndex} from 'lodash';

@Injectable()
export class BlocksFacade extends StoreDataFacade<SurveysBlocksState> {
    constructor(store: Store<SurveysBlocksState>) {
        super(store, selectBlockIds, selectBlockEntities, selectAllBlocks, selectBlockTotal);
    }

    public getActive(): ISurveyBlock {
        let block = null;
        this.store.pipe(select(selectSelectedBlock), take(1)).subscribe((_block: ISurveyBlock) => {
            block = _block;
        });

        return block;
    }

    public getActiveId(): string {
        let id = null;
        this.store.pipe(select(selectSelectedBlockId), take(1)).subscribe((_id: string) => {
            id = _id;
        });

        return id;
    }

    public getPreviouslyActiveId(): string {
        let id = null;
        this.store.pipe(select(selectPreviouslySelectedBlockId), take(1)).subscribe((_id: string) => {
            id = _id;
        });

        return id;
    }

    public getNextBlock(startingBlockId: string): ISurveyBlock {
        const blocks: ISurveyBlock[] = this.getArray();
        const currentIndex = findIndex(blocks, {id: startingBlockId});
        const next = currentIndex + 1;
        const hasNextBlock = this.getTotal() - 1 >= next;

        return hasNextBlock ? this.getArray()[next] : null;
    }

    public blockIsLast(blockId: string): boolean {
        if (!blockId) return null;

        const blocks: ISurveyBlock[] = this.getArray();
        const index = findIndex(blocks, {id: blockId});

        return blocks.length - 1 === index;
    }

    public seen(): string[] {
        let value: string[];
        this.store.pipe(select(selectSeenBlocks), take(1)).subscribe((v: string[]) => (value = v));

        return value;
    }

    public completed(): string[] {
        let value: string[];
        this.store.pipe(select(selectCompletedBlocks), take(1)).subscribe((v: string[]) => (value = v));

        return value;
    }
}
