import {type} from '@clients/shared/utilities';
import {Action} from '@clients/shared/models';
import {ISurveyValidationSuccessApiResponse} from '../../../models/api-responses/SurveyValidateApiResponse.interface';
import {ISurveyConfig} from '../../../models/survey/ISurveyConfig';
import {SurveyLoadedPayload} from '../../../models/survey/ISurveyLoadedPayload';

export const SurveyActionTypes = {
    RESET: type('[surveys] reset'),

    VALIDATE_SURVEY: type('[surveys] bootstrap survey'),
    SURVEY_VALIDATION_SUCCESS: type('[surveys] validation succeeded'),
    SURVEY_VALIDATION_FAILURE: type('[surveys] validation failed'),

    SET_LANGUAGE: type('[surveys] set the survey language'),

    LOAD_SURVEY: type('[surveys] load survey'),
    SURVEY_LOADED: type('[surveys] survey loaded'),
    SURVEY_LOAD_FAILED: type('[surveys] survey load failed'),
    SURVEY_ON_LOAD_PROCESSED: type('[surveys] survey on load processed'),
    SURVEY_CONFIG_LOADED: type('[surveys] survey config loaded'),

    SURVEY_START: type('[surveys] survey start'),

    COMPLETE_SURVEY: type('[surveys] complete survey'),
    SURVEY_COMPLETED: type('[surveys] survey completed'),

    DISABLE_RESUME: type('[surveys] disable resume')
};

/* istanbul ignore next */
export class ResetSurveyAction implements Action {
    public readonly type: string = SurveyActionTypes.RESET;
}

export class ValidateSurveyAction implements Action<string> {
    public readonly type: string = SurveyActionTypes.VALIDATE_SURVEY;

    // context
    constructor(public payload: string) {}
}

export class SurveyValidationSuccessAction implements Action<ISurveyValidationSuccessApiResponse> {
    public readonly type: string = SurveyActionTypes.SURVEY_VALIDATION_SUCCESS;

    constructor(public payload: ISurveyValidationSuccessApiResponse) {}
}

export class SurveyValidationFailureAction implements Action<any> {
    public readonly type: string = SurveyActionTypes.SURVEY_VALIDATION_FAILURE;

    // error response
    constructor(public payload: any) {}
}

export class LoadSurveyAction implements Action<string> {
    public readonly type: string = SurveyActionTypes.LOAD_SURVEY;

    // surveyId
    constructor(public payload: string) {}
}

export class SetLanguageAction implements Action<string> {
    public readonly type: string = SurveyActionTypes.SET_LANGUAGE;

    // language code
    constructor(public payload: string) {}
}

export class SurveyLoadedAction implements Action<SurveyLoadedPayload> {
    public readonly type: string = SurveyActionTypes.SURVEY_LOADED;

    constructor(public payload: SurveyLoadedPayload) {}
}

export class SurveyLoadFailedAction implements Action<any> {
    public readonly type: string = SurveyActionTypes.SURVEY_LOAD_FAILED;

    // todo | interface for error https://healthtel.atlassian.net/browse/SB-995
    constructor(public payload: any) {}
}

export class SurveyOnLoadProcessedAction implements Action {
    public readonly type: string = SurveyActionTypes.SURVEY_ON_LOAD_PROCESSED;
}

export class SurveyConfigLoadedAction implements Action<ISurveyConfig> {
    public readonly type: string = SurveyActionTypes.SURVEY_CONFIG_LOADED;

    constructor(public payload: ISurveyConfig) {}
}

export class SurveyStartAction implements Action<SurveyLoadedPayload> {
    public readonly type: string = SurveyActionTypes.SURVEY_START;

    constructor(public payload: SurveyLoadedPayload) {}
}

/* istanbul ignore next */
export class CompleteSurveyAction implements Action {
    public readonly type: string = SurveyActionTypes.COMPLETE_SURVEY;
}

export class SurveyCompletedAction implements Action {
    public readonly type: string = SurveyActionTypes.SURVEY_COMPLETED;
}

export class DisableSurveyResumeAction implements Action {
    public readonly type: string = SurveyActionTypes.DISABLE_RESUME;
}
