<div *ngIf="element" class="surveys-element-suggested-care">
    <surveys-element-html
        *ngIf="element.data?.label"
        [element]="{data: element.data.label}"
        style="display: block; margin-bottom: 20px"
    >
    </surveys-element-html>

    <surveys-render-survey-element *ngFor="let element of elements" [element]="element">
    </surveys-render-survey-element>
</div>
