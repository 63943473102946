import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {ENV_SERVICE} from '@clients/shared/config';
import {PersistenceProvider} from '@clients/shared/persistence';

@Component({
    selector: 'member-direct-invalid-route',
    templateUrl: './invalid-route.component.html',
    styleUrls: ['./invalid-route.component.scss']
})
export class InvalidRouteComponent implements OnInit {
    private pureDevMode: boolean;
    constructor(
        private persistence: PersistenceProvider,
        private router: Router,
        @Inject(ENV_SERVICE) envService: MemberDirectEnvService
    ) {
        this.pureDevMode = envService.surveySettings.pureDevMode;
    }

    ngOnInit() {
        const linkId: string = this.persistence.get('linkId');
        if (linkId) {
            this.router.navigateByUrl(`/${linkId}`);
        } else if (this.pureDevMode) {
            this.router.navigateByUrl(`/survey/devmode/start`);
        } else {
            this.router.navigateByUrl(`/error`);
        }
    }
}
