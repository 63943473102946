import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {getEnv} from '@clients/shared/config';

import {AppModule} from './app/app.module';

if (getEnv().production) {
    enableProdMode();
}
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
