<div *ngIf="control" class="r-element r-masked-textarea">
    <r-label [label]="label" [required]="required" [labelPos]="labelPos">
        <p *ngIf="placeholder" class="placeholder" id="{{ labeledby }}">{{ placeholder }}</p>
        <textarea
            #textArea
            [ngClass]="{invalid: textLength >= maxlength}"
            (input)="updateElement($event)"
            [autocomplete]="autocomplete"
            [maxlength]="maxlength"
            [formControl]="control"
            [attr.aria-describedby]="describedby"
            (keydown)="keyUp.emit($event)"
        ></textarea>
        <div class="count-text">{{ textLength }} / {{ maxlength }}</div>
    </r-label>
</div>
