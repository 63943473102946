import {Injectable} from '@angular/core';
import {
    BlockHasBeenSeenAction,
    BlockQuestionsCompleteAction,
    BlockQuestionsIncompleteAction
} from '../+state/blocks/blocks.actions';
import {getElementId} from '../+state/elements/elements.reducer';
import {SurveyElementType} from '../enum/SurveyElementType';
import {VALUE_TYPES_ENUM, withValue} from '../utilities/with-value.function';
import {ISurveyQuestion} from '../../models/survey/ISurveyQuestion';
import {SurveyElement} from '../../models/survey/ISurveyElement';
import {getBlockId} from '../+state/blocks/blocks.reducer';
import {ISurveyBlock} from '../../models/survey/ISurveyBlock';
import {Dictionary} from '@ngrx/entity';
import {MultiSelectSurveyResponse, SurveyResponse} from '../../models/survey-responses/SurveyResponse.interface';
import {Store} from '@ngrx/store';
import {BlocksFacade} from '../+state/blocks/blocks.facade';
import {get} from 'lodash';
import {ResponsesFacade} from '../+state/responses/responses.facade';
import {MultiSelectQuestionGroup} from '../../models/survey-responses/MultiSelectQuestionGroup.interface';
import {SurveyContentService} from './survey-content.service';
import {ISurveyMultipartQuestion} from '@clients/member-direct/surveys';

@Injectable({
    providedIn: 'root'
})
export class BlockStateService {
    private questionTypes: SurveyElementType[] = [
        SurveyElementType.SURVEY_QUESTION,
        SurveyElementType.MULTIPART_QUESTION
    ];

    constructor(
        private store: Store<any>,
        private blocksFacade: BlocksFacade,
        private responsesFacade: ResponsesFacade,
        private surveyContentService: SurveyContentService
    ) {}

    /**
     * Given the complexity of the block state logic, this is our only public function.
     * This function checks the active and prior block and determines if the state of either
     * has changed given the responses to and existence of questions that a user may have
     * answered in a particular way or skipped.
     */
    public update() {
        this.processActiveBlock();
        this.processLastBlock();
    }

    /**
     * Check the currently-active block and see if we already know whether
     * its state should be changed.
     */
    private processActiveBlock() {
        const activeBlock: ISurveyBlock = this.blocksFacade.getActive();

        // completion blocks are always seen and completed right away
        if (activeBlock.completed) {
            const blockId = getBlockId(activeBlock);
            this.markBlockAsSeen(blockId);
            this.markBlockAsComplete(blockId);
        }
    }

    /**
     * Check the previously-active block to see if its state should change.
     */
    private processLastBlock() {
        // sanity check one
        const priorBlockId: string = this.blocksFacade.getPreviouslyActiveId();
        if (!priorBlockId) return;

        // sanity check two
        const priorBlock: ISurveyBlock = this.blocksFacade.get(priorBlockId);
        if (!priorBlock) return;

        // the last block should always been marked as seen since we
        // know the user has just been there.
        this.markBlockAsSeen(priorBlockId);
        // either mark the prior block complete or incomplete
        this.blockIsComplete(priorBlock)
            ? this.markBlockAsComplete(priorBlockId)
            : this.markBlockAsIncomplete(priorBlockId);
    }

    private blockIsComplete(block: ISurveyBlock): boolean {
        // pull a list of all of the questions in a block
        const elements: ISurveyQuestion<any>[] = this.surveyContentService.getElementsByType(
            block.content,
            this.questionTypes
        ) as ISurveyQuestion<any>[];
        if (!elements || !elements.length) return true;

        // get the list of all available question IDs that are not multi-select
        const questionIds: string[] = this.getBasicQuestionIds(elements);

        // build the multi-select question groups
        const multiSelectQuestionGroups: MultiSelectQuestionGroup[] = this.getMultiSelectQuestionGroups(elements);

        // if there are no simple question IDs and no multiselect questions, then the
        // block is complete
        /* istanbul ignore if */
        if ((!questionIds || !questionIds.length) && !multiSelectQuestionGroups.length) return true;

        // get the list of all logged responses
        const responses = this.responsesFacade.getEntities();
        const idsOfUserProvidedResponses: string[] = Object.keys(responses);

        // check if basic and multi-select questions have been answered
        const allBasicQuestionsAreAnswered: boolean = this.allBasicQuestionsAreAnswered(
            questionIds,
            idsOfUserProvidedResponses
        );
        const allMultiSelectQuestionsAreAnswered: boolean = this.allMultiSelectQuestionsAreAnswered(
            multiSelectQuestionGroups,
            responses
        );

        return allBasicQuestionsAreAnswered && allMultiSelectQuestionsAreAnswered;
    }

    private getBasicQuestionIds(elements: SurveyElement[]): string[] {
        return elements
            .filter((ele: SurveyElement) => !ele.hidden)
            .filter(
                (ele: ISurveyQuestion<any> | ISurveyMultipartQuestion) =>
                    get(ele, 'question.type') !== SurveyElementType.MULTIPLE_SELECT
            )
            .reduce((agg: string[], ele: ISurveyQuestion<any> | ISurveyMultipartQuestion) => {
                if (ele.type === SurveyElementType.MULTIPART_QUESTION) {
                    agg = agg.concat(this.getBasicQuestionIds(ele.data));
                } else {
                    agg.push(ele.id);
                }

                return agg;
            }, []);
    }

    private getMultiSelectQuestionGroups(elements: SurveyElement[]): MultiSelectQuestionGroup[] {
        return elements
            .filter(
                (element: ISurveyQuestion<any>) => get(element, 'question.type') === SurveyElementType.MULTIPLE_SELECT
            )
            .map(
                (element: ISurveyQuestion<any>): MultiSelectQuestionGroup =>
                    this.parseMultiSelectQuestionGroupFromElement(element)
            );
    }

    private parseMultiSelectQuestionGroupFromElement(element: SurveyElement): MultiSelectQuestionGroup {
        const questionId: string = getElementId(element);
        const responseOptions = withValue(() => get(element, `question.data.options`), VALUE_TYPES_ENUM.ARRAY);
        const responseIds: string[] = withValue(
            () => responseOptions.map((option) => option.id),
            VALUE_TYPES_ENUM.ARRAY
        );

        return {
            questionId,
            responseIds
        };
    }

    /**
     * Checks all multiselect response groups to make sure at least one option from each
     * multiselect has been chosen indicating that the user has responded to all multiselect
     * questions by interacting with at least one option.
     */
    private allMultiSelectQuestionsAreAnswered(
        multiSelectQuestionGroups: MultiSelectQuestionGroup[],
        responses: Dictionary<SurveyResponse | MultiSelectSurveyResponse> = {}
    ): boolean {
        // if we don't actually have any multiselect groups then all are considered answered
        if (!multiSelectQuestionGroups || !multiSelectQuestionGroups.length) return true;

        const numberOfAnsweredMultiSelectGroups = multiSelectQuestionGroups.filter(
            (questionGroup: MultiSelectQuestionGroup) =>
                this.multiSelectQuestionHasBeenAnswered(questionGroup, responses)
        ).length;
        const totalNumberOfMultiSelectGroups = multiSelectQuestionGroups.length;

        return numberOfAnsweredMultiSelectGroups === totalNumberOfMultiSelectGroups;
    }

    private allBasicQuestionsAreAnswered(questionIds: string[], idsOfUserProvidedResponses: string[]): boolean {
        return questionIds.filter((id: string) => idsOfUserProvidedResponses.indexOf(id) === -1).length === 0;
    }

    private multiSelectQuestionHasBeenAnswered(
        questionGroup: MultiSelectQuestionGroup,
        responses: Dictionary<SurveyResponse | MultiSelectSurveyResponse>
    ): boolean {
        // if the question has no possible answered, then mark it as answered
        if (!questionGroup.responseIds.length) return true;

        let answered = false;
        for (const id of questionGroup.responseIds) {
            // if we find a response for an id, then the entire multi-select question group
            // can be considered answered. Currently this only checks if there's at least
            // one answer rather than considering validation.
            //
            // todo | support validation of min / max selections https://healthtel.atlassian.net/browse/SB-1006
            //
            if (this.multiSelectHasAnswer(id, responses)) {
                answered = true;
                break;
            }
        }

        return answered;
    }

    private multiSelectHasAnswer(
        id: string,
        responses: Dictionary<SurveyResponse | MultiSelectSurveyResponse>
    ): boolean {
        return responses[id] && responses[id].value === true;
    }

    private markBlockAsSeen(blockId: string): void {
        this.store.dispatch(new BlockHasBeenSeenAction(blockId));
    }

    private markBlockAsIncomplete(blockId: string): void {
        this.store.dispatch(new BlockQuestionsIncompleteAction(blockId));
    }

    private markBlockAsComplete(blockId: string): void {
        this.store.dispatch(new BlockQuestionsCompleteAction(blockId));
    }
}
