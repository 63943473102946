import * as faker from 'faker';

import {MultilingualData} from '@clients/member-direct/translation';

import {SurveyElement} from './ISurveyElement';

export interface ISurveyBlock {
    id: string;
    label: string;
    heading?: MultilingualData<string>;
    content: SurveyElement[];
    completed?: boolean;
    intro?: boolean;
    config?: SurveyBlockConfig;
    setId?: (id: string) => void;
    contentId?: string;
}

export interface SurveyBlockConfig {
    block?: {
        manualBlockNavigation?: {
            enabled: boolean;
            nextBlockId?: string;
        };
        display?: {
            renderInSidebarBlockNavigation?: boolean;
        };
    };
    questions?: {
        advanceAlways?: {
            enabled: boolean;
            nextBlockId?: string;
        };
        showPreview?: {
            enabled: boolean;
            previewCount?: number;
        };
        showBlockHeading?: {
            enabled: boolean;
        };
        scrollMode?: {
            enabled: boolean;
            disableQuestionSkipping?: boolean;
        };
        singleQuestionMode?: {
            enabled: boolean;
        };
        showQuestionCounter?: {
            enabled: boolean;
        };
    };
}

export class SurveyBlockCreator implements ISurveyBlock {
    public id = faker.datatype.uuid();
    public label = faker.random.words(2);
    public content = [];
    public completed: boolean;
    public intro: boolean;

    /* istanbul ignore next */
    constructor(content: SurveyElement[] = null, completed = false, intro = false) {
        this.content = content;
        this.completed = completed;
        this.intro = intro;
    }

    public setId(id: string) {
        this.id = id;
    }
}
