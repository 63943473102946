import {Component, Injector} from '@angular/core';
import {Loader} from '../loader.abstract';

@Component({
    selector: 'clients-bar-loader',
    templateUrl: './bar-loader.component.html',
    styleUrls: ['./bar-loader.component.scss']
})
export class BarLoaderComponent extends Loader {
    constructor(injector: Injector) {
        super(injector);
    }
}
