export function formatResponseQueryParams(data: any): any {
    const obj: any = {};

    // join all props by commas
    const arr = data.split(',');

    arr.forEach((item) => {
        const kvPair = item.split(':');
        obj[kvPair[0]] = kvPair[1];
    });

    return obj;
}
