import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {filter} from 'rxjs/operators';

@Injectable()
export class AppActions {
    _actions = new Subject<Action>();

    public ofType(type: string): Observable<Action> {
        return this._actions.pipe(filter(this.filterCallback(type)));
    }

    nextAction(action: Action) {
        this._actions.next(action);
    }

    private filterCallback =
        (type: string) =>
        (action: Action): boolean =>
            action.type === type;
}
