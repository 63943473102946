import {Component, Injector, Input} from '@angular/core';

import {ISurveyFootnote} from '../../../models/survey/ISurveyContent';
import {BaseElement} from '../../base-element.abstract';

@Component({
    selector: 'surveys-element-footnote',
    styleUrls: ['./element-footnote.component.scss'],
    template: ` <surveys-element-html [element]="element" [useCustomStyles]="true"></surveys-element-html> `
})
export class ElementFootnoteComponent extends BaseElement {
    @Input()
    element: ISurveyFootnote;

    /* istanbul ignore file */
    constructor(injector: Injector) {
        super(injector);
    }
}
