import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Component, OnDestroy, OnInit} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {
    COMPANY_LOGO_SIZE,
    selectSelectedSurvey,
    SetLanguageAction,
    Survey,
    SurveyConfigService,
    SurveyFacade
} from '@clients/member-direct/surveys';
import {ILanguage, TranslationService} from '@clients/member-direct/translation';
import {DROPDOWN_POSITION_ENUM, IDropdownData} from '@clients/shared/common-forms';
import {FLEX_LAYOUT_ENUM, R_BREAKPOINTS_ALIASES, WithMediaComponent} from '@clients/shared/layout-grid';
import {or} from '@clients/shared/utilities';
import {select, Store} from '@ngrx/store';

import {selectApplicationLanguage, selectNavigationQuote, selectTranslatedLogo} from '../../+state';
import {SetAppLanguageAction} from '../../+state/config/config.actions';
import {IQuoteSchema} from '../../+state/config/config.interfaces';
import {memberDirectFeatureLocalePrefix} from '../../locale/locale';

@Component({
    selector: 'member-direct-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends WithMediaComponent implements OnInit, OnDestroy {
    public survey: Observable<Survey>;
    public logo: Observable<string>;
    // todo | refactor once we fully deprecate options https://healthtel.atlassian.net/browse/SB-989
    public logoFromConfig: string;
    public language: Observable<string>;
    public logoAltText: string;
    public quote: Observable<IQuoteSchema>;
    public isMobile: boolean;
    public languageDropdownData: Array<IDropdownData>;
    public dropdownPosition: DROPDOWN_POSITION_ENUM = DROPDOWN_POSITION_ENUM.TOP;
    public layoutGap = '20px';
    public LAYOUT = FLEX_LAYOUT_ENUM;
    public mobileMenuOpen: boolean;
    public mobileMenuInstructions =
        'This menu tracks your progress. After completing a section, you can revisit it by using this menu.';

    public shouldShowNameInSidebar = false;
    public shouldShowSidebarBlockNavigation = false;
    public logoSize = COMPANY_LOGO_SIZE.small;

    private destroyed$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private store: Store<any>,
        public media: MediaObserver,
        public surveyConfigService: SurveyConfigService,
        public translationService: TranslationService,
        public surveyFacade: SurveyFacade
    ) {
        super();
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.setupMediaWatcher(media, this.handleMediaQueryUpdates.bind(this));
    }

    public handleMediaQueryUpdates(changes: MediaChange[]): void {
        this.activeMediaQuery = changes && changes[0] ? changes[0].mqAlias : '';
        this.isMobile = or(
            this.activeMediaQuery === R_BREAKPOINTS_ALIASES.XS,
            this.activeMediaQuery === R_BREAKPOINTS_ALIASES.SM
        );

        this.dropdownPosition = this.isMobile ? DROPDOWN_POSITION_ENUM.BOTTOM : DROPDOWN_POSITION_ENUM.TOP;
        this.layoutGap = this.isMobile ? '0' : '20px';
    }

    ngOnInit(): void {
        this.survey = this.store.pipe(select(selectSelectedSurvey));
        // @deprecated
        this.logo = this.store.pipe(select(selectTranslatedLogo));
        this.language = this.store.pipe(select(selectApplicationLanguage));
        this.quote = this.store.pipe(select(selectNavigationQuote));

        this.language.pipe(takeUntil(this.destroyed$)).subscribe((language: string) => {
            this.updateLogoAltText(language);
        });

        this.surveyConfigService.surveyConfigUpdated.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.updateAvailableLanguages();
            this.updateLogoAltText(this.surveyFacade.getSurveyLanguage());
            this.logoFromConfig = this.surveyConfigService.getLogo();
            this.shouldShowNameInSidebar = this.surveyConfigService.shouldShowNameInSidebar();
            this.shouldShowSidebarBlockNavigation = this.surveyConfigService.shouldShowSidebarBlockNavigation();
            this.logoSize = this.surveyConfigService.getSideBarCompanyLogoSize();
        });
    }

    onChangeHandler(value: string): void {
        this.store.dispatch(new SetLanguageAction(value));
        this.store.dispatch(new SetAppLanguageAction(value));
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    public closeMobileMenu(): void {
        this.mobileMenuOpen = false;
    }

    private updateAvailableLanguages(): void {
        this.languageDropdownData = this.surveyConfigService.getAvailableLanguages().map((language: ILanguage) => ({
            label: this.translationService.translateWithLanguageProvided(
                `${memberDirectFeatureLocalePrefix}.languageNames`,
                language.code
            ),
            value: language.code
        }));
    }

    private updateLogoAltText(language: string): void {
        this.logoAltText = this.surveyConfigService.getLogoAltText(language);
    }
}
