export enum SurveyInstructionType {
    LOAD_INTRO = 'LOAD_INTRO',
    LOAD_COMPLETE = 'LOAD_COMPLETE',
    LOAD_BLOCK_BY_ID = 'LOAD_BLOCK_BY_ID',
    SHOW_ELEMENT = 'SHOW_ELEMENT',
    HIDE_ELEMENT = 'HIDE_ELEMENT',
    DISPLAY_RELATED_QUESTION = 'DISPLAY_RELATED_QUESTION',
    HIDE_RELATED_QUESTION = 'HIDE_RELATED_QUESTION',
    HIGHLIGHT_ELEMENT = 'HIGHLIGHT_ELEMENT',
    OPEN_EXTERNAL_LINK = 'OPEN_EXTERNAL_LINK',
    SET_DATA_PROP = 'SET_DATA_PROP'
}

export const ELEMENT_VISIBILITY_SURVEY_INSTRUCTIONS = [
    SurveyInstructionType.HIDE_ELEMENT,
    SurveyInstructionType.SHOW_ELEMENT,
    SurveyInstructionType.DISPLAY_RELATED_QUESTION,
    SurveyInstructionType.HIDE_RELATED_QUESTION
];

export interface SurveyInstruction {
    type: SurveyInstructionType;
    data?: any;
    when?:
        | any
        | {
              prop: string;
              value: any;
          };
}
