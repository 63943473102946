/* istanbul ignore file */
import {ISurveyBlock} from '../../models/survey/ISurveyBlock';
import {Survey} from '../../models/survey/ISurvey';
import {Dictionary} from '@ngrx/entity/src/models';
import {get, isEmpty} from 'lodash';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SurveyElement} from '../../models/survey/ISurveyElement';
import {getElementId} from './elements/elements.reducer';
import {SurveysResponsesState} from './responses/responses.interface';

import * as fromSurvey from './survey/survey.reducer';
import * as fromBlocks from './blocks/blocks.reducer';
import * as fromElements from './elements/elements.reducer';
import * as fromResponses from './responses/responses.reducer';
import * as fromPharmacies from './pharmacies/pharmacies.reducer';
import * as fromSurveyContent from './survey-content/survey-content.reducer';

import {SurveysCombinedReducerState} from './interface';

export const selectTopLevelReducer = createFeatureSelector<SurveysCombinedReducerState>('surveys');

export const selectSurveyState = createSelector(
    selectTopLevelReducer,
    (state: SurveysCombinedReducerState) => state.survey
);
export const selectContext = createSelector(selectSurveyState, fromSurvey.getContext);

export const selectSelectedSurvey = createSelector(selectSurveyState, fromSurvey.getSurvey);
export const selectSurveyValid = createSelector(selectSurveyState, fromSurvey.getValid);
export const selectSurveyLanguage = createSelector(selectSurveyState, fromSurvey.getLanguage);
export const selectSurveyDefaultLanguage = createSelector(selectSurveyState, fromSurvey.getDefaultLanguage);
export const selectSelectedLanguage = createSelector(
    selectSurveyLanguage,
    selectSurveyDefaultLanguage,
    (userSelectedLanguage: string, defaultLanguage: string) => userSelectedLanguage || defaultLanguage
);
export const selectSurveyConfig = createSelector(selectSurveyState, fromSurvey.getConfig);
export const selectSurveyDisclaimer = createSelector(selectSelectedSurvey, (survey: Survey) => {
    const disclaimer = get(survey, 'schema.disclaimer');
    if (!disclaimer) return null;

    return disclaimer;
});

// blocks
export const selectBlocksState = createSelector(
    selectTopLevelReducer,
    (state: SurveysCombinedReducerState) => state.blocks
);
export const {
    selectIds: selectBlockIds,
    selectEntities: selectBlockEntities,
    selectAll: selectAllBlocks,
    selectTotal: selectBlockTotal
} = fromBlocks.blocksAdapter.getSelectors(selectBlocksState);

export const selectSelectedBlockId = createSelector(selectBlocksState, fromBlocks.getIdOfSelectedEntity);
export const selectPreviouslySelectedBlockId = createSelector(
    selectBlocksState,
    fromBlocks.getIdOfPreviouslySelectedEntity
);

export const selectSelectedBlock = createSelector(
    selectBlockEntities,
    selectSelectedBlockId,
    (blocks: Dictionary<ISurveyBlock>, id: string): ISurveyBlock | null => (blocks ? blocks[id] : null)
);
export const selectSeenBlocks = createSelector(selectBlocksState, fromBlocks.getSeen);
export const selectCompletedBlocks = createSelector(selectBlocksState, fromBlocks.getCompleted);
export const selectBlockIsReady = createSelector(selectBlocksState, fromBlocks.getReady);

// elements
export const selectElementsState = createSelector(
    selectTopLevelReducer,
    (state: SurveysCombinedReducerState) => state.elements
);
export const {
    selectIds: selectElementIds,
    selectEntities: selectElementEntities,
    selectAll: selectAllElements,
    selectTotal: selectElementTotal
} = fromElements.elementsAdapter.getSelectors(selectElementsState);

export const selectHidden = createSelector(selectElementsState, fromElements.getHidden);

export const selectSelectedElementId = createSelector(selectElementsState, fromElements.getIdOfSelectedEntity);

export const selectSelectedElement = createSelector(
    selectElementEntities,
    selectSelectedElementId,
    (elements: Dictionary<SurveyElement>, id: string): SurveyElement | null => (elements ? elements[id] : null)
);

export const selectVisibleElements = createSelector(
    selectAllElements,
    selectHidden,
    (elements: SurveyElement[], hidden: string[]) =>
        !hidden ? elements : elements.filter((element: SurveyElement) => !hidden.includes(getElementId(element)))
);

// responses
export const selectResponsesState = createSelector(
    selectTopLevelReducer,
    (state: SurveysCombinedReducerState) => state.responses
);
export const {
    selectIds: selectResponseIds,
    selectEntities: selectResponseEntities,
    selectAll: selectAllResponses,
    selectTotal: selectResponseTotal
} = fromResponses.responsesAdapter.getSelectors(selectResponsesState);

export const selectAllResponsesInOrder = createSelector(selectResponsesState, fromResponses.getAllResponsesInOrder);

//--MultipleSelectQuestion
export const selectMultipleSelectQuestionEntities = () =>
    createSelector(selectResponsesState, (state: SurveysResponsesState) => {
        if (!isEmpty(state.entities)) {
            return state.entities;
        }
        return [];
    });
//--MultipleSelectQuestion

export const selectResponsesComplete = createSelector(selectResponsesState, fromResponses.getComplete);

// pharmacies
export const selectPharmaciesState = createSelector(
    selectTopLevelReducer,
    (state: SurveysCombinedReducerState) => state.pharmacies
);
export const {
    selectIds: selectPharmacyIds,
    selectEntities: selectPharmacyEntities,
    selectAll: selectAllPharmacies,
    selectTotal: selectPharmacyTotal
} = fromPharmacies.pharmaciesAdapter.getSelectors(selectPharmaciesState);

export const selectPharmaciesLoadedOnce = createSelector(selectPharmaciesState, fromPharmacies.getPharmaciesLoadedOnce);

// survey content
export const selectSurveyContentState = createSelector(
    selectTopLevelReducer,
    (state: SurveysCombinedReducerState) => state.surveyContent
);

export const {
    selectIds: selectSurveyContentIds,
    selectEntities: selectSurveyContentEntities,
    selectAll: selectSurveyContent,
    selectTotal: selectSurveyContentTotal
} = fromSurveyContent.adapter.getSelectors(selectSurveyContentState);
