import {Component, Input} from '@angular/core';

import {SurveyElement} from '../../../models/survey/ISurveyElement';

@Component({
    selector: 'surveys-element-flu-mind-bender',
    templateUrl: './element-flu-mind-bender.component.html',
    styleUrls: ['./element-flu-mind-bender.component.scss']
})
export class ElementFluMindBenderComponent {
    @Input()
    public element: SurveyElement;

    constructor() {}
}
