import {Component, Injector, Input} from '@angular/core';

import {RunInstructionsAction} from '../../+state/blocks/blocks.actions';
import {ISurveyPanel} from '../../../models/survey/ISurveyContent';
import {BaseElement} from '../../base-element.abstract';

@Component({
    selector: 'surveys-element-panel',
    template: `
        <div *ngIf="element" class="surveys-element-panel" (click)="execute()">
            <surveys-element-html [element]="element"></surveys-element-html>
        </div>
    `,
    styleUrls: ['./element-panel.component.scss']
})
export class ElementPanelComponent extends BaseElement {
    @Input()
    public element: ISurveyPanel;

    /* istanbul ignore file */
    constructor(injector: Injector) {
        super(injector);
    }

    public execute() {
        this.store.dispatch(new RunInstructionsAction(this.element));
    }
}
