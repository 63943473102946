import {SurveysResponsesState} from '../responses.interface';
import {OptionType} from '../../../enum/OptionType.enum';
import {find} from 'lodash';
import {MultiSelectResponseRemoveNoneAction} from '../responses.actions';
import {Dictionary} from '@ngrx/entity';
import {MultiSelectSurveyResponse, SurveyResponse} from '../../../../models/survey-responses/SurveyResponse.interface';

export function removeNoneOptionHandler(
    responsesAdapter,
    entities: Dictionary<SurveyResponse | MultiSelectSurveyResponse>,
    action: MultiSelectResponseRemoveNoneAction,
    state: SurveysResponsesState
): SurveysResponsesState {
    const none = find(entities, {
        optionType: OptionType.NONE,
        questionId: action.payload
    });

    return none ? responsesAdapter.removeOne(none.id, state) : state;
}
