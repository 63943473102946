import {EventTrackPayload} from '@clients/shared/analytics';

const EVENT_NAMES = {
    LANGUAGE_CHANGED: 'Language Changed'
};

export class LanguageChanged implements EventTrackPayload {
    readonly eventName = EVENT_NAMES.LANGUAGE_CHANGED;
    public properties;

    constructor(language: string) {
        this.properties = {
            new_language: language
        };
    }
}
