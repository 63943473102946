import {find, findIndex, findLast, get, map} from 'lodash';

import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ScrollToConfigOptions, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';

import {ActivateBlockAction} from '../+state/blocks/blocks.actions';
import {HighlightElementAction, ScrollToElementAction} from '../+state/elements/elements.actions';
import {ElementsFacade} from '../+state/elements/elements.facade';
import {ISurveyBlock} from '../../models/survey/ISurveyBlock';
import {SurveyElement} from '../../models/survey/ISurveyElement';
import {surveysConfig} from '../config/surveys.config';
import {SurveyElementType} from '../enum/SurveyElementType';
import {BlockConfigService} from './block-config.service';
import {SurveyContentService} from './survey-content.service';

@Injectable({
    providedIn: 'root'
})
export class BlockQuestionsService {
    // the minimum distance from the top of the screen to which we will allow
    // elements to scroll.
    private minimumScrollOffset = surveysConfig.elements.scrolling.minimumScrollOffset;

    private validSurveyQuestionTypes = [SurveyElementType.SURVEY_QUESTION, SurveyElementType.MULTIPART_QUESTION];
    private validSingleQuestionSurveyQuestionTypes = [SurveyElementType.SURVEY_QUESTION];

    constructor(
        private blockConfigService: BlockConfigService,
        private store: Store<any>,
        private elementsFacade: ElementsFacade,
        private scrollToService: ScrollToService,
        private surveyContentService: SurveyContentService,
        @Inject(DOCUMENT) private document
    ) {}

    /**
     * Series of tasks to do when a block is activated.
     */
    public blockActivated(block: ISurveyBlock) {
        if (
            this.blockConfigService.shouldAlwaysAdvance(block) ||
            block.content[0].type === SurveyElementType.HTML ||
            block.content[0].type === SurveyElementType.IMAGE
        ) {
            this.highlightFirstElementInBlock(block);
        }
    }

    /**
     * Series of tasks to do when a question has been answered.
     */
    public questionAnswered(block: ISurveyBlock, questionId: string): void {
        if (this.blockConfigService.shouldAlwaysAdvance(block)) {
            this.proceedToNextQuestionOrBlock(block, questionId);
        }
    }

    public nextQuestion(block: ISurveyBlock, questionId: string): void {
        this.proceedToNextQuestionOrBlock(block, questionId);
    }

    public prevQuestion(block: ISurveyBlock, questionId: string): void {
        this.navToPreviousQuestion(block, questionId);
    }

    /**
     * Series of tasks to do when an element has been highlighted.
     */
    public elementHighlighted(block: ISurveyBlock, id: string): void {
        this.scrollToElement(id);
    }

    /**
     * Searches through the flat list of elements and highlights the first
     * in the sequence.
     */
    private highlightFirstElementInBlock(block: ISurveyBlock): void {
        //if HTML or image are highlighted, it takes removes the selected class from all questions
        //and greys them out. This is not desired behavior in preventative surveys.
        //highlighting the first question adds the selected tag to the first question
        const elements: SurveyElement[] = this.surveyContentService.getElementsByType(
            block.content,
            this.validSurveyQuestionTypes
        );
        const firstElementId: string = get(elements, '[0].id');
        if (block.content[0].type === SurveyElementType.HTML || block.content[0].type === SurveyElementType.IMAGE) {
            this.scrollToHTMLElement(block.content[0].id);
        } else {
            this.highlightElement(firstElementId);
        }
    }

    /**
     * Searches through the flat list of elements and highlights the first
     * in the sequence.
     */
    private proceedToNextQuestionOrBlock(block: ISurveyBlock, questionId: string): void {
        const validTypes: SurveyElementType[] = this.getValidQuestionTypesForBlock(block);
        const elements: SurveyElement[] = this.surveyContentService.getElementsByType(block.content, validTypes);
        const indexOfAnsweredQuestion = findIndex(elements, {id: questionId});

        const hidden = this.elementsFacade.getHidden();
        const nextVisibleQuestionId: string = this.getFirstNonHiddenQuestion(elements, indexOfAnsweredQuestion, hidden);
        const nextBlockId: string = this.getNextBlockId(block);

        if (nextVisibleQuestionId) {
            this.highlightElement(nextVisibleQuestionId);
        } else if (nextBlockId) {
            this.activateBlock(nextBlockId);
        }
    }
    /* istanbul ignore next */
    private navToPreviousQuestion(block: ISurveyBlock, questionId: string): void {
        const validTypes: SurveyElementType[] = this.getValidQuestionTypesForBlock(block);
        const elements: SurveyElement[] = this.surveyContentService.getElementsByType(block.content, validTypes);
        const indexOfAnsweredQuestion = findIndex(elements, {id: questionId});

        const hidden = this.elementsFacade.getHidden();
        const prevVisibleQuestionId: string = this.getPreviousNonHiddenQuestion(
            elements,
            indexOfAnsweredQuestion,
            hidden
        );

        if (prevVisibleQuestionId) {
            this.highlightElement(prevVisibleQuestionId);
        }
    }

    private scrollToElement(id: string): void {
        const elementId = `element-id-${id}`;
        const offset: number = this.calculateElementOffset(elementId);
        const config: ScrollToConfigOptions = {
            target: elementId,
            duration: surveysConfig.elements.scrolling.scrollDuration,
            easing: surveysConfig.elements.scrolling.easing,
            offset
        };

        this.scrollToService.scrollTo(config);
    }

    private calculateElementOffset(elementId: string): number {
        const element = this.document.getElementById(elementId);
        let elementOffset = 0;
        if (element) elementOffset = element.clientHeight;

        // ideally we'd split the difference for the question and scroll to what would be
        // approximately the same center. however, we never want to be less than 100px from the top
        // so if the proposed offset is greater than -100, we need to use -100 instead.
        const proposedOffset = (window.innerHeight * 0.33 - elementOffset / 2) * -1;

        return proposedOffset > this.minimumScrollOffset ? this.minimumScrollOffset : proposedOffset;
    }

    /**
     * This method would return the first visible question from the slice of the content
     * determined by the idx argument.
     * If there's no visible question, the returned value would be null.
     */
    private getFirstNonHiddenQuestion(elements: SurveyElement[], idx: number, hidden: string[]): string | null {
        const next = elements.slice(idx + 1);
        if (next.length === 0) return null;
        const ids = map(next, 'id');
        const firstVisible = find(ids, (id: string) => !hidden.includes(id));

        return firstVisible ? firstVisible : null;
    }

    private getPreviousNonHiddenQuestion(elements: SurveyElement[], idx: number, hidden: string[]): string | null {
        const prev = elements.slice(0, idx);
        if (prev.length === 0) return null;
        const ids = map(prev, 'id');
        const lastVisible = findLast(ids, (id: string) => !hidden.includes(id));

        return lastVisible ? lastVisible : null;
    }

    private getValidQuestionTypesForBlock(block: ISurveyBlock): SurveyElementType[] {
        return this.blockConfigService.shouldRenderInSingleQuestionMode(block)
            ? this.validSingleQuestionSurveyQuestionTypes
            : this.validSurveyQuestionTypes;
    }

    /**
     * Blocks that auto-advance must provide the ID of the subsequent block
     * to load. This function returns that ID.
     */
    private getNextBlockId(block: ISurveyBlock): string {
        return get(block.config, 'questions.advanceAlways.nextBlockId');
    }

    private scrollToHTMLElement(id: string): void {
        this.store.dispatch(new ScrollToElementAction(id));
    }

    private highlightElement(id: string): void {
        this.store.dispatch(new HighlightElementAction(id));
    }

    private activateBlock(id: string): void {
        this.store.dispatch(new ActivateBlockAction(id));
    }
}
