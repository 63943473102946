import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Injector} from '@angular/core';
import {UntilComponentDestroyed} from '@clients/shared/common-forms';
import {MemoizedSelector, select, Store} from '@ngrx/store';

import {TranslationService} from '../../services/translation.service';

export abstract class WithLanguageAbstract<S> extends UntilComponentDestroyed {
    public language: string;
    public language$: Observable<string>;
    protected store: Store<any>;
    protected translationService: TranslationService;

    protected constructor(injector: Injector, languageSelector: MemoizedSelector<S, string>) {
        super();
        this.store = injector.get(Store);
        this.translationService = injector.get(TranslationService);
        this.language$ = this.store.pipe(select(languageSelector));

        this.language$.pipe(takeUntil(this.destroyed$)).subscribe((language: string) => {
            /*
             * Handling the default language should be done in the selectors
             * */
            this.language = language;
            this.languageChanged(language);
        });
    }

    /**
     * Helper method to allow our components to pull translations out of the
     * surveys feature locale.
     * @deprecated
     */
    public translate(path): string {
        return this.translationService.translate(path);
    }

    protected abstract languageChanged(language: string): void;
}
