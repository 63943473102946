import {Component, Input} from '@angular/core';

@Component({
    selector: 'surveys-element-content-id',
    templateUrl: './element-content-id.component.html',
    styleUrls: ['./element-content-id.component.scss']
})
export class ElementContentIdComponent {
    @Input()
    public contentId: string;
}
