import {ActionReducerMap} from '@ngrx/store';
import {SurveysCombinedReducerState} from './interface';

// reducers
import * as fromSurvey from './survey/survey.reducer';
import * as fromBlocks from './blocks/blocks.reducer';
import * as fromElements from './elements/elements.reducer';
import * as fromResponses from './responses/responses.reducer';
import * as fromPharmacies from './pharmacies/pharmacies.reducer';
import * as fromSurveyContent from './survey-content/survey-content.reducer';

export const surveyFeatureReducers: ActionReducerMap<SurveysCombinedReducerState> = {
    survey: fromSurvey.surveyReducer,
    blocks: fromBlocks.blocksReducer,
    elements: fromElements.elementsReducer,
    responses: fromResponses.responsesReducer,
    pharmacies: fromPharmacies.pharmaciesReducer,
    surveyContent: fromSurveyContent.reducer
};

// initial state
import {surveyInitialState} from './survey/survey.init';
import {blocksInitialState} from './blocks/blocks.init';
import {elementsInitialState} from './elements/elements.init';
import {responsesInitialState} from './responses/responses.init';
import {pharmaciesInitialState} from './pharmacies/pharmacies.init';

export const surveyFeatureReducerState: SurveysCombinedReducerState = {
    survey: surveyInitialState,
    blocks: blocksInitialState,
    elements: elementsInitialState,
    responses: responsesInitialState,
    pharmacies: pharmaciesInitialState,
    surveyContent: fromSurveyContent.surveyContentInitialState
};

// selectors
export * from './selectors';
