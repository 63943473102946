import {Component} from '@angular/core';

@Component({
    selector: 'clients-grid-container',
    template: `
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px" fxLayoutGap.xs="0">
            <ng-content></ng-content>
        </div>
    `
})
export class ContainerComponent {
    constructor() {}
}
