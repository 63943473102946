import * as faker from 'faker';

import {MultilingualData} from '@clients/member-direct/translation';
import {getRandomEnum} from '@clients/shared/utilities';

import {SurveyElementType} from '../../lib/enum/SurveyElementType';
import {SurveyInstruction} from './ISurveyInstruction';
import {ISurveyValidator} from './ISurveyValidator';

export interface ISurveyElementBase {
    id: string;
}

export interface ISurveyElementStandalone extends ISurveyElementBase {
    type: SurveyElementType;
}

export interface ICanHaveInstructions {
    instructions?: SurveyInstruction[];
}

export interface ICanHaveNestedContent {
    content?: SurveyElement[];
    elements?: SurveyElement[];
}

export interface ICanHaveValidators {
    validators?: ISurveyValidator[];
}

export interface ICanHaveLabel {
    label?: MultilingualData<string>;
}

// our interface for elements that load dynamic content includes the
// data prop but makes it optional since an element that can load dynamic
// content may have its static data, or it may simply provide an id to
// load from the server.
export interface ICanLoadDynamicContent<T> {
    dynamicContentId?: string;
    data?: T;
}

export class SurveyElement implements ISurveyElementStandalone {
    public id: string;
    public type: SurveyElementType;
    public label?: MultilingualData<string>;
    public instructions?: SurveyInstruction[];
    public hidden?: boolean;
    public data?: any;
    public content?: SurveyElement[];
    public elements?: SurveyElement[];
    public question?: any;
    public config?;
    public dynamicContentId?: string;

    constructor(
        type: SurveyElementType = getRandomEnum(SurveyElementType),
        label: MultilingualData<string> = {
            en: faker.random.word(),
            es: faker.random.word()
        },
        data: any = null,
        instructions: SurveyInstruction[] = null,
        hidden = false,
        question: any = null,
        config: any = null,
        id: string = faker.datatype.uuid()
    ) {
        this.type = type;

        /* istanbul ignore next */
        this.label = label ? label : {en: faker.random.words(2)};

        this.instructions = instructions;
        this.data = data;
        this.id = id;
        this.hidden = hidden;
        this.question = question;
        this.config = config;
    }
}
