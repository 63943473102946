<div class="placeholder">
    <clients-grid-layout
        [layout]="LAYOUT.ROW"
        [layoutXs]="LAYOUT.COLUMN"
        [layoutSm]="LAYOUT.ROW"
        [align]="'space-between center'"
        [alignXs]="'center flex-start'"
        [alignSm]="'space-between center'"
        [layoutGap]="0"
    >
        <div><r-skeleton [size]="SKELETON_SIZE.LARGE"></r-skeleton></div>
        <div><r-skeleton [size]="SKELETON_SIZE.MID"></r-skeleton></div>
    </clients-grid-layout>
</div>
