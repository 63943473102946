import {Component, Injector, Input} from '@angular/core';

import {RunInstructionsAction} from '../../+state/blocks/blocks.actions';
import {ISurveyButton} from '../../../models/survey/ISurveyContent';
import {BaseElement} from '../../base-element.abstract';

@Component({
    selector: 'surveys-element-button',
    templateUrl: './element-button.component.html',
    styleUrls: ['./element-button.component.scss']
})
export class ElementButtonComponent extends BaseElement {
    @Input()
    public element: ISurveyButton;

    /* istanbul ignore file */
    constructor(injector: Injector) {
        super(injector);
    }

    public execute() {
        this.store.dispatch(new RunInstructionsAction(this.element));
    }
}
