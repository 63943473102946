import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IdvService} from './services/idv.service';
import {StoreModule} from '@ngrx/store';
import {idvReducer} from './+state/idv.reducer';
import {idvInitialState} from './+state/idv.init';
import {IdvVerificationComponent} from './components/idv-verification/idv-verification.component';
import {LoadingModule} from '@clients/shared/loading';
import {CommonFormsModule} from '@clients/shared/common-forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotificationsModule} from '@clients/shared/notifications';
import {RenderVerificationFieldComponent} from './components/render-verification-field/render-verification-field.component';
import {TranslationModule} from '@clients/member-direct/translation';
import {idvLocale} from './locale/locale';

@NgModule({
    imports: [
        CommonModule,
        CommonFormsModule,
        BrowserAnimationsModule.withConfig({disableAnimations: true}),

        // ngrx
        StoreModule.forFeature('idv', idvReducer, {
            initialState: idvInitialState
        }),
        LoadingModule,
        NotificationsModule,
        TranslationModule.forChild([idvLocale])
    ],
    declarations: [IdvVerificationComponent, RenderVerificationFieldComponent],
    exports: [IdvVerificationComponent],
    providers: [IdvService]
})
export class IdvModule {}
