<div *ngIf="element" class="surveys-element-input-birthdate" role="group" [attr.aria-labelledby]="element.id">
    <surveys-element-question-text
        *ngIf="multipartQuestionHeader"
        [text]="multipartQuestionHeader"
        [textSize]="'small'"
        [classes]="['multipart-label']"
    ></surveys-element-question-text>

    <label for="element.id">
        <div *ngIf="element.label" class="label">
            <surveys-element-question-text
                [id]="element.id"
                [text]="element.label[language]"
                [required]="element.required"
            ></surveys-element-question-text>
        </div>
        <div class="inputs">
            <div class="input-element birthdate">
                <r-masked-input
                    [control]="control"
                    [shouldFocus]="shouldFocus"
                    [type]="'birthdate'"
                    [placeholder]="'MM/DD/YYYY'"
                    [labeledby]="element.id"
                    (validInput)="(validForm)"
                    [describedby]="multipartQuestionId ? multipartQuestionId : null"
                >
                </r-masked-input>
            </div>
        </div>
    </label>
    <div class="button-element">
        <r-button
            *ngIf="showProceedActions()"
            class="continue-button"
            [e2e]="'button-' + element.id + '-continue'"
            [describedby]="element.id"
            [affirmative]="true"
            [negative]="false"
            (onClick)="advance()"
        >
            {{ 'surveys.navigation.continue' | translate }}
        </r-button>
    </div>
</div>
