export const LOG_NAMES = {
    // api
    SURVEY_VALIDATION_ERROR: 'survey_validation_error',
    SURVEY_LOAD_ERROR: 'survey_load_error',
    RESPONSE_SAVE_ERROR: 'response_save_error',
    PHARMACY_LOAD_ERROR: 'pharmacy_load_error',
    DELETE_QUESTION_DATA_ERROR: 'delete_question_data_error',
    CUSTOM_CONTENT_LOAD_ERROR: 'custom_content_load_error',
    THEME_FONT_LOAD_FAILED: 'theme_font_load_failed'
};
