import {Component, Injector, OnInit} from '@angular/core';
import {BaseElement} from '../../base-element.abstract';
import {Observable} from 'rxjs';
import {selectSurveyDisclaimer} from '../../+state';
import {select} from '@ngrx/store';
import {MultilingualData} from '@clients/member-direct/translation';
import {isObject} from '@clients/shared/utilities';

@Component({
    selector: 'surveys-element-disclaimer',
    templateUrl: './element-disclaimer.component.html',
    styleUrls: ['./element-disclaimer.component.scss']
})
export class ElementDisclaimerComponent extends BaseElement implements OnInit {
    public disclaimer: Observable<MultilingualData<string>>;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.disclaimer = this.store.pipe(select(selectSurveyDisclaimer));
    }

    /**
     * Returns true if disclaimer is defined, not null,
     * not a string and not an array and has at least
     * one defined language as a key.
     */
    public hasDisclaimer(disclaimer: MultilingualData<string>): boolean {
        if (!disclaimer || typeof disclaimer === 'symbol' || !isObject(disclaimer, true)) return false;

        return Object.keys(disclaimer).length > 0;
    }
}
