import {Component, Injector, OnInit} from '@angular/core';
import {inputMasks, monthPipe} from '@clients/shared/common-forms';
import {InputBase} from '../../input-base.abstract';

@Component({
    selector: 'surveys-element-input-month',
    templateUrl: './element-input-month.component.html',
    styleUrls: ['./element-input-month.component.scss']
})
export class ElementInputMonthComponent extends InputBase<string | number> implements OnInit {
    public monthMask = inputMasks.month;
    public monthPipe = monthPipe;

    /* istanbul ignore next */
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        this.enableAutoSave();
    }

    protected transformBeforeDisplay(value: number): string {
        return value ? value.toString() : '';
    }

    protected transformBeforeSave(value: string): number {
        return value ? parseInt(value, 10) : undefined;
    }
}
