import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {selectSelectedBlock, selectSelectedSurvey, selectVisibleElements} from '../../+state';
import {SurveysCombinedReducerState} from '../../+state/interface';
import {Survey} from '../../../models/survey/ISurvey';
import {select, Store} from '@ngrx/store';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {ISurveyBlock} from '../../../models/survey/ISurveyBlock';

@Component({
    selector: 'surveys-render-survey',
    templateUrl: './render-survey.component.html',
    styleUrls: ['./render-survey.component.scss']
})
export class RenderSurveyComponent implements OnInit {
    public survey: Observable<Survey>;
    public block: Observable<ISurveyBlock>;

    // todo | redo this so that each element controls its own destiny https://healthtel.atlassian.net/browse/SB-967
    public visibleElements: Observable<SurveyElement[]>;

    constructor(private store: Store<SurveysCombinedReducerState>) {}

    ngOnInit() {
        this.survey = this.store.pipe(select(selectSelectedSurvey));
        this.block = this.store.pipe(select(selectSelectedBlock));
        this.visibleElements = this.store.pipe(select(selectVisibleElements));
    }
}
