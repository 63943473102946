export const auth0LockOptions: Auth0LockConstructorOptions = {
    auth: {
        responseType: 'code',
        redirect: true,
        params: {
            scope: 'openid profile'
        }
    },
    configurationBaseUrl: 'https://cdn.auth0.com',
    theme: {
        logo: `/assets/images/icario_2c_oceanTeal_RGB.png`,
        primaryColor: '#AC9FB9'
    },
    languageDictionary: {
        forgotPasswordAction: 'Forgot your password?',
        emailInputPlaceholder: 'your@example.com',
        passwordInputPlaceholder: 'password'
    },
    closable: false,
    rememberLastLogin: false,
    loginAfterSignUp: false,
    allowSignUp: false,
    avatar: null
};

const auth0 = {
    clientId: 'SkMudHadA5SpYblZ',
    domain: 'revdev.com',
    redirectUrl: 'http://localhost:4200/callbacks/auth0',
    audience: 'https://revel-admin-api.revel-health.com'
};

const lockOptions = {
    ...auth0LockOptions,
    auth: {
        ...auth0LockOptions.auth,
        redirectUrl: auth0.redirectUrl,
        audience: auth0.audience
    }
};

export const AUTH0_CONFIG_MOCK = {
    auth: auth0,
    lockOptions: lockOptions
};
