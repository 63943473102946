<div *ngIf="control" class="r-element r-masked-input">
    <r-label
        [label]="label"
        [required]="required"
        [labelPos]="labelPos"
        [attr.label]="type === 'tel' ? 'Phone Number' : type"
    >
        <p *ngIf="placeholder" class="placeholder" id="{{ labeledby }}">{{ placeholder }}</p>
        <input
            *ngIf="type === 'text'"
            matInput
            [type]="type"
            (input)="updateElement($event)"
            [ngClass]="{invalid: textLength >= maxlength}"
            [maxlength]="maxlength"
            [formControl]="control"
            [attr.aria-describedby]="describedby"
            (keydown)="keyUp.emit($event)"
        />
        <input
            *ngIf="type === 'year'"
            [ngClass]="{invalid: !validInput}"
            mask="0000"
            (input)="checkYear($event)"
            [formControl]="control"
            [attr.aria-describedby]="describedby"
            (keydown)="keyUp.emit($event)"
        />
        <input
            *ngIf="type === 'weight'"
            mask="000"
            [formControl]="control"
            [attr.aria-describedby]="describedby"
            (keydown)="keyUp.emit($event)"
        />
        <input
            *ngIf="type === 'email'"
            matInput
            [ngClass]="{invalid: !validInput}"
            type="email"
            (input)="checkEmail($event)"
            [formControl]="control"
            [attr.aria-describedby]="describedby"
            (keydown)="keyUp.emit($event)"
        />
        <input
            *ngIf="type === 'month'"
            [ngClass]="{invalid: !validInput}"
            mask="M0"
            [formControl]="control"
            [attr.aria-describedby]="describedby"
            (keydown)="keyUp.emit($event)"
        />
        <input
            *ngIf="type === 'tel'"
            matInput
            [ngClass]="{invalid: !validInput}"
            type="tel"
            mask="(000) 000-0000"
            (input)="checkPhone($event)"
            [formControl]="control"
            [attr.aria-describedby]="describedby"
            label="Phone Number"
            (keydown)="keyUp.emit($event)"
        />
        <input
            *ngIf="type === 'birthdate'"
            matInput
            [ngClass]="{invalid: !validInput}"
            mask="M0/d0/0000"
            [formControl]="control"
            (input)="checkBirthdate($event)"
            [dropSpecialCharacters]="false"
            [attr.aria-describedby]="describedby"
            (keyup)="keyUp.emit($event)"
        />
    </r-label>
    <div class="count-text" *ngIf="maxlength && type === 'text'">{{ textLength }} / {{ maxlength }}</div>
</div>
