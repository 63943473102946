/**
 * Matt Blum <matt.blum@revel-health.com>
 * 03/06/2019
 *
 * If you're going to add to this list, please do the following:
 * 1. Make sure we actually need a new survey element type
 * 2. Define an interface for it in /libs/surveys/src/models/survey/SurveyContent.interface.ts
 * 3. If your element type can have nested content of some sort, please add a function to the
 *    nested content map in /libs/surveys/src/lib/services/survey-content.service.ts
 * 4. If you find anything else that's missing from this list, please add it.
 */
export enum SurveyElementType {
    // input containers
    SURVEY_QUESTION = 'SURVEY_QUESTION',
    // user input
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    MULTIPLE_SELECT = 'MULTIPLE_SELECT',
    YES_NO = 'YES_NO',
    // text inputs
    INPUT_BIRTHDATE = 'INPUT_BIRTHDATE',
    INPUT_EMAIL = 'INPUT_EMAIL',
    INPUT_HEIGHT = 'INPUT_HEIGHT',
    INPUT_WEIGHT = 'INPUT_WEIGHT',
    INPUT_YEAR = 'INPUT_YEAR',
    INPUT_MONTH = 'INPUT_MONTH',
    INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
    INPUT_TEXT = 'INPUT_TEXT',
    INPUT_LONG_TEXT = 'INPUT_LONG_TEXT',
    // content
    CONTAINER = 'CONTAINER',
    HTML = 'HTML',
    IMAGE = 'IMAGE',
    BUTTON = 'BUTTON',
    ACCORDION_GROUP = 'ACCORDION_GROUP',
    ACCORDION = 'ACCORDION',
    STATS = 'STATS',
    FOOTNOTE = 'FOOTNOTE',
    MULTIPART_QUESTION = 'MULTIPART_QUESTION',
    ROW = 'ROW',
    PANEL = 'PANEL',

    // custom
    CUSTOM = 'CUSTOM'
}
