<div
    class="surveys-element-survey-question"
    inViewport
    [inViewportOptions]="inViewportOptions"
    (inViewportChange)="onInViewportChange($event)"
    (click)="activateQuestion()"
>
    <div
        *ngIf="valid"
        class="valid"
        [class.selected]="hasSelectedState && selected"
        [class.deselected]="hasSelectedState && !selected"
        [class.always-display-as-selected]="shouldAlwaysDisplayAsSelected"
        [attr.aria-disabled]="hasSelectedState && !selected"
    >
        <div class="element element-id-{{ element.id }}" [ngSwitch]="questionElement.type">
            <surveys-element-yes-no
                *ngSwitchCase="types.YES_NO"
                [element]="questionElement"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-yes-no>

            <surveys-element-multiple-choice
                *ngSwitchCase="types.MULTIPLE_CHOICE"
                [element]="questionElement"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-multiple-choice>

            <surveys-element-multiple-select
                *ngSwitchCase="types.MULTIPLE_SELECT"
                [element]="questionElement"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-multiple-select>

            <surveys-element-input-birthdate
                *ngSwitchCase="types.INPUT_BIRTHDATE"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-birthdate>

            <surveys-element-input-email
                *ngSwitchCase="types.INPUT_EMAIL"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-email>

            <surveys-element-input-height
                *ngSwitchCase="types.INPUT_HEIGHT"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-height>

            <surveys-element-input-weight
                *ngSwitchCase="types.INPUT_WEIGHT"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-weight>

            <surveys-element-input-year
                *ngSwitchCase="types.INPUT_YEAR"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-year>

            <surveys-element-input-month
                *ngSwitchCase="types.INPUT_MONTH"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-month>

            <surveys-element-input-phone-number
                *ngSwitchCase="types.INPUT_PHONE_NUMBER"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-phone-number>

            <surveys-element-input-text
                *ngSwitchCase="types.INPUT_TEXT"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-text>

            <surveys-element-input-long-text
                *ngSwitchCase="types.INPUT_LONG_TEXT"
                [element]="questionElement"
                [selected]="selected"
                [multipartQuestionHeader]="multipartQuestionHeader"
                (answered)="questionAnswered()"
            >
            </surveys-element-input-long-text>
        </div>
    </div>

    <div *ngIf="!valid" class="invalid">Invalid question type.</div>
</div>
