import {Component, EventEmitter, Input, Output} from '@angular/core';

import {GeoJsonDataPoint} from '../../../models/mapping/GeoJsonDataPoint.interface';

@Component({
    selector: 'surveys-element-pharmacy-list',
    templateUrl: './element-pharmacy-list.component.html',
    styleUrls: ['./element-pharmacy-list.component.scss']
})
export class ElementPharmacyListComponent {
    @Input()
    public pharmacies: GeoJsonDataPoint[];
    @Input()
    public dataLoaded: boolean;

    @Output()
    public enteringZipCode: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}
}
