import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {LoadingEffects} from './+state/loading.effects';
import {loadingInitialState} from './+state/loading.init';
import {loadingReducer} from './+state/loading.reducer';
import {BarLoaderComponent} from './components/bar-loader/bar-loader.component';
import {CircleLoaderComponent} from './components/circle-loader/circle-loader.component';

const LOADERS = [CircleLoaderComponent, BarLoaderComponent];

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('loading', loadingReducer, {
            initialState: loadingInitialState
        }),
        EffectsModule.forFeature([LoadingEffects]),
        MatProgressSpinnerModule
    ],
    declarations: [LOADERS],
    exports: [LOADERS]
})
export class LoadingModule {}
