import {Component, Input, OnInit} from '@angular/core';

export enum BANNER_TEXT_POSITION_ENUM {
    left = 'left',
    right = 'right'
}

@Component({
    selector: 'r-banner',
    template: `
        <div [ngClass]="'banner ' + textPositionClass">
            <h2 *ngIf="text">{{ text }}</h2>
            <img [src]="imageSrc" *ngIf="imageSrc" [alt]="imageAltText" />
        </div>
    `,
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    @Input()
    imageSrc?: string;

    @Input()
    imageAltText?: string;

    @Input()
    text?: string;

    @Input()
    textPosition?: BANNER_TEXT_POSITION_ENUM = BANNER_TEXT_POSITION_ENUM.left;

    public textPositionClass: string;

    constructor() {}

    ngOnInit() {
        this.textPositionClass = BANNER_TEXT_POSITION_ENUM[this.textPosition];
    }
}
