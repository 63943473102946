import {EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {SurveyContent} from './survey-content.model';
import {SurveyContentActions, SurveyContentActionTypes} from './survey-content.actions';
import {SurveyContentState} from './survey-content.interface';

export const adapter: EntityAdapter<SurveyContent> = createEntityAdapter<SurveyContent>();

export const surveyContentInitialState: SurveyContentState = {
    ids: [],
    entities: {}
};

export function reducer(state: SurveyContentState, action: SurveyContentActions): SurveyContentState {
    switch (action.type) {
        case SurveyContentActionTypes.LoadSurveyContents: {
            return adapter.removeMany(action.payload.ids, state);
        }

        // case SurveyContentActionTypes.AddSurveyContent: {
        //     return adapter.addOne(action.payload.surveyContent, state);
        // }

        // case SurveyContentActionTypes.UpsertSurveyContent: {
        //     return adapter.upsertOne(action.payload.surveyContent, state);
        // }

        // case SurveyContentActionTypes.AddSurveyContents: {
        //     return adapter.addMany(action.payload.surveyContents, state);
        // }
        //

        case SurveyContentActionTypes.UpsertSurveyContents: {
            return adapter.upsertMany(action.payload.surveyContents, state);
        }

        //
        // case SurveyContentActionTypes.UpdateSurveyContent: {
        //     return adapter.updateOne(action.payload.surveyContent, state);
        // }
        //
        // case SurveyContentActionTypes.UpdateSurveyContents: {
        //     return adapter.updateMany(action.payload.surveyContents, state);
        // }
        //
        // case SurveyContentActionTypes.DeleteSurveyContent: {
        //     return adapter.removeOne(action.payload.id, state);
        // }
        //
        // case SurveyContentActionTypes.DeleteSurveyContents: {
        //     return adapter.removeMany(action.payload.ids, state);
        // }
        //
        // case SurveyContentActionTypes.ClearSurveyContents: {
        //     return adapter.removeAll(state);
        // }

        default: {
            return state;
        }
    }
}
