import {Component, Input, OnInit} from '@angular/core';
import {GeoJsonDataPoint} from '../../../models/mapping/GeoJsonDataPoint.interface';
import {FLEX_LAYOUT_ENUM} from '@clients/shared/layout-grid';
import {Address} from '@clients/shared/common-forms';
import {formatPharmacyAddress} from '../../utilities/format-pharmacy-address.function';
import {formatPharmacyPhoneNumber} from '../../utilities/format-pharmacy-phone-number.function';
import {get} from 'lodash';

@Component({
    selector: 'surveys-element-pharmacy',
    templateUrl: './element-pharmacy.component.html',
    styleUrls: ['./element-pharmacy.component.scss']
})
export class ElementPharmacyComponent implements OnInit {
    @Input()
    pharmacy: GeoJsonDataPoint;
    public address: Address;
    public phoneNumber: string;
    public LAYOUT = FLEX_LAYOUT_ENUM;

    constructor() {}

    ngOnInit() {
        this.address = formatPharmacyAddress(get(this.pharmacy, 'properties.streetAddress'));
        this.phoneNumber = formatPharmacyPhoneNumber(get(this.pharmacy, 'properties.primaryPhoneNumber'));
    }
}
