import {concat, EMPTY, from, of} from 'rxjs';
import {concatMap, map, tap} from 'rxjs/operators';

import {Inject, Injectable} from '@angular/core';
import {MemberDirectEnvService} from '@clients/member-direct/survey-common';
import {EventTrackAction} from '@clients/shared/analytics';
import {ENV_SERVICE} from '@clients/shared/config';
import {EmptyAction, isNotNullOrUndefined} from '@clients/shared/utilities';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';

import {ISurveyBlock} from '../../../models/survey/ISurveyBlock';
import {SurveyElement} from '../../../models/survey/ISurveyElement';
import {SurveyLoadedPayload} from '../../../models/survey/ISurveyLoadedPayload';
import {ContentBlockViewed} from '../../analytics/blocks.events';
import {ContentTracking} from '../../analytics/content-tracking.class';
import {BlockService} from '../../services/block.service';
import {SurveyContentService} from '../../services/survey-content.service';
import {SurveyInstructionsService} from '../../services/survey-instructions.service';
import {getElementId} from '../elements/elements.reducer';
import {ResponsesFacade} from '../responses/responses.facade';
import {LoadSurveyContents} from '../survey-content/survey-content.actions';
import {CompleteSurveyAction, SurveyActionTypes, SurveyLoadedAction} from '../survey/survey.actions';
import {
    ActivateBlockAction,
    BlockActivatedAction,
    BlockNotReadyAction,
    BlockReadyAction,
    BlocksActionTypes,
    BlocksLoadedAction,
    LoadIntroBlockAction,
    NextQuestionAction,
    PrevQuestionAction,
    RehydrateBlocksAction,
    RunInstructionsAction
} from './blocks.actions';
import {BlocksFacade} from './blocks.facade';
import {getBlockId} from './blocks.reducer';

@Injectable()
export class BlocksEffects {
    /**
     * After any onLoad instructions are processed, either load the
     * intro block or if one is already loaded, do nothing.
     */
    surveyOnLoadProcessed = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.SURVEY_ON_LOAD_PROCESSED),
            map(() => {
                if (this.pureDevMode && this.activeBlock) {
                    return new ActivateBlockAction(this.activeBlock);
                }

                const currentlyActiveBlockId = this.blocksFacade.getActiveId();
                if (isNotNullOrUndefined(currentlyActiveBlockId)) return new EmptyAction();

                return new LoadIntroBlockAction();
            })
        )
    );

    surveyLoaded = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActionTypes.SURVEY_LOADED),
            map((action: SurveyLoadedAction) => action.payload),
            map((survey: SurveyLoadedPayload) => new BlocksLoadedAction(survey.schema.blocks))
        )
    );

    loadIntroBlock = createEffect(() =>
        this.actions$.pipe(
            ofType(BlocksActionTypes.LOAD_INTRO),
            map(() => this.loadIntroBlockHandler())
        )
    );

    activateBlock = createEffect(() =>
        this.actions$.pipe(
            ofType(BlocksActionTypes.ACTIVATE_BLOCK),
            concatMap((action: ActivateBlockAction) => this.activateBlockHandler(action))
        )
    );

    navToNextQuestion = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BlocksActionTypes.NEXT_QUESTION),
                tap((action: NextQuestionAction) => this.nextQuestionHandler(action))
            ),
        {dispatch: false}
    );

    navToPrevQuestion = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BlocksActionTypes.PREV_QUESTION),
                tap((action: PrevQuestionAction) => this.prevQuestionHandler(action))
            ),
        {dispatch: false}
    );

    /**
     * Make sure that when a block is activated, it actually exists. If it does not,
     * then our client state is bad and we need to load the intro block as a fallback.
     * @type {Observable<any>}
     */
    blockActivated = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BlocksActionTypes.BLOCK_ACTIVATED),
                tap((action: BlockActivatedAction) => this.blockActivatedHandler(action))
            ),
        {dispatch: false}
    );

    /**
     * @type {Observable<any>}
     * @test in blocks.effects.no-dispatch.spec.ts
     */
    runInstructions = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BlocksActionTypes.RUN_INSTRUCTIONS),
                map((action: RunInstructionsAction) => action.payload),
                map((element: SurveyElement) => this.runInstructionsHandler(element))
            ),
        {dispatch: false}
    );

    /**
     * For some on the rehydration data we need to actually dispatch an action so that
     * the other listeners can know what happened.
     * @type {Observable<void>}
     */
    rehydrate = createEffect(
        () =>
            this.actions$.pipe(
                ofType(BlocksActionTypes.REHYDRATE),
                map((action: RehydrateBlocksAction) => action.payload),
                map((savedData: any) => this.rehydrateHandler(savedData))
            ),
        {dispatch: false}
    );

    private pureDevMode: boolean;
    private activeBlock: string;
    constructor(
        private actions$: Actions,
        private blocksFacade: BlocksFacade,
        private responsesFacade: ResponsesFacade,
        private surveyInstructionsService: SurveyInstructionsService,
        private store: Store<any>,
        private blockService: BlockService,
        private surveyContentService: SurveyContentService,
        @Inject(ENV_SERVICE) envService: MemberDirectEnvService
    ) {
        this.pureDevMode = envService.surveySettings?.pureDevMode;
        this.activeBlock = envService.surveySettings?.activeBlock;
    }

    private nextQuestionHandler(action: NextQuestionAction) {
        this.blockService.nextQuestion(this.blocksFacade.getActive(), action.payload);
    }

    private prevQuestionHandler(action: PrevQuestionAction) {
        this.blockService.prevQuestion(this.blocksFacade.getActive(), action.payload);
    }

    private loadIntroBlockHandler(): ActivateBlockAction {
        let intro = this.blocksFacade.find({intro: true});
        if (!intro) intro = this.blocksFacade.getArray()[0];

        return intro ? new ActivateBlockAction(getBlockId(intro)) : ({type: 'NULL'} as unknown as ActivateBlockAction);
    }

    private activateBlockHandler(action: ActivateBlockAction) {
        // get the block that is being activated
        const block: ISurveyBlock = this.blocksFacade.get(action.payload);
        let dynamicIds: string[] = [];
        // determine whether block has dynamic content that needs to be loaded
        if (block && 'content' in block) {
            dynamicIds = this.surveyContentService.getCustomContentIds(block.content);
        }
        const hasDynamicContent: boolean = dynamicIds && dynamicIds.length > 0;

        // do analytics
        this.doBlockAnalytics(this.blocksFacade.getPreviouslyActiveId(), block);

        return concat(
            // always return the BlockActivatedQuestion
            of(new BlockActivatedAction(block)),

            // if there is a block and its completed, then return that
            // action otherwise do nothing.
            block && block.completed ? of(new CompleteSurveyAction()) : EMPTY,

            // if the block has elements which have dynamic content then return
            // related actions, otherwise return the BlockReady action so that
            // the page renders.
            from(
                hasDynamicContent
                    ? [new LoadSurveyContents({ids: dynamicIds}), new BlockNotReadyAction()]
                    : [new BlockReadyAction()]
            )
        );
    }

    private blockActivatedHandler(action: BlockActivatedAction): void {
        const blocks = this.blocksFacade.getEntities();
        const active = this.blocksFacade.getActiveId();
        if (!active || !blocks || !(active in blocks)) {
            this.store.dispatch(new LoadIntroBlockAction());
            return;
        }

        this.blockService.blockActivated(action.payload);

        // begin tracking time viewing this block
        ContentTracking.startContentTimer(active);
    }

    private doBlockAnalytics(oldBlockId: string, currentBlock: ISurveyBlock): void {
        // if there's an old block ID, retrieve its time and send the
        // ContentBlockViewed event through our analytics system
        if (oldBlockId && currentBlock && oldBlockId !== getBlockId(currentBlock)) {
            const timeOnOldBlock = ContentTracking.stopContentTimer(oldBlockId);
            this.store.dispatch(new EventTrackAction(new ContentBlockViewed(oldBlockId, timeOnOldBlock)));
        }
    }

    private runInstructionsHandler(element: SurveyElement): void {
        if (!element || !element.instructions) return;

        const instructions = element.instructions;
        const elementId = getElementId(element);
        this.surveyInstructionsService.process(instructions, elementId);
    }

    private rehydrateHandler(savedData: any): void {
        savedData.idOfSelectedEntity && this.store.dispatch(new ActivateBlockAction(savedData.idOfSelectedEntity));
    }
}
