import {Component, Input} from '@angular/core';

@Component({
    selector: 'surveys-element-counter',
    styleUrls: ['./element-counter.component.scss'],
    template: ` <div class="surveys-element-counter">{{ count | number }}</div> `
})
export class ElementCounterComponent {
    @Input()
    count: number;

    // have to do this for Typescript. not sure why.
    public locale;

    constructor() {}
}
