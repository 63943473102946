import {filter as _filter, some as _some} from 'lodash';

import {isObject} from '@clients/shared/utilities';
import {Dictionary} from '@ngrx/entity';

import {ISurveyResponse} from '../../models/survey-responses/SurveyResponse.interface';
import {SurveyInstruction} from '../../models/survey/ISurveyInstruction';

export function instructionShouldRun(
    instruction: SurveyInstruction,
    responses: Dictionary<ISurveyResponse>,
    invokingElementId: string | number = null
): boolean {
    let prop: string | number;
    let instructionValues: Array<any>;

    // no condition was specified
    if (!('when' in instruction)) return true;

    // if when is a complex condition
    if (isObject(instruction.when, true)) {
        // then the prop is set within the when object
        prop = instruction.when.prop;
        // as is the value we're looking for
        instructionValues = Array.isArray(instruction.when.value)
            ? [...instruction.when.value]
            : [instruction.when.value];
    } else {
        // prop is implied to be the invoking element id
        prop = invokingElementId;
        // and then matching value is the when property
        instructionValues = [instruction.when];
    }

    const allResponses = _filter(responses, (v) => v.questionId === prop);

    // case for when we want to run instruction for an unanswered question
    if (_some(instructionValues, (v) => v === null) && allResponses.length === 0) {
        return true;
    }

    return _some(instructionValues, (v) => _some(allResponses, (r) => r.answerId === v || r.value === v));
}
