import {Dictionary} from '@ngrx/entity';
import {SurveyResponse} from '../../models/survey-responses/SurveyResponse.interface';
import {isObject} from '@clients/shared/utilities';

export function markResponseEntitiesAsRecorded(entities: Dictionary<SurveyResponse>): Dictionary<SurveyResponse> {
    const responses = {};

    if (!entities || !isObject(entities)) return responses;

    Object.keys(entities).forEach((key: string) => {
        responses[key] = {
            ...entities[key],
            recorded: true
        };
    });

    return responses;
}
